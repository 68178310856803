import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import store from '@/store/store'
import { computed, ComputedRef } from 'vue'

interface ReturnUseCompany {
  defaultStage: ComputedRef<boolean>
}

export default function useCompany(): ReturnUseCompany {
  const defaultStage: ComputedRef<boolean> = computed(
    () => store.getters[`${ ModuleNamespaces.AUTH }/getCompanyDefaultStage`]
  )
  return {
    defaultStage
  }
}
