<template lang="pug">

  div(class="edit-template-container")

    LfHeaderTitle(
      :title="getHeaderTitle"
      class="header-title"
      :buttons="buttons"
      :executeAction="onExecuteAction"
    )

    section(v-if="selectedTemplateData" class="edit-template-section-container")

      //- SUBJECT
      div(class="subject-container")
        span(class="text-label") {{ text.subject }}
        div(class="fake-dropdown-subject-container")
          button(
            ref="fakeDropdownSubject"
            class="fake-dropdown-subject-button"
            @click="toggleFakeDropdownSubject"
          ) {{ text.insertTags }}
            i(:class="['angle-icon', renderAngleIcon]")
          ul(v-if="isOpenFakeDropdownSubject" class="fake-dropdown-subject-content")
            li(
              v-for="(tag, index) in selectedTemplateData.tags.subject"
              :key="index"
              @click="addSubjectTag(tag.tag)"
            ) {{ tag.label }}
          input(ref="subjectTextInput" v-model="selectedTemplateData.subject"  class="subject-text")

      //- BODY
      div(class="body-container")
        span(class="text-label") {{ text.body }}
        ejs-richtexteditor(
          ref="richtexteditor"
          class="rich-text-editor"
          :height="500"
          v-model="selectedTemplateData.body"
          :toolbarSettings="toolbarSettings"
          :created="onCreateRichTextEditor"
          :actionComplete="removeDefaultContentClasses"
          :blur="removeDefaultContentClasses"
        )

    SpinnerLayerComponent(v-else class="spinner-layer")

</template>

<script lang="ts">
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'
import {
  RichTextEditorComponent,
  Toolbar,
  Link,
  Image,
  QuickToolbar,
  HtmlEditor
} from '@syncfusion/ej2-vue-richtexteditor'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { Action } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import {
  EmailTemplateData,
  EmailTemplate
} from '@/components/maintenance/customerPortal/types/maintenanceCustomerPortalTypes'
import LfHeaderTitle from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'
import { ActionBarButton } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'

const maintenanceModule = ModuleNamespaces.MAINTENANCE

@Component({
  components: {
    SpinnerLayerComponent,
    'ejs-richtexteditor': RichTextEditorComponent,
    LfHeaderTitle
  },
  provide: {
    richtexteditor: [Toolbar, Link, Image, QuickToolbar, HtmlEditor]
  }
})
export default class EditTemplateComponent extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  template!: EmailTemplate

  @Action('fetchEmailTemplate', { namespace: maintenanceModule })
  fetchEmailTemplateAction: (id: string) => Promise<EmailTemplateData>

  @Action('saveEmailTemplate', { namespace: maintenanceModule })
  saveEmailTemplateAction: (emailTemplate: EmailTemplate) => Promise<void>

  $refs!: {
    fakeDropdownSubject: HTMLElement
    richtexteditor: InstanceType<typeof RichTextEditorComponent>
    subjectTextInput: HTMLInputElement
  }

  isOpenFakeDropdownSubject = false

  text = {
    body: this.$t('components.edit_template.body'),
    editTemplate: this.$t('components.file_manager.actions.edit_template'),
    insertTags: this.$t('components.edit_template.insert_tags'),
    subject: this.$t('components.edit_template.subject'),
    buttons: {
      cancel: this.$t('action_buttons.cancel'),
      save: this.$t('action_buttons.save')
    }
  }

  fakeDropdownTags = {
    body: 'body_tags',
    subject: 'subject_tags'
  }

  fakeDropdown: { container: Element | null; content: Element | null; button: Element | null } = {
    container: null,
    content: null,
    button: null
  }

  icons = {
    angleDown: 'lf-icon-angle-down',
    angleUp: 'lf-icon-angle-up',
  }

  isExpandedFakeDropdownSubject = false

  selectedTemplateData: EmailTemplateData | null = null

  toolbarSettings = {
    items: [
      'Bold',
      'Italic',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      {
        undo: true,
        template: `
          <div class="fake-dropdown-body-container">
            <button class="fake-dropdown-body-button">${this.text.insertTags}
              <i class="angle-icon ${this.renderAngleIcon}"></i>
            </button>
            <ul class="fake-dropdown-body-content hide" id="${this.fakeDropdownTags.body}"></ul>
          </div>
        `
      }
    ]
  }

  get renderAngleIcon() {
    const { angleDown, angleUp } = this.icons
    return this.isExpandedFakeDropdownSubject ? angleUp : angleDown
  }

  get getHeaderTitle() {
    return `${this.template.name}`
  }

  get buttons(): ActionBarButton[] {
    return [
      {
        tooltip: this.text.buttons.cancel,
        class: 'red-color',
        action: ActionName.CLOSE,
        enabled: true,
        variant: LabelButtonVariant.OUTLINED
      },
      {
        tooltip: this.text.buttons.save,
        class: 'green-color',
        action: ActionName.SAVE,
        enabled: true
      }
    ]
  }

  @Emit()
  onCloseForm() {
    this.removeDefaultContentClasses()
  }

  created() {
    const closeFunctions = [this.closeOnClickOutsideBodyFakeDropdown, this.closeOnClickOutsideSubjectFakeDropdown]
    closeFunctions.forEach((closeFunction) => window.addEventListener('click', closeFunction))
  }

  mounted() {
    this.setSelectedTemplateData()
  }

  destroyed() {
    const listeners = [
      this.closeOnClickOutsideBodyFakeDropdown,
      this.closeOnClickOutsideSubjectFakeDropdown,
      this.showOrHideBodyTagList
    ]
    listeners.forEach((listener) => window.removeEventListener('click', listener))
  }

  async setSelectedTemplateData() {
    this.selectedTemplateData = await this.fetchEmailTemplateAction(this.template.id)
  }

  toggleFakeDropdownSubject() {
    this.isExpandedFakeDropdownSubject = !this.isExpandedFakeDropdownSubject
    this.isOpenFakeDropdownSubject = !this.isOpenFakeDropdownSubject
  }

  addSubjectTag(tag: string) {
    const input = this.$refs.subjectTextInput
    const cursorPosition = input.selectionStart || 0

    const { subject } = this.selectedTemplateData as EmailTemplateData
    const newText = subject.slice(0, cursorPosition) + tag + subject.slice(cursorPosition)

    this.selectedTemplateData!.subject = newText

    const newCursorPosition = cursorPosition + tag.length
    input.setSelectionRange(newCursorPosition, newCursorPosition)
    input.focus()
  }

  closeOnClickOutsideSubjectFakeDropdown(event: Event) {
    const eventTargetNode = event.target as Node
    if (this.$refs.fakeDropdownSubject && !this.$refs.fakeDropdownSubject.contains(eventTargetNode)) {
      this.isOpenFakeDropdownSubject = false
      this.isExpandedFakeDropdownSubject = false
    }
  }

  closeOnClickOutsideBodyFakeDropdown(event: Event) {
    this.fakeDropdown.container = document.querySelector('.fake-dropdown-body-container')
    const eventTargetNode = event.target as Node
    if (
      this.fakeDropdown.container &&
      !this.fakeDropdown.container.contains(eventTargetNode) &&
      this.fakeDropdown.content
    ) {
      this.fakeDropdown.content.classList.add('hide')
    }
  }

  insertBodyTag(_e: Event, tag: string) {
    // TODO: Revision 25.1.*
    // this.$refs.richtexteditor.ej2Instances.executeCommand('insertText', tag, { undo: true })
    ;(this as any).$refs.richtexteditor.ej2Instances.executeCommand('insertText', tag, { undo: true })
    this.showOrHideBodyTagList()
  }

  showOrHideBodyTagList() {
    this.fakeDropdown.content!.classList.toggle('hide')
  }

  removeDefaultContentClasses() {
    const content = document.querySelector('.e-content')
    if (content) {
      Array.from(content.querySelectorAll('[class]')).forEach((element) => {
        element.classList.forEach((className) => {
          if (className.startsWith('e-')) {
            element.classList.remove(className)
          }
        })
      })
    }
  }

  onCreateRichTextEditor() {
    this.removeDefaultContentClasses()
    this.fakeDropdown.button = document.querySelector('.fake-dropdown-body-button')
    this.fakeDropdown.content = document.querySelector('.fake-dropdown-body-content')

    if (this.fakeDropdown.button) {
      this.fakeDropdown.button.addEventListener('click', this.showOrHideBodyTagList)
    }
    const customBodySelect = document.getElementById(this.fakeDropdownTags.body)

    const tags = this.selectedTemplateData!.tags.body

    for (const opt of tags) {
      const el = document.createElement('li')
      el.textContent = opt.label
      customBodySelect!.appendChild(el)
      el.addEventListener('click', (e) => this.insertBodyTag(e, opt.tag))
    }
  }

  async onSaveForm() {
    await this.saveEmailTemplateAction(this.selectedTemplateData as EmailTemplate)
    this.onCloseForm()
  }

  async onExecuteAction(actionName: string) {
    switch (actionName) {
      case ActionName.CLOSE:
        this.onCloseForm()
        break
      case ActionName.SAVE:
        this.onSaveForm()
        break
      default:
        break
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-template-container {
  @include flex($flex-direction: column);
  padding: 0 10px;

  .spinner-layer {
    height: 200px;
  }

  .fake-dropdown-subject-content,
  ::v-deep .fake-dropdown-body-content {
    @include borders;
    @include list;
    @include scroll-styles;
    display: block;
    position: absolute;
    min-width: 200px;
    max-height: 400px;
    color: $gray-01;
    font-size: 14px;
    font-family: $secondary-font;
    background: $white-01;
    z-index: 9999;

    &.hide {
      display: none;
    }

    li {
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: $gray-04;
      }
    }
  }

  .fake-dropdown-subject-button,
  ::v-deep .fake-dropdown-body-button {
    @include flex;
    @include border($direction: right);
    font-family: $secondary-font;
    color: $corporate-color;
    font-size: 14px;
    height: 40px;
    padding-left: 12px;
  }

  ::v-deep .fake-dropdown-body-button {
    @include border($direction: left);
  }

  .text-label {
    color: $corporate-color;
    font-family: $corporate-font-bold;
    font-size: 14px;
    padding-left: 12px;
  }

  .angle-icon,
  ::v-deep .angle-icon {
    font-size: 18px;
    padding-left: 30px;
    padding-right: 12px;
  }

  .tab-header {
    width: 100%;
  }

  .edit-template-section-container {
    @include flex($flex-direction: column);
    max-width: 888px;

    #custom_subject_select {
      height: 40px;
      color: $corporate-color;
      font-family: $secondary-font;
      font-size: 14px;
      width: 190px;
    }

    .subject-container {
      @include flex($flex-direction: column, $align-items: flex-start);
      width: 100%;

      .fake-dropdown-subject-container {
        @include borders;
        position: relative;
        display: inline-block;
        height: 80px;
        width: 100%;

        .subject-text {
          @include flex($justify-content: flex-start);
          @include border($direction: top);
          width: 100%;
          min-height: 40px;
          height: 40px;
          font-family: $secondary-font;
          color: $gray-01;
          font-size: 14px;
          padding: 0 12px;
        }
      }
    }

    .body-container {
      margin: 20px 0;

      .rich-text-editor {
        background-color: $corporate-color;

        ::v-deep .e-content {
          @include scroll-styles;
        }

        ::v-deep .e-rte-toolbar {
          @include border;
          background: $white-01;

          .e-content {
            @include border($direction: top);
          }

          .e-toolbar-item,
          .e-toolbar-items,
          .e-btn-icon,
          .e-tbar-btn {
            background: $white-01;
          }

          .e-btn-icon.e-icons,
          .e-rte-dropdown-btn-text {
            color: $corporate-color;
          }
        }
      }
    }
  }
}
</style>
