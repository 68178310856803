import { ContextMenuClickEventArgs, ContextMenuOpenEventArgs } from "@syncfusion/ej2-vue-grids"
import { computed } from "vue"

interface PropsUseGridContextMenu {
  gridRef: any
}

interface ResponseUseGridContextMenu {
  contextMenuBeforeOpen: (args: any) => void
  contextMenuClicked: (args: ContextMenuClickEventArgs) => void
}

export default function useGridContextMenu(props: PropsUseGridContextMenu): ResponseUseGridContextMenu {
  const { gridRef } = props

  const gridInstance = computed(() => (gridRef.value as any)?.ej2Instances)

  function contextMenuBeforeOpen(args: ContextMenuOpenEventArgs) {
    if (!args.column) {
      args.cancel = true
      return
    }
    return { ...args, hideItems, showAllItems }
  }

  const hideItems = (itemTexts: string[]) => {
    const contextmenu = gridInstance.value!.contextMenuModule.contextMenu
    const contextMenuElemenent = contextmenu.element

    // Mostramos todos los elementos
    showAllItems()

    // Ocultamos los items que llegan como parámetro a la función
    contextmenu.enableItems(itemTexts, false)
    const disabledItems = contextMenuElemenent.querySelectorAll('.e-disabled')
    disabledItems.forEach((item: any) => {
      item.classList.add('hidden-contextmenu-option')
    })
  }

  const showAllItems = () => {
    const contextmenu = gridInstance.value!.contextMenuModule.contextMenu
    const contextMenuElemenent = contextmenu.element

    const allItems = contextMenuElemenent.querySelectorAll('.e-menu-item')
    allItems.forEach((item: any) => {
      item.classList.remove('hidden-contextmenu-option')
      item.classList.remove('e-disabled')
    })
  }

  const contextMenuClicked = (args: ContextMenuClickEventArgs) => {
    const { event, item, rowInfo } = args
    return { event, item, rowInfo }
  }


  return {
    contextMenuBeforeOpen,
    contextMenuClicked
  }
}
