<template lang="pug">
span( :class="getTextColor(getText)") {{ getText }}
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'columnStatusTemplate'
})
export default class StatusTemplateComponent extends Vue {
  data: any

  get getText() {
    const { field } = this.data.column
    const value = this.data[field]
    if (value === '' || value === null || value === undefined || value === false) {
      return ''
    }
    if (value === true) {
      return this.$t('action_buttons.yes')
    }
    return value
  }

  getTextColor(status: string): string {
    switch (status) {
      case 'Sin Iniciar':
        return 'blue status'
      case 'Iniciada':
        return 'yellow status'
      case 'Pte. Resolución':
      case 'Pendiente':
      case 'Parcialmente cobrada':
      case 'Pte. Resolución':
        return 'orange status'
      case 'Activado':
      case 'Finalizada':
      case 'Cobrada':
        return 'green status'
      case 'Vencida':
        return 'brown status'
      case 'Cancelada':
        return 'grey status'
      case this.$t('action_buttons.yes'):
        return 'green status'
      case this.$t('action_buttons.no'):
      case 'Bloqueado':
      case 'Sin cobrar':
        return 'red status'
      case 'Sin portal':
        return 'light-grey status'
      default:
        return ''
    }
  }
}
</script>
