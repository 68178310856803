<template lang="pug">

div(class="sub-action-selector-dialog-container")
  CustomDialogHeaderComponent(:headerTitle="texts.title" class="header")

  SpinnerLayerComponent(v-if="dialogState === LOAD_STATE.LOADING" class="spinner-layer")

  template(v-if="dialogState === LOAD_STATE.LOADED")
    span(class="required-fields-advice-base") {{ texts.infoAdvice }}
    LexonSelectComponent(
      id="actionId"
      name="actionId"
      class="lexon-select"
      v-model="selectedActionId"
      :label="texts.label"
      keyName="subject"
      keyValue="id"
      :paginated="false"
      :attach="false"
      validationRules="required"
      :returnObject="false"
      :tableHeaders="tableHeaders"
      :propItems="getActionList"
    )

  div(
      v-if="dialogState === LOAD_STATE.NO_DATA"
      class="info-message-container"
    )
      div(class="info-message")
        span(:class="[infoIcon, 'icon']")
        span(class="text") {{  texts.noData }}


  CustomDialogActionButtonsComponent(
    v-if="dialogState === LOAD_STATE.LOADED"
    class="actions"
    :primaryButtonText="texts.save"
    :mainAction="save"
    :disabledButtons="!selectedActionId"
  )

</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { LOAD_STATE } from '@/store/modules/dialog/dialogTypes'
import TemplateGeneratorComponent from '@/components/template/TemplateGenerator/TemplateGeneratorComponent.vue'
import { AxiosError } from 'axios'
import { SUBACTION_ERRORS } from '@/store/modules/sub-actions/subActionsTypes'

@Component({
  components: {
    TemplateGeneratorComponent,
    CustomDialogActionButtonsComponent: () =>
      import('@/components/Dialog/CustomDialog/CustomDialogActionButtonsComponent.vue'),
    CustomDialogHeaderComponent: () => import('@/components/Dialog/CustomDialog/CustomDialogHeaderComponent.vue'),
    LexonSelectComponent: () => import('@/components/forms/fields/LexonSelect/LexonSelectComponent.vue'),
    SpinnerLayerComponent: () => import('@/components/Spinner/SpinnerLayerComponent.vue')
  }
})

export default class SubActionSelectorDialogComponent extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  componentProps!: { parentActionId: number}

  @Action('fetchActionList', { namespace: ModuleNamespaces.SUB_ACTIONS })
  fetchActionList: ({}) => void

  @Action('closeCustomDialog', { namespace: ModuleNamespaces.DIALOG })
  closeCustomDialog: () => void

  @Getter('getActionList', { namespace: ModuleNamespaces.SUB_ACTIONS })
  getActionList: () => {}

  @Watch('getActionList')
  async changeDialogState(data: any) {
    if(!Array.isArray(data)){
      this.dialogState = LOAD_STATE.LOADING
      return
    }
    this.dialogState = (data.length) ? LOAD_STATE.LOADED : LOAD_STATE.NO_DATA;
  }

  LOAD_STATE: any = LOAD_STATE
  dialogState: LOAD_STATE = LOAD_STATE.LOADING

  texts = {
    title: this.$t('components.dialog.sub_actions_relate_title').toString(),
    label: this.$t('components.dialog.sub_actions_relate_label').toString(),
    save: this.$t('action_buttons.save').toString(),
    ok: this.$t('components.dialog.sub_actions_relate_ok',).toString(),
    error: this.$t('components.dialog.sub_actions_relate_error',).toString(),
    response_error: this.$t('components.dialog.sub_actions_relate_response_error',).toString(),
    noData: this.$t('components.dialog.sub_actions_no_data').toString(),
    infoAdvice: this.$t('components.form_generator.required_fields').toString()
  }

  tableHeaders = ['type', 'subject']

  infoIcon = Icons.INFO

  selectedActionId: null | number = null

  async created() {
    this.fetchActionList(this.componentProps)
  }

  async save() {
    if(!this.componentProps.parentActionId || !this.selectedActionId) {
      this.closeCustomDialog()
      this.showError(this.texts.error)
      return
    }
    try {
      await this.$store.dispatch(
        `${ ModuleNamespaces.SUB_ACTIONS }/relateSubAction`,
        {
          parentActionId: this.componentProps.parentActionId,
          subActionId: this.selectedActionId
        }
      )
      this.closeCustomDialog()
      this.showSuccess(this.texts.ok)
    } catch (error) {
      this.errorHandler(error)
    }
  }

  errorHandler (error: any): void {
    this.closeCustomDialog()
    if (error instanceof AxiosError
      && error.response
      && error.response.data
      && error.response.data.exceptionDesc
      && error.response.data.exceptionDesc.includes(SUBACTION_ERRORS.RESPONSE_ERROR)
    ) {
      this.showError(this.texts.response_error)
    } else {
      this.showError(this.texts.error)
    }
  }

  showError(message: string) {
    this.showAlert({message: message, type: AlertsTypes.ERROR});
  }

  showSuccess(message: string) {
    this.showAlert({message: message, type: AlertsTypes.SUCCESS});
  }

  showAlert({message, type}: any) {
    this.$store.dispatch(
      `${ModuleNamespaces.ALERTS}/showAlert`,
      {
        type,
        message,
        componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW
      },
      { root: true }
    )
  }
}
</script>

<style lang="scss" scoped>
.sub-action-selector-dialog-container {
  @include flex($flex-direction: column, $justify-content: space-between);
  height: 260px;

  .header,
  .lexon-select,
  .actions {
    width: 100%;
  }

  .required-fields-advice-base{
    margin-top: 0px;
    padding-right: 0px;
  }

  .lexon-select {
    padding-bottom: 30px;
  }

  .spinner-layer {
    --spinner-layer-min-height: 150px;
  }

  .info-message-container {
    @include flex($justify-content: space-between);
    width: 100%;
    height: 50px;
    color: $white-01;
    padding: 0 20px;
    margin-bottom: 30px;
    background-color: $blue-02;

    .info-message {
      @include flex;

      .icon {
        font-size: 30px;
        margin-right: 20px;
        opacity: 0.5;
      }

      .text {
        font-family: $corporate-font;
        font-size: 14px;
      }
    }
  }
}
</style>
