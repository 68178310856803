<template>
  <div class="card">
    <div class="title-container">Centro de ayuda</div>
    <ul class="help-widgets-container">
      <li v-for="(widget, index) of helpWidgets" :key="index" class="help-widget">
        <a :href="widget.url" target="_blank" class="help-widget-link">
          {{ widget.text }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { externalURLS } from '@/router/routes/urlRoutes'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n-composable'

const authModule = ModuleNamespaces.AUTH

// TRANSLATIONS
const { t } = useI18n()
const text = {
  courses: t('components.dialog.support_service.help_center.courses'),
  frecuentQuestions: t('components.dialog.support_service.help_center.frequent_questions'),
  manuals: t('components.dialog.support_service.help_center.manuals'),
  title: t('components.dialog.support_service.help_center.title'),
  videos: t('components.dialog.support_service.help_center.videos')
}

// GETTER
const encryptedIdProUser = computed<string>(() => store.getters[`${authModule}/getEncryptedIdProUser`])

// METHODS
async function getEncryptIdProUser() {
  await store.dispatch(`${authModule}/encryptIdProUser`)
}

// HOOKS
onMounted(() => {
  getEncryptIdProUser()
})

// COMPUTED
const urls = computed(() => {
  return {
    courses: `${externalURLS.courses}${encryptedIdProUser.value}`,
    faqs: `${externalURLS.faqs}${encryptedIdProUser.value}`,
    helpCenterEmail: externalURLS.help_center_email,
    manuals: `${externalURLS.manuals}${encryptedIdProUser.value}`,
    helpCenter: `${externalURLS.help_center}${encryptedIdProUser.value}`,
    videos: `${externalURLS.videos}${encryptedIdProUser.value}`
  }
})

const helpWidgets = computed(() => {
  return [
    {
      text: text.frecuentQuestions,
      url: urls.value.faqs
    },
    {
      text: text.videos,
      url: urls.value.videos
    },
    {
      text: text.manuals,
      url: urls.value.manuals
    },
    {
      text: text.courses,
      url: urls.value.courses
    }
  ]
})
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  padding: $spacing-sm;
  flex-direction: column;
  align-items: center;
  gap: $spacing-sm;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: $cornerRadius-md;
  background: $main-050;

  .title-container {
    @include milano-bold-24;
    padding: $spacing-xs;
    color: $highlight-200;
    text-align: center;
  }

  .help-widgets-container {
    @include list;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $spacing-sm;
    width: 100%;

    .help-widget {
      width: 100%;

      .help-widget-link {
        @include milano-medium-18;
        display: flex;
        width: 100%;
        padding: $spacing-lg $spacing-xs;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: $spacing-xxs;
        border-radius: $cornerRadius-md;
        background: $neutral-white-000;
        text-decoration: none;
      }
    }
  }
}
</style>
