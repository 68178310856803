<template lang="pug">
  span() {{ boolToYesNo }}
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'boolToYesNoTemplate'
})
export default class BoolToYesNoTemplateComponent extends Vue {
  data: any

  get boolToYesNo() {
    if (this.data.main === true) {
      return this.$t('action_buttons.yes')
    } else {
      return this.$t('action_buttons.no')
    }
  }
}
</script>
