export const vars = {
  action_color: '#D50057',
  blue_09: '#63B9E4',
  blue_16: '#425CC7',
  blue_17: '#6fcfeb',
  blue_18: '#486AAE',
  blue_19: '#68C4C2',
  brown_01: '#8d1b60',
  brown_02: '#9B2743',
  brown_03: '#D0C7B6',
  corporate_color: '#001978',
  corporate_font: 'MTTMilano, Lato, Arial, sans-serif',
  customer_color: '#e65f37',
  dashboard_statistic_customers: '#ED7F4B',
  dashboard_statistic_expedients: '#425CC7',
  dashboard_statistic_receivable: '#D50057',
  dashboard_statistic_unbilled: '#99E6D8',
  disabled_color: '#999999',
  document_color: '#001978',
  error_color: '#d0021b',
  expedient_color: '#ffcf2B',
  gold_01: '#D3BA89',
  gray_01: '#565656',
  gray_07: '#333333',
  gray_13: '#68737B',
  green_09: '#3D7F4B',
  green_10: '#99E6D8',
  green_11: '#CDCF3B',
  green_12: '#115740',
  green_13: '#D9C756',
  invoice_color: '#63B9E4',
  navigation_drawer_width: '250px',
  orange_02: '#e65f37',
  orange_04: '#ED7F4B',
  pink_01: '#D50057',
  pink_02: '#ee99bc',
  progress_bar_default_color: '#DDDCDC',
  progress_bar_error: '#e16668',
  progress_bar_success: '#59ac85',
  purple_05: '#522D6D',
  secondary_font: 'Arial, Helvetica, sans-serif',
  success_color: '#417505',
  warning_color: '#CF7700',
  white_01: '#ffffff',
  yellow_01: '#ffcf2B'
}
