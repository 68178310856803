<template lang="pug">

section(class="panel-view-container" v-if="groupByTerm && !showSpinnerLayer")

  LfHeaderTitleComponent(:title="headerText" class="header-title")

  LexonKanbanSidebar(
    :groupByTermProp="groupByTerm"
    :sortByOptionProp="sortBy"
    :checkboxes="kanbanColumns"
    :responsibles="responsibles"
    :filtersInfo="filtersInfo"
    @emit-search-term="emitSearchTermToKanban"
    @emit-group-by-term="emitGroupByTermToKanban"
    @emit-toggle-column="emitToggleColumn"
    @emit-sort-by-option="emitChangeSortOption"
    @emit-clear-filters="emitClearFilters"
    @emit-filters-info="emitFiltersInfo"
  )

  div(class="kanban-and-configuration-module-container")

    LexonKanbanComponent(
      :key="`kanba-key-${kanbanKey}`"
      :kanbanColumns="kanbanColumns"
      :actions="actions"
      :sortByOptionProp="sortBy"
      :searchTerm="searchTerm"
      :groupByTerm="groupByTerm"
      :toggleColumnInfo="toggleColumnInfo"
      :swimlaneGroupByKeyField="groupByTerm"
      :responsibles="responsibles"
      :filtersInfo="filtersInfo"
    )

</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import LexonKanbanComponent from '@/components/LexonKanban/LexonKanbanComponent.vue'
import LexonKanbanSidebar from '@/components/LexonKanban/LexonKanbanSidebar.vue'
import LfHeaderTitleComponent from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'
import { TranslateResult } from 'vue-i18n'
import {
  KanbanGroupBy,
  KanbanCardItem,
  KanbanAction,
  kanbanColumns,
  actionTypeSidebarFilterOptions
} from '@/components/LexonKanban/types/LexonKanbanTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action, Getter } from 'vuex-class'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { objectActionTypes } from '@/store/modules/actions/actionsTypes'

const actionsModule: string = ModuleNamespaces.ACTIONS
const authModule: string = ModuleNamespaces.AUTH
const configurationModule: string = ModuleNamespaces.CONFIGURATION
const spinnerModule: string = ModuleNamespaces.SPINNER
const usersModule = ModuleNamespaces.USERS

@Component({
  components: {
    LexonKanbanComponent,
    LexonKanbanSidebar,
    LfHeaderTitleComponent
  }
})
export default class ActionsPanelView extends Vue {
  @Action('fetchPanelActions', { namespace: actionsModule })
  fetchPanelActionsAction: () => []

  @Action('fetchCurrentListConfiguration', { namespace: configurationModule })
  fetchKanbanConfigurationAction: (alias: string) => Promise<void>

  @Action('saveUserCustomConfigurationList', { namespace: configurationModule })
  saveSidebarConfigurationAction: ({}) => void

  @Action('editPanelAction', { namespace: actionsModule })
  editPanelAction: ({}) => void

  @Action('fetchResponsibles', { namespace: usersModule })
  fetchResponsiblesAction: () => []

  @Action('hideSpinnerLayer', { namespace: spinnerModule })
  hideSpinnerLayerAction: () => void

  @Getter('getCurrentListConfiguration', { namespace: configurationModule })
  listConfigurationGetter: any

  @Getter('getUserId', { namespace: authModule })
  userIdGetter: number

  @Getter('getShowSpinnerLayer', { namespace: spinnerModule })
  showSpinnerLayer: boolean

  actions: KanbanCardItem[] | null = null

  actionTypeSidebarFilterOptions = actionTypeSidebarFilterOptions

  headerText: TranslateResult = this.$t('components.lexon_kanban.header_title')

  searchTerm: string = ''

  groupByTerm: string = ''

  sortBy: string = ''

  toggleColumnInfo = {}

  kanbanKey: number = 0

  responsibles = []

  filtersInfo: any = {
    responsible: [],
    actionTypeText: []
  }

  texts = {
    responsible: this.$t('components.lexon_kanban.responsible'),
    startDate: this.$t('components.lexon_kanban.start_date'),
    type: this.$t('components.lexon_kanban.type')
  }

  actionTypes = objectActionTypes

  get kanbanColumns() {
    return kanbanColumns.map((column, index) => {
      return {
        ...column,
        value: this.panelConfiguration.columns[index].value
      }
    })
  }

  get panelConfiguration() {
    if (this.listConfigurationGetter && Object.keys(this.listConfigurationGetter).length) {
      return JSON.parse(this.listConfigurationGetter['Config'])
    }
  }

  get sidebarConfiguration() {
    return {
      IdUser: this.userIdGetter,
      Alias: ListNames.ACTIONS_PANEL,
      Config: {
        columns: this.kanbanColumns,
        sortSettings: this.sortBy,
        groupSettings: this.groupByTerm,
        filterSettings: this.filtersInfo,
        searchSettings: ''
      }
    }
  }

  @Watch('panelConfiguration')
  initSidebarConfiguration() {
    const { groupSettings, sortSettings, filterSettings } = this.panelConfiguration
    this.groupByTerm = groupSettings
    this.sortBy = sortSettings
    this.filtersInfo = filterSettings
  }

  async created() {
    await this.initPanel()
    this.hideSpinnerLayerAction()
  }

  mounted() {
    this.$root.$on('saveAction', this.saveAction)
  }

  getActionTypeLiteral(type: string) {
    return this.actionTypeSidebarFilterOptions.filter((item) => item.idActionType === type)[0].text
  }

  async initPanel() {
    await this.fetchKanbanConfigurationAction(ListNames.ACTIONS_PANEL)
    this.initActions()
    this.responsibles = await this.fetchResponsiblesAction()
  }

  async initActions() {
    this.actions = await this.fetchPanelActionsAction()
    this.actions.forEach((action) => {
      action.actionTypeText = this.getActionTypeLiteral(action.idActionType)
      action.dateWithoutHour = action.dateActionAt.substring(0, 10)
    })
  }

  emitSearchTermToKanban(term: string): void {
    this.searchTerm = term
  }

  emitGroupByTermToKanban(term: string): void {
    this.kanbanKey++
    switch (term) {
      case this.$t('components.lexon_kanban.responsible'):
        this.groupByTerm = KanbanGroupBy.RESPONSIBLE
        break
      case this.$t('components.lexon_kanban.type'):
        this.groupByTerm = KanbanGroupBy.ACTION_TYPE
        break
      case this.$t('components.lexon_kanban.start_date'):
        this.groupByTerm = KanbanGroupBy.START_DATE
    }
    this.saveSidebarConfigurationAction(this.sidebarConfiguration)
  }

  emitToggleColumn(checkboxInfo: any) {
    this.saveSidebarConfigurationAction(this.sidebarConfiguration)
    this.toggleColumnInfo = checkboxInfo
  }

  emitChangeSortOption(sortByOption: string) {
    this.sortBy = sortByOption
    this.saveSidebarConfigurationAction(this.sidebarConfiguration)
    this.kanbanKey++
  }

  async emitClearFilters() {
    this.initActions()
    this.filtersInfo = {
      responsible: [],
      actionTypeText: []
    }
    this.saveSidebarConfigurationAction(this.sidebarConfiguration)
    this.searchTerm = ''
    this.kanbanKey++
  }

  emitFiltersInfo(filterInfo: { term: string; value: string }) {
    this.filtersInfo[filterInfo.term] = filterInfo.value
    this.saveSidebarConfigurationAction(this.sidebarConfiguration)
  }

  async saveAction(actionData: KanbanAction, reloadActions: boolean = false) {
    const stateId = this.actionTypes.filter((option: any) => option.status === actionData.state)[0].id
    actionData.stateId = stateId
    await this.editPanelAction(actionData)
    if (reloadActions) {
      this.actions = await this.fetchPanelActionsAction()
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-view-container {
  display: flex;
  flex-direction: column;

  .kanban-and-configuration-module-container {
    @include flex;
    position: relative;
    margin-top: 12px;
  }
}
</style>
