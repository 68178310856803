export enum ExpedientFields {
  ACTION_TYPE = 'typeId',
  ACTIVE_STAGES = 'activeStages',
  ARCHIVED = 'archived',
  ARCHIVE_DATE = 'dateArchive',
  ARCHIVE_REFERENCE = 'fileReference',
  CUSTOMER_FACT = 'customerfact',
  EXPEDIENT_DATA_FIELDSET = 'datosEconomicos',
  EXPEDIENT_STATUS_FIELDSET = 'expedientStatus',
  EXPEDIENT_TYPE_ID = 'idExpedientType',
  EXPEDIENT_TYPE_SUBJECT_ID = 'idExpedientSubjectType',
  PERIODIC_BILLING = 'periodicBillingSwitch',
  PERIODIC_BILLING_ALERT_INFO = 'periodicBillingAlertInfo',
  PERIODIC_BILLING_ALERT_WARNING = 'periodicBillingAlertWarning',
  STAGE = 'idStage',
  TAGS = 'tags',
  USER_ID = 'idUser'
}
