
import { Icons } from '@/icons/icons'
import { i18n } from '@/plugins/vue-i18n'
import { ActionName, ItemModel } from '../../LfGridTypes'

export const kebakMenuToolbarItem: ItemModel = {
  id: ActionName.CUSTOM_TOOLBAR_CONTEXT_MENU,
  align: 'Right',
  cssClass: 'lf-btn-kebak',
  prefixIcon: Icons.KEBAB,
  contextMenuItems: [
    {
      id: ActionName.PRINT,
      iconCss: Icons.PRINT,
      text: i18n.t('components.grid_table.tooltip.print').toString()
    },
    {
      id: ActionName.EXPORT,
      iconCss: Icons.DOWNLOAD,
      text: i18n.t('components.grid_table.tooltip.export').toString()
    }
  ]
}

export const columnsChooserToolbarItem: ItemModel = {
  text: ActionName.COLUMNS_CHOOSER,
  align: 'Right',
  prefixIcon: Icons.OPTIONS
}


export const searchToolbarItem: ItemModel = {
  id: ActionName.SEARCH,
  text: 'Search',
  align: 'Left'
}

export const filterToolbarItem: ItemModel = {
  id: ActionName.FILTER,
  text: i18n.t('components.grid_table.tooltip.clean_filter').toString(),
  align: 'Left',
  cssClass: 'lf-btn-model1'
}
