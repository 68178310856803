import { CounterInfo } from '@/components/counters/types/CounterTypes'

export interface DashboardWidgetsState {
  comeFromDashboardWidgetFlag: boolean // Con este flag hacemos que al redirigir a un listado desde un widget, Seguarde correctamente la variable rememberUserConfig
  actionWidget: {
    status: ListStatus,
    list: ActionItem [],
  },
  counterWidget: {
    status: ListStatus,
    list: CounterInfo [],
  }
}

export enum ListStatus {
  EMPTY = 'EMPTY',
  OK = 'OK',
  ERROR = 'ERROR',
  LOADING = 'LOADING'
}

export type ActionItem = {
  "dueDate": string,
  "subject": string,
  "idAction": string,
  "actionType": string,
  "description": string,
  "customerName": string,
  "expedientName": string
}

export type CounterItem = CounterInfo &
{
  filterSettings: [],
  sortSettings: [],
  archived: number,
  listAlias: string,
  url: string,
}

export const dashboardWidgetsMutationTypes = {
  SET_COME_FROM_DASHBOARD_WIDGET_FLAG: 'SET_COME_FROM_DASHBOARD_WIDGET_FLAG',
  SET_ACTION_WIDGET_LIST: 'SET_ACTION_WIDGET_LIST',
  SET_COUNTER_WIDGET_LIST: 'SET_COUNTER_WIDGET_LIST',
  SET_ACTION_WIDGET_ERROR_STATUS: 'SET_ACTION_WIDGET_ERROR_STATUS',
  SET_COUNTER_WIDGET_ERROR_STATUS: 'SET_COUNTER_WIDGET_ERROR_STATUS'
}

export const TIME_REPORT_WIDGET = {
  REPORT_ID: '23',
  REPORT_ALIAS: 'InfSumHorasTrabajadas'
}
