import { MutationTree } from 'vuex'
import { AlertMode, AlertsData, AlertsState } from '@/store/modules/alerts/alertsTypes'

export const mutations: MutationTree<AlertsState> = {
  SHOW_ALERT(state, alertData) {
    state.showAlert = true
    state.alertMode = calculeAlertMode(alertData)
    state.alertData = alertData
  },
  CLOSE_ALERT(state) {
    state.showAlert = false
    state.alertMode = null
    state.alertData = null
  }
}

function calculeAlertMode(alertData: AlertsData): AlertMode {
  if(!!alertData.message && !!alertData.subMessage){
    return AlertMode.FULL
  }
  if(!!alertData.message && !alertData.subMessage){
    return AlertMode.MESSAGE
  }
  if(!alertData.message && !!alertData.subMessage){
    return AlertMode.SUBMESSAGE
  }
  return AlertMode.ICON
}
