<template lang="pug">
  section(v-if="Object.keys(listConfiguration).length && itemsData")

    GridTableComponent(
      ref="grid"
      :itemsData="itemsData"
      :gridConfiguration="gridConfiguration"
      :title="headerTitle"
      :toolbarOptions="toolbarOptionsCustom"
      :contextMenuItems="gridContextMenuItems"
      :listName="listConfiguration['Alias']"
      :context="context"
      @cellClicked="openForm"
      @contextMenuClicked="onContextMenuClicked"
    )

</template>

<script lang="ts">
import { Mixins, Prop, Component, Emit } from 'vue-property-decorator'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import GridTableComponent from '@/components/grids/GridTable/GridTableComponent.vue'
import GridContextMenuOptionsMixin from '@/mixins/GridContextMenuOptionsMixin.vue'
import GridToolbarOptionsMixin from '@/mixins/GridToolbarOptionsMixin.vue'
import {
  columnsChooserToolbarItem,
  kebakMenuToolbarItem,
  searchToolbarItem
} from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import { gridConfigurationWithActionColumn } from '@/helpers/grid'
import { Action } from 'vuex-class'
import LfHeaderTitle from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'

@Component({
  components: {
    GridTableComponent,
    LfHeaderTitle
  }
})
export default class UsersMaintenanceGridComponent extends Mixins(
  GridToolbarOptionsMixin,
  GridContextMenuOptionsMixin
) {
  @Prop({
    type: String
  })
  headerTitle!: string

  @Prop({
    type: Array
  })
  itemsData!: []

  @Prop({
    type: Object
  })
  listConfiguration!: []

  @Action('saveSelectedRegisterId', { namespace: ModuleNamespaces.SELECTED_REGISTER })
  saveSelectedRegisterId: ({}) => Promise<void>

  context = ContextName.MAINTENANCE_USERS_GRID

  get gridContextMenuItems() {
    return [this.editMenuItem]
  }

  get gridConfiguration() {
    const config = JSON.parse((this as any).listConfiguration['Config'])
    const gridConfiguration = gridConfigurationWithActionColumn(config)
    return JSON.stringify(gridConfiguration)
  }

  get toolbarOptionsCustom() {
    return [searchToolbarItem, columnsChooserToolbarItem, kebakMenuToolbarItem]
  }

  @Emit('openFormEvent')
  openForm(selectedRegister: any | null = null) {
    this.saveSelectedRegisterId({ id: selectedRegister.id, context: this.context })
    return selectedRegister
  }

  onContextMenuClicked(_args: any, selectedRegister: any) {
    this.openForm(selectedRegister)
  }
}
</script>
