import { MainService } from '@/services/MainService'

export class SubActionsService {
  constructor() {
  }

  async getPredefineSubAction(code: string) {
    try {
      const { data } = await new MainService().postData(
        'maintenance/manActionsCodifications/post',
        { code }
      )

      delete data[0].id
      delete data[0].actionId
      delete data[0].rateId
      delete data[0].economicEstimate
      delete data[0].billable

      return data
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log('getPredefineSubAction error !!!')
    }
  }
}
