<template lang="pug">

div(
  class="lexon-kanban-filters-container"
)
  section(class="filters-container")
    div(class="row-1")
      //- Search
      div(class="search-input-container")
        span(class="search-text") {{ `${texts.search}:` }}
        LexonTextFieldComponent(
          id="search"
          class="filter-input"
          v-model="searchTerm"
          :placeholder="texts.searchPlaceholder"
          @input="emitSearchTerm"
        )

      LfLabelButtonComponent(
        class="action-button"
        :text="texts.cleanFilters"
        :action="clearFilters"
        :disabled="filterButtonStatus"
        :variant="outlinedVariant"
      )

    //- Filters
    div(class="row-2")
      section(class="filters-section")
        LexonAutocompleteComponent(
          v-model="filters.responsible"
          :prop-items="responsibles"
          itemName="name"
          class="filter-input"
          :placeholder="texts.responsible"
          :label="`${texts.responsible}:`"
          multiple
          chips
          @change="emitFiltersInfo($event, 'responsible')"
        )
        LexonAutocompleteComponent(
          v-model="filters.actionTypeText"
          :prop-items="actionTypeSidebarFilterOptions"
          itemName="text"
          class="filter-input"
          :placeholder="texts.type"
          :label="`${texts.type}:`"
          multiple
          chips
          @change="emitFiltersInfo($event, 'actionTypeText')"
        )

  div(class="actions-container")
    p(class="warning-text") {{ texts.warning }}
    div(class="buttons-container")
      div(class="filter-actions")
        button(:class="{'selected-button': groupByTermProp === 'idActionType'}" @click="emitGroupByTerm('Tipo')") {{ texts.type  }}
        button(:class="{'selected-button': groupByTermProp === 'responsible'}" @click="emitGroupByTerm('Responsable')") {{ texts.responsible }}
        button(:class="{'selected-button': groupByTermProp === 'dateWithoutHour'}" @click="emitGroupByTerm('Fecha alta')") {{ texts.startDate }}
      div(class="sort-actions")
        button(:class="{'selected-button': sortByOptionProp === 'Ascending'}" @click="emitSortByOption('Ascending')")
            span(:class="icon.arrowUp")
        button(:class="{'selected-button': sortByOptionProp === 'Descending'}" @click="emitSortByOption('Descending')")
          span(:class="icon.arrowDown")

      v-menu(bottom nudge-bottom="38" content-class="navigation-context-menu" :close-on-content-click="false")
        template(v-slot:activator="{ on, attrs }")
          button(
            v-bind="attrs"
            v-on="on"
          )
            LfLabelButtonComponent(
              class="action-button"
              :text="texts.configurationText"
              :variant="outlinedVariant"
              :prependIcon="icon.config"
            )
        v-list(class="kanban-checkboxes-list")
          v-list-item(v-for="(checkbox, checkboxIndex) in parsecheckboxes" :key="checkboxIndex" :class="{'checked': checkbox.value}")
            label( class="label")
              input(
                class="checkbox"
                :id="checkbox.keyField"
                :name="checkbox.keyField"
                :checked="checkbox.value"
                type="checkbox"
                @input="emitToggleColumn($event, checkbox.keyField, checkboxIndex)"
              )
              span {{ checkbox.headerText  }}

</template>

<script lang="ts">
import { Component, Emit, Prop, Watch, Mixins } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { TranslateResult } from 'vue-i18n'
import {
  SortByOptions,
  KanbanGroupBy,
  actionTypeSidebarFilterOptions as actionTypesFilterOptions
} from '@/components/LexonKanban/types/LexonKanbanTypes'
import CheckboxComponent from '@/components/Checkbox/CheckboxComponent.vue'
import LexonTextFieldComponent from '@/components/forms/fields/LexonTextField/LexonTextFieldComponent.vue'
import LexonAutocompleteComponent from '@/components/forms/fields/LexonAutocomplete/LexonAutocompleteComponent.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'
import LfLabelButtonComponent from '@/components/forms/buttons/label/LfLabelButtonComponent.vue'
import { LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'

@Component({
  components: {
    CheckboxComponent,
    LexonTextFieldComponent,
    LexonAutocompleteComponent,
    LfLabelButtonComponent
  }
})
export default class LexonKanbanSidebar extends Mixins(PermissionsMixin) {
  @Prop({
    type: String
  })
  groupByTermProp!: string

  @Prop({
    type: String
  })
  sortByOptionProp!: string

  @Prop({
    type: Array
  })
  checkboxes!: []

  @Prop({
    type: Array
  })
  responsibles!: []

  @Prop({
    type: Object
  })
  filtersInfo!: { responsible: []; actionTypeText: [] }

  $refs!: {
    sidebar: any
  }

  outlinedVariant = LabelButtonVariant.OUTLINED

  texts = {
    ascendingAutocompleteOption: this.$t('components.lexon_kanban.ascending'),
    configurationText: this.$t('components.lexon_kanban.configuration'),
    descendingAutocompleteOption: this.$t('components.lexon_kanban.descending'),
    responsible: this.$t('components.lexon_kanban.responsible'),
    search: this.$t('components.lexon_kanban.search'),
    searchPlaceholder: this.$t('components.lexon_kanban.search_placeholder'),
    startDate: this.$t('components.lexon_kanban.start_date'),
    type: this.$t('components.lexon_kanban.type'),
    warning: this.$t('components.lexon_kanban.warning'),
    applyFilters: this.$t('components.lexon_kanban.apply_filters'),
    cleanFilters: this.$t('components.lexon_kanban.clean_filters')
  }

  searchTerm: string = ''

  groupByTerm: string = ''

  sortByOption: string = ''

  filters = {
    responsible: [],
    actionTypeText: []
  }

  groupByAutocompleteOptions: TranslateResult[] = [this.texts.startDate, this.texts.responsible, this.texts.type]

  sortAutocompleteOptions = [
    {
      text: this.texts.ascendingAutocompleteOption,
      value: SortByOptions.ASCENDING
    },
    {
      text: this.texts.descendingAutocompleteOption,
      value: SortByOptions.DESCENDING
    }
  ]

  icon = {
    arrowLeft: Icons.ANGLE_LEFT,
    configuration: Icons.CONFIGURATION,
    arrowDown: Icons.ARROW_DOWN,
    arrowUp: Icons.ARROW_UP,
    config: Icons.CONFIGURATION
  }

  parsecheckboxes = []

  @Watch('groupByTermProp', { immediate: true })
  changeGroupByTerm(): void {
    switch (this.groupByTermProp) {
      case KanbanGroupBy.ACTION_TYPE:
        const typeText = this.texts.type as string
        this.groupByTerm = `${typeText.charAt(0).toUpperCase()}${typeText.slice(1)}`
        break
      case KanbanGroupBy.RESPONSIBLE:
        const responsibleText = this.texts.responsible as string
        this.groupByTerm = `${responsibleText.charAt(0).toUpperCase()}${responsibleText.slice(1)}`
        break
      case KanbanGroupBy.START_DATE:
        const startText = this.texts.startDate as string
        this.groupByTerm = `${startText.charAt(0).toUpperCase()}${startText.slice(1)}`
        break
    }
  }

  @Watch('sortByOptionProp', { immediate: true })
  changeSortByOption(): void {
    this.sortByOption = this.sortByOptionProp
  }

  @Emit()
  emitSearchTerm(): string {
    return this.searchTerm
  }

  @Emit()
  emitGroupByTerm(value: string) {
    this.groupByTerm = value
    return this.groupByTerm
  }

  @Emit()
  emitSortByOption(value: string) {
    this.sortByOption = value
    return this.sortByOption
  }

  @Emit()
  emitToggleColumn(status: any, columnKeyField: string, checkboxIndex: number) {
    const checkboxInfo = {
      visible: status.target.checked,
      keyField: columnKeyField,
      index: checkboxIndex
    }
    ;(this as any).parsecheckboxes[checkboxIndex].value = status.target.checked
    return checkboxInfo
  }

  @Emit()
  emitClearFilters(): void {}

  @Emit()
  emitFiltersInfo(e: any, term: string) {
    const filterInfo = {
      term,
      value: []
    }
    if (e) {
      filterInfo.value = e
    }
    return filterInfo
  }

  get filterButtonStatus(): boolean {
    const { responsible, actionTypeText } = this.filters
    return responsible.length || actionTypeText.length || this.searchTerm ? false : true
  }

  get actionTypeSidebarFilterOptions() {
    return actionTypesFilterOptions.filter(
      ({ actionEntityId }) => this.checkEntityPermissionsGetter(actionEntityId).canView
    )
  }

  mounted() {
    this.filters = this.filtersInfo
    this.parsecheckboxes = this.checkboxes
  }

  clearFilters() {
    this.filters = {
      responsible: [],
      actionTypeText: []
    }
    this.emitClearFilters()
  }
}
</script>

<style lang="scss" scoped>
.lexon-kanban-filters-container {
  .filters-container {
    display: flex;
    padding: $spacing-xs $spacing-xs $spacing-md $spacing-xs;
    flex-direction: column;
    align-items: flex-start;
    gap: $spacing-xs;
    margin-top: 24px;
    background-color: $neutral-grey-100;

    .row-1 {
      display: flex;
      padding: $spacing-xs;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;

      .search-input-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $spacing-md;

        .search-text {
          @include milano-bold-16;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: $spacing-xxxs;
          color: $neutral-grey-600;
        }

        &::v-deep .messages-container {
          display: none;
        }

        .filter-input {
          background-color: $neutral-white-000;
        }
      }

      .buttons-container {
        display: flex;

        .clean-button {
          @include milano-bold-16;
          padding: $spacing-xs $spacing-sm;
          color: $neutral-grey-500;
          margin-right: 8px;
          cursor: pointer;
        }
      }
    }

    .row-2 {
      width: 100%;
      padding: 0 $spacing-xs;

      .filters-section {
        display: flex;
        width: 100%;
        gap: $spacing-sm;

        .filter-input {
          width: 50%;

          ::v-deep .lf-autocomplete-container {
            .label-text {
              @include milano-regular-16;
              color: $neutral-grey-800;
            }

            .lf-autocomplete {
              background-color: $neutral-white-000;
            }

            .messages-container {
              display: none;
            }
          }
        }
      }
    }
  }

  .actions-container {
    margin-top: 24px;
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-xs;

    .warning-text {
      @include milano-regular-14;
      margin: 0;
      color: $neutral-grey-800;
    }

    .buttons-container {
      @include flex;

      .filter-actions {
        @include flex;
        border-radius: $cornerRadius-sm;
        background-color: $neutral-grey-100;

        button {
          @include milano-regular-16;
          padding: $spacing-xxs $spacing-sm;
          color: $neutral-grey-800;
          height: 38px;

          &.selected-button {
            @include milano-bold-16;
            border-radius: $cornerRadius-sm;
            border: 1px solid $main-400;
            background: $main-100;
          }
        }
      }

      .sort-actions {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        margin: 0 10px;

        button {
          display: flex;
          width: 38px;
          height: 38px;
          padding: $spacing-xs;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: $cornerRadius-sm;
          border: 1px solid $neutral-grey-500;

          &.selected-button {
            border-color: $main-1000;
            color: $main-1000;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.kanban-checkboxes-list {
  .v-list-item {
    @include flex;
    min-height: 38px !important;
  }

  .label {
    @include arial-regular-14;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    height: 38px;
    cursor: pointer;
    padding: 0;
    color: $neutral-grey-800;

    .checkbox {
      position: absolute;
      opacity: 0;
    }

    span {
      display: contents;
      width: 100%;
      height: 38px;

      &:before {
        display: inline-block;
        font-family: $lf-icons-font;
        font-size: 20px;
        content: '\e91f';
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }

  .checked {
    .label {
      span::before {
        content: '\e92b';
        color: $main-1000;
        cursor: pointer;
      }
    }
  }
}
</style>
