<template>
  <transition name="fade" appear>
    <div v-if="showStaticAlert" :class="['static-alert-container', getAlertType]">
      <span :class="[getAlertIcon, 'alert-icon']" />
      <div class="alert-info-container">
        <div v-if="alert.title" class="alert-title">{{ alert.title }}</div>
        <div v-html="alert.message"></div>
        <div v-if="alert.acceptLink || alert.cancelLink" class="alert-links">
          <div v-if="alert.acceptLink" class="link" @click="alert.acceptLink.callback">{{ alert.acceptLink.text }}</div>
          <div v-if="alert.cancelLink" class="link" @click="alert.cancelLink.callback">{{ alert.cancelLink.text }}</div>
        </div>
      </div>
      <div v-if="alert.cancelButton" class="lf-icon-close close-icon" @click.stop="closeStaticAlert"></div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from 'vue'
import { AlertsData, AlertsIcons } from '@/store/modules/alerts/alertsTypes'

const { alert } = defineProps({
  alert: {
    type: Object as PropType<AlertsData>,
    required: true
  }
})

const showStaticAlert = ref(true)

const closeStaticAlert = () => {
  showStaticAlert.value = false
}

const getAlertType = computed(() => alert.type)

const getAlertIcon = computed(() => {
  return AlertsIcons[alert.type]
})
</script>

<style lang="scss" scoped>
@include fade-transition;

.static-alert-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: left !important;
  width: 100%;
  padding: $spacing-xs $spacing-sm;
  gap: $spacing-xs;
  border-radius: $cornerRadius-sm;
  margin-bottom: $spacing-md;

  &.info-text {
    background-color: $tertiary-byzantineBlue-050;
  }

  &.error-text {
    background-color: $tertiary-persianRed-050;
  }

  &.success-text {
    background-color: $tertiary-seaGreen-050;
  }

  &.warning-text {
    background-color: $tertiary-saffron-050;
  }

  .lf-icon-close-round-full {
    color: $tertiary-persianRed-200;
  }

  .lf-icon-warning-full {
    color: $tertiary-saffron-200;
  }

  .lf-icon-check-round-full {
    color: $tertiary-seaGreen-200;
  }

  .lf-icon-info-full {
    color: $tertiary-byzantineBlue-200;
  }

  .alert-icon {
    font-size: 22px;
    align-self: flex-start;
  }

  .alert-info-container {
    @include milano-regular-14;
    color: $neutral-grey-800;
    width: 97%;

    .alert-title {
      @include milano-bold-16;
      margin-bottom: 5px;
    }

    .alert-links {
      @include milano-medium-14;
      display: flex;
      align-items: center;
      gap: $spacing-md;
      color: $tertiary-byzantineBlue-200;
      margin-top: $spacing-xs;

      .link {
        cursor: pointer;
      }
    }
  }

  .close-icon {
    position: absolute;
    top: 8px;
    right: 12px;
  }
}
</style>
r
