<template lang="pug"></template>
<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { TranslateResult } from 'vue-i18n'
import { extend } from 'vee-validate'
import { customValidatorTypes } from '@/store/modules/forms/formsTypes'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'

const formsModule: string = ModuleNamespaces.FORMS
const dialogModule = ModuleNamespaces.DIALOG
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
const entitiesModule = ModuleNamespaces.ENTITIES

@Component
export default class IDNumberProfessionalValidatorMixin extends Vue {
  @Action('iDNumberProfessionalValidate', { namespace: formsModule })
  iDNumberProfessionalValidateAction: ({}) => {}

  @Action('showDialog', { namespace: dialogModule })
  showDialog: ({}) => {}

  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegister: (context: string) => any

  @Getter('getSelectedRelatedData', { namespace: selectedRegisterModule })
  selectedRelatedData: any

  @Getter('getCustomValidateField', { namespace: formsModule })
  getCustomValidateField: (key: string) => any

  @Getter('getSelectedEntityName', { namespace: entitiesModule })
  selectedEntityName: string

  mounted() {
    extend('uniqueIDNumberProfessional', {
      validate: (value: string) => {
        return this.validateUniqueIDProfessionalNumber(value)
      },
      message: () => {
        return ''
      }
    })
  }

  async validateUniqueIDProfessionalNumber(value: string): Promise<boolean> {
    const iDNumber: string = this.getCustomValidateField(customValidatorTypes.DNI)

    if (value === iDNumber) {
      return true
    }

    const { id } = this.selectedRegister('customerPanelBillingProfessionals')

    const data: any = await this.iDNumberProfessionalValidateAction({
      params: {
        id: id || 0,
        dni: value
      }
    })

    return this.checkIfContinueToProfessionalsGrid(data[0])
  }

  checkIfContinueToProfessionalsGrid(value: any): boolean {
    if (!value || value.length === 0) {
      return true
    }

    this.showDialog({
      type: DialogTypes.WARNING,
      message: this.$t('components.dialog.professional_id_number_validation', {
        contactType: value.type,
        name: value.name
      }) as TranslateResult,
      mainButtonText: this.$t('action_buttons.no'),
      secondaryButtonText: this.$t('action_buttons.yes'),
      action: () => this.$emit('execute-action', ActionName.CLOSE)
    })
    return true
  }
}
</script>
