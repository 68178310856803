<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class MaskTextBoxClearButtonMixin extends Vue {
  onTextBoxCreated() {
    const maskTextBox: any = this.$refs.maskTextBox
    if (maskTextBox) {
      maskTextBox.$el.addEventListener('mouseover', this.showClearButton)
      maskTextBox.$el.addEventListener('mouseout', this.hideClearButton)
    }
  }

  onTextBoxDestroyed() {
    const maskTextBox: any = this.$refs.maskTextBox
    if (maskTextBox) {
      maskTextBox.$el.removeEventListener('mouseover', this.showClearButton)
      maskTextBox.$el.removeEventListener('mouseout', this.hideClearButton)
    }
  }

  showClearButton() {
    const maskTextBox: any = this.$refs.maskTextBox
    if (maskTextBox) {
      const clearButton = maskTextBox.$el.parentNode.querySelector('.e-clear-icon')
      if (clearButton.classList.contains('e-clear-icon-hide') && maskTextBox.value) {
        clearButton.classList.remove('e-clear-icon-hide')
      }
    }
  }

  hideClearButton(event: MouseEvent) {
    const maskTextBox: any = this.$refs.maskTextBox
    if (maskTextBox) {
      const clearButton = maskTextBox.$el.parentNode.querySelector('.e-clear-icon')
      const isTarget: boolean = event.relatedTarget === clearButton
      if (!clearButton.classList.contains('e-clear-icon-hide') && !isTarget) {
        clearButton.classList.add('e-clear-icon-hide')
      }
    }
  }
}
</script>
