<template lang="pug">
div(class="pdf-preview-container")

  PdfPreviewToolbarComponent(
    ref="toolbar"
    :totalNumberOfPages="totalNumberOfPages"
    @next-page-event="goToNextPage"
    @previous-page-event="goToPreviousPage"
    @close-viewer-event="closeViewerEvent"
  )

  PdfPreviewMagnificationComponent(
    @page-fit-event="pageFit"
    @zoom-in-event="zoomIn"
    @zoom-out-event="zoomOut"
  )

  ejs-pdfviewer(
    v-if="!isLoading"
    ref="pdfViewer"
    id="pdf-viewer"
    documentPath="empty.pdf"
    :serviceUrl="serviceUrl"
    :documentLoad="onDocumentLoad"
    :ajaxRequestInitiate="onAjaxRequestInit"
    :enableToolbar="false"
    :enableThumbnail="false"
    :enableNavigationToolbar="false"
    :ajaxRequestFailed="onServiceFailure"
    :documentLoadFailed="onServiceFailure"
  )
</template>

<script lang="ts">
import { Vue, Component, Emit } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import PdfPreviewToolbarComponent from '@/components/FileManager/Preview/components/PdfPreviewComponent/PdfPreviewToolbarComponent.vue'
import PdfPreviewMagnificationComponent from '@/components/FileManager/Preview/components/PdfPreviewComponent/PdfPreviewMagnificationComponent.vue'
import { PdfViewerPlugin, PdfViewer, Magnification, Navigation } from '@syncfusion/ej2-vue-pdfviewer'
import { DocumentPreview } from '@/components/FileManager/Preview/types/PreviewTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

Vue.use(PdfViewerPlugin)

const authModule: string = ModuleNamespaces.AUTH

// https://ej2.syncfusion.com/documentation/pdfviewer/getting-started/
// Pdf documents (.pdf)
@Component({
  components: {
    PdfPreviewMagnificationComponent,
    PdfPreviewToolbarComponent
  },
  provide: {
    PdfViewer: [Magnification, Navigation]
  }
})
export default class PdfPreviewComponent extends Vue {
  @Action('cancelPendingRequests', { namespace: authModule })
  cancelPendingRequests: () => {}

  @Getter('getAuthToken', { namespace: authModule }) authToken: string

  ajaxRequest: XMLHttpRequest[] = []
  currentDocument: DocumentPreview
  originalRequest: any
  isLoading = true

  $refs!: {
    toolbar: any
    pdfViewer: any
  }

  totalNumberOfPages = 0

  get pdfViewerInstance(): PdfViewer {
    return this.$refs.pdfViewer ? this.$refs.pdfViewer.ej2Instances : null
  }

  get toolbarInstance() {
    return (this.$refs.toolbar = this.$refs.toolbar.ej2Instances)
  }

  get serviceUrl() {
    return process.env.VUE_APP_SYNCFUSION_PDF_API
  }

  @Emit()
  closeViewerEvent() {}

  mounted() {
    this.addXMLRequestCallback()
  }

  destroyed() {
    if (this.ajaxRequest.length) {
      this.ajaxRequest.map((request) => {
        if (request && typeof request.abort === 'function' && request.onabort !== null) {
          request.abort()
        }
      })
    }
    XMLHttpRequest.prototype.open = this.originalRequest
  }

  addXMLRequestCallback() {
    this.originalRequest = XMLHttpRequest.prototype.open
    const me = this
    XMLHttpRequest.prototype.open = function () {
      me.ajaxRequest.push(this)
      this.addEventListener('load', () => {
        me.ajaxRequest = me.ajaxRequest.filter((request) => request !== this)
      })
      me.originalRequest.apply(this, arguments as any)
    }
  }

  onServiceFailure() {
    this.$emit('failPreview')
  }

  goToPreviousPage() {
    this.pdfViewerInstance.navigation.goToPreviousPage()
  }

  goToNextPage() {
    this.pdfViewerInstance.navigation.goToNextPage()
  }

  pageFit() {
    this.pdfViewerInstance.magnification.fitToPage()
  }

  zoomIn() {
    this.pdfViewerInstance.magnification.zoomIn()
  }

  zoomOut() {
    this.pdfViewerInstance.magnification.zoomOut()
  }

  async preview(documentPreview: DocumentPreview) {
    this.currentDocument = documentPreview

    this.isLoading = false

    await this.$nextTick()

    if (!this.pdfViewerInstance) {
      return
    }

    this.pdfViewerInstance.load('empty.pdf', '')
  }

  onAjaxRequestInit(args: any) {
    if (args.name === 'ajaxRequestInitiate') {
      if (!this.currentDocument || !this.currentDocument.id) {
        return
      }

      this.pdfViewerInstance.ajaxRequestSettings.ajaxHeaders = [
        {
          headerName: 'Authorization',
          headerValue: `Bearer ${this.authToken}`
        }
      ]

      args.JsonData.isFileName = false
      args.JsonData.documentId = this.currentDocument.id

      this.pdfViewerInstance.setJsonData(args.JsonData)
    }
  }

  onDocumentLoad() {
    this.totalNumberOfPages = this.pdfViewerInstance.pageCount
  }
}
</script>

<style lang="scss">
@import '~@syncfusion/ej2-base/styles/material.css';
@import '~@syncfusion/ej2-icons/styles/material.css';
@import '~@syncfusion/ej2-inputs/styles/material.css';
@import '~@syncfusion/ej2-popups/styles/material.css';
@import '~@syncfusion/ej2-buttons/styles/material.css';
@import '~@syncfusion/ej2-splitbuttons/styles/material.css';
@import '~@syncfusion/ej2-layouts/styles/material.css';
@import '~@syncfusion/ej2-grids/styles/material.css';
@import '~@syncfusion/ej2-pdfviewer/styles/material.css';

.e-filemanager {
  .e-pane:not(.e-layout-content):not(.e-navigation) {
    overflow: hidden !important;
  }

  .pdf-preview-container {
    position: relative;
    width: 100% !important;
    padding-top: 33px;

    .e-toolbar {
      height: 120px !important;
      bottom: 20px !important;
    }

    #pdf-viewer {
      height: 100% !important;
      width: 100% !important;

      .e-spinner-pane .e-spinner-inner .e-spin-material {
        stroke: $corporate-color;
      }

      .e-pv-viewer-container {
        @include scroll-styles;
        width: 100% !important;
        border-bottom: none !important;
      }
    }
  }
}
</style>
