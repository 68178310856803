<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { EndpointEntity, ParamsEntity } from '@/store/modules/endpoint/endpointTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { uuid } from '@/helpers/helpers'
import store from '@/store/store'
import { Getter } from 'vuex-class'

const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER

@Component
export default class FieldBehaviorsMixin extends Vue {
  @Prop({
    type: String
  })
  validationRules!: string

  @Prop({
    type: Boolean,
    default: false
  })
  disabled: boolean

  @Prop({
    type: String
  })
  validatorName!: string

  /**
   * Validation Mode event
   * @values  aggressive | passive | lazy | eager
   * Aggressive: Triggered when the user presses a key (on input).
   * Passive: Triggered when the form is submitted.
   * Lazy: Triggered when the user leaves the input (on blur or change).
   * Eager: Is a combination of aggressive and lazy, as it first validates when the user leaves the input
   *  then if the input is invalid it will behave aggressively until the input is valid again
   *  and it will go back to being lazy.
   * ...more information: https://vee-validate.logaretm.com/v3/guide/interaction-and-ux.html#interaction-modes
   */
  @Prop({
    type: String,
    default: 'aggressive'
  })
  validationMode!: string

  @Getter('getParentRegisterId', { namespace: selectedRegisterModule })
  getParentRegisterId: number

  validator: any = null
  validatorRules: string = this.validationRules || ''
  validationName: string = this.validatorName || `validator-${uuid()}`

  @Watch('disabled')
  changeDisabled(disabled: boolean) {
    if (disabled) {
      this.validatorRules = ''
    } else if (this.validationRules) {
      this.validatorRules = this.validationRules
    }
    this.onChangeDisabled(disabled)
  }

  initValidator(validator: any) {
    this.validator = validator
    if (this.disabled) {
      this.validatorRules = ''
    }
  }

  get requiredField() {
    return this.validationRules && this.validationRules.includes('required') && !this.disabled
  }

  onChangeDisabled(_disabled: boolean) {}

  validateField() {
    if (this.validator && !this.disabled) {
      return this.validator.validate()
    }
    return true
  }

  parseEndpointEntity(endpoint: EndpointEntity) {
    const filterOptions = {
      idUser: store.state.authModule.authData.user.id,
      idStage: store.state.expedientsModule.stageStatus,
      selectedRegisterId: this.getParentRegisterId || this.$route.params.selectedRegisterId,
      invoiceType: store.state.invoicesModule.invoiceType || null,
      idEntityType: store.state.entitiesModule.idEntityType,
      selectedActionType: store.state.actionsModule.selectedActionType,
      fileManagerItem: store.state.fileManagerModule.fileManagerItem
    }
    const values: string[] = []
    const parsedParams: any = {}
    if ('undefined' === typeof endpoint.params.forEach) {
      return endpoint
    }
    endpoint.params.forEach((item: ParamsEntity) => {
      if ((filterOptions as any)[item.value]) {
        item.value = (filterOptions as any)[item.value]
        values.push(item.value)
      }
      parsedParams[item.name] = item.value
    })
    endpoint.params = parsedParams
    endpoint.url = String.format(endpoint.url, ...values)

    return endpoint
  }
}
</script>
