<template lang="pug">
div
  LfHeaderTitleComponent(:title="$t('views.billing_provider_bankdrafts.title')" class="header-title")
  LfTotalsComponent(
    v-if="totals && totals.length"
    :items="totals"
  )
  GridTable(
    v-if="renderGrid && !showSpinnerLayer"
    ref="grid"
    :itemsData="billingDataFormatted"
    :gridConfiguration="gridConfiguration"
    :toolbarOptions="toolbarOptionsItems"
    :contextMenuItems="contextMenuItems"
    :listName="listConfiguration['Alias']"
    :context="context"
    @gridTableFilter="onGridTableFilter"
    @toolbarClicked="onToolbarClicked"
    :archivedFilter="archivedSelectedOption"
    :commandClick="onCommandClick"
    @customToolbarContextMenuClick="onCustomToolbarContextMenuClick"
    excelExportedFileName="Pagos de proveedores"
  )

  ejs-contextmenu(
    :id="idContextMenu"
    class="context-menu-component e-contextmenu-archived"
    :items="invoicesArchivedContextMenuItemsParsed"
    :select="onInvoicesArchivedClick"
  )

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import ArchivedFilterMixin from '@/mixins/ArchivedFilterMixin.vue'
import { Action, Getter } from 'vuex-class'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { BillingTypes, TotalItem } from '@/store/modules/billing/billingTypes'
import LfHeaderTitleComponent from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'
import LfTotalsComponent from '@/components/LfTotals/LfTotalsComponent.vue'
import { ActionName, CommandClickEventArgs, CommandModel } from '@/components/grids/LfGrid/LfGridTypes'
import { Icons } from '@/icons/icons'
import { gridConfigurationWithActionColumn } from '@/helpers/grid'
import { URLS } from '@/router/routes/urlRoutes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import {
  columnsChooserToolbarItem,
  filterToolbarItem,
  searchToolbarItem
} from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import { ContextMenuClickEventArgs } from '@syncfusion/ej2-vue-grids'

const billingModule: string = ModuleNamespaces.BILLING
const dialogModule = ModuleNamespaces.DIALOG

@Component({
  components: {
    LfHeaderTitleComponent,
    LfTotalsComponent,
    GridTable
  }
})
export default class ProviderBankdraftsView extends mixins(ListViewMixin, ArchivedFilterMixin) {
  @Action('fetchProviderBankdraftList', { namespace: billingModule })
  fetchProviderBankdraftList: (filter: {}) => Promise<void>

  @Action('fetchProviderBankdraftTotals', { namespace: billingModule })
  fetchProviderBankdraftTotals: (filter: {}) => Promise<void>

  @Action('showDialog', { namespace: dialogModule })
  showDialogAction: ({}) => {}

  @Action('billingRemove', { namespace: billingModule })
  removeProviderBandraft: (url: string) => { success: boolean; message: string }

  @Getter('getTotals', { namespace: billingModule })
  totals: TotalItem[]

  idContextMenu: string = 'invoicesArchivedContextMenuComponent'
  gridFilters: any = {}

  get context() {
    return ContextName.BILLING
  }

  get filters() {
    return {
      ...this.gridFilters,
      archived: this.archivedSelectedOption
    }
  }

  get toolbarOptionsItems() {
    const { filters } = this.toolbarOptionsTooltipTexts
    return [
      searchToolbarItem,
      filterToolbarItem,
      columnsChooserToolbarItem,
      {
        id: ActionName.CUSTOM_TOOLBAR_CONTEXT_MENU,
        align: 'Right',
        cssClass: 'lf-btn-kebak',
        prefixIcon: Icons.KEBAB,
        contextMenuItems: [
          ...(!this.isPortalUser
            ? [
                {
                  id: ActionName.ARCHIVED,
                  iconCss: Icons.FILTER,
                  text: filters,
                  items: this.selectArchivedOption
                }
              ]
            : []),
          {
            id: ActionName.PRINT,
            iconCss: Icons.PRINT,
            text: this.$t('components.grid_table.tooltip.print').toString()
          },
          {
            id: ActionName.EXPORT,
            iconCss: Icons.DOWNLOAD,
            text: this.$t('components.grid_table.tooltip.export').toString()
          }
        ]
      }
    ]
  }

  get gridConfiguration() {
    const config = JSON.parse(this.listConfiguration['Config'])
    const { canDelete } = this.viewPermission
    const commandBtns: CommandModel[] = canDelete
      ? [
          {
            id: ActionName.OPEN_NEW_TAB,
            type: 'None',
            title: this.$t('action_buttons.open_new_window').toString(),
            buttonOption: {
              iconCss: Icons.OPEN_WINDOW,
              cssClass: 'custombutton'
            }
          },
          {
            id: ActionName.REMOVE,
            type: 'None',
            title: this.$t('action_buttons.remove').toString(),
            buttonOption: {
              iconCss: Icons.REMOVE,
              cssClass: 'custombutton'
            }
          }
        ]
      : [
          {
            id: ActionName.OPEN_NEW_TAB,
            type: 'None',
            title: this.$t('action_buttons.open_new_window').toString(),
            buttonOption: {
              iconCss: Icons.OPEN_WINDOW,
              cssClass: 'custombutton'
            }
          }
        ]

    const listConfig = gridConfigurationWithActionColumn(config, commandBtns)
    return JSON.stringify(listConfig)
  }

  async created() {
    await this.fetchCurrentListConfiguration(ListNames.PROVIDER_BANKDRAFTS)
    this.initializeArchivedSelectedOption({ selectedOption: 1, contextMenu: this.idContextMenu })
    await this.fetchGridItems()
    this.saveSelectedEntityName(BillingTypes.PROVIDER_BANKDRAFTS)
    this.renderGrid = true
    this.hideSpinnerLayerAction()
  }

  async fetchGridItems() {
    await this.fetchProviderBankdraftList({ archived: this.archivedSelectedOption })
  }

  onGridTableFilter(filters: any) {
    this.gridFilters = filters
    this.fetchProviderBankdraftTotals(this.filters)
  }

  onToolbarClicked(args: any) {
    const target = args.originalEvent.target.closest('button')
    if (target && target.id === 'archived') {
      this.toggleArchivedContextMenu(args.originalEvent, this.idContextMenu)
      args.cancel = true
    }
  }

  async onInvoicesArchivedClick(args: any) {
    this.archivedSelectedOption = args.item.actionType
    this.changeContextMenuIcons(args.item.actionType, this.idContextMenu)
    await this.saveConfig(this.archivedSelectedOption)
    await this.fetchGridItems()
    await this.fetchProviderBankdraftTotals(this.filters)
  }

  onCommandClick({ commandColumn, rowData }: CommandClickEventArgs) {
    const action = commandColumn?.id
    const { id, description } = rowData as any
    switch (action) {
      case ActionName.REMOVE:
        this.confirmDelete(id, description)
        break
      default:
        this.open(id, '_blank')
        break
    }
  }

  open(id: string, target = '_self') {
    const routeData = this.$router.resolve({
      name: `${URLS.BILLING}-${URLS.PROVIDER_BANKDRAFTS}`,
      params: { selectedRegisterId: id }
    })
    if (routeData && target === '_blank') {
      window.open((routeData as any).href, '_blank')
    } else {
      this.$router.push((routeData as any).resolved)
    }
  }

  confirmDelete(id: string, name: string) {
    this.showDialogAction({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_register_text', {
        register: name,
        text: this.$t('components.dialog.provider_bankdraft_literal')
      }),
      action: async () => {
        await this.remove(id, name)
        ;(this as any).$refs.grid.refresh()
      },
      mainButtonText: this.$t('action_buttons.remove'),
      secondaryButtonText: this.$t('action_buttons.cancel')
    })
  }

  async remove(id: string, name: string) {
    const url = `/invoices/providers/bankdraft/${id}/delete`
    const { success } = await this.removeProviderBandraft(url)
    if (success) {
      this.showDialogAction({
        type: DialogTypes.SUCCESS,
        message: this.$t('components.dialog.success_removed_register', {
          text: this.$t('components.dialog.provisions_literal'),
          register: name
        })
      })
    } else {
      this.showDialogAction({
        type: DialogTypes.ERROR,
        message: this.$t('components.dialog.error_remove_order_form')
      })
    }
  }

  onCustomToolbarContextMenuClick(args: ContextMenuClickEventArgs) {
    const { item } = args
    const { id } = item

    if (id?.includes(ActionName.ARCHIVED) && !this.isArchiveContextMenuFirstLevel(id)) {
      this.onInvoicesArchivedClick(args)
      return
    }
  }
}
</script>
