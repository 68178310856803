<template lang="pug">

v-dialog(
  v-if="isOpened"
  v-model="isOpened"
  :transition="transition"
  persistent
  scrollable
  v-bind="dialogProps"
)
  v-card
    header(class="header")
      v-card-title {{ title }}
    v-card-text(
      class="card-text sub-header"
      v-if="subTitle"
    )
      span(v-html="subTitle")
    v-card-text(class="card-text")
      slot(name="body")
        span(class="required-fields-advice") {{ $t('components.form_generator.required_fields') }}
        FormGeneratorComponent(
          :schema="formSchema"
          :fieldValues="formFieldValues"
          :context="context"
          formTag="selectForm"
          class="form-generator"
          @execute-action="executeAction"
          @saveFormData="onSaveFormData"
        )
    v-card-actions(v-if="showActionButtons")
      v-spacer
      LfActionsBarComponent(
        class="action-buttons"
        :buttons="buttons"
        @execute-action="executeAction"
        :showKebabMenu="false"
      )

</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { i18n } from '@/plugins/vue-i18n'
import { Icons } from '@/icons/icons'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import LfActionsBarComponent from '@/components/ActionsBar/LfActionsBarComponent.vue'
import { LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'

const namespace = ModuleNamespaces.FORMS

interface ButtonsText {
  closeButton: string
  saveButton: string
}

@Component({
  components: {
    LfActionsBarComponent,
    FormGeneratorComponent: () => import('@/components/forms/FormGenerator/FormGeneratorComponent.vue')
  }
})
export default class PromptDialogComponent extends Vue {
  @Prop({
    type: Number,
    default: 352
  })
  width!: number

  @Prop({
    type: String,
    required: true
  })
  title!: string

  @Prop({
    type: Boolean,
    default: false
  })
  hideOverlay!: string

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  subTitle!: string

  @Prop({
    type: String,
    required: true
  })
  context!: string

  @Prop({
    type: Boolean,
    default: false
  })
  isOpened!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  showActionButtons!: boolean

  @Prop({
    type: String,
    default: ''
  })
  transition!: string

  @Prop({
    type: Array
  })
  formSchema!: () => []

  @Prop({
    type: Object,
    default: () => ({})
  })
  formFieldValues!: object

  @Prop({
    type: Object,
    default: () => {
      return {
        closeButton: i18n.t('action_buttons.cancel'),
        saveButton: i18n.t('action_buttons.save')
      }
    }
  })
  texts!: ButtonsText

  @Getter('checkIfFormIsValid', { namespace })
  checkIfFormIsValid: (context: string) => boolean

  closeData = {
    icon: Icons.CLOSE,
    action: ActionName.CLOSE
  }

  @Emit()
  executeAction(value: string) {
    return value
  }

  get buttons() {
    return [
      {
        icon: Icons.CLOSE,
        tooltip: this.texts.closeButton,
        class: 'secondary-color',
        action: ActionName.CLOSE,
        variant: LabelButtonVariant.OUTLINED,
        enabled: true
      },
      {
        icon: Icons.CHECK,
        tooltip: this.texts.saveButton,
        class: 'main-color',
        action: ActionName.SAVE,
        enabled: this.checkIfFormIsValid(this.context)
      }
    ]
  }

  get dialogProps(): object {
    return {
      width: this.width,
      title: this.title,
      'hide-overlay': this.hideOverlay
    }
  }

  onSaveFormData(schema: [], formData: object, field: any, value: any) {
    this.$emit('changeFormField', schema, formData, field, value)
  }
}
</script>

<style lang="scss" scoped>
.v-dialog__content {
  background-color: $dialog-background-color;
  pointer-events: unset;

  .required-fields-advice {
    @include milano-regular-14;
    display: flex;
    position: relative;
    color: $neutral-grey-600;
    align-self: flex-end;
    padding-right: 16px;
    width: 100%;
    justify-content: flex-end;

    &:before {
      @include milano-bold-20;
      display: inline-block;
      position: relative;
      top: -4px;
      content: '*';
      color: $subject-fireEngineRed;
      margin-right: 3px;
    }
  }

  ::v-deep .v-dialog {
    border-radius: $cornerRadius-md;
    border: 1px solid $main-200;
    background: $neutral-white-000;
    box-shadow: $shadow-md;

    .form-generator .form {
      width: 100%;
    }

    .v-card {
      background-color: $white-01;
      border-radius: 0;
      padding: $spacing-xl;

      .header {
        display: flex;
        align-items: center;
        height: 46px;
        padding: $spacing-xs;
        border-radius: $cornerRadius-sm;
        background: $main-1000;

        .v-card__title {
          @include ellipsis;
          @include milano-bold-18;
          color: $neutral-white-000;
          padding: 0px $spacing-xs;
        }
      }

      .sub-header {
        @include flex;
        span {
          @include flex;
          font-family: $corporate-font;
          color: $blue-04;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          padding: 20px 0;
        }
      }

      .v-card__actions {
        padding: 0;
      }

      .form-generator > div {
        padding: 0 !important;
      }

      .card-text {
        @include scroll-styles;
        overflow-x: hidden;
        padding: 0;
        margin-top: $spacing-xl;
        > div {
          @include flex($flex-direction: column);

          > div {
            padding: 0;
          }
        }
      }

      .action-buttons {
        margin-top: $spacing-xl;
      }
    }
  }
}
</style>
