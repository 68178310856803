<template lang="pug">
  div
    div(class="expedient-actions")
      GridTableComponent(
        v-if="gridColumns"
        ref="grid"
        :itemsData="gridData"
        :gridConfiguration="gridColumns"
        :title="title"
        :toolbarOptions="customToolbarOptions"
        :contextMenuItems="contextMenuItemsWithoutRemove"
        :listName="listName"
        :context="agendaContext"
        :showHeader="false"
        isInTab
        :commandClick="onCommandClick"
        @cellClicked="onCellClicked"
        @contextMenuClicked="onContextMenuClicked"
      )

    div(v-if="showCalendarDialog" class="dialog-container" @click.stop="checkIfHideDialog")
      div(class="dialog-message-container")
        header(class="dialog-header")
          span(class="dialog-header-text") {{ labelDialog }}
          span(@click.stop="hideDialog" :class="[icons.close, 'icon-close']")
        div(v-show="showSpinner" class="lf-dialog-spinner")
          SpinnerLayerComponent(class="spinner-layer")
        iframe(
          :src="url"
          frameborder="0"
          allowfullscreen)

</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import { Action, Getter } from 'vuex-class'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { ContextMenuItem } from '@/store/modules/contextMenu/contextMenuTypes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { URLS } from '@/router/routes/urlRoutes'
import GridTableComponent from '@/components/grids/GridTable/GridTableComponent.vue'
import GridToolbarOptionsMixin from '@/mixins/GridToolbarOptionsMixin.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import {
  ActionName,
  CommandClickEventArgs,
  CommandModel,
  GridConfiguration,
  commandButtonType
} from '@/components/grids/LfGrid/LfGridTypes'
import { gridConfigurationWithActionColumn } from '@/helpers/grid'
import {
  columnsChooserToolbarItem,
  kebakMenuToolbarItem,
  searchToolbarItem
} from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'

const appointmetsModule = ModuleNamespaces.APPOINTMENTS
const authModule = ModuleNamespaces.AUTH
const dialogModule = ModuleNamespaces.DIALOG
const expedientsModule: string = ModuleNamespaces.EXPEDIENTS
const lexboxModule = ModuleNamespaces.LEXBOX
const listItemsModule = ModuleNamespaces.LIST_ITEMS
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    GridTableComponent,
    SpinnerLayerComponent,
    TabHeaderComponent: () => import('@/components/tabs/TabHeader/TabHeaderComponent.vue'),
    TabsComponent: () => import('@/components/tabs/TabsComponent/TabsComponent.vue')
  }
})
export default class ExpedientAgendaComponent extends Mixins(GridToolbarOptionsMixin) {
  @Prop({
    type: Number,
    required: true
  })
  expedientId!: string

  @Prop({
    type: Array,
    required: true
  })
  gridContextMenuItems!: ContextMenuItem[]

  @Prop({
    type: Object,
    default: () => {}
  })
  columns!: GridConfiguration

  @Prop({
    type: String,
    default: ''
  })
  title!: string

  @Prop({
    type: String,
    default: ''
  })
  listName!: string

  @Prop({
    type: Boolean,
    default: false
  })
  showGrid!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  showFilters!: boolean

  @Action('fetchExpedientsAgenda', { namespace: expedientsModule })
  fetchExpedientsAgenda: ({}) => void

  @Action('deleteAppointment', { namespace: appointmetsModule }) deleteAppointment: (id: string) => void
  @Action('setExpedientStageStateForm', { namespace: expedientsModule })
  setExpedientStageStateForm: ({}) => void
  @Action('saveSelectedRegisterId', { namespace: selectedRegisterModule })
  saveSelectedRegisterId: ({}) => Promise<void>
  @Action('fetchCalendarServiceUrl', { namespace: lexboxModule })
  fetchServiceUrl: (calendarQuery: object) => void
  @Action('showDialog', { namespace: dialogModule }) showDialogInfo: ({}) => {}

  @Getter('getUserName', { namespace: authModule }) userName: string
  @Getter('getNavisionId', { namespace: authModule }) navisionId: number
  @Getter('getUserId', { namespace: authModule }) userId: number
  @Getter('getListItemsWithFormattedDates', { namespace: listItemsModule })
  gridData: []
  @Getter('getCompanyAppointmentsVersion', { namespace: authModule })
  calendarVersion: number
  @Getter('getEventCalendarServiceUrl', { namespace: lexboxModule })
  eventServiceUrl: string

  stageField: { field: any; index: number } = { field: null, index: 0 }

  // gridColumns = this.columns

  agendaContext: string = ContextName.EXPEDIENT_AGENDA

  showCalendarDialog: boolean = false

  labelDialog = this.$t('components.schedule.edit_event')

  icons = {
    close: Icons.CLOSE
  }

  url: string = ''

  showSpinner = true

  actualId: number | null = null

  get contextMenuItemsWithoutRemove() {
    return [
      {
        text: this.$t('components.context_menu.edit'),
        iconCss: Icons.EDIT
      },
      {
        text: this.$t('components.context_menu.open_window'),
        iconCss: Icons.OPEN_WINDOW
      }
    ]
  }

  get customToolbarOptions() {
    return [searchToolbarItem, columnsChooserToolbarItem, kebakMenuToolbarItem]
  }

  get gridColumns() {
    if (!this.columns) {
      return ''
    }
    const config = this.columns
    const commandButtons: CommandModel[] = [
      {
        id: ActionName.OPEN_NEW_TAB,
        type: commandButtonType.NONE,
        title: this.$t('action_buttons.open_new_window').toString(),
        buttonOption: {
          iconCss: Icons.OPEN_WINDOW,
          cssClass: 'custombutton'
        }
      }
    ]
    const gridConfiguration = gridConfigurationWithActionColumn(config, commandButtons)
    return JSON.stringify(gridConfiguration)
  }

  @Watch('eventServiceUrl')
  getUrl(value: string) {
    this.url = value
    if (value) {
      this.showSpinner = false
    }
  }

  async created() {
    window.addEventListener('message', this.eventReceived)
  }

  destroyed() {
    window.removeEventListener('message', this.eventReceived)
  }

  eventReceived = (event: any) => {
    try {
      const data = JSON.parse(event.data)
      if (event.data && data.actionCancelled) {
        if (data.removed === true) {
          this.deleteEvent()
        } else if (data.message === 'Not Found') {
          this.showDialogInfo({
            type: DialogTypes.INFO,
            message: this.$t('components.dialog.event_not_found_in_calendar'),
            action: this.deleteEvent
          })
        } else if (data.message === 'Not Account') {
          this.showDialogInfo({
            type: DialogTypes.ERROR,
            message: this.$t('components.dialog.event_not_account')
          })
        } else if (data.message === 'Not Permission') {
          this.showDialogInfo({
            type: DialogTypes.ERROR,
            message: this.$t('components.dialog.event_not_permission')
          })
        }
        this.hideDialog()
      }
    } catch (error) {}
  }

  async deleteEvent() {
    await this.deleteAppointment((this as any).actualId)
    this.hideDialog()
  }

  openDialog() {
    this.showCalendarDialog = true
  }

  checkIfHideDialog($event: Event) {
    const elementClicked = $event.target as HTMLElement
    if (elementClicked.classList.contains('dialog-container')) {
      this.hideDialog()
    }
  }

  async hideDialog() {
    this.showCalendarDialog = false
    this.showSpinner = true
    this.url = ''
    await this.reloadData()
  }

  async reloadData() {
    await this.fetchExpedientsAgenda({
      selectedRegisterId: this.expedientId
    })
  }

  async onCellClicked(selectedRegister: any) {
    this.actualId = selectedRegister.id
    this.loadServiceUrl(selectedRegister)
    this.openDialog()
  }

  loadServiceUrl(selectedRegister: any) {
    const calendarParams = {
      query: {
        idActuation: selectedRegister.idAction,
        idEvent: selectedRegister.idEvent,
        idCalendar: selectedRegister.idCalendar,
        calendarName: selectedRegister.calendarName,
        iCalUId: selectedRegister.iCalUId,
        account: selectedRegister.account,
        title: selectedRegister.subject,
        idClienteLef: this.navisionId,
        idUserApp: this.userId,
        idUser: selectedRegister.idUser,
        name: this.userName
      },
      provider: selectedRegister.provider
    }
    this.fetchServiceUrl(calendarParams)
  }

  async onContextMenuClicked(args: any, selectedRegister: any) {
    if (args.item) {
      if (args.item.text === this.$t('components.context_menu.edit')) {
        this.onCellClicked(selectedRegister)
      }
      if (args.item.text === this.$t('components.context_menu.open_window')) {
        this.open(selectedRegister.id)
      }
    }
    args.cancel = true
  }

  open(id: string) {
    const routeData = this.$router.resolve({ name: `${URLS.AGENDA}`, query: { id } })
    window.open(routeData.href, '_blank')
  }

  onCommandClick({ commandColumn, rowData }: CommandClickEventArgs) {
    const action = commandColumn?.id
    const { id } = rowData as any
    if (action === ActionName.OPEN_NEW_TAB) {
      this.open(id)
    }
  }
}
</script>

<style lang="scss" scoped>
.expedient-actions {
  .spinner-layer {
    width: 100%;
    height: 100%;
    min-height: 470px;
    background-color: $white-01;
  }

  .tab-header-container {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  ::v-deep #file-manager_layout {
    border: 1px solid $corporate-color !important;
  }
}
.dialog-container {
  @include flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: auto;

  .dialog-message-container {
    @include flex($flex-direction: column);
    position: absolute;
    background-color: $white-01;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    margin: 0.5rem;
    max-width: 700px;

    .dialog-header {
      @include flex;
      align-self: flex-end;
      padding: 1rem 1.5rem;
      .dialog-header-text {
        position: absolute;
        left: 20px;
        color: $blue-01;
        font-family: $corporate-font;
        font-size: 24px;
      }
    }

    .icon-close {
      align-self: baseline;
      font-size: 18px;
      color: $corporate-color;
      margin-left: 28px;
      cursor: pointer;

      &:hover {
        color: $blue-04;
      }
    }

    iframe {
      width: 698px;
      height: 600px;
    }
  }
  .lf-dialog-spinner {
    margin-top: 10%;
    position: absolute;
  }
}
</style>
