<template>
  <div class="empty-notes">
    <p class="text-1">{{ text.noNotes }}</p>
    <p class="text-2">{{ text.addNotesMesage }}</p>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n-composable'

// TRANSLATIONS
const { t } = useI18n()

const text = {
  addNotesMesage: t('components.widgets.expedient_reminder_notes.add_notes_message'),
  noNotes: t('components.widgets.expedient_reminder_notes.no_notes')
}
</script>

<style lang="scss" scoped>
.empty-notes {
  display: flex;
  height: 98px;
  flex-direction: column;
  padding: $spacing-lg $spacing-xl;
  justify-content: center;
  align-items: flex-start;
  gap: $spacing-xxs;
  align-self: stretch;

  p {
    color: $neutral-grey-600;
    margin: 0;

    &.text-1 {
      @include milano-medium-16;
    }

    &.text-2 {
      @include milano-regular-14;
    }
  }
}
</style>
