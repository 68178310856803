<template>
  <div>
    <Grid
      v-if="gridConfiguration"
      :gridConfiguration="gridConfiguration"
      :itemData="contacts"
      :toolbarOptions="toolbarOptions"
      :toolbarClick="onToolbarClick"
      :commandClick="onCommandClick"
      :contextMenuItems="contextMenuItems"
      @rowClick="onRowClick"
      @contextMenuClicked="onContextMenuClicked"
      :exportName="texts.exportName"
    ></Grid>

    <PromptDialog
      :title="parseModalTitle"
      :isOpened="openedPrompt"
      :context="context"
      :formSchema="formSchema"
      :formFieldValues="formData"
      :width="modalWidth"
      @execute-action="onExecuteAction"
    ></PromptDialog>
  </div>
</template>
<script lang="ts" setup>
import { ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { computed, ref } from 'vue'
import Grid from '@/components/grids/LfGrid/LfGridComponent.vue'
import {
  CommandClickEventArgs,
  CommandColumnModel,
  ContextMenuClickEventArgs,
  ContextMenuItemModel,
  RecordClickEventArgs
} from '@syncfusion/ej2-vue-grids'
import { Icons } from '@/icons/icons'
import { ToolbarItemModel } from '@syncfusion/ej2-vue-filemanager'
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations'
import { useI18n } from 'vue-i18n-composable'
import { DialogData, DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { ActionName } from '@/components/grids/LfGrid/LfGridTypes'
import PromptDialog from '@/components/Dialog/PromptDialogComponent.vue'
import useContactContacts from './composables/useContactContacts'
import {
  kebakMenuToolbarItem,
  searchToolbarItem
} from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import useGridConfiguration from '@/composables/useGridConfiguration'

const { t } = useI18n()
const { read, contacts, save, remove } = useContactContacts()

const props = defineProps({
  formSchema: {
    type: String,
    required: true
  },
  listName: {
    type: String,
    required: true
  },
  context: {
    type: String,
    default: 'contactContacts'
  },
  modalWidth: {
    type: Number,
    default: 940
  },
  entityType: {
    type: String,
    default: '42'
  }
})

const openedPrompt = ref(false)
const formData = ref({})
const newFormFlag = ref(true)

const texts = {
  buttons: {
    openWindow: t('action_buttons.open_new_window').toString(),
    remove: t('action_buttons.remove').toString(),
    print: t('action_buttons.print').toString(),
    export: t('action_buttons.export').toString(),
    addAssociatedPerson: t('action_buttons.addAssociatedPerson').toString()
  },
  modal: {
    addAssociatedPerson: 'Nueva Persona Asociada',
    editAssociatedPerson: 'Editar Persona Asociada'
  },
  contextMenuItems: {
    edit: t('action_buttons.edit').toString(),
    remove: t('action_buttons.remove').toString()
  },
  exportName: t('entity.contacts_contacts').toString()
}

const commandsBts: CommandColumnModel[] = [
  {
    title: t('action_buttons.open_new_window').toString(),
    type: 'None',
    buttonOption: {
      iconCss: Icons.OPEN_WINDOW,
      cssClass: 'custombutton'
    }
  }
]

const { gridConfiguration } = useGridConfiguration({
  listName: props.listName,
  context: props.context,
  commandButtons: commandsBts
})

async function getFormConfiguration() {
  await store.dispatch(`${ModuleNamespaces.CONFIGURATION}/fetchCurrentViewConfiguration`, {
    objectType: ConfigurationTypes.VIEW,
    alias: props.formSchema,
    context: props.context
  })
}

function showDialog({ type, message, action }: DialogData) {
  store.dispatch(`${ModuleNamespaces.DIALOG}/showDialog`, { type, message, action })
}

// STORE GETTERS
const selectedEntityName = computed(() => store.getters[`${ModuleNamespaces.ENTITIES}/getSelectedEntityName`])

const selectedRegisterId = computed(() =>
  store.getters[`${ModuleNamespaces.SELECTED_REGISTER}/getSelectedRegisterId`](selectedEntityName.value).toString()
)

const selectedRegisterName = computed(() =>
  store.getters[`${ModuleNamespaces.SELECTED_REGISTER}/getSelectedRegisterName`](selectedEntityName.value).toString()
)

const formSchema = computed(() =>
  store.getters[`${ModuleNamespaces.CONFIGURATION}/getCurrentViewConfiguration`](props.context)
)

const permissions = computed(() => store.getters[`${ModuleNamespaces.AUTH}/getPermission`](props.entityType))

// COMPUTEDS
const toolbarOptions = computed((): ToolbarItemModel[] => {
  return [
    searchToolbarItem,
    {
      id: ActionName.ADD,
      text: texts.buttons.addAssociatedPerson,
      align: 'Right',
      tooltipText: texts.buttons.addAssociatedPerson,
      cssClass: 'lf-btn-model4'
    },
    kebakMenuToolbarItem
  ]
})

const contextMenuItems = computed((): ContextMenuItemModel[] => {
  const { canDelete } = permissions.value
  return [
    {
      id: ActionName.EDIT,
      text: texts.contextMenuItems.edit,
      iconCss: Icons.EDIT
    },
    ...(canDelete
      ? [
          {
            id: ActionName.REMOVE,
            text: texts.contextMenuItems.remove,
            iconCss: Icons.REMOVE
          }
        ]
      : [])
  ]
})

const parseModalTitle = computed(() => {
  return newFormFlag.value ? texts.modal.addAssociatedPerson : texts.modal.editAssociatedPerson
})

// EVENTS
const onRowClick = (args: RecordClickEventArgs) => {
  openedPrompt.value = true
  formData.value = args.rowData as any
  newFormFlag.value = false
}

const onToolbarClick = ({ item }: ClickEventArgs) => {
  const { id } = item
  if (ActionName.ADD === id) {
    openedPrompt.value = true
    formData.value = {}
    newFormFlag.value = true
  }
}

const onCommandClick = (args: CommandClickEventArgs) => {
  const { rowData } = args
  const { id, name } = rowData as any
  deleteContact(id, name)
}

const onExecuteAction = async (actionName: string) => {
  if (ActionName.CLOSE === actionName) {
    openedPrompt.value = false
    return
  }
  if (ActionName.SAVE === actionName) {
    await save({
      ...formData.value,
      idContact: selectedRegisterId.value
    })
    openedPrompt.value = false
    read(selectedRegisterId.value)
  }
}

function onContextMenuClicked({ rowInfo, item }: ContextMenuClickEventArgs) {
  const { rowData } = rowInfo as any
  const { id } = item
  if (id === ActionName.REMOVE) {
    deleteContact(rowData.id, rowData.name)
    return
  }
  if (id === ActionName.EDIT) {
    formData.value = rowData
    openedPrompt.value = true
  }
}

// METHODS
function deleteContact(id: string, name: string) {
  showDialog({
    type: DialogTypes.INFO,
    message: t('components.dialog.lexon_delete_contact_contacts_message', {
      associatedPerson: name,
      clientName: selectedRegisterName.value
    }).toString(),
    action: async () => {
      confirmDelete(id, name)
    }
  })
}

async function confirmDelete(idContact: string, name: string) {
  const data = await remove(idContact)
  if (!data) {
    return
  }
  showDialog({
    type: DialogTypes.SUCCESS,
    message: t('components.dialog.lexon_delete_contact_contacts_message_ok', {
      associatedPerson: name
    }).toString()
  })
  read(selectedRegisterId.value)
}

async function init() {
  await getFormConfiguration()
  await read(selectedRegisterId.value)
}
// HOOKS
init()
</script>
