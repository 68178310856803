import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { computed, ComputedRef } from 'vue'
import { UserTool } from '@/store/modules/auth/authTypes'

interface UseUserToolInterface {
  toolList: ComputedRef<UserTool[]>,
  loadTools: () => Promise<void>
}

export default function useUserTool(): UseUserToolInterface {
  const toolList: ComputedRef<UserTool[]> = computed(() => store.getters[`${ ModuleNamespaces.AUTH }/getTools`])
  const loadTools = () => store.dispatch(`${ ModuleNamespaces.AUTH }/loadTools`)
  return {
    toolList,
    loadTools
  }
}
