<template lang="pug">

div(class="lexon-colorpicker-container")
  ejs-colorpicker(
      mode="Palette"
      id="color-picker"
      name="color-picker"
      v-model="color"
      cssClass="lexon-colorpicker-popup"
      v-bind="$attrs"
    )

</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ColorPickerPlugin } from '@syncfusion/ej2-vue-inputs'

Vue.use(ColorPickerPlugin)

@Component
export default class LexonColorPickerComponent extends Vue {
  @Prop({
    type: String,
    default: '#001978'
  })
  defaultValue!: string

  color = ''

  @Watch('color')
  changedColor(color: string) {
    this.$emit('input', color)
  }

  mounted() {
    this.rendereColor()
  }

  rendereColor() {
    if (this.$attrs && this.$attrs.value) {
      this.color = this.$attrs.value
    } else {
      this.color = this.defaultValue
    }
  }
}
</script>

<style lang="scss" scoped>
.lexon-colorpicker-container {
  @include flex;
  height: 74px;

  ::v-deep .lexon-colorpicker-popup {
    @include borders($color: $neutral-grey-400);
    border-radius: $cornerRadius-sm;
    padding: 4px;
    position: relative;
    top: 4px;
    height: $input-size;

    button,
    button:hover,
    button.e-active {
      background: transparent;
    }

    .e-caret {
      width: 30px;

      &::before {
        font-family: $lf-icons-font;
        font-size: 20px;
        color: $corporate-color;
        content: '\e90b';
      }
    }
  }

  .color-name-textfield {
    width: 100%;
  }
}
</style>

<style lang="scss">
.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-colorpicker.e-split-btn .e-selected-color .e-split-preview,
.e-colorpicker-container .e-split-btn-wrapper .e-split-colorpicker.e-split-btn .e-selected-color .e-split-preview {
  border-radius: 50% !important;
}

.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-colorpicker.e-split-btn .e-selected-color,
.e-colorpicker-container .e-split-btn-wrapper .e-split-colorpicker.e-split-btn .e-selected-color {
  background: none !important;
  width: 26px;
  height: 26px;
}
.e-colorpicker-popup.lexon-colorpicker-popup {
  .e-color-palette,
  .e-color-picker {
    .e-switch-ctrl-btn {
      @include flex;

      .e-ctrl-btn {
        display: flex;
        flex-direction: row-reverse;

        button {
          @include main-action-button--model1;
          height: 33px;
          padding: 0 16px;
        }
      }
    }
  }
}
</style>
