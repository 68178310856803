import { DetailsViewSettingsModel } from '@syncfusion/ej2-vue-filemanager'

export enum FirmaContextMenuId {
  CERTIFY_DOCUMENT = 'certity-document',
  FIRMA = 'firma',
  MONITORING_REVIEW = 'monitoring-review',
  SEND_CERTIFIED_EMAIL = 'send-certified-email',
  SEND_CERTIFIED_SMS = 'send-certified-sms',
  SEND_FIRMA = 'send-firma'
}

export enum EditContextMenuId {
  EDIT = 'edit',
  CUT = 'cut',
  COPY = 'copy',
  RENAME = 'rename',
  PASTE = 'paste'
}

export enum SendContextMenuId {
  SEND = 'send',
  EMAIL = 'email',
  PUBLISH = 'publish',
  PUBLISHFOLDER = 'publishFolder',
  UNPUBLISH = 'unpublish',
  UNPUBLISHFOLDER = 'unpublishFolder'
}

export enum OpenMSOffice {
  WOPI = 'wopi',
  LOCAL = 'local'
}

export enum DownloadContextMenuId {
  DOWNLOAD = 'download',
  DOWNLOAD_ORIGINAL = 'download-original',
  DOWNLOAD_AS_PDF = 'download-as-pdf'
}

export interface OpenMSOfficeFileData {
  id: number
  name: string | null
  originalFileName: string
}

export interface DetailsViewSettings extends DetailsViewSettingsModel {
  openMSOffice?: OpenMSOffice
}

export interface HeaderColumn {
  index: number | null
  width: number | null
}

export interface PublishData {
  items: any[]
  publish: boolean
  docsString: string
  allFolders: boolean
  dialogText: string
  dialogSuccessText: string
  dialogButtonText: string
  publishAction: string
  itemsLength: number
}

export interface FinishUploadData {
  docsString: string
  dialogSuccessText: string
}
