<script lang="ts">
import { Action, Getter } from 'vuex-class'
import { Prop, Component } from 'vue-property-decorator'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ContactFields } from './types/ContactFieldTypes'
import TabsView from '@/views/Tabs/TabsView.vue'

const formsModule: string = ModuleNamespaces.FORMS
const entitiesModule: string = ModuleNamespaces.ENTITIES

@Component
export default class ContactsDetailView extends TabsView {
  @Prop({
    type: String
  })
  contactPersonType!: string

  @Action('saveSelectedEntityName', { namespace: entitiesModule })
  saveSelectedEntityName: (type: string) => void

  @Action('prepareRegisterFormDataToSave', { namespace: formsModule })
  prepareFormData: ({}) => void

  @Getter('getRegisterFormData', { namespace: formsModule })
  getFormData: () => object

  contactPersonTypeNames = [
    `${this.$t('routes.new_f')} ${this.$t('navigation.drawer-menu-items.natural_person')}`,
    `${this.$t('routes.new_f')} ${this.$t('navigation.drawer-menu-items.legal_entity')}`
  ]

  created() {
    this.saveSelectedEntityName(this.getContext)
  }

  async onInitForm(params: any) {
    const { formData, schema, isNewForm } = params
    this.isNewRegister = isNewForm
    if (this.getContext === ContextName.CONTACT_NATURAL && isNewForm) {
      formData.reference = await this.fetchMaxCustomerReference()
    }
    this.formLoaded = true
    this.checkIfDisableCharteredFields(formData[ContactFields.CHARTERED], schema)
  }

  checkIfDisableCharteredFields(charteredFieldValueschema: undefined | number, schema: any) {
    if (!charteredFieldValueschema) {
      this.hideCharteredFields(schema, true)
      this.disableField(ContactFields.PROFESSIONAL_TYPE, schema, true)
    }
  }

  get breadcrumbsText() {
    if (this.getSelectedRegisterData) {
      return this.getSelectedRegisterData.name
        ? this.getSelectedRegisterData.name + ' ' + (this.getSelectedRegisterData.firstSurname || '')
        : this.contactPersonTypeNames[parseInt(this.contactPersonType)].charAt(0).toUpperCase() +
            this.contactPersonTypeNames[parseInt(this.contactPersonType)].slice(1).toLowerCase()
    }
    return ''
  }

  onFieldChanged(schema: any, formData: any, field: any, value: any) {
    if (ContactFields.CHARTERED === field.id) {
      const disabled = '0' === value
      this.disableField(ContactFields.PROFESSIONAL_TYPE, schema, disabled)
      if (disabled || formData[ContactFields.PROFESSIONAL_TYPE] !== null) {
        this.hideCharteredFields(schema, disabled)
      }
    }
    if (ContactFields.PROFESSIONAL_TYPE === field.id) {
      const hidden = null === value
      this.hideCharteredFields(schema, hidden)
    }
    if (ContactFields.UNNUMBERED === field.id) {
      const disabled = '1' === value
      const locationFields = schema.find((item: any) => {
        return item.fieldsetId === ContactFields.LOCATION_FIELDS
      })
      this.disableField(ContactFields.NUMBER, locationFields.fields, disabled)
    }
  }

  disableField(fieldId: string, schema: any, disabled: boolean) {
    const field = schema.find((item: any) => {
      return item.id === fieldId
    })
    field.disabled = disabled
  }

  hideCharteredFields(schema: any, hidden: boolean) {
    const charteredFields = schema.find((item: any) => {
      return item.fieldsetId === ContactFields.CHARTERED_FIELDS
    })
    charteredFields.fields.map((item: any) => (item.hidden = hidden))
  }

  async save(redirectToGridTable = true, resolve: any = null, _idObjectType = '') {
    const formData: any = this.getFormData
    formData.idPersonType = this.contactPersonType
    this.prepareFormData(formData)

    const { type } = this.entity(this.getContext)
    const { canSave } = this.checkEntityPermissionsGetter(type)
    if (canSave) {
      await this.saveRegisterFormData({
        endpoint: this.endpoints(this.entity(this.getContext).type).save,
        idSelectedRegister: this.entity(this.getContext).id,
        goToURL: redirectToGridTable ? this.reviewRedirect() : null,
        context: this.getContext
      })
    }

    if (resolve) {
      resolve()
    }
  }
}
</script>
