<template lang="pug">
  div(class="column-info-container")
    span(:class="[idType.icon, 'icon']")
    span {{ idType.text }}
</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import { Vue } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'
import { FirmaRequestTypes, ParsedFirmaAudit } from '@/store/modules/firma/firmaTypes'

@Component({
  name: 'monitoringDialogRequestColumnTemplate'
})
export default class MonitoringDialogRequestColumnTemplateComponent extends Vue {
  data: ParsedFirmaAudit

  texts = {
    signatureRequest: this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.signature_request'),
    certifiedDocument: this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.certified_document'),
    certifiedEmail: this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.certified_email'),
    certifiedSMS: this.$t('components.firma_dialogs.monitoring_and_audits.grid_columns.certified_SMS')
  }

  get idType() {
    switch (this.data.idType) {
      case FirmaRequestTypes.FIRMA_REQUEST:
        return { icon: Icons.SIGNATURE, text: this.texts.signatureRequest }
      case FirmaRequestTypes.CERTIFIED_EMAIL:
        return { icon: Icons.CERTIFIED_EMAIL, text: this.texts.certifiedEmail }
      case FirmaRequestTypes.CERTIFIED_SMS:
        return { icon: Icons.CERTIFIED_SMS, text: this.texts.certifiedSMS }
      case FirmaRequestTypes.CERTIFIED_DOCUMENT:
        return { icon: Icons.CERTIFIED_DOCUMENT, text: this.texts.certifiedDocument }
      default:
        return null
    }
  }
}
</script>

<style lang="scss" scoped>
.column-info-container {
  @include flex($justify-content: flex-start);

  .icon {
    font-size: 18px;
    margin-right: 6px;
  }
}
</style>
