import { MutationTree } from 'vuex'
import { FormsState } from '@/store/modules/forms/formsTypes'
import { Vue } from 'vue-property-decorator'

export const mutations: MutationTree<FormsState> = {
  SET_FORM_IS_VALID(state, context) {
    Vue.set(state.formIsValid, context, true)
  },
  RESET_FORM_IS_VALID(state, context) {
    Vue.set(state.formIsValid, context, false)
  },
  CLEAR_FORM_IS_VALID(state) {
    state.formIsValid = {}
  },
  PREPARE_REGISTER_FORM_DATA(state, formData) {
    state.registerFormData = formData
  },
  RESET_REGISTER_FORM_DATA(state) {
    state.registerFormData = {}
  },
  CHANGE_RELATED_FORM_STATUS(state, { status, context }) {
    Vue.set(state.showRelatedForm, context, status)
  },
  CHANGE_ADITIONAL_FORM_STATUS(state, { status, context }) {
    Vue.set(state.showAditionalForm, context, status)
  },
  VALIDATE_FORM(state) {
    state.validateFormFlag = true
  },
  RESET_VALIDATE_FORM_FLAG(state) {
    state.validateFormFlag = false
  },
  SET_LOADING_FORM_DATA(state, loading) {
    state.loadingFormData = loading
  },
  SET_RELATED_DATA(state, { data, relatedAlias }) {
    Vue.set(state.relatedData, relatedAlias, data)
  },
  RESET_RELATED_DATA(state) {
    state.relatedData = {}
  },
  SET_CUSTOM_VALIDATE_FIELD(state, { field, value }) {
    Vue.set(state.customValidateField, field, value)
  },
  RESET_CUSTOM_VALIDATE_FIELD(state) {
    state.customValidateField = {}
  },
  LOAD_NEW_REGISTER (state, { data, context }) {
    Vue.set(state.newRegister, context, data)
  }
}
