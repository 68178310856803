<template lang="pug">

PrintOrSaveInvoicesStepComponent(
  :stepNumber="stepNumber"
  :title="texts.title"
  :explanation="texts.explanation"
)

  TemplateSelectorTreeComponent(
    :idEntityType="idEntityType"
    class="invoices-template-selector-container"
    @templateSelected="templateSelected"
    @closeDialog="onCloseDialog"
  )

</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import PrintOrSaveInvoicesStepComponent from '@/components/billing/PrintOrSaveInvoicesDialog/PrintOrSaveInvoicesStepComponent.vue'
import TemplateSelectorTreeComponent from '@/components/template/TemplateSelectorComponent/TemplateSelectorTreeComponent.vue'
import { ITemplate } from '@/store/modules/template/templateTypes'
import { PrintOrSaveInvoicesSteps } from './types/PrintOrSaveInvoicesDialogTypes'

@Component({
  components: {
    PrintOrSaveInvoicesStepComponent,
    TemplateSelectorTreeComponent
  }
})
export default class InvoicesTemplateSelectorComponent extends Vue {
  @Prop({
    type: [Number, String],
    required: true
  })
  idEntityType!: number

  texts = {
    explanation: this.$t('components.invoice_template_selector.explanation'),
    title: this.$t('components.invoice_template_selector.title')
  }

  stepNumber = PrintOrSaveInvoicesSteps.SELECT_INVOICES

  @Emit('templateSelectedInvoice')
  templateSelected(templateSelected: ITemplate) {
    return templateSelected
  }

  @Emit('closeDialog')
  onCloseDialog() {}
}
</script>

<style lang="scss" scoped>
.invoices-template-selector-container {
  ::v-deep .no-result-dialog {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 343px;
    max-height: 100%;
  }
}
.invoices-template-selector-container {
  margin-top: 0;
  padding-top: 0;

  ::v-deep .v-treeview {
    .v-icon {
      &::before {
        font-size: 18px;
        color: $corporate-color;
      }

      &::after {
        display: none;
      }
    }

    .is-directory,
    .is-template {
      font-size: 14px;
    }

    .is-directory {
      color: $corporate-color;
    }

    .is-template {
      color: $black-01;
    }
  }
}
</style>
