<template lang="pug">
  v-dialog(
    v-if="showDialog"
    v-model="showDialog"
    persistent
    scrollable
    max-width="1300px"
  )
    template(v-slot:default)

      v-card(
        class="modal-add-folder lf-modal-drop"
      )
        v-card-title()
          CustomDialogHeaderComponent(:headerTitle="texts.title")
          span(class='modal-subtitle' v-if="!showResume" ) {{texts.subTitle}}
        v-card-text(
          style="height: 450px;"
        )
          div(
            v-show="isLoading"
            class="loading")
            SpinnerLayerComponent(
              class="spinner-layer"
            )
            div(
              class="loading-text"
            ) {{ texts.loadingFolders }}
          v-treeview(
            ref="treeView"
            v-show="!isLoading"
            v-if="!showResume"
            v-model="selectedNodes"
            open-all
            :openAll="true"
            :open="opened"
            selectable
            dense
            :items="treeComputed"
            :on-icon="icons.on"
            :off-icon="icons.off"
            selected-color="#001978"
            item-text="name"
            item-children="nodes"
            item-disabled="isDisabled"
            @input="onSelectNode"
          )
            template(v-if="!isLoading" v-slot:prepend="{ item }")
              span(
                v-if="item.isDirectory"
                :class="icons.folder"
              )
              div(
                v-else
                class="e-filemanager"
              )
                span(
                  v-if="item.isValid"
                  :class="item.icon"
                )
                span(
                  v-else
                  :class="icons.danger"
                )
            template(v-if="!isLoading" v-slot:label="{ item }")
              span(
                v-if="item.isValid"
              ) {{item.name}}
              span(
                v-else
              )
                strike {{item.name}}
            template(v-if="!isLoading" v-slot:append="{ item }")
              span(
                v-if="item.isFile"
                class="item-detail"
              ) {{ item.sizeFormatted }}
              span(
                v-if="!item.isValid"
                class="invalid"
              ) {{getInvalidCauseDescription(item.invalidFileCause)}}
              SpinnerLayerComponent(
                v-show="item.isProcessing"
                class="spinner-layer"
              )
              span(
                v-if="item.isProcessed && !item.uploadResult.error"
                :class="['uploaded-ok', icons.uploaded]"
              )
              span(
                v-if="item.isProcessed && item.uploadResult.error"
                class="uploaded-fail" :class="icons.danger"
              ) {{item.uploadResult.errorDesc}}
          div(
            v-else
          )
            LfTotalsComponent(
              :items="resumeInfo"
            )
            
            SimpleGridTableComponent(
              ref="grid"
              v-if="hasErrors"
              disableContextMenu
              :title="resumeTitle"
              :columns="nodeErrorColumns"
              :itemsData="wrongNodes"
              :showButtons="false"
              :redirectOnEdit="false"
              :showGrid="hasErrors"
              :contextMenuItems="[]"
              :showFilters="false"
              :isFrozenColsMode="false"
              :allowPaging="false"
              :allowSelection="false"
            )
        v-divider
        v-card-actions(
          v-if="!isLoading"
          class="modal-footer"
        )
          div(class="processed-wrapper" v-if="!showResume")
            div(class="left" v-if="isSaving")
              span(class="procesados" v-html="processProgressText")
            div(class="right")
              button(
                @click.stop="close"
                class="button secondary-action-button"
                :title="texts.cancel"
              ) {{ texts.cancel }}
              button(
                v-if="hasSelectedData && !isSaving"
                @click.stop="upload"
                class="button main-action-button"
                :title="texts.save"
              ) {{ texts.save }}
          div(
            v-else
            class="processed-wrapper"
          )
            div(class="left")
              span(class="procesados" v-html="processProgressText")
            div(class="right")
              button(
                @click.stop="close"
                class="button main-action-button"
                :title="texts.finish"
              ) {{ texts.finish }}

  </template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import {
  FileManagerCreateFolder,
  FileManagerDeleteFolder,
  fileManagerOpers,
  FolderNode,
  invalidFileCause,
  MaxFileUploadSize,
  UploadFolderResume
} from '@/store/modules/fileManager/fileManagerTypes'
import { uuid } from '@/helpers/helpers'
import mime from 'mime'
import { Icons } from '@/icons/icons'
import { formatBytes, getFileExtension } from '@/helpers/file'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { MainService } from '@/services/MainService'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import SimpleGridTableComponent from '@/components/grids/SimpleGridTable/SimpleGridTableComponent.vue'
import { arrayHasValue } from '@/helpers/array'
import LfTotalsComponent from '@/components/LfTotals/LfTotalsComponent.vue'

const dialogModule = ModuleNamespaces.DIALOG
const authModule = ModuleNamespaces.AUTH

@Component({
  components: {
    CustomDialogHeaderComponent: () => import('@/components/Dialog/CustomDialog/CustomDialogHeaderComponent.vue'),
    SimpleGridTableComponent,
    SpinnerLayerComponent,
    LfTotalsComponent
  }
})
export default class DropFolderComponent extends Vue {
  @Prop({
    type: Object,
    default: () => ({})
  })
  fileManagerInstance!: any

  @Prop({
    type: Object,
    required: true
  })
  maxFileUploadSize: MaxFileUploadSize

  @Prop({
    type: String,
    required: true
  })
  allowedExtensions: string

  @Prop({
    type: String,
    required: true
  })
  basePath: string

  @Prop({
    type: [Number, String]
  })
  idEntity!: number | string

  @Prop({
    type: [Number, String]
  })
  idEntityType!: number | string

  @Prop({
    type: Number
  })
  idStage!: number

  @Prop({
    type: Object,
    required: true
  })
  cwd: any

  @Action('showDialog', { namespace: dialogModule }) openDialog: ({}) => void
  @Action('closeDialog', { namespace: dialogModule }) closeDialog: () => void
  @Action('cancelPendingRequests', { namespace: authModule }) cancelPendingRequests: () => {}

  tree: FolderNode[] = []
  treeComputed: FolderNode[] = []

  showDialog: boolean = false
  showResume: boolean = false

  uploadResume: UploadFolderResume = {
    failedNodes: [],
    numFilesToUpload: 0,
    numFilesUploaded: 0,
    numFoldersToUpload: 0,
    numFoldersUploaded: 0,
    numInvalidFiles: 0,
    hasFolders: false,
    hasFiles: false,
    items: []
  }

  numProcessed = 0
  totalToProcess = 0

  opened: string[] = []
  selectedNodes: string[] = []

  icons = {
    danger: Icons.DANGER,
    document: Icons.CSV,
    folder: Icons.FOLDER,
    folderOpen: Icons.FOLDER_OPEN,
    on: Icons.CHECK_FULL,
    off: Icons.BOX_INACTIVE,
    uploaded: Icons.CHECK,
    uploadedOk: Icons.CHECK_ROUND_FULL,
    uploadedFail: Icons.CLOSE_ROUND_FULL
  }

  texts = {
    title: this.$t('components.file_manager.dialogs.upload_folders_title'),
    subTitle: this.$t('components.file_manager.dialogs.upload_folders'),
    cancel: this.$t('action_buttons.cancel'),
    save: this.$t('action_buttons.add'),
    finish: this.$t('action_buttons.finish'),
    uploadResumeOk: this.$t('components.file_manager.dialogs.upload_folders_finished_ok'),
    uploadResumeFail: this.$t('components.file_manager.dialogs.upload_folders_finished_fail'),
    totalFoldersOkText: this.$t('components.file_manager.dialogs.num_folders_uploaded'),
    totalFilesOkText: this.$t('components.file_manager.dialogs.num_files_uploaded'),
    totalFoldersFailText: this.$t('components.file_manager.dialogs.num_folders_fail_upload'),
    totalFilesFailText: this.$t('components.file_manager.dialogs.num_files_fail_upload'),
    loadingFolders: this.$t('components.file_manager.dialogs.loading_folders'),
    foldersOk: this.$t('components.file_manager.dialogs.num_folders_uploaded'),
    foldersFail: this.$t('components.file_manager.dialogs.num_folders_fail_upload'),
    filesOk: this.$t('components.file_manager.dialogs.num_files_uploaded'),
    filesFail: this.$t('components.file_manager.dialogs.num_files_fail_upload'),
    resumeColumnTitleFile: this.$t('components.grid_table.column_title.failed_file'),
    resumeColumnTitlePath: this.$t('components.grid_table.column_title.path'),
    resumeColumnTitleCause: this.$t('components.grid_table.column_title.failed_cause')
  }

  body = document.body

  isSaving: boolean = false
  isLoading: boolean = true
  stop: boolean = false

  currentBasePath: string = ''

  get resumeInfo() {
    return [
      {
        alias: 'resume-ok',
        detail: this.texts.foldersOk,
        totalAmount: this.uploadResume.numFoldersToUpload
      },
      {
        alias: 'resume-fail',
        detail: this.texts.foldersFail,
        totalAmount: this.numFoldersWrong
      },
      {
        alias: 'resume-ok',
        detail: this.texts.filesOk,
        totalAmount: this.uploadResume.numFilesToUpload - this.numFilesWrong
      },
      {
        alias: 'resume-fail',
        detail: this.texts.filesFail,
        totalAmount: this.numFilesWrong
      }
    ]
  }
  
  get getCurrentBasePath(): string {
    if (!this.currentBasePath) {
      return this.basePath
    }
    return this.currentBasePath
  }

  get loadingText(): string {
    return `Cargando ${this.uploadResume.numFoldersToUpload} carpetas y
        ${this.uploadResume.numFilesToUpload} archivos.`
  }

  get resumeTitle(): string {
    if (this.uploadResume.numFoldersUploaded <= 0 && this.uploadResume.numFilesUploaded <= 0) {
      return this.texts.uploadResumeFail.toString()
    } else {
      return this.texts.uploadResumeOk.toString()
    }
  }

  get wrongNodes(): FolderNode[] {
    return this.findNodes(false, 'isValid', this.treeComputed)
  }

  get nodeErrorColumns() {
    return JSON.stringify([
      { field: 'name', width: 50, visible: true, headerText: this.texts.resumeColumnTitleFile, templateName: 'fileIconColumnTemplate' },
      { field: 'path', width: 90, visible: true, headerText: this.texts.resumeColumnTitlePath },
      { field: 'invalidFileCauseText', width: 90, visible: true, headerText: this.texts.resumeColumnTitleCause }
    ])
  }

  get hasSelectedData() {
    return this.selectedNodes.length
  }

  get hasErrors() {
    return this.wrongNodes.length > 0
  }

  get numFoldersWrong() {
    return this.uploadResume.numFoldersToUpload - this.uploadResume.numFoldersUploaded
  }

  get numFilesWrong() {
    return this.uploadResume.numFilesToUpload - this.uploadResume.numFilesUploaded
  }

  get processProgressText() {
    return this.$t('components.file_manager.dialogs.processing_files_and_folders', {
      processed: this.numProcessed,
      total: this.totalToProcess
    })
  }

  @Watch('fileManagerInstance')
  onSetFileManagerInstance(instance: any) {
    if (instance) {
      this.fileManagerInstance.addEventListener('success', this.attachEvents)
      this.attachEvents()
    }
  }

  attachEvents() {
    this.fileManagerInstance.detailsviewModule.element.addEventListener('drop', this.onDrop)
    this.fileManagerInstance.detailsviewModule.element.addEventListener('dragover', this.allowDrop)
  }

  allowDrop(e: any) {
    e.preventDefault()
  }

  resetState() {
    this.tree = []
    this.treeComputed = []
    this.showResume = false
    this.numProcessed = 0
    this.totalToProcess = 0
    this.opened = []
    this.selectedNodes = []
    this.currentBasePath = ''
    this.isSaving = false
    this.stop = false
    this.isLoading = true
    this.uploadResume = {
      numFilesUploaded: 0,
      numFoldersUploaded: 0,
      numFilesToUpload: 0,
      numFoldersToUpload: 0,
      numInvalidFiles: 0,
      failedNodes: [],
      hasFolders: false,
      hasFiles: false,
      items: []
    }
  }

  async onDrop(e: DragEvent) {
    e.preventDefault()

    if (this.cantUploadsFilesHere()) {
      e.stopPropagation()
      return false
    }

    if (e.dataTransfer) {
      if (e.dataTransfer.effectAllowed === 'copyMove') {
        this.typeDroppingAlert(e)
        this.$emit('droppingFolders')
        e.stopPropagation()
        return false
      }

      this.resetState()

      let items

      if (e.dataTransfer.items) {
        items = e.dataTransfer.items
      } else {
        items = e.dataTransfer.files
      }

      const numItems = items.length

      let canDropFolders = numItems > 0

      if (canDropFolders) {
        canDropFolders =
          e.dataTransfer.items &&
          e.dataTransfer.items[0] &&
          typeof e.dataTransfer.items[0].webkitGetAsEntry === 'function'

        if (canDropFolders) {
          const items = this.getItemsDataTransfer(numItems, e)

          if (this.cantUploadsFilesHereByPermissions()) {
            e.stopPropagation()
            return false
          }

          if (this.isDroppingFromZip(items)) {
            this.$emit('dropToZipNotSupported')
            this.$emit('droppingFolders')
            e.stopPropagation()
            return false
          }

          Promise.all(items.map((item) => this.getFilesAndFolders(item, '', 0, ''))).then(() => {
            this.isLoading = false
            this.treeComputed = this.tree
            this.treeComputed.map((node: FolderNode) => this.selectedNodes.push(node.id))
          })

          /***
           * TODO: this.uploadResume.numFilesToUpload ever is 0.
           * If we add await to the above promise, the value is updated,
           * but this.uploadResume.numFoldersToUpload will be 0
           * because it does not pass through the onSelectNode function.
           */
           if (this.uploadResume.numFoldersToUpload > 0) {
            this.$emit('droppingFolders')
            this.showDialog = true
          } 

          return true
        }
      }
      this.$emit('cantDropFolders')
    }
  }

  getItemsDataTransfer(numItems: number, e: DragEvent) {
    const items: any[] = []

    for (let index = 0; index < numItems; index++) {
      const item: any = e.dataTransfer!.items[index].webkitGetAsEntry()
      if (item !== null) {
        this.uploadResume.items.push(item.name)

        if (typeof item.isDirectory !== 'undefined' && item.isDirectory) {
          this.uploadResume.hasFolders = true
        } else {
          this.uploadResume.hasFiles = true
        }

        items.push(item)
      }
    }

    return items
  }

  cantUploadsFilesHere() {
    const cantUpload = !this.cwd.canCreateDocument && !this.cwd.canCreateFolder
    if (this.idEntity.toString() === '0' || cantUpload) {
      this.$emit('cantUploadsFilesHere')
      return true
    }

    return false
  }

  cantUploadsFilesHereByPermissions() {
    if (this.uploadResume.hasFolders && !this.cwd.canCreateFolder) {
      this.$emit('cantUploadsFoldersHere')
      return true
    }

    if (!this.cwd.canCreateDocument) {
      this.$emit('cantUploadsFilesHere')
      return true
    }

    return false
  }

  isDroppingFromZip(items: any[]) {
    return !items.some((item) => item !== null)
  }

  typeDroppingAlert(e: DragEvent) {
    const dataTransferTypes = e.dataTransfer!.types || []
    const webMailTypes = ['maillistrow', 'attachment', 'multimaillistconversationrows']
    const typeIsWebMail = arrayHasValue(dataTransferTypes, webMailTypes)
    if (typeIsWebMail) {
      this.$emit('cantDropFiles')
    } else {
      this.$emit('dropToZipNotSupported')
    }
  }

  async getFilesAndFolders(item: any, parentDirectoryId: string, level: number, parentNodeId: string) {
    if (item.isDirectory) {
      level += 1
      parentDirectoryId = this.addDirectoryToTree(item, parentDirectoryId, parentNodeId, level)
      parentNodeId = parentDirectoryId
      const reader = item.createReader()

      return new Promise<void>((resolve) => {
        reader.readEntries((entries: any) => {
          if (entries.length) {
            Promise.all(
              entries.map((subEntry: any) => this.getFilesAndFolders(subEntry, parentDirectoryId, level, parentNodeId))
            ).then(() => {
              resolve()
            })
          } else {
            resolve()
          }
        })
      })
    } else {
      this.getFile(item, parentDirectoryId, parentNodeId)
    }
    level -= 1
  }

  async getFile(entry: any, parentDirectoryId: string, parentNodeId: string) {
    const self = this

    return new Promise((resolve) => {
      entry.file((file: any) => {
        resolve(file)
      })
    }).then((data) => {
      entry.data = data
      self.addFileToTree(entry, parentDirectoryId, parentNodeId)
      self.$emit('after-get-files', self.tree)
    })
  }

  addDirectoryToTree(entry: any, parentDirectoryId: string, parentNodeId: string, level: number): string {
    const id = uuid()
    const directory = this.getBaseNode(id, entry, level)
    directory.isDirectory = true
    directory.idParent = parentNodeId
    this.insertNodeIntoDirectory(directory, parentDirectoryId, level)
    this.$emit('create-node-directory', directory)
    this.selectedNodes.push(id)
    this.opened.push(id)
    this.$set(this.uploadResume, 'numFoldersToUpload', this.uploadResume.numFoldersToUpload + 1)
    return id
  }

  addFileToTree(entry: any, parentDirectoryId: string, parentNodeId: string): string {
    const id = uuid()
    const file = this.getBaseNode(id, entry, 0)
    file.isFile = true
    file.idParent = parentNodeId
    file.data = entry.data
    file.mimeType = mime.getType(entry.name)
    file.size = this.getSize(entry.data)
    file.sizeFormatted = formatBytes(file.size)
    file.extension = getFileExtension(entry.name)
    file.icon = 'e-fe-unknown'
    if (file.extension) {
      file.icon = `e-fe-${file.extension.toLowerCase()}`
    }
    file.isValid = this.getIsValid(file)
    file.isDisabled = !file.isValid
    file.path = entry.fullPath.replace(`/${file.name}`, '')
    this.insertNodeIntoDirectory(file, parentDirectoryId)
    this.$emit('create-node-file', file)
    if (file.isValid) {
      this.selectedNodes.push(id)
    } else {
      this.uploadResume.numInvalidFiles++
      file.invalidFileCauseText = this.getInvalidCauseDescription(file.invalidFileCause).toString()
    }
    this.opened.push(id)
    this.$set(this.uploadResume, 'numFilesToUpload', this.uploadResume.numFilesToUpload + 1)
    return id
  }

  insertNodeIntoDirectory(node: FolderNode, directoryId: string, level: number = 0) {
    directoryId = directoryId || '0'
    if (directoryId === '0') {
      node.level = level
      this.tree.push(node)
    } else {
      this.addNodeToNode(this.tree, node, directoryId, false)
    }
  }

  addNodeToNode(nodes: FolderNode[], node: FolderNode, targetNodeId: string, found: boolean): boolean {
    if (!found) {
      const max = nodes.length
      for (let i = 0; i < max; i++) {
        if (nodes[i].id === targetNodeId) {
          nodes[i].nodes.push(node)
          found = true
          break
        } else if (typeof nodes[i].nodes !== 'undefined' && nodes[i].nodes.length) {
          found = this.addNodeToNode(nodes[i].nodes, node, targetNodeId, found)
        }
      }
    }
    return found
  }

  findNode(propertyValue: string, propertyName: string, nodes: FolderNode[]): FolderNode | null {
    return nodes.reduce((accumulator: any, node: FolderNode) => {
      if (accumulator) {
        return accumulator
      }
      if ((node as any)[propertyName] === propertyValue) {
        return node
      }
      if (node.nodes) {
        return this.findNode(propertyValue, propertyName, node.nodes)
      }
    }, null)
  }

  findNodes(propertyValue: any, propertyName: string, nodes: FolderNode[], found: FolderNode[] = []): FolderNode[] {
    nodes.map((node: FolderNode) => {
      if ((node as any)[propertyName] === propertyValue) {
        found.push(node)
      }
      if (node.nodes) {
        const foundNodes = this.findNodes(propertyValue, propertyName, node.nodes, found)
        if (foundNodes.length) {
          found.concat(foundNodes)
        }
      }
    })
    return found
  }

  async selectParentNodes(node: FolderNode, nodes: FolderNode[]) {
    const parentNode: FolderNode | null = this.findNode(node.idParent, 'id', nodes)
    if (parentNode) {
      parentNode.isSelected = true
      if (parentNode.idParent) {
        await this.selectParentNodes(parentNode, nodes)
      }
    }
  }

  countSelectedNodes(node: FolderNode) {
    if (node.isDirectory) {
      if (node.isSelected) {
        this.uploadResume.numFoldersToUpload += 1
      }
      if (node.nodes.length) {
        Promise.all(node.nodes.map((childrenNode: FolderNode) => this.countSelectedNodes(childrenNode)))
      }
    } else if (node.isSelected || !node.isValid) {
      this.uploadResume.numFilesToUpload += 1
    }
  }

  getBaseNode(id: string, entry: any, level: number): FolderNode {
    return {
      entry,
      id,
      idParent: '',
      name: entry.name.toString(),
      hasSubFolders: false,
      parentDirectoryId: '',
      isDirectory: false,
      isFile: false,
      isOpen: true,
      isProcessing: false,
      isProcessed: false,
      isSelected: false,
      isValid: true,
      isDisabled: false,
      nodes: [],
      data: null,
      mimeType: '',
      size: 0,
      sizeFormatted: '',
      icon: '',
      extension: '',
      invalidFileCause: '',
      invalidFileCauseText: '',
      folderId: 0,
      uploadResult: {
        error: '',
        errorDesc: ''
      },
      path: '',
      basePath: '',
      level
    }
  }

  getSize(metaDataFile: any): number {
    let size = 0

    if (typeof metaDataFile.size !== 'undefined') {
      size = metaDataFile.size
    } else if (typeof metaDataFile.total !== 'undefined') {
      size = metaDataFile.total
    }

    return size
  }

  getIsValid(file: FolderNode): boolean {
    if (!file.isFile) {
      file.invalidFileCause = invalidFileCause.UNKNOWN
      return false
    }

    if (!file.extension) {
      file.invalidFileCause = invalidFileCause.EXTENSION
      return false
    }

    if (!this.allowedExtensions.includes(`.${file.extension.toLowerCase()}`)) {
      file.invalidFileCause = invalidFileCause.EXTENSION
      return false
    }

    if (this.maxFileUploadSize.size < file.size) {
      file.invalidFileCause = invalidFileCause.SIZE
      return false
    }

    return true
  }

  getInvalidCauseDescription(cause: string) {
    switch (cause) {
      case invalidFileCause.EXTENSION:
        return this.$t('components.file_manager.advises.invalid_extension')
      case invalidFileCause.SIZE:
        return this.$t('components.file_manager.advises.max_file_upload_size', {
          size: this.maxFileUploadSize.sizeFormatted
        })
      case invalidFileCause.UPLOAD_ERROR:
        return this.$t('components.file_manager.error_messages.upload_error')
      default:
        if (cause !== '') {
          return cause
        }
        return this.$t('components.file_manager.advises.invalid_file')
    }
  }

  onSelectNode(args: any) {
    if (!this.isLoading) {
      Promise.all(this.treeComputed.map((node: FolderNode) => this.unselectAllNodes(node))).then(() => {
        Promise.all(
          args.map((id: string) => {
            const node: FolderNode | null = this.findNode(id, 'id', this.treeComputed)
            if (node) {
              node.isSelected = true
              this.selectParentNodes(node, this.treeComputed)
            }
          })
        )
      })
    }
  }

  unselectAllNodes(node: FolderNode) {
    node.isSelected = false
    if (node.nodes.length) {
      Promise.all(node.nodes.map((childrenNode: FolderNode) => this.unselectAllNodes(childrenNode)))
    }
  }

  upload() {
    if (this.isSaving) {
      return false
    }

    this.isSaving = true

    this.uploadResume.numFilesToUpload = 0
    this.uploadResume.numFoldersToUpload = 0

    Promise.all(this.treeComputed.map((node: FolderNode) => this.countSelectedNodes(node))).then(() => {
      this.totalToProcess = this.uploadResume.numFilesToUpload
      this.numProcessed = this.uploadResume.numInvalidFiles

      Promise.all(this.treeComputed.map((node: FolderNode) => this.uploadNode(node)))
        .then(() => {
          this.isSaving = false
          this.showResume = true
        })
        .finally(() => {
          if (!this.hasErrors) {
            this.showDialog = false
            this.$emit('uploadOk', this.uploadResume)
          }
          if (!this.stop) {
            this.fileManagerInstance.refresh()
          } else {
            this.isSaving = false
            this.showDialog = false
            this.closeDialog()
            this.$emit('cancelUpload')
            this.fileManagerInstance.refresh()
          }
        })
    })
  }

  async uploadNode(node: FolderNode) {
    if (this.stop) {
      return false
    }

    if (node.isSelected) {
      if (node.idParent) {
        const parentNode: FolderNode | null = this.findNode(node.idParent, 'id', this.treeComputed)
        if (parentNode) {
          const folderPath: string = `Folder:${parentNode.folderId}`
          const parts = this.basePath.split('/').filter(String)
          parts.push(folderPath)
          this.currentBasePath = parts.join('/')
        }
      }

      if (node.isDirectory) {
        await this.uploadFolder(node)
      } else if (node.isFile) {
        await this.uploadFile(node).catch((_error) => {
          node.isValid = false
          node.invalidFileCause = invalidFileCause.UPLOAD_ERROR
          node.uploadResult.error = ''
          node.uploadResult.errorDesc = ''
          node.invalidFileCauseText = this.getInvalidCauseDescription(node.invalidFileCause).toString()
        })
        this.numProcessed++
      }

      if (node.nodes.length) {
        await Promise.all(node.nodes.map((childNode: FolderNode) => this.uploadNode(childNode)))
      }
    }
  }

  async uploadFolder(node: FolderNode) {
    node.isProcessing = true

    const post: FileManagerCreateFolder = {
      action: fileManagerOpers.CREATE,
      basePath: this.getCurrentBasePath,
      name: node.name
    }

    const { data } = await new MainService().postBody('/repository/folder/create', post)

    if (data.error) {
      node.uploadResult.error = data.code
      node.uploadResult.errorDesc = data.message
    } else {
      node.folderId = data.idFolder
      node.basePath = this.getBasePath(node)
      this.uploadResume.numFoldersUploaded += 1
    }

    node.isProcessing = false
    node.isProcessed = true
  }

  getBasePath(node: FolderNode) {
    const parentNodeIds: number[] = []

    this.getParentNodeIds(node, parentNodeIds)

    const paths: string[] = []

    parentNodeIds.reverse().map((id: number) => {
      paths.push(`Folder:${id.toString()}`)
    })

    const folders: string = paths.join('/')

    const sep: string = this.basePath.toString().endsWith('/') ? '' : '/'

    return this.basePath + sep + folders
  }

  getParentNodeIds(node: FolderNode, ids: number[]) {
    ids.push(node.folderId)
    if (node.idParent) {
      const parentNode = this.findNode(node.idParent, 'id', this.treeComputed)
      if (parentNode) {
        this.getParentNodeIds(parentNode, ids)
      }
    }
  }

  async uploadFile(node: FolderNode) {
    if (node.isValid) {
      node.isProcessing = true

      const formData = new FormData()

      const parentNode: FolderNode | null = this.findNode(node.idParent, 'id', this.treeComputed)

      const basePath: string = parentNode ? parentNode.basePath : this.getCurrentBasePath

      formData.append('uploadFiles', node.data)
      formData.append('basePath', basePath)
      formData.append('idEntity', this.idEntity.toString())
      formData.append('idEntityType', this.idEntityType.toString())
      if (this.idStage) {
        formData.append('idStage', this.idStage.toString())
      }

      const { data } = await new MainService().postBody('/repository/file/put', formData)

      if (data.error) {
        node.isValid = false
        node.invalidFileCause = data.message
        node.uploadResult.error = data.code
        node.uploadResult.errorDesc = data.message
        node.invalidFileCauseText = this.getInvalidCauseDescription(node.invalidFileCause).toString()
      } else {
        this.uploadResume.numFilesUploaded += 1
      }

      node.isProcessing = false
      node.isProcessed = true
    }
  }

  async cancelUpload() {
    this.stop = true
    this.cancelPendingRequests()

    Promise.all(this.treeComputed.map((node: FolderNode) => this.removeFolder(node))).finally(() => {
      this.isSaving = false
      this.showDialog = false
      this.closeDialog()
      this.$emit('cancelUpload')
      this.fileManagerInstance.refresh()
    })
  }

  async removeFolder(node: FolderNode) {
    if (node.folderId) {
      const post: FileManagerDeleteFolder = {
        action: fileManagerOpers.DELETE,
        basePath: this.getCurrentBasePath,
        data: []
      }
      post.data.push({
        idFolder: node.folderId,
        isFile: false
      })
      await new MainService().postBody('/repository/file/delete', post)
    }
  }

  close() {
    if (!this.isSaving) {
      this.showDialog = false
    } else {
      this.openDialog({
        type: DialogTypes.WARNING,
        message: this.$t('components.file_manager.dialogs.cancel_upload_folder'),
        action: this.cancelUpload
      })
    }
  }
}
</script>

<style lang="scss">
@include file-icons;
</style>

<style lang="scss" scoped>
@import '~@/styles/partials/components/filemanager/drop/modal';
@import '~@/styles/partials/components/filemanager/drop/grid';
</style>
