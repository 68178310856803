<template lang="pug">
  div(class="document-type-template")
    span(:class="['e-fe-icon', iconClass]")
</template>

<script lang="ts">
import { Vue } from 'vue-property-decorator'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'documentType'
})
export default class DocumentTypeTemplateComponent extends Vue {
  data: any

  get extensionName() {
    return this.data.initialFile.split('.').pop()
  }

  get iconClass() {
    return `e-fe-${this.extensionName.toLowerCase()}`
  }
}
</script>

<style lang="scss">
@include file-icons($container-name: 'document-type-template');
.document-type-template {
  max-width: 20px;
}

.document-type-template .e-fe-icon {
  font-family: $lf-icons-font;
  background-image: none !important;
  vertical-align: top;
  color: $corporate-color;
  font-size: 22px;
}
</style>
