export enum ActionFields {
  ACTION_TYPE = 'typeId',
  APPOINTMENT = 'appointment',
  BILLABLE = 'billable',
  BILLED_FIELDS = 'billedFields',
  CATEGORY = 'categoryId',
  CODE = 'code',
  COLOR = 'color',
  DATE_ACTION_AT = 'dateActionAt',
  DURATION = 'duration',
  ECONOMIC_ESTIMATE = 'economicEstimate',
  END = 'end',
  EXPEDIENT_ID = 'idExpedient',
  EXPIRATION = 'expiration',
  PRESENTATION = 'presentationDate',
  PUBLIC = 'public',
  RATE_ID = 'rateId',
  RELATIONS = 'relations',
  SCHEDULE_LEXBOX = 'scheduleLexbox',
  STAGE = 'idStage',
  START = 'start',
  STATE = 'stateId',
  TIMING_FIELDS = 'timingFields',
  USER_ID = 'userId',
  LEXNET = 'lexnetFields',
  SUBACTION_ALERT_INFO = 'subActionAlertInfo',
  PARENT_ACTION = 'parentAction'
}
