import { GetterTree } from 'vuex'
import { InvoicesState, Invoice, InvoiceStatus } from '@/store/modules/invoices/invoicesTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const getters: GetterTree<InvoicesState, RootState> = {
  getAllInvoices(state): Invoice[] {
    return state.invoices
  },
  getTotalUnbilled(state): string {
    return state.totalUnbilled
  },
  getTotalReceivable(state): string {
    return state.totalReceivable
  },
  getInvoiceType(state): string {
    return state.invoiceType
  },
  getInvoiceGenerate(state, id) {
    return state.invoicesGenerated.find(({ idEntity }) => idEntity === id)
  },
  getInvoiceGenerateAll(state) {
    return state.invoicesGenerated
  },
  getInvoiceGenerateReady(state): boolean {
    const { invoicesGenerated } = state
    return invoicesGenerated.length !== 0 && !invoicesGenerated.some(({ status }) => status === InvoiceStatus.LOADING)
  },
  getInvoiceGenerateOK(state): boolean {
    const { invoicesGenerated } = state
    return invoicesGenerated.length !== 0 && invoicesGenerated.every(({ status }) => status === InvoiceStatus.LOADED)
  },
  getInvoiceGenerateErrorAll(state): boolean {
    const { invoicesGenerated } = state
    return invoicesGenerated.length !== 0 && invoicesGenerated.every(({ status }) => status === InvoiceStatus.ERROR)
  },
  getInvoiceGenerateError(state): boolean {
    const { invoicesGenerated } = state
    return invoicesGenerated.length !== 0 && invoicesGenerated.some(({ status }) => status === InvoiceStatus.ERROR)
  },
  getCustomeInvoiceTotals(state) {
    return state.totals.customerInvoices;
  }
}
