<template>
  <v-list-item
    :class="['alert-item', { unread: props.alert.visibility === AlertMessageVisibility.NO_READ }]"
    @click="goToAlert()"
  >
    <span :class="[props.alert.icon, 'lf-icon__lg', 'icon']" />
    <div class="item-info">
      <span class="alert-text">{{ props.alert.message }}</span>
    </div>
  </v-list-item>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import useActionsFilteredAlertsMessages from '@/components/AlertsMessages/composables/useActionsFilteredAlertsMessages'
import { AlertMessage, AlertMessageVisibility } from '@/store/modules/alertsMessages/alertsMessagesTypes'

// PROPS
const props = defineProps({
  alert: {
    type: Object as PropType<AlertMessage>,
    required: true
  }
})

// COMPOSABLE
const { goToAlertsMessagesURL } = useActionsFilteredAlertsMessages()

// METHODS
async function goToAlert() {
  goToAlertsMessagesURL(props.alert)
}
</script>

<style lang="scss" scoped>
.alert-item {
  display: flex;
  height: auto !important;
  max-height: 500px !important;
  padding: $spacing-xxs $spacing-xs;
  align-items: flex-start;
  gap: $spacing-xs;
  align-self: stretch;

  &.unread {
    border-left: 2px solid $main-1000;

    .item-info .alert-text {
      color: $main-1000;
    }
  }

  .icon {
    color: $neutral-grey-800;
  }

  .item-info {
    display: flex;
    flex-direction: column;
    margin: 0 $spacing-xs;

    .alert-text {
      @include milano-medium-14;
      color: $neutral-grey-800;
    }
  }
}
</style>
