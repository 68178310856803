<template lang="pug">
div(class="document-form")
  TabHeaderComponent(
    @saveForm="onSaveForm"
    @closeForm="onCloseForm"
    @removeItem="onRemoveItem"
    :buttons="buttons"
    :title="title"
  )
  TabsComponent(
    ref="tabComponent"
    v-if="tabs && tabs.length"
    :tabs="tabs"
    :context="context"
    class="tabs-secondary-styles"
    :permissionsEntityProp="permissionsEntity"
    @changed-tab="tabChanged"
    @simpleGridActionNew="onIntervenerActionNew"
    @simpleGridActionRemove="onIntervenerActionRemove"
    @simpleGridActionEdit="onIntervenerActionEdit"
    @renderForm="onRenderForm"
    @form-open="onOpenIntervenerForm"
    @form-save="onSaveIntervenerForm"
    @form-close="onCloseIntervenerForm"
    @form-mounted="onInitDocumentForm"
    @onTabHeaderRemoveItem="removeIntervener"
  )
</template>

<script lang="ts">
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action, Getter, Mutation } from 'vuex-class'
import {
  ConfigurationAlias,
  ConfigurationElements,
  ConfigurationTypes
} from '@/store/modules/configuration/configurationTypes'
import { Icons } from '@/icons/icons'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { RepositoryService } from '@/services/RepositoryService'
import { EditDocumentMode } from '@/store/modules/fileManager/fileManagerTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { FirmaRequestStatus } from '@/store/modules/firma/firmaTypes'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'
import { LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'

const configurationModule: string = ModuleNamespaces.CONFIGURATION
const dialogModule: string = ModuleNamespaces.DIALOG
const fileManagerModule: string = ModuleNamespaces.FILE_MANAGER
const formsModule: string = ModuleNamespaces.FORMS
const listItemsModule: string = ModuleNamespaces.LIST_ITEMS
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER
const tabsModule: string = ModuleNamespaces.TABS
const expedientsModule: string = ModuleNamespaces.EXPEDIENTS

enum DocumentTabs {
  TAB_FORM,
  TAB_INTERVENING
}

enum DocumentComponents {
  COMPONENT_FORM = 0,
  COMPONENT_LIST = 0
}

@Component({
  components: {
    TabsComponent: () => import('@/components/tabs/TabsComponent/TabsComponent.vue'),
    TabHeaderComponent: () => import('@/components/tabs/TabHeader/TabHeaderComponent.vue')
  }
})
export default class FileManagerEditDocumentComponent extends Mixins(PermissionsMixin) {
  @Prop({
    type: Number
  })
  idDocument!: number

  @Prop({
    type: Number
  })
  idEntity!: number

  @Prop({
    type: Number
  })
  idEntityType!: number

  @Prop({
    type: Number
  })
  idEntityTypeOld!: number

  @Prop({
    type: String
  })
  source!: string

  @Prop({
    type: Number
  })
  firmaStatus!: number

  @Prop({
    type: String
  })
  basePath!: string

  @Prop({
    type: Number
  })
  editDocumentMode: EditDocumentMode

  @Prop({
    type: Number,
    default: 14
  })
  permissionsEntity: number

  @Action('fetchCurrentListConfiguration', { namespace: configurationModule })
  fetchCurrentListConfiguration: (alias: string) => {}
  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfiguration: ({}) => {}
  @Action('showDialog', { namespace: dialogModule }) showDialog: ({}) => void
  @Action('saveSelectedRegisterId', { namespace: selectedRegisterModule }) saveSelectedRegisterId: ({}) => void
  @Action('fetchDocument', { namespace: fileManagerModule }) fetchDocument: (idDocument: string) => void

  @Mutation('REMOVE_LIST_ITEMS', { namespace: listItemsModule })
  removeListItems: () => []
  @Mutation('RESET_CURRENT_LIST_CONFIGURATION', { namespace: configurationModule })
  resetListConfiguration: () => {}
  @Mutation('SET_CURRENT_VIEW_CONFIGURATION', { namespace: configurationModule })
  setViewConfiguration: ({}) => void
  @Mutation('SET_LOADING_FORM_DATA', { namespace: formsModule })
  setLoadingFormData: (loading: boolean) => void
  @Mutation('FETCH_SELECTED_REGISTER_DATA', { namespace: selectedRegisterModule })
  saveSelectedRegisterData: ({}) => void
  @Mutation('CHANGE_DISABLED_TABS', { namespace: tabsModule })
  changeDisplayTabs: (status: boolean) => void
  @Mutation('OPEN_FILE_MANAGER_EDIT_COMPONENT', { namespace: fileManagerModule })
  fileManagerEditComponentOpen: () => void
  @Mutation('CLOSE_FILE_MANAGER_EDIT_COMPONENT', { namespace: fileManagerModule })
  fileManagerEditComponentClose: () => void

  @Getter('getCurrentViewConfiguration', { namespace: configurationModule })
  getCurrentViewConfiguration: (context: string) => []
  @Getter('getCurrentViewTabs', { namespace: configurationModule })
  getCurrentViewTabs: (context: string) => []
  @Getter('checkIfFormIsValid', { namespace: formsModule }) checkIfFormIsValid: (context: string) => boolean
  @Getter('getRegisterFormData', { namespace: formsModule }) registerFormData: any
  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterId: (context: string) => number
  @Getter('getDocument', { namespace: fileManagerModule }) document: string
  @Getter('getExpedientStageState', { namespace: expedientsModule })
  expedientStageState: string
  @Getter('getHasStages', { namespace: expedientsModule })
  expedientHasStagesGetter: Boolean

  context = ContextName.DOCUMENT
  documentContext = ContextName.DOCUMENT
  contextInteveners = ContextName.DOCUMENT_INTERVENERS
  editMode = false
  editModeIntervening = false
  documentIntervener: { id: string; name: string; type: string }
  formInterveningConfiguration = {}
  interveningList = {}
  interveningListAlias = ''
  idDocumentIntervener = 0
  isShowingInterveningForm = false
  showInterveningForm = false
  newDocumentSaved = false
  file = null

  get tabs() {
    return this.getCurrentViewTabs(this.documentContext)
  }

  get title() {
    let title = ''
    switch (this.editDocumentModeComputed) {
      case EditDocumentMode.uploadEdit:
        title = `${this.$t('components.file_manager.actions.edit_document').toString().toUpperCase()}: ${
          (this as any).document.Nombre
        }`
        break
      case EditDocumentMode.uploadNew:
        title = this.$t('components.file_manager.actions.new_document').toString().toUpperCase()
        break
      case EditDocumentMode.wordOnlineNew:
        title = this.$t('components.file_manager.actions.new_word_document').toString().toUpperCase()
        break
      case EditDocumentMode.excelOnlineNew:
        title = this.$t('components.file_manager.actions.new_excel_document').toString().toUpperCase()
        break
      case EditDocumentMode.powerPointOnlineNew:
        title = this.$t('components.file_manager.actions.new_ppoint_document').toString().toUpperCase()
        break
    }
    return title
  }

  get editDocumentModeComputed() {
    return this.newDocumentSaved ? EditDocumentMode.uploadEdit : this.editDocumentMode
  }

  get buttons() {
    const { canSave, canDelete } = this.checkEntityPermissionsGetter(this.permissionsEntity)
    if (!this.editMode) {
      return [
        {
          icon: Icons.CLOSE,
          tooltip: this.$t('action_buttons.close'),
          class: 'red-color',
          action: ActionName.CLOSE,
          enabled: !this.showInterveningForm,
          variant: LabelButtonVariant.TEXT
        },
        {
          icon: Icons.CHECK,
          tooltip: this.$t('action_buttons.save'),
          class: 'green-color',
          action: ActionName.SAVE,
          enabled: this.checkIfFormIsValid(this.context),
          hidden: !canSave
        }
      ]
    }
    return [
      {
        icon: Icons.CLOSE,
        tooltip: this.$t('action_buttons.close'),
        class: 'red-color',
        action: ActionName.CLOSE,
        enabled: !this.showInterveningForm,
        variant: LabelButtonVariant.TEXT
      },
      {
        icon: Icons.CHECK,
        tooltip: this.$t('action_buttons.save'),
        class: 'green-color',
        action: ActionName.SAVE,
        enabled: this.checkIfFormIsValid(this.context) && !this.showInterveningForm,
        hidden: !canSave
      },
      {
        icon: Icons.REMOVE,
        prependIcon: Icons.REMOVE,
        action: ActionName.REMOVE,
        enabled: true,
        hidden: !canDelete,
        variant: LabelButtonVariant.TEXT
      }
    ]
  }

  get interveningButtons() {
    const { canSave, canDelete } = this.checkEntityPermissionsGetter(this.permissionsEntity)
    if (this.editModeIntervening) {
      return [
        {
          icon: Icons.REMOVE,
          tooltip: this.$t('action_buttons.remove'),
          class: 'blue-color',
          action: ActionName.REMOVE,
          enabled: true,
          hidden: !canDelete
        },
        {
          icon: Icons.CLOSE,
          tooltip: this.$t('action_buttons.close'),
          class: 'red-color',
          action: ActionName.CLOSE,
          enabled: true
        },
        {
          icon: Icons.CHECK,
          tooltip: this.$t('action_buttons.save'),
          class: 'green-color',
          action: ActionName.SAVE,
          enabled: () => this.checkIfFormIsValid(this.context),
          hidden: !canSave
        }
      ]
    } else {
      return [
        {
          icon: Icons.CLOSE,
          tooltip: this.$t('action_buttons.close'),
          class: 'red-color',
          action: ActionName.CLOSE,
          enabled: true
        },
        {
          icon: Icons.CHECK,
          tooltip: this.$t('action_buttons.save'),
          class: 'green-color',
          action: ActionName.SAVE,
          enabled: () => this.checkIfFormIsValid(this.context)
        }
      ]
    }
  }

  async tabChanged(tabIndex: number, objectType: string, resolve: any) {
    if (
      !(this as any).tabs[tabIndex] ||
      !(this as any).tabs[tabIndex].items ||
      !(this as any).tabs[tabIndex].items.length
    ) {
      typeof resolve === 'function' && resolve()
      return false
    }

    const { module, alias, loadMethod } = (this as any).tabs[tabIndex].items[0]

    if (ConfigurationElements.TAB_HEADER === objectType) {
      typeof resolve === 'function' && resolve()
      return true
    }

    if (ConfigurationElements.EDIT_DOCUMENT_INTERVENER === objectType) {
      await this.onSaveForm(false)
      this.removeListItems()
      await this.$store.dispatch(`${module}/${loadMethod}`, this.selectedRegisterId(this.documentContext))
      await this.resetListConfiguration()
      this.interveningListAlias = alias
      await this.fetchCurrentListConfiguration(alias)
    } else {
      await this.saveSelectedRegisterData({
        selectedRegisterData: this.document,
        context: ContextName.DOCUMENT
      })
    }
    await this.$nextTick()
    typeof resolve === 'function' && resolve()
    await this.$nextTick()
    this.$emit('showForm')
  }

  onCloseForm() {
    this.$emit('close')
  }

  async onSaveForm(closeForm = true) {
    const { canSave } = this.checkEntityPermissionsGetter(this.permissionsEntity)
    if (!canSave) {
      return true
    }

    const document = this.registerFormData
    let file = document['ArchivoInicial']

    if ((!file || !file.name) && this.file) {
      file = JSON.parse(JSON.stringify(this.file))
      this.file = null
    }

    document.Id = this.selectedRegisterId(this.documentContext)
    document.idEntity = this.idEntity
    document.idEntityType = this.idEntityType
    document.idEntityTypeOld = this.idEntityTypeOld
    document.source = this.source
    document.path = this.basePath
    switch (this.editDocumentModeComputed) {
      case EditDocumentMode.wordOnlineNew:
        document.extension = 'docx'
        document['ArchivoInicial'] = `${document['Nombre']}.${document.extension}`
        break
      case EditDocumentMode.excelOnlineNew:
        document.extension = 'xlsx'
        document['ArchivoInicial'] = `${document['Nombre']}.${document.extension}`
        break
      case EditDocumentMode.powerPointOnlineNew:
        document.extension = 'pptx'
        document['ArchivoInicial'] = `${document['Nombre']}.${document.extension}`
        break
      default:
        document['ArchivoInicial'] = file.name
        break
    }

    if (document['IdCategory'] === undefined) {
      document['IdCategory'] = null
    }

    if (document['IdUser'] === undefined) {
      document['IdUser'] = null
    }

    const formData = new FormData()
    formData.append('document', JSON.stringify(document))
    formData.append('fileData', file)

    let response: any = null

    try {
      switch (this.editDocumentModeComputed) {
        case EditDocumentMode.uploadEdit:
          response = await new RepositoryService().updateDocument(formData)
          break
        case EditDocumentMode.uploadNew:
          response = await new RepositoryService().insertDocument(formData)
          break
        case EditDocumentMode.excelOnlineNew:
        case EditDocumentMode.powerPointOnlineNew:
        case EditDocumentMode.wordOnlineNew:
          response = await new RepositoryService().createNewDocumentOnline(formData)
          break
      }
    } catch (error) {
      this.file = file
      this.$emit('on-save-document-fail')
      return
    }
    const { data } = response
    if (closeForm) {
      if (this.editDocumentModeComputed <= EditDocumentMode.powerPointOnlineNew) {
        if (data.id) {
          this.$emit('on-save-document-ok', data)
        } else {
          this.$emit('on-save-document-fail')
        }
      } else {
        if (data.result) {
          this.$emit('on-save-document-ok', file)
        } else {
          this.$emit('on-save-document-fail')
        }
      }
      return
    }

    const id = data.id || document.Id
    if (id) {
      this.saveSelectedRegisterId({
        id,
        context: this.documentContext
      })
      this.fetchDocument(id)
      this.newDocumentSaved = true
      await this.updateValidationRules(false)
    }
  }

  async onOpenIntervenerForm() {}

  async onSaveIntervenerForm() {
    const formData = this.registerFormData
    const documentIntervener = {
      IdDocument: this.selectedRegisterId(this.documentContext),
      IdIntervener: formData.intervener.id,
      IdIntervenerType: formData.intervenerType.id
    }

    let response = { data: { result: null } }

    if (this.editModeIntervening) {
      ;(documentIntervener as any).id = this.idDocumentIntervener
    }

    response = await new RepositoryService().insertDocumentIntervener(documentIntervener)

    const { data } = response

    if (data.result) {
      this.$emit('on-save-intervener-ok', this.editModeIntervening)
    } else {
      this.$emit('on-save-intervener-fail', this.editModeIntervening)
    }

    await this.closeIntervenerForm()
  }

  goToIntervenersTab() {
    const tabComponent: any = this.$refs.tabComponent
    if (tabComponent) {
      tabComponent.goToTab(1, true)
    }
  }

  onCloseIntervenerForm() {
    this.closeIntervenerForm()
  }

  async closeIntervenerForm() {
    this.showInterveningForm = false
    await this.$nextTick()
    this.context = this.documentContext
    await this.updateInterveningListView()
    this.goToIntervenersTab()
    await this.$nextTick()
    this.refreshIntervenerList()
  }

  async refreshIntervenerList() {
    this.removeListItems()
    await this.$nextTick()
    const { module, loadMethod } = (this as any).tabs[1].items[0]
    await this.$store.dispatch(`${module}/${loadMethod}`, this.selectedRegisterId(this.documentContext))
  }

  onRemoveItem() {
    if (this.firmaStatus === FirmaRequestStatus.PENDING_SIGNATURE) {
      this.showDialog({
        type: DialogTypes.ERROR,
        message: this.$t('components.firma_dialogs.warning_delete_sign_pending')
      })
    } else {
      const message =
        this.firmaStatus === null
          ? this.$t('components.dialog.remove_document')
          : this.$t('components.firma_dialogs.warning_delete_in_signaturit')
      this.showDialog({
        type: DialogTypes.INFO,
        message,
        action: async () => {
          this.$emit('on-remove-document')
        }
      })
    }
  }

  onRenderForm(_tabIndex: number) {
    const tabComponent: any = this.$refs.tabComponent
    if (tabComponent && this.isShowingInterveningForm) {
      tabComponent.goToTab(1, true)
      this.isShowingInterveningForm = false
      this.showInterveningForm = true
    }
  }

  async onIntervenerActionNew() {
    this.idDocumentIntervener = 0
    this.openIntervenerForm(0)
  }

  removeIntervener() {
    const { intervener, intervenerType } = this.registerFormData

    const documentIntervener = {
      id: this.selectedRegisterId(this.contextInteveners),
      name: intervener.name,
      type: intervenerType.alias
    }

    this.onIntervenerActionRemove(documentIntervener)
  }

  onIntervenerActionRemove(documentIntervener: any) {
    this.documentIntervener = documentIntervener
    this.showDialog({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.document_intervener_remove', {
        intervenerType: documentIntervener.type,
        intervenerName: documentIntervener.name
      }),
      action: this.removeDocumentIntervener
    })
  }

  onIntervenerActionEdit(documentIntervener: any) {
    const data = {
      intervenerType: {
        id: documentIntervener.idIntervenerType,
        name: documentIntervener.type,
        alias: documentIntervener.alias
      },
      intervener: {
        id: documentIntervener.idIntervener,
        name: documentIntervener.name
      }
    }
    this.context = this.contextInteveners
    this.idDocumentIntervener = documentIntervener.id
    this.openIntervenerForm(documentIntervener.id, data)
  }

  async openIntervenerForm(id: number, data: {} = {}) {
    this.editModeIntervening = id > 0
    this.saveSelectedRegisterId({
      id,
      context: this.contextInteveners
    })
    await this.saveSelectedRegisterData({
      selectedRegisterData: data,
      context: this.contextInteveners
    })
    await this.fetchCurrentViewConfiguration({
      objectType: ConfigurationTypes.VIEW,
      alias: ConfigurationAlias.DOCUMENT_PROPERTIES_INTERVENERS,
      context: this.contextInteveners
    })
    await this.$nextTick()
    this.formInterveningConfiguration = this.getCurrentViewConfiguration(this.contextInteveners)
    await this.updateInterveningFormView()
    this.context = this.contextInteveners
    this.isShowingInterveningForm = true
  }

  onInitDocumentForm(params: any) {
    const { formData } = params
    this.toggleStageField()
    this.$emit('documentInitForm', formData)
  }

  toggleStageField() {
    const stageField = this.$el.querySelector('#idStage') as HTMLElement | null

    if (null !== stageField) {
      if (this.expedientHasStagesGetter) {
        stageField.style.display = 'inherit'
      } else {
        stageField.style.display = 'none'
      }
    }
  }

  async removeDocumentIntervener() {
    const response: any = await new RepositoryService().deleteDocumentIntervener(this.documentIntervener.id)
    if (!response.result) {
      this.showDialog({
        type: DialogTypes.SUCCESS,
        message: this.$t('components.dialog.document_intervener_remove_success', {
          intervenerType: this.documentIntervener.type,
          intervenerName: this.documentIntervener.name
        })
      })
      if (this.showInterveningForm) {
        this.closeIntervenerForm()
      } else {
        this.refreshIntervenerList()
      }
    }
  }

  async updateInterveningListView() {
    const tabs = this.getCurrentViewTabs(this.documentContext)

    const tabIntervening: any = (tabs as any)[DocumentTabs.TAB_INTERVENING]
    tabIntervening.items = []
    tabIntervening.items[0] = this.interveningList

    const viewConfiguration = { tabs }

    await this.updateViewConfiguration(viewConfiguration)
  }

  async updateInterveningFormView() {
    const tabs = this.getCurrentViewTabs(this.documentContext)
    this.interveningList = (tabs as any)[DocumentTabs.TAB_INTERVENING].items[DocumentComponents.COMPONENT_LIST]

    const { module, loadMethod } = (this as any).tabs[DocumentTabs.TAB_INTERVENING].items[0]
    await this.$store.dispatch(`${module}/${loadMethod}`, this.selectedRegisterId(this.documentContext))

    const tabIntervening: any = (tabs as any)[DocumentTabs.TAB_INTERVENING]
    tabIntervening.items = this.formInterveningConfiguration

    tabIntervening.items[0].buttons = this.interveningButtons

    const viewConfiguration = { tabs }

    await this.updateViewConfiguration(viewConfiguration)
  }

  async updateValidationRules(updateViewConfiguration = true) {
    if (this.editDocumentModeComputed !== EditDocumentMode.uploadNew) {
      const tabs = this.getCurrentViewTabs(this.documentContext)
      const numFields = (tabs as any)[DocumentTabs.TAB_FORM].items[DocumentComponents.COMPONENT_FORM].fields.length

      for (let i = 0; i < numFields; i++) {
        const field = (tabs as any)[DocumentTabs.TAB_FORM].items[DocumentComponents.COMPONENT_FORM].fields[i]

        const isFieldset = !!field.fields

        if (isFieldset) {
          field.fields.filter((child: any) => {
            if (typeof child.mandatoryOnEdit !== 'undefined' && typeof child.validationRules !== 'undefined') {
              if (!child.mandatoryOnEdit) {
                const rules: string[] = child.validationRules.split('|')
                const newRules: string[] = rules.filter((rule) => rule !== 'fileRequired')
                const newRulesJoin: string = newRules.join('|')
                Vue.set(child, 'validationRules', newRulesJoin)
              }
            }
          })
        }
      }
      if (updateViewConfiguration) {
        const viewConfiguration = { tabs }
        await this.updateViewConfiguration(viewConfiguration)
      }
    }
  }

  disableField(node: any, field: string) {
    if (typeof node.fieldType !== 'undefined' && node.fieldType === field) {
      Vue.set(node, 'disabled', true)
    }
  }

  async updateConfigurationByEditMode() {
    if (this.editDocumentModeComputed <= EditDocumentMode.powerPointOnlineNew) {
      const tabs = this.getCurrentViewTabs(this.documentContext)
      const numFields = (tabs as any)[DocumentTabs.TAB_FORM].items[DocumentComponents.COMPONENT_FORM].fields.length

      for (let i = 0; i < numFields; i++) {
        const field = (tabs as any)[DocumentTabs.TAB_FORM].items[DocumentComponents.COMPONENT_FORM].fields[i]
        const isFieldset = !!field.fields
        if (isFieldset) {
          field.fields.map((f: any) => {
            this.disableField(f, 'LexonFileUploadComponent')
          })
        } else {
          this.disableField(field, 'LexonFileUploadComponent')
        }
      }

      const viewConfiguration = { tabs }

      await this.updateViewConfiguration(viewConfiguration)
    }
  }

  async updateViewConfiguration(viewConfiguration: any) {
    this.setViewConfiguration({
      viewConfiguration: null,
      context: this.documentContext
    })

    await this.$nextTick()

    this.setViewConfiguration({
      viewConfiguration,
      context: this.documentContext
    })

    await this.$nextTick()
  }

  async created() {
    this.editMode = Object.keys(this.document).length > 0
    await this.fetchCurrentViewConfiguration({
      objectType: ConfigurationTypes.VIEW,
      alias: ConfigurationAlias.DOCUMENT_PROPERTIES,
      context: this.documentContext
    })
    this.saveSelectedRegisterId({
      id: this.idDocument,
      context: this.documentContext
    })
    await this.$nextTick()
    await this.updateValidationRules()
    await this.$nextTick()
    await this.updateConfigurationByEditMode()
    await this.$nextTick()
  }

  mounted() {
    this.changeDisplayTabs(true)
    this.fileManagerEditComponentOpen()
  }

  destroyed() {
    this.changeDisplayTabs(false)
    this.fileManagerEditComponentClose()
  }
}
</script>

<style lang="scss" scoped>
.document-form {
  .tab-header-container {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
</style>
