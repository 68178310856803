import { ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Ref, ref } from 'vue'

interface PropsUseFormSchema {
  formAlias: string
  context: string
}

interface ReturnUseFormSchema {
  formSchema: Ref<any[]>
  getFormSchema: () => Promise<void>
}

export default function useFormSchema(props: PropsUseFormSchema): ReturnUseFormSchema {
  const formSchema = ref([])

  const getFormSchema = async () => {
    await store.dispatch(`${ModuleNamespaces.CONFIGURATION}/fetchCurrentViewConfiguration`, {
      objectType: ConfigurationTypes.VIEW,
      alias: props.formAlias,
      context: props.context
    })

    formSchema.value = await store.getters[`${ModuleNamespaces.CONFIGURATION}/getCurrentViewConfiguration`](props.context)
  }

  return {
    getFormSchema,
    formSchema
  }
}
