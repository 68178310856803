import { computed, ComputedRef } from 'vue'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { AlertMessage, ListStatus } from '@/store/modules/alertsMessages/alertsMessagesTypes'

interface AlertsMessagesActions {
  alertsMessagesList: ComputedRef<AlertMessage[]>,
  status: ComputedRef<ListStatus>,
  loadAlertsMessagesList: () => Promise<void>
}

export default function useActionsAlertsMessages(): AlertsMessagesActions {
  const alertsMessagesList: ComputedRef<AlertMessage[]> = computed(() => store.getters[`${ ModuleNamespaces.ALERTS_MESSAGES }/getAlertsMessagesListData`])
  const status: ComputedRef<ListStatus> = computed(() => store.getters[`${ ModuleNamespaces.ALERTS_MESSAGES }/getAlertsMessagesListStatus`])
  const loadAlertsMessagesList = async () => {
    await store.dispatch(`${ModuleNamespaces.ALERTS_MESSAGES}/fetchAlertsMessagesList`)
  }

  return {
    alertsMessagesList,
    status,
    loadAlertsMessagesList,
  }
}
