<template lang="pug">

v-navigation-drawer(
  app
  clipped
  dark
  width="250"
  mini-variant-width="53"
  :height="drawerHeight"
  :class="['navigation-drawer', checkDrawerStatus, { 'is-portal': isPortalUser }]"
  :mini-variant="mini"
  permanent
  :temporary="$vuetify.breakpoint.mdAndUp ? false : true"
)

  v-list(dense class="pa-0" )
    template(v-for="(item, index) in siteMenu")

      //- Item with children
      v-list-group(
        v-if="item.children.length"
        :key="`${item.text}-${index}`"
        class="submenu-list"
        :value="isPortalUser ? true : checkIfOpened(item.text)"
        :append-icon="iconRight"
        :prepend-icon="!mini ? item.icon : ''"
        :ripple="false"
        :disabled="mini"
        )

        v-popover(
            v-if="mini"
            offset="0"
            placement ="right"
            slot="prependIcon"
            trigger="hover"
            :class="checkIfHighlight(item)"
            popoverClass="popover-custom-item"
          )
          v-icon {{ item.icon }}
          template(slot="popover")
            ul(class="popover popover-menu")
              li(v-for="(child, i) in item.children" :key="i" :class="checkPopoverItemClasses(!!child.children)")

                //- Popover with subchild
                v-list-group(
                    v-if="child.children.length"
                    :key="`${child.text}-${index}`"
                    :prepend-icon="child.icon"
                    :append-icon="iconRight"
                    :ripple="false"
                  )
                  template(v-slot:activator)
                    v-list-item(:ripple="false")  {{ child.text.toUpperCase() }}

                  v-list-item(
                      v-for="(subchild, subIndex) in child.children"
                      :key="`${subchild.text}-${subIndex}`"
                      class="subchild-item"
                      :to="subchild.internalUrl ? `/${subchild.url}` : ''"
                      :target="subchild.target"
                      :href="subchild.internalUrl ? '' : formatUrl(subchild.url)"
                      :ripple="false"
                    )
                    v-list-item-action(v-if="subchild.icon")
                        v-icon(:class="checkIfCircleIconClass(subchild.icon, true)") {{ subchild.icon }}
                    v-list-item-content
                      v-list-item-title(class="subchild-title") {{ subchild.text.toUpperCase() }}

                //- Popover without subchilds
                div(v-else :class="['popover-no-subchild-item', { 'first-item': i === 0 }]")
                  router-link(
                      v-if="child.internalUrl"
                      :class="['popover-item-text', { 'first-item': i === 0 }]"
                      :to="`/${child.url}`"
                      :target="child.target"
                    )
                      v-icon(v-if="i === 0" class="popover-icon") {{ item.icon }}
                      span(class="popover-text") {{ child.text }}
                  a(
                    v-else
                    class="popover-item-text"
                    :target="child.target"
                    :href="formatUrl(child.url)"
                    ) {{ child.text }}


        template(v-slot:activator)
          v-list-item(class="header-submenu-text-item" :ripple="false")
            v-list-item-content
              v-list-item-title(class="list-item-title px-0") {{ item.text.toUpperCase() }}

        template(v-for="(child, index) in item.children")

          //- Child with subchilds
          v-list-group(
            v-if="child.children.length"
            :key="`${child.text}-${index}`"
            class="subchild-list"
            sub-group
            :ripple="false"
            :disabled="mini"
            )
            template(v-slot:activator)
              v-list-item(:ripple="false")
                v-list-item-content
                  v-list-item-title {{ child.text.toUpperCase() }}

            template(slot="prependIcon")
              v-icon {{ child.icon }}

            template(slot="appendIcon")
              v-icon {{ iconRight }}

            v-list-item(
                v-for="(subchild, subindex) in child.children"
                :key="`${subchild.text}-${index}`"
                class="subchild-item px-2"
                :to="subchild.internalUrl ? `/${subchild.url}` : ''"
                :href="subchild.internalUrl ? '' : formatUrl(subchild.url)"
                :target="subchild.target"
                :ripple="false"
              )
              v-list-item-action(v-if="subchild.icon")
                  v-icon(:class="checkIfCircleIconClass(subchild.icon, true)") {{ subchild.icon }}
              v-list-item-content(class="pa-1")
                v-list-item-title {{ subchild.text.toUpperCase() }}


          //- Child without subchilds
          v-list-item(
              v-if="!child.children.length && !mini"
              class="submenu-item"
              :to="child.internalUrl ? `/${child.url}` : ''"
              :href="child.internalUrl ? '' : formatUrl(child.url)"
              :target="child.target"
              :ripple="false"
            )
            v-list-item-content
              v-list-item-title(class="list-item-title") {{ child.text.toUpperCase() }}
                v-icon(v-if="renderOpenWindowIcon(item.url)" class="open-window-icon-submenu") {{ iconOpenWindow }}

      //- Item without children
      v-list-item(
          v-else
          :key="`${item.text}-${index}`"
          class="menu-item"
          :class="checkSpecialItemClasses(item.text)"
          :to="item.internalUrl ? getInternalUrl(item.url, item.internalUrl) : ''"
          :href="item.internalUrl ? '' : formatUrl(item.url)"
          :target="item.target"
          :ripple="false"
        )

        template(v-if="!mini")
          v-list-item-action
            v-icon(class="drawer-icon") {{ item.icon }}
          v-list-item-content
            v-list-item-title(
                class="list-item-title"
              ) {{ item.text.toUpperCase() }}
              v-icon(v-if="renderOpenWindowIcon(item.url)" class="open-window-icon") {{ iconOpenWindow }}

        template(v-else)
          //- Popover without children
          v-popover(offset="0"  placement ="right" trigger="hover" :popoverClass ="[item.text, { 'is-portal': isPortalUser }, 'popover-custom-item']")
            v-icon {{ item.icon }}
            template(slot="popover")
              router-link(
                v-if="item.internalUrl"
                :class="['popover', 'popover-link', checkSpecialItemClasses(item.text)]"
                :to="`/${item.url}`"
                :target="item.target"
              )
                v-icon(class="popover-icon") {{ item.icon }}
                span(class="popover-text") {{ item.text }}
              a(
                v-else
                class="popover popover-link"
                :href="formatUrl(item.url)"
                :target="item.target"
                ) {{ item.text }}

  div(class="toogle-menu-btn-container" :class="{'mini': mini}" @click="toogleNavigationDrawer")
    button(class="toogle-menu-btn")
      span {{ $t('navigation.drawer-menu-items.collapse') }}
      div(class="arrows-container")
        span(:class="iconRight")
        span(:class="iconLeft")
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { SiteMenuItem } from '@/store/modules/menus/menusTypes'
import { normalizeText } from '@/helpers/helpers'
import {
  MENU_MINI_STATUS_MIN_WIDTH,
  MENU_MINI_STATUS_MAX_WIDTH,
  userPortalDrawerNames
} from '@/components/Navigation/NavigationConsts'
import { URLS } from '@/router/routes/urlRoutes'

const menusModule = ModuleNamespaces.MENUS
const authModule = ModuleNamespaces.AUTH

@Component
export default class DrawerComponent extends Vue {
  @Prop({
    type: Boolean
  })
  mini!: boolean

  @Prop({
    type: Number
  })
  windowWidth!: number

  @Prop({
    type: Boolean
  })
  collapseButtonIsClicked!: boolean

  @Prop({
    type: Boolean
  })
  isFooterVisible!: boolean

  @Prop({
    type: String
  })
  drawerHeight: string

  @Getter('getSiteMenu', { namespace: menusModule }) siteMenu: SiteMenuItem[]
  @Getter('getIsPortalUser', { namespace: authModule }) isPortalUser: boolean

  iconRight = Icons.ANGLE_RIGHT

  iconLeft = Icons.ANGLE_LEFT

  iconOpenWindow = Icons.OPEN_WINDOW

  get checkDrawerStatus(): string {
    return this.mini ? 'mini' : 'opened'
  }

  @Emit()
  toogleNavigationDrawer() {}

  @Watch('windowWidth', { immediate: true })
  collapse() {
    this.checkIfCollapse()
  }

  checkIfOpened(itemText: string): boolean {
    const itemWithoutAccents = itemText.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    return itemWithoutAccents.toLowerCase() === this.$route.meta?.parentUrl
  }

  formatUrl(url: string) {
    return `${process.env.VUE_APP_URL}/${url}`
  }

  checkIfCollapse() {
    if (this.windowWidth > MENU_MINI_STATUS_MIN_WIDTH && this.windowWidth <= MENU_MINI_STATUS_MAX_WIDTH) {
      this.$store.state.menusModule.drawerInMiniStatus = true
    } else if (!this.collapseButtonIsClicked && this.windowWidth > MENU_MINI_STATUS_MAX_WIDTH) {
      this.$store.state.menusModule.drawerInMiniStatus = false
    }
  }

  checkIfHighlight(item: SiteMenuItem) {
    let highlighted = false

    item.children.forEach((c) => {
      const normalize = normalizeText(c.text)

      if (normalize.replace(/\s/g, '-').toLowerCase() === this.$route.name) {
        highlighted = true
      }
    })

    return { highlighted }
  }

  checkSpecialItemClasses(itemText: string): object | string {
    const itemTextNormalize = normalizeText(itemText)
    let highlighted = false

    if (this.$route.name) {
      highlighted =
        this.$route.name.includes(itemTextNormalize.toLowerCase()) ||
        (itemTextNormalize === userPortalDrawerNames.EXPEDIENTS && this.$route.name.includes(URLS.EXPEDIENTS)) ||
        (itemTextNormalize === this.$t('navigation.drawer-menu-items.contacts') &&
          this.$route.name === URLS.CONTACTS_ALL)
    }

    if (this.$t('routes.actions_lexnet').toString() === itemText.toLowerCase()) {
      highlighted = false
    }

    return { highlighted }
  }

  checkPopoverItemClasses(hasSubchild: boolean): string {
    return hasSubchild ? 'popover-subchild-list' : 'popover-menu-item'
  }

  checkIfCircleIconClass(icon: string, subchildtype: boolean): object {
    return subchildtype
      ? { 'subchild-circle-icon': icon === 'lf-icon-step-first' }
      : { 'child-circle-icon': icon === 'lf-icon-step-first' }
  }

  getInternalUrl(url: string): string {
    return this.mini ? '' : `/${url}`
  }

  getExternalUrl(url: string) {
    return this.mini ? '' : this.formatUrl(url)
  }

  renderOpenWindowIcon(url: string) {
    const urls = [URLS.AGENDA, URLS.EMAIL, URLS.LEXNET, URLS.ANOTHER_APPLICATIONS]
    if (urls.includes(url)) {
      return true
    }
    return false
  }
}
</script>

<style lang="scss" scoped>
@import '~@/components/Navigation/Drawer/styles/drawerStyles';
</style>

<style lang="scss">
.popover.Inicio {
  position: relative;
  top: -5px !important;
}

.popover-custom-item .wrapper .popover-inner > div {
  display: flex;
  background-color: $main-200;

  .popover-icon {
    width: auto;
    color: $main-1000;

    &::before {
      font-size: 20px;
      width: 20px;
      height: 20px;
    }
  }

  .popover-text {
    margin-left: 20px;
  }
}
</style>
