<template lang="pug">
section.lexbox-redirect-container
  HeaderComponent

  section.lexbox-redirect-section

</template>
<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import HeaderComponent from '@/components/Header/HeaderComponent.vue'

const authModule: string = ModuleNamespaces.AUTH
const spinnerModule: string = ModuleNamespaces.SPINNER

@Component({
  components: {
    HeaderComponent
  }
})
export default class OnlineView extends Vue {
  @Action('encryptIdProUser', { namespace: authModule })
  encryptIdProUser: () => void

  @Getter('getEncryptedIdProUser', { namespace: authModule })
  encryptedIdProUser: string

  @Mutation('SHOW_SPINNER', { namespace: spinnerModule })
  showSpinner: (showText: boolean) => void

  async mounted() {
    this.showSpinner(true)
    await this.encryptIdProUser()
    const path = `${process.env.VUE_APP_LOGIN_ONLINE}${this.encryptedIdProUser}`
    window.location.href = path
  }
}
</script>

<style lang="scss" scoped>
.lexbox-redirect {
  &-container {
    @include external-view-layout;
    font-family: $corporate-font;
    color: $corporate-color;
  }

  &-section {
    @include flex;
    flex-direction: column;
    margin: 30px;
  }

  &-card {
    @include flex($flex-direction: column, $justify-content: flex-start);
    @include borders;
    width: 450px;
    padding-top: 50px;
  }
}
</style>
