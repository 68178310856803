import useStageSelector from '@/components/StageSelector/composable/useStageSelector'
import useSelectedRegister from '@/composables/useSelectedRegister'
import store from '@/store/store'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ref } from 'vue'

interface ResponseUseExpedientInsurers {
  expedientInsurers: any
  expedientData: any
  saveExpedient: (formData: any) => Promise<boolean>
  removeInsurer: (id: string) => Promise<boolean>
  fetchExpedientInsurers: () => void
  saveInsurer: (formData: any) => Promise<{success: boolean, id: string}>
}

export default function useExpedientInsurers(): ResponseUseExpedientInsurers {
  const expedientInsurers = ref([])
  const { selectedStage } = useStageSelector()

  const { selectedRegisterId, selectedRegisterData } = useSelectedRegister({entityName: ContextName.EXPEDIENTS})

  const fetchExpedientInsurers = async () => {
    await store.dispatch(`${ModuleNamespaces.EXPEDIENTS}/fetchExpedientInsurers`, {
      selectedRegisterId: selectedRegisterId.value,
      filter: {
        ...(selectedStage.value && { idStage: selectedStage.value })
      }
    })

    expedientInsurers.value = store.getters[`${ModuleNamespaces.LIST_ITEMS}/getListItemsWithFormattedDates`]
  }

  const saveInsurer = async (formData: any) => {
    const { idInsurer } = formData
    const data = {
      ...formData,
      idInsurer: idInsurer,
      idExpedient: selectedRegisterId.value,
    }
    return await store.dispatch(`${ModuleNamespaces.EXPEDIENTS}/saveExpedientInsurer`, data)
  }

  const removeInsurer = async (
    id: string
  ) => await store.dispatch(`${ModuleNamespaces.EXPEDIENTS}/removeExpedientInsurer`, id)


  const saveExpedient = async (formData: any) => {
    const data = {
      ...formData,
      id: selectedRegisterId.value
    }
    return await store.dispatch(`${ModuleNamespaces.EXPEDIENTS}/saveExpedient`, data)
  }

  return {
    expedientInsurers,
    expedientData: selectedRegisterData,
    saveExpedient,
    fetchExpedientInsurers,
    removeInsurer,
    saveInsurer
  }
}
