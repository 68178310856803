<template lang="pug">

div(class="users-maintenance")

  SpinnerLayerComponent(v-if="showSpinner" class="spinner-layer")

  UsersMaintenanceGridComponent(
    v-if="isGridRenderMode"
    :itemsData="usersData"
    :listConfiguration="listConfiguration"
    @openFormEvent="onformOpenEvent"
    :context="context"
    :headerTitle="headerTitle"
  )

  UserManintenanceTabsComponent(
    v-if="isTabRenderMode"
    @closeTabsEvent="onCloseTabsEvent"
    @saveFormEvent="onSaveFormEvent"
    @hideFatherComponentSpinnerEvent="onHideFatherComponentSpinnerEvent"
    @saveOnFormTabChangeEvent="onSaveOnFormTabChangeEvent"
    :userData="selectedRegister"
    :headerTitle="headerTitle"
  )

</template>

<script lang="ts">
import { Action, Getter } from 'vuex-class'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Prop, Component, Vue } from 'vue-property-decorator'
import { UsersMaintenanceComponentMode } from './types/UsersMaintenanceComponentTypes'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import UserManintenanceTabsComponent from './components/UserManintenanceTabsComponent.vue'
import UsersMaintenanceGridComponent from './components/UsersMaintenanceGridComponent.vue'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'

const alertsModule: string = ModuleNamespaces.ALERTS
const usersModule: string = ModuleNamespaces.USERS
const configurationModule: string = ModuleNamespaces.CONFIGURATION

@Component({
  components: {
    SpinnerLayerComponent,
    UserManintenanceTabsComponent,
    UsersMaintenanceGridComponent
  }
})
export default class UsersMaintenanceComponent extends Vue {
  @Prop({
    type: String
  })
  headerTitle!: string

  @Action('fetchCurrentListConfiguration', { namespace: configurationModule })
  fetchCurrentListConfiguration: (alias: string) => {}

  @Action('fetchListUsers', { namespace: usersModule })
  fetchListUsersAction: () => []

  @Action('saveMaintenanceUser', { namespace: usersModule })
  saveMaintenanceUserAction: ({}) => Promise<boolean>

  @Action('rememberUserCustomConfigurationList', { namespace: configurationModule })
  rememberUserCustomConfigurationList: (flag: boolean) => {}

  @Action('showAlert', { namespace: alertsModule })
  showAlert: ({}) => {}

  @Getter('getUsersData', { namespace: usersModule }) usersData: []
  @Getter('getCurrentListConfiguration', { namespace: configurationModule }) listConfiguration: object

  selectedRegister = {}

  showSpinner = true

  renderMode: UsersMaintenanceComponentMode = UsersMaintenanceComponentMode.SPINNER

  get isGridRenderMode(): boolean {
    return this.renderMode === UsersMaintenanceComponentMode.GRID
  }

  get isTabRenderMode(): boolean {
    return this.renderMode === UsersMaintenanceComponentMode.TABS
  }

  get context() {
    return ContextName.MAINTENANCE_USERS
  }

  mounted() {
    this.fetchUsersGridData()
  }

  showSpinnerLayer() {
    this.renderMode = UsersMaintenanceComponentMode.SPINNER
    this.showSpinner = true
  }

  async fetchUsersGridData() {
    await this.fetchListUsersAction()
    await this.fetchCurrentListConfiguration('listUsers')
    this.renderMode = UsersMaintenanceComponentMode.GRID
    this.showSpinner = false
  }

  async onformOpenEvent(selectedRegister: any = null) {
    this.showSpinner = true
    this.renderMode = UsersMaintenanceComponentMode.TABS
    this.selectedRegister = selectedRegister
  }

  async onCloseTabsEvent() {
    this.showSpinnerLayer()
    this.fetchUsersGridData()
    this.rememberUserCustomConfigurationList(true)
  }

  async onSaveFormEvent(formData: object) {
    this.showSpinnerLayer()
    await this.onSaveOnFormTabChangeEvent(formData)
    await this.fetchUsersGridData()
    this.rememberUserCustomConfigurationList(true)
  }

  onHideFatherComponentSpinnerEvent() {
    this.showSpinner = false
  }

  async onSaveOnFormTabChangeEvent(formData: object) {
    const response = await this.saveMaintenanceUserAction(formData)
    if (response) {
      this.showAlert({
        type: AlertsTypes.SUCCESS,
        message: this.$t('components.alerts.register_save'),
        componentWhereIsRendered: ComponentWhereIsRendered.MAIN
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.users-maintenance {
  height: 100%;

  .spinner-layer {
    height: 100%;
    align-self: center;
  }

  .tab-header-container {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
</style>
