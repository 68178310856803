<template lang="pug">
  DocumentOnline
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import { documentOnlineActions } from '@/store/modules/documentOnline/documentOnlineTypes'
import { Action, Getter, Mutation } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { getFileExtension } from '@/helpers/file'
import { isSafari } from '@/helpers/navigator'
import { createLink } from '@/helpers/blob'
import DocumentOnline from '@/components/DocumentOnline/DocumentOnlineComponent.vue'
import OpenMSOfficeFiles from '@/mixins/OpenMSOfficeFilesMixin.vue'
import { OpenMSOffice, OpenMSOfficeFileData } from '@/components/FileManager/types/FileManagerTypes'

const authModule = ModuleNamespaces.AUTH
const documentOnlineModule = ModuleNamespaces.DOCUMENT_ONLINE
const fileManagerModule = ModuleNamespaces.FILE_MANAGER

@Component({
  components: {
    DocumentOnline
  }
})
export default class OpenFileComponent extends Mixins(OpenMSOfficeFiles) {
  @Action('saveFileName', { namespace: documentOnlineModule }) saveFileName: (fileName: string) => void
  @Action('fetchRequestToken', { namespace: documentOnlineModule }) fetchRequestToken: (params: any) => string
  @Action('fetchAppType', { namespace: documentOnlineModule }) fetchAppType: (params: any) => string
  @Action('fetchFileToken', { namespace: documentOnlineModule }) fetchFileToken: (idFile: string) => void

  @Action('saveFileSize', { namespace: documentOnlineModule }) saveFileSize: (fileSize: number) => void
  @Action('saveFileUrl', { namespace: documentOnlineModule }) openOnlineEditor: (fileUrl: string) => void

  @Action('openFile', { namespace: fileManagerModule }) execOpenFile: (id: string) => Blob
  @Action('downloadFile', { namespace: fileManagerModule }) execDownloadFile: (id: string) => Blob
  @Action('fetchOpenOfficeIn', { namespace: fileManagerModule }) fetchOpenOfficeIn: () => OpenMSOffice

  @Mutation('SAVE_ACTION', { namespace: documentOnlineModule }) setDocumentOnlineAction: (action: string) => void

  @Getter('getFileAction', { namespace: documentOnlineModule }) fileAction: string
  @Getter('getFile', { namespace: fileManagerModule }) blobFile: Blob
  @Getter('getUserId', { namespace: authModule }) idUser: number
  @Getter('getFileToken', { namespace: documentOnlineModule }) fileToken: string
  @Getter('getRequestToken', { namespace: documentOnlineModule }) requestToken: string
  @Getter('getRequestTokenTtl', { namespace: documentOnlineModule }) requestTokenTtl: string

  downloadExtensions = ['.msg', '.eml', '.rtf']

  openMSOfficeConfig: OpenMSOffice = OpenMSOffice.WOPI

  openFile(file: any) {
    if (this.isOnlineEditable(file.originalFileName)) {
      this.setDocumentOnlineAction(documentOnlineActions.EDIT)
      this.editDocumentOnline(file)
    } else {
      this.customOpenFile(file)
    }
  }

  openFileWithOffice(file: OpenMSOfficeFileData) {
    const name: string = file.name || ''
    const extension = '.' + getFileExtension(name) || ''
    const getMSOfficeLocalUri = this.getMSOfficeLocalURI(extension)

    if (this.openMSOfficeConfig === OpenMSOffice.LOCAL && getMSOfficeLocalUri) {
      const data = {
        id: file.id,
        originalFileName: file.originalFileName,
        type: extension
      }
      this.openMSOfficeLocal(data)
    } else {
      this.openFile(file)
    }
  }

  async downloadFile(file: any) {
    await this.execDownloadFile(file.id)
    const blobUrl = URL.createObjectURL(this.blobFile)
    createLink(blobUrl, file.originalFileName)
  }

  async customOpenFile(file: any) {
    await this.execOpenFile(file.id)

    const blobUrl = URL.createObjectURL(this.blobFile)

    let fileName = ''
    if (
      this.downloadExtensions.find((extension: string) =>
        file.originalFileName.toLowerCase().includes(extension.toLowerCase())
      )
    ) {
      fileName = file.originalFileName
    }

    if (isSafari()) {
      const url = `/${URLS.REPOSITORY}?fileId=${file.id}`
      createLink(url, '', true)
      return
    }

    createLink(blobUrl, fileName)
  }

  async editDocumentOnline(file: any | null) {
    this.saveFileName(file.originalFileName)

    const formData = new FormData()
    formData.set('FileName', file.originalFileName)
    formData.set('FileVersion', Date.now().toString())
    formData.set('wopiUserId', this.idUser.toString())

    await this.fetchRequestToken(formData)

    formData.set('AccessToken', this.requestToken)
    formData.set('AccessTokenTtl', this.requestTokenTtl)

    await this.fetchAppType(formData)

    await this.fetchFileToken(file.id)

    if (this.fileAction === documentOnlineActions.NEW) {
      this.saveFileSize(0)
    }

    this.openOnlineEditor(`${process.env.VUE_APP_FILE_EDIT_URL}${this.fileToken}`)
  }

  isOnlineEditable(fileName: string): boolean {
    const extension = getFileExtension(fileName)
    if (!extension) {
      return false
    }
    return ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].includes(extension.toLowerCase())
  }

  async mounted() {
    this.openMSOfficeConfig = await this.fetchOpenOfficeIn()
  }
}
</script>
