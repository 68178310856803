import { ActionTree } from 'vuex'
import {
  CounterItem,
  dashboardWidgetsMutationTypes,
  DashboardWidgetsState
} from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { MainService } from '@/services/MainService'
import { counterWidgetAdapter } from '@/store/modules/dashboardWidgets/dashboardWidgetsAdapters'

export const actions: ActionTree<DashboardWidgetsState, RootState> = {
  toggleComeFromDashboardWidgetFlag({ commit }, status) {
    commit('SET_COME_FROM_DASHBOARD_WIDGET_FLAG', status)
  },
  async loadActionsWidget({ commit }) {
    try {
      const { data } = await new MainService().getData('v2/widget/actions')
      commit(dashboardWidgetsMutationTypes.SET_ACTION_WIDGET_LIST, data)
    } catch (error) {
      commit(dashboardWidgetsMutationTypes.SET_ACTION_WIDGET_ERROR_STATUS)
    }
  },
  async loadCountersWidget({ commit }, { router }) {
    try {
      const { data } = await new MainService().getData('v2/widget/counter')
      commit(dashboardWidgetsMutationTypes.SET_COUNTER_WIDGET_LIST, counterWidgetAdapter(data, router))
    } catch (error) {
      commit(dashboardWidgetsMutationTypes.SET_COUNTER_WIDGET_ERROR_STATUS)
    }
  },
  async updateGridConfigFromCounter({commit}, counter: CounterItem) {
    try {
      const formData: FormData = new FormData()
      formData.append('archived', counter.archived.toString())
      formData.append('searchSettings', '')
      formData.append('sortSettings', JSON.stringify(counter.sortSettings))
      formData.append('filterSettings', JSON.stringify(counter.filterSettings))
      await new MainService().putFormData(
        `v2/user-setting/${ counter.listAlias }/widget`,
        formData
      )
      commit('SET_COME_FROM_DASHBOARD_WIDGET_FLAG', true)
    } catch (error) {
      console.log('ERROR:', error)
    }
  },
}
