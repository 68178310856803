<template lang="pug">

div(
  class="no-results-container"
  :class="[withBordersClass, verticalAlignCenterClass]"
)
  span(
    v-if='hasIcon'
    :class="[noResultsIcon, 'icon']"
  )
  div(class="no-results-text")
    p(class="first-paragraph") {{ noResultsFirstText }}
    p(class="second-paragraph" v-html="noResultsSecondText")

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { i18n } from '@/plugins/vue-i18n'

@Component
export default class NoResultsComponent extends Vue {
  @Prop({
    type: Boolean,
    default: true
  })
  hasIcon!: boolean

  @Prop({
    type: String,
    default: Icons.ARCHIVE_EMPTY
  })
  noResultsIcon!: string

  @Prop({
    type: String,
    default: i18n.t('components.no_results.no_search_results_text')
  })
  noResultsFirstText!: string

  @Prop({
    type: String,
    default: i18n.t('components.no_results.try_again')
  })
  noResultsSecondText!: string

  @Prop({
    type: Boolean,
    default: true
  })
  withBorders!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  verticalAlignCenter!: boolean

  get withBordersClass() {
    return this.withBorders ? 'with-borders' : ''
  }

  get verticalAlignCenterClass() {
    return this.verticalAlignCenter ? 'vertical-align-center' : ''
  }
}
</script>

<style lang="scss" scoped>
.lf-selector-tree {
  display: grid;
  height: 95%;
}

.no-results-container {
  @include flex($flex-direction: row);
  padding: 80px 10px 120px;
  margin-top: 2px;
  text-align: left;

  &.with-borders {
    @include borders;
    border: 1px solid $main-200;
    border-radius: $cornerRadius-md;
  }

  &.vertical-align-center {
    height: 99%;
  }

  .icon {
    font-size: 35px;
    color: $main-400;
    margin-right: 8px;
  }

  .no-results-text {
    color: $neutral-grey-600;

    .first-paragraph {
      @include milano-medium-16;
      margin: 0;
    }

    .second-paragraph {
      @include milano-regular-14;
      margin: 2px 0 0 0;
    }
  }
}
</style>
