<template>
  <div class="numeration">
    <NumerationTitleComponent />
    <LfGridComponent
      v-if="gridConfiguration"
      :gridConfiguration="gridConfiguration"
      :itemData="numerationList"
      :toolbarOptions="toolbarItemsOptions"
      :contextMenuItems="contextMenuItems"
      :commandClick="onCommandClick"
      :exportName="texts.title"
      @toolbarClicked="onToolbarClick"
      @contextMenuClicked="onContextMenuClicked"
      @rowClick="onRowClick"
    ></LfGridComponent>
  </div>
</template>

<script lang="ts" setup>
import LfGridComponent from '@/components/grids/LfGrid/LfGridComponent.vue'
import { ActionName, CommandClickEventArgs } from '@/components/grids/LfGrid/LfGridTypes'
import { computed } from 'vue'
import { Icons } from '@/icons/icons'
import { ToolbarItemModel } from '@syncfusion/ej2-vue-filemanager'
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations'
import {
  commandColumnConfigurationPanel
} from '@/views/ConfigurationPanel/types/configurationPanelTypes'
import useGridConfiguration from '@/composables/useGridConfiguration'
import { ContextMenuClickEventArgs, ContextMenuItemModel, RecordClickEventArgs } from '@syncfusion/ej2-vue-grids'
import { useI18n } from 'vue-i18n-composable'
import {
  kebakMenuToolbarItem,
  searchToolbarItem
} from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import NumerationTitleComponent from './NumerationTitleComponent.vue'
import useNumeration from './composables/useNumeration'

const { t } = useI18n()

// DEFINE CONST  ======================================================================================================
const texts = {
  title: 'Numeración automática',
  buttons: {
    new: t('action_buttons.new_numeration').toString()
  }
}

// COMPOSABLES   ======================================================================================================
const { gridConfiguration } = useGridConfiguration({
  listName: 'listManNumeration',
  context: 'configurationPanelAutomaticNumbering',
  commandButtons: commandColumnConfigurationPanel
})

const { numerationList, read } = useNumeration()

// COMPUTEDS ==========================================================================================================
const toolbarItemsOptions = computed((): ToolbarItemModel[] => {
  return [
      searchToolbarItem,
      {
        id: ActionName.ADD,
        text: texts.buttons.new,
        align: 'Right',
        tooltipText: texts.buttons.new,
        cssClass: 'lf-btn-model2'
      },
      kebakMenuToolbarItem
    ]
})

const contextMenuItems = computed((): ContextMenuItemModel[] => {
  return [
  {
      id: ActionName.EDIT,
      text: t('components.context_menu.edit').toString(),
      iconCss: Icons.EDIT
    },
    {
      id: ActionName.REMOVE,
      text: t('components.context_menu.remove').toString(),
      iconCss: Icons.REMOVE
    }
  ]
})

// EVENTS =============================================================================================================
const emit = defineEmits([
  'row-click',
  'command-click',
  'toolbar-click',
  'context-menu-click'
])

const onToolbarClick = ({ item }: ClickEventArgs) => {
  emit('toolbar-click', item)
}


const onRowClick = ({ rowData }: RecordClickEventArgs) => {
  emit('row-click', rowData)
}

const onCommandClick = ({ rowData }: CommandClickEventArgs) => {
  emit('command-click', rowData)
}

const onContextMenuClicked = ({ rowInfo, item }: ContextMenuClickEventArgs) => {
  emit('context-menu-click', item, rowInfo)
}

async function init() {
  read()
}

// HOOKS =============================================================================================================
init()
</script>
