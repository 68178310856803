import useRouter from "@/composables/useRouter"
import { URLS } from "@/router/routes/urlRoutes"
import { Expedient } from "@/store/modules/expedients/expedientsTypes"
import store from "@/store/store"
import { ModuleNamespaces } from "@/store/types/storeGlobalTypes"
import { ComputedRef, computed, ref } from "vue"

interface ResponseContactExpedients {
  expedients: ComputedRef<Expedient[]>
  read: (id: string) => void
  open: (selectedRegisterId: string, target?: string) => void
}

export default function useContactExpedients({listName}: {listName: string}): ResponseContactExpedients {

  const router = useRouter()
  const expedients = ref<Expedient[]>([])

  const read = async (selectedRegisterId: string) => {
    await store.dispatch(`${ModuleNamespaces.EXPEDIENTS}/fetchExpedients`, {
      selectedRegisterId,
      listName
    })
    expedients.value = store.getters[`${ModuleNamespaces.LIST_ITEMS}/getListItemsWithFormattedDates`]
  }

  const computedExpedients = computed(() => expedients.value)

  const open = (selectedRegisterId: string, target = '_self') => {
    const routeData = router.resolveRoute({
      name: `${URLS.EXPEDIENTS}-${URLS.EXPEDIENTS}`,
      params: { selectedRegisterId }
    })
    if (target === '_self') {
      router.goToRoute((routeData as any).resolved)
    } else {
      window.open((routeData as any).href, '_blank')
    }
  }

  return {
    expedients: computedExpedients,
    open,
    read
  }
}
