import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { CounterItem } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import VueRouter from 'vue-router'

export function counterWidgetAdapter(counterList: any [], router: VueRouter): CounterItem [] {
  return counterList.map(
    (counter: any) => {
      if (
        counter.title === undefined ||
        counter.qty === undefined ||
        counter.sortSettings === undefined ||
        counter.filterSettings === undefined ||
        counter.listAlias === undefined ||
        counter.url === undefined
      ) {
        console.log('CounterWidget required properties not defined')
        throw new Error('CounterWidget required properties not defined')
      }
      if (counter?.archived === undefined) {
        counter.archived = 0
      }
      counter.action = () => counterAction(counter, router)
      return counter
    }) as CounterItem []
}

async function counterAction(counter: CounterItem, router: VueRouter) {
  await store.dispatch(`${ModuleNamespaces.DASHBOARD_WIDGETS}/updateGridConfigFromCounter`, counter)
  await router.push(counter.url)
}
