<template lang="pug">
div(class="toolbar")

  ul(class="toolbar-list")
    li(v-if="showItemByUser" class="toolbar-item")
      AlertsContextMenuComponent

    li(v-if="showConfigPanel" class="toolbar-item")
      ConfigPanelContextMenuComponent(
        :showItem="showItemByUser"
        :configurationUrl="configurationUrl"
      )

    span(class="separator")

    li(class="avatar-container")
      AvatarContextMenuComponent

    li(v-if="!isPortalUser" class="toolbar-item" @click="toggleSupportServiceDialog")
      span(:class="[helpIcon, 'icon-style']")

    li(v-if="!isPortalUser" class="toolbar-item")
      HubContextMenuComponent

  SupportServiceDialogComponent(
    :showDialog="showSupportServiceDialog"
    @hide-dialog="toggleSupportServiceDialog"
  )
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import SupportServiceDialogComponent from '@/components/SupportServiceDialog/SupportServiceDialogComponent.vue'
import HubContextMenuComponent from '@/components/Navigation/AppBarToolbarItems/contextMenus/HubContextMenuComponent.vue'
import ConfigPanelContextMenuComponent from '@/components/Navigation/AppBarToolbarItems/contextMenus/ConfigPanelContextMenuComponent.vue'
import AlertsContextMenuComponent from '@/components/Navigation/AppBarToolbarItems/contextMenus/alerts/AlertsContextMenuComponent.vue'
import AvatarContextMenuComponent from '@/components/Navigation/AppBarToolbarItems/contextMenus/AvatarContextMenuComponent.vue'
import { URLS } from '@/router/routes/urlRoutes'
import { PermissionVerbs } from '@/store/modules/auth/authTypes'
import { MaintenanceEntityId } from '@/general/entityIds'

const authModule = ModuleNamespaces.AUTH

@Component({
  components: {
    ConfigPanelContextMenuComponent,
    SupportServiceDialogComponent,
    HubContextMenuComponent,
    AlertsContextMenuComponent,
    AvatarContextMenuComponent
  }
})
export default class AppBarToolbarItemsComponent extends Vue {
  @Getter('getIsPortalUser', { namespace: authModule }) isPortalUser: boolean
  @Getter('getIsLexonDmsUser', { namespace: authModule }) isDMSUser: boolean
  @Getter('getPermission', { namespace: authModule }) checkPermissions: (entityId: number) => PermissionVerbs

  currentUrl: string = ''

  showSupportServiceDialog: boolean = false

  helpIcon = Icons.HELP

  get showConfigPanel() {
    const { canView } = this.checkPermissions(MaintenanceEntityId.MAINTENANCE)
    const showMaintenance: boolean = !!canView
    return this.showItemByUser || (this.isDMSUser && showMaintenance)
  }

  get showItemByUser() {
    return !this.isPortalUser && !this.isDMSUser
  }

  get configurationUrl() {
    return this.isDMSUser
      ? `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_DOCUMENTS_CATEGORY}`
      : `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_USUARIOS}`
  }

  @Watch('$route')
  encodeUrl() {
    this.currentUrl = encodeURIComponent(document.location.href)
  }

  toggleSupportServiceDialog() {
    this.showSupportServiceDialog = !this.showSupportServiceDialog
  }
}
</script>

<style lang="scss" scoped>
.icon-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: $neutral-grey-700;
}

.toolbar-list {
  @include flex;
  height: 42px;
  list-style-type: none;
  padding: 0;

  .toolbar-item {
    @include flex;
    padding-left: $spacing-lg;
    cursor: pointer;
  }

  .avatar-container {
    padding-left: $spacing-xxs;
  }

  .separator {
    height: 100%;
    width: 1px;
    background-color: $neutral-grey-700;
    margin: 0 $spacing-lg;
  }
}

@include untilTablet {
  .toolbar-list {
    margin-right: 0;
  }
}

@include tabletAndMore {
  .toolbar-list {
    margin-right: 0;
  }
}
</style>
