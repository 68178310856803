<template lang="pug">

div(
  class="dialog-actions-container"
)
  LfActionsBarComponent(
    :buttons="buttons"
    @execute-action="executeAction"
    class="action-buttons"
    :showKebabMenu="false"
  )

</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import {
  PrintOrSaveInvoicesSteps,
  PrintInvoiceEvents
} from '@/components/billing/PrintOrSaveInvoicesDialog/types/PrintOrSaveInvoicesDialogTypes'
import LfActionsBarComponent from '@/components/ActionsBar/LfActionsBarComponent.vue'
import { ActionBarButton, ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'
import { Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ITemplate } from '@/store/modules/template/templateTypes'

const templateModule = ModuleNamespaces.TEMPLATE

@Component({
  components: {
    LfActionsBarComponent
  }
})
export default class PrintOrSaveInvoicesDialogActionsComponent extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  currentStep!: number

  @Prop({
    type: Boolean,
    default: false
  })
  disabledNextButton!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  showDownloadButton!: boolean

  @Getter('getTemplateTree', { namespace: templateModule })
  tree: () => ITemplate[]

  texts = {
    prev: this.$t('action_buttons.prev'),
    next: this.$t('action_buttons.next'),
    cancel: this.$t('action_buttons.cancel'),
    finish: this.$t('action_buttons.finish'),
    generate: this.$t('action_buttons.generate'),
    download: this.$t('action_buttons.downloadPDF')
  }

  printInvoiceEvents = PrintInvoiceEvents

  executeAction(actionName: string) {
    switch (actionName) {
      case ActionName.CLOSE:
        return this.eventButton(PrintInvoiceEvents.CANCEL)
      case ActionName.NEXT:
        return this.eventButton(PrintInvoiceEvents.CONFIGURE_OPTIONS)
      case ActionName.PREV:
        return this.eventButton(PrintInvoiceEvents.SELECT_INVOICES)
      case ActionName.GENERATE_DOCUMENT:
        return this.eventButton(PrintInvoiceEvents.GENERATE)
      case ActionName.FINISH_INVOICE_GENERATION:
        return this.eventButton(PrintInvoiceEvents.FINISH)
    }
  }

  get buttons(): ActionBarButton[] {
    return [
      {
        tooltip: this.texts.cancel,
        variant: LabelButtonVariant.OUTLINED,
        action: ActionName.CLOSE,
        hidden: !this.renderCancelButton,
        enabled: true
      },
      {
        tooltip: this.texts.next,
        variant: LabelButtonVariant.SOLID,
        action: ActionName.NEXT,
        hidden: !this.renderNextButton,
        enabled: !this.disabledNextButton
      },
      {
        tooltip: this.texts.prev,
        variant: LabelButtonVariant.OUTLINED,
        action: ActionName.PREV,
        hidden: !this.renderPrevButton,
        enabled: true
      },
      {
        tooltip: this.texts.generate,
        variant: LabelButtonVariant.SOLID,
        action: ActionName.GENERATE_DOCUMENT,
        hidden: !this.renderPrevButton,
        enabled: true
      },
      {
        tooltip: this.texts.finish,
        variant: LabelButtonVariant.SOLID,
        action: ActionName.FINISH_INVOICE_GENERATION,
        hidden: !this.renderFinishButton,
        enabled: true
      }
    ]
  }

  get renderNextButton() {
    return this.currentStep === PrintOrSaveInvoicesSteps.SELECT_INVOICES && this.tree.length > 0
  }

  get renderPrevButton() {
    return this.currentStep === PrintOrSaveInvoicesSteps.CONFIGURE_OPTIONS
  }

  get renderPrimaryButton() {
    return this.currentStep === PrintOrSaveInvoicesSteps.SELECT_INVOICES
  }

  get renderDownloadButton() {
    return this.currentStep === PrintOrSaveInvoicesSteps.GENERATION_PROGRESS && this.showDownloadButton
  }

  get renderFinishButton() {
    return this.currentStep === PrintOrSaveInvoicesSteps.FINALIZE_PROCESS
  }

  get renderCancelButton() {
    return [
      PrintOrSaveInvoicesSteps.SELECT_INVOICES,
      PrintOrSaveInvoicesSteps.GENERATION_PROGRESS,
      PrintOrSaveInvoicesSteps.CONFIGURE_OPTIONS
    ].includes(this.currentStep)
  }

  @Emit('eventButton')
  eventButton(event: string) {
    return { event }
  }
}
</script>

<style lang="scss" scoped>
.dialog-actions-container {
  @include flex($justify-content: flex-end);
  width: 100%;
  margin-top: $spacing-xl;

  ::v-deep .main-action-button {
    @include main-action-button--rectangle;
  }

  ::v-deep .secondary-action-button {
    @include secondary-action-button--rectangle;
  }
}
</style>
