<template lang="pug">

WidgetBaseComponent(
  ref="widget"
  class="next-events-widget"
  :loadedData="allDataLoaded && !showSpinner"
)
  //- HEADER
  template(v-slot:header)
    div(class="header-bar")
      h2 {{ expedientGlobalVisionAppointmentsGetter.title }}
      v-menu(bottom left nudge-bottom="24" content-class="navigation-context-menu")
        template(v-slot:activator="{ on, attrs }")
          button(
            v-if="userHasPermissionToSaveAtLeastOneActionType"
            :class="[icons.addButton, 'add-button']"
            v-bind="attrs" v-on="on"
          )

        v-list(class="list")
          v-list-item(v-for="(item, index) in contextMenuItems" :key="index" class="list-item" @click="onContextMenuItemClicked(item.actionType)")
            span(:class="item.iconCss")
            span {{ item.text }}

  //- BODY
  template(v-slot:body)
    section(v-if="expedientGlobalVisionAppointmentsGetter.body.length > 0" class="events-container")
      div(v-for="(event, index) in  expedientGlobalVisionAppointmentsGetter.body" class="event" :key="index" @click.stop="goToEvent(event)")
        span(:class="[checkActionIconHelper(event.idActionType), 'lf-icon__md', 'event-icon']")
        div(class="event-info-container")
          span(class="event-subject") {{ event.subject }}
          div(class="event-info")
            div(class="data")
              span(class="label") {{ text.date  }}
              span {{ event.startDate }}
            div(class="data")
              span(class="label") {{ text.hour }}
              span {{ `${event.hourStart}h - ${event.hourEnd}h` }}
            div(class="data")
              span(class="label") {{  text.duration }}
              span {{ event.duration || '-' }}

    div(v-else class="no-events-container")
      div(class="wrapper")
        span(:class="[icons.archiveEmpty, 'explanation-icon', 'lf-icon__xl']")
        div(class="explanation-container")
          span(class="explanation-title") {{ text.noEvents }}
          p(class="explanation") {{ text.explanation }}

</template>

<script lang="ts">
import { Component, Mixins, Watch } from 'vue-property-decorator'
import WidgetBaseComponent from '@/components/widgets/WidgetBaseComponent.vue'
import EventContainerComponent from '@/components/events/EventContainer/EventContainerComponent.vue'
import { Icons } from '@/icons/icons'
import { NextEventsWidgetData } from '@/components/widgets/ExpedientWidgets/ExpedientNextEvents/ExpedientNextEventsWidgetTypes'
import { ActionTypes, checkActionIcon } from '@/store/modules/actions/actionsTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action, Getter, Mutation } from 'vuex-class'
import { TabNames } from '@/store/modules/tabs/tabsTypes'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'
import { entity } from '@/store/modules/entities/entitiesTypes'

const appointmentsModule: string = ModuleNamespaces.APPOINTMENTS
const expedientsModule = ModuleNamespaces.EXPEDIENTS
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    EventContainerComponent,
    WidgetBaseComponent
  }
})
export default class ExpedientNextEventsWidget extends Mixins(PermissionsMixin) {
  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterId: (context: string) => any
  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterIdGetter: (context: string) => number
  @Getter('getExpedientGlobalVisionAppointments', { namespace: appointmentsModule })
  expedientGlobalVisionAppointmentsGetter: () => NextEventsWidgetData
  @Getter('getExpedientStageState', { namespace: expedientsModule })
  expedientStageStateGetter: string

  @Mutation('RESET_EXPEDIENT_GLOBAL_VISION_WIDGET_APPOINTMENTS', { namespace: appointmentsModule })
  resetWidgetMutation: () => void

  @Action('fetchExpedientGlobalVisionAppointments', { namespace: appointmentsModule })
  fetchExpedientGlobalVisionAppointmentsAction: ({
    idExpedient,
    source,
    idStage
  }: {
    idExpedient: number
    source: string
    idStage: string
  }) => NextEventsWidgetData

  $refs!: {
    widget: any
  }

  checkActionIconHelper = checkActionIcon

  text = {
    noEvents: this.$t('components.widgets.expedient_next_events.no_events'),
    explanation: this.$t('components.widgets.expedient_next_events.explanation'),
    date: this.$t('components.widgets.expedient_next_events.date'),
    hour: this.$t('components.widgets.expedient_next_events.hour'),
    duration: this.$t('components.widgets.expedient_next_events.duration')
  }

  contexts = {
    expedientSchedule: ContextName.EXPEDIENT_SCHEDULE_WIDGET,
    expedientContext: ContextName.EXPEDIENTS
  }

  icons = {
    actionCall: Icons.CALL,
    actionMeeting: Icons.MEETING,
    actionOther: Icons.OTHERS_F,
    actionProcedure: Icons.PROCEDURES,
    addButton: Icons.ADD,
    archiveEmpty: Icons.ARCHIVE_EMPTY,
    internalManagement: Icons.INTERNAL_MANAGEMENT,
    task: Icons.TASK
  }

  showSpinner = true

  get contextMenuItems() {
    return [
      ...(this.checkEntityPermissionsGetter(entity.actions.calls.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.calls'),
              iconCss: this.icons.actionCall,
              actionType: ActionTypes.CALLS
            }
          ]
        : []),
      ...(this.checkEntityPermissionsGetter(entity.actions.meetings.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.meetings'),
              iconCss: this.icons.actionMeeting,
              actionType: ActionTypes.MEETINGS
            }
          ]
        : []),
      ...(this.checkEntityPermissionsGetter(entity.actions.procedures.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.procedures'),
              iconCss: this.icons.actionProcedure,
              actionType: ActionTypes.PROCEDURES
            }
          ]
        : []),
      ...(this.checkEntityPermissionsGetter(entity.actions.task.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.task'),
              iconCss: this.icons.task,
              actionType: ActionTypes.TASK
            }
          ]
        : []),
      ...(this.checkEntityPermissionsGetter(entity.actions.internalManagement.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.internal_management'),
              iconCss: this.icons.internalManagement,
              actionType: ActionTypes.INTERNAL_MANAGEMENT
            }
          ]
        : []),
      ...(this.checkEntityPermissionsGetter(entity.actions.others.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.others_f'),
              iconCss: this.icons.actionOther,
              actionType: ActionTypes.OTHERS
            }
          ]
        : [])
    ]
  }

  get selectedExpedientId(): number {
    return this.selectedRegisterIdGetter(this.contexts.expedientContext)
  }

  get allDataLoaded(): boolean {
    return !!(
      this.expedientGlobalVisionAppointmentsGetter && Object.keys(this.expedientGlobalVisionAppointmentsGetter).length
    )
  }

  @Watch('expedientStageStateGetter')
  updateData() {
    this.showSpinner = true
    this.fetchEvents()
  }

  created() {
    setTimeout(() => {
      this.fetchEvents()
    })
  }

  beforeDestroy() {
    this.resetWidgetMutation()
  }

  async fetchEvents() {
    await this.fetchExpedientGlobalVisionAppointmentsAction({
      idExpedient: this.selectedExpedientId as unknown as number,
      source: this.contexts.expedientSchedule,
      idStage: this.expedientStageStateGetter
    })
    this.showSpinner = false
  }

  onContextMenuItemClicked(actionType: string): void {
    this.$router.push({
      name: `${URLS.EXPEDIENTS}-${URLS.EXPEDIENTS}`,
      params: { selectedRegisterId: this.selectedRegisterId(this.contexts.expedientContext) },
      query: {
        tab: TabNames.ACTIONS,
        action: actionType
      }
    })
  }

  goToEvent(event: any): void {
    const routeData = this.$router.resolve({ name: `${URLS.AGENDA}`, query: { id: event.id } })
    window.open(routeData.href, '_blank')
  }
}
</script>

<style lang="scss" scoped>
@import '~@/components/Navigation/AppBarToolbarItems/contextMenus/styles/contextmenu.scss';

.navigation-context-menu {
  --menu-with: 200px;
}

.next-events-widget {
  min-height: 207px;
  border-radius: $cornerRadius-md;
  border: 1px solid $main-300;

  .header-bar {
    @include flex($justify-content: space-between);
    width: 100%;

    .add-button {
      @include borders;
      display: flex;
      padding: $spacing-xxs;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: $cornerRadius-sm;
    }
  }

  .events-container {
    @include scroll-styles;
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    padding-bottom: $spacing-xs;

    .event {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      border-radius: $cornerRadius-sm;
      border: 1px solid $neutral-grey-200;
      border-left: 2px solid $tertiary-marianBlue-100;
      width: 257px;
      min-width: 257px;
      padding: $spacing-xs;
      gap: $spacing-xs;
      cursor: pointer;

      .event-icon {
        display: flex;
        align-items: flex-start;
        color: $main-1000;
      }

      .event-info-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: $spacing-xs;
        flex: 1 0 0;

        .event-subject {
          @include milano-bold-14;
          color: $main-1000;
        }

        .event-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          align-self: stretch;

          .data {
            @include milano-regular-14;
            color: $neutral-grey-800;

            .label {
              @include milano-bold-14;
              margin-right: $spacing-xs;
            }
          }
        }
      }
    }
  }

  .no-events-container {
    display: flex;
    padding: $spacing-lg $spacing-xl;
    justify-content: flex-start;
    align-items: center;
    gap: $spacing-xs;
    align-self: stretch;
    border-radius: $cornerRadius-sm;

    .wrapper {
      @include milano-medium-16;
      display: flex;
      align-items: center;
      color: $neutral-grey-600;

      .explanation-icon {
        align-self: flex-start;
        margin-right: $spacing-xs;
        margin-top: $spacing-xxs;
        color: $main-400;
      }

      .explanation {
        @include milano-regular-14;
        margin: 0;
      }
    }
  }
}
</style>
