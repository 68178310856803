import { render, staticRenderFns } from "./LfTitleComponent.vue?vue&type=template&id=7a1fa97a&scoped=true"
import script from "./LfTitleComponent.vue?vue&type=script&setup=true&lang=ts"
export * from "./LfTitleComponent.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./LfTitleComponent.vue?vue&type=style&index=0&id=7a1fa97a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a1fa97a",
  null
  
)

export default component.exports