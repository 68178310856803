<template lang="pug">

PrintOrSaveInvoicesStepComponent(
  :stepNumber="stepNumber"
  class="invoices-progress-visualizer-container"
)
  ul(class="visualizer-list")
    LfProgressBarComponent(v-for="(file, index) in invoices" :key="index" :file="file" keyName="numberInvoice")

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import PrintOrSaveInvoicesStepComponent from '@/components/billing/PrintOrSaveInvoicesDialog/PrintOrSaveInvoicesStepComponent.vue'
import { PrintOrSaveInvoicesSteps } from './types/PrintOrSaveInvoicesDialogTypes'
import LfProgressBarComponent from '@/components/ProgressBar/LfProgressBarComponent.vue'

@Component({
  components: {
    PrintOrSaveInvoicesStepComponent,
    LfProgressBarComponent
  }
})
export default class InvoicesProgressVisualizer extends Vue {
  @Prop({
    required: true
  })
  invoices: []

  stepNumber = PrintOrSaveInvoicesSteps.GENERATION_PROGRESS
}
</script>

<style lang="scss" scoped>
.invoices-progress-visualizer-container {
  --invoices-fieldset-height: 100%;
  --invoices-fieldset-padding-right: 20px;
  --spinner-layer-width: 20px;
  --spinner-image-font-size: 10px;

  .visualizer-list {
    @include list;
  }
}
</style>
