<template>
  <div class="alerts-messages-container">

    <SpinnerLayerComponent v-if="ListStatus.LOADING === status" class="list-spinner"/>

    <template v-if="ListStatus.OK === status">
      <div class="selector-container">
        <div class="select-container">
          <v-spacer/>
          <LexonSelectComponent
            class="lf-select"
            v-model="selectedOrderItem"
            keyName="text"
            keyValue="value"
            :propItems="orderItems"
            :clearable="false"
            :showSearch="false"
            @input="(e) => onOrderChange(e.value)"
          />
        </div>
      </div>
      <AlertsMessagesListComponent :alerts="filteredList.data"/>
      <div class="paginator-container">
        <ServerPaginationComponent
          :currentPage="numPage"
          :totalRegisters="filteredList.pagination.totalRecs"
          @change-page="onPageChanged"
        />
      </div>
    </template>

    <section v-if="ListStatus.EMPTY === status" class="no-data-container">
      <span :class="[noDataIcon, 'lf-icon__xl', 'no-data-icon']"/>
      <div class="texts">
        <p class="no-data-text">{{ texts.noData }}</p>
        <i18n :path="path" tag="div" class="link-text">
          <template v-slot:link>
            <router-link :to="alertConfigPanelURL" class="actions-link">{{ texts.link }}</router-link>
          </template>
        </i18n>
      </div>
    </section>

    <section v-if="ListStatus.ERROR === status" class="no-data-container">
      <span :class="[errorIcon, 'lf-icon__xl', 'no-data-icon']"/>
      <div class="texts">
        <p class="no-data-text">{{ texts.loadError }}</p>
      </div>
    </section>

  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n-composable'
import { Icons } from '@/icons/icons'
import useActionsFilteredAlertsMessages from '@/components/AlertsMessages/composables/useActionsFilteredAlertsMessages'
import ServerPaginationComponent from '@/components/Pagination/ServerPaginationComponent.vue'
import { onMounted, ref } from 'vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { AlertMessageOrderEnum, ListStatus } from '@/store/modules/alertsMessages/alertsMessagesTypes'
import { getObjectByKeyAndValue } from '@/helpers/object'
import LexonSelectComponent from '@/components/forms/fields/LexonSelect/LexonSelectComponent.vue'
import AlertsMessagesListComponent from './AlertsMessagesListComponent.vue'

// COMPOSABLE
const { filteredList, status, loadFilteredList, setFilter, selectedOrder } = useActionsFilteredAlertsMessages()

// TRANSLATIONS
const { t } = useI18n()

const texts = {
  alerts: t('navigation.alerts_menu.alerts'),
  loadError: t('components.alerts_messages.load_error'),
  viewMoreAlerts: t('navigation.alerts_menu.more_alerts'),
  link: t('navigation.alerts_menu.no_data.link'),
  noData: t('navigation.alerts_menu.no_data.text'),
  expirationOrder: t('components.alerts_messages.expiration_order'),
  chronologicalOrder: t('components.alerts_messages.chronological_order'),
  changeVisibilityError: t('components.alerts_messages.change_visibility_error')
}

// DATA
const noDataIcon = Icons.ARCHIVE_EMPTY
const errorIcon = Icons.CLOSE_ROUND
const alertConfigPanelURL = `/panel-configuracion/alertas`
const path = 'navigation.alerts_menu.no_data.link_text'
const orderItems = [
  { value: AlertMessageOrderEnum.CRONO, text: texts.chronologicalOrder },
  { value: AlertMessageOrderEnum.EXPIR, text: texts.expirationOrder }
]
const numPage = ref<number>(1)
const showListSpinner = ref<boolean>(false)
const selectedOrderItem = ref<any>(getObjectByKeyAndValue(orderItems, 'value', selectedOrder.value))

// METHODS
async function onPageChanged(direction: number) {
  showListSpinner.value = true
  if (direction === 1) {
    numPage.value += 1
  } else {
    numPage.value -= 1
  }
  await setFilter({ page: numPage.value })
  await init()
  showListSpinner.value = false
}

async function onOrderChange(order: AlertMessageOrderEnum) {
  showListSpinner.value = true
  await setFilter({ order })
  await init()
  showListSpinner.value = false
}

async function init() {
    await loadFilteredList()
}

// HOOKS
onMounted(async () => {
  await init()
})
</script>

<style scoped lang="scss">
.alerts-messages-container {
  .select-container {
    display: flex;
    padding: 5px $spacing-xs 5px $cornerRadius-md;
    background: $neutral-grey-050;

    .lf-select {
      width: 280px;

      ::v-deep .messages-container {
        display: none;
      }
    }
  }

  .list-spinner {
    height: 395px;
  }

  .no-data-container {
    display: flex;
    padding: $spacing-lg $spacing-xl;
    justify-content: center;
    align-items: center;
    gap: $spacing-xs;
    height: 397px;

    .no-data-icon {
      color: $main-400;
    }

    .texts {
      .no-data-text {
        @include milano-medium-16;
        color: $neutral-grey-600;
        margin-bottom: 0;
      }

      .link-text {
        @include milano-regular-14;
        color: $neutral-grey-600;

        .actions-link {
          color: $main-800;
          text-decoration-line: underline;
        }
      }
    }
  }

  .paginator-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 10px;
    position: relative;

    ::v-deep .pagination-component {
      display: flex;

      .pagination-item {
        button {
          width: auto;

          &.icon {
            font-size: 10px;
            width: auto;
            min-width: 24px;
          }
        }
      }
    }
  }
}
</style>
