<template lang="pug">

v-navigation-drawer(
  app
  clipped
  width="250"
  :class="['navigation-drawer', 'configuration-panel', { 'is-portal': isPortalUser  }]"
  permanent
  :temporary="$vuetify.breakpoint.mdAndUp ? false : true"
)

  v-list(dense class="pa-0" )

    //- Configuration panel
    button(class="configuration-panel-button" @click="redirectConfigurationPanel")
      span(:class="iconLeft")
      span(class="text") {{ $t('navigation.drawer-menu-items.config_panel') }}

    template(v-for="(item, index) in configurationMenu")

      //- Item with children
      v-list-group(
        v-if="item.children.length"
        :key="`${item.text}-${index}`"
        class="submenu-list"
        :value="isPortalUser ? true : checkIfOpened(item.text)"
        :append-icon="iconRight"
        :ripple="false"
        )

        template(v-slot:activator)
          v-list-item(class="header-submenu-text-item" :ripple="false")
            v-list-item-content
              v-list-item-title(class="list-item-title px-0") {{ item.text.toUpperCase() }}

        template(v-for="(child, index) in item.children")

          //- Child without subchilds
          v-list-item(
              class="submenu-item"
              :to="`/${child.url}`"
              :target="child.target"
              :ripple="false"
            )
            v-list-item-content
              v-list-item-title(class="list-item-title") {{ child.text.toUpperCase() }}

      //- Item without children
      v-list-item(
          v-else
          :key="`${item.text}-${index}`"
          class="menu-item"
          :to="`/${item.url}`"
          :target="item.target"
          :ripple="false"
          active-class="highlighted"
        )

        template
          v-list-item-content
            v-list-item-title(
                class="list-item-title"
              ) {{ item.text.toUpperCase() }}

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { SiteMenuItem } from '@/store/modules/menus/menusTypes'
import { URLS } from '@/router/routes/urlRoutes'

const menusModule = ModuleNamespaces.MENUS
const authModule = ModuleNamespaces.AUTH

@Component
export default class ConfigurationDrawerComponent extends Vue {
  @Getter('getConfigurationMenu', { namespace: menusModule }) configurationMenu: SiteMenuItem[]
  @Getter('getIsPortalUser', { namespace: authModule }) isPortalUser: boolean
  @Getter('getIsLexonDmsUser', { namespace: authModule }) isDMSUser: boolean

  iconRight = Icons.ANGLE_RIGHT

  iconLeft = Icons.ANGLE_LEFT

  get configurationUrl() {
    return this.isDMSUser ? URLS.REPOSITORY : URLS.DASHBOARD
  }

  checkIfOpened(itemText: string): boolean {
    const itemWithoutAccents = itemText.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    return itemWithoutAccents.toLowerCase() === this.$route.meta?.parentUrl
  }

  redirectConfigurationPanel() {
    this.$router.push({ name: this.configurationUrl })
  }
}
</script>

<style lang="scss" scoped>
@import '~@/components/Navigation/Drawer/styles/drawerStyles';
</style>
