import { GetterTree } from 'vuex'
import { RootState } from '@/store/types/storeGlobalTypes'
import {
  AlertsConfiguration,
  AlertsConfigurationState
} from '@/store/modules/alertsConfiguration/alertsConfigurationTypes'

export const getters: GetterTree<AlertsConfigurationState, RootState> = {
  getAlertsConfiguration(state: AlertsConfigurationState): AlertsConfiguration {
    return state.configuration
  },
}
