import { i18n } from '@/plugins/vue-i18n'

export const allowedFirmaExtensions = ['.pdf']
export interface FirmaSignInfo {
  selectedFileName?: string
  selectedFileId: number
  selectedRequestType?: FirmaRequestTypes
}
export interface FirmaState {
  auditsStatusInfo: any
  firmaToken: string | null
  monitoredAudits: FirmaAudit[] | null
  firmaSignInfo: FirmaSignInfo | null
}

export enum FirmaRequestTypes {
  FIRMA_REQUEST = 1,
  CERTIFIED_EMAIL = 3,
  CERTIFIED_SMS = 2,
  CERTIFIED_DOCUMENT = 4
}

export enum FirmaAuditDetailEndpoint {
  FIRMA_AUDIT_DETAIL = 'signatures',
  EMAIL_AUDIT_DETAIL = 'emails',
  SMS_AUDIT_DETAIL = 'sms'
}

export enum FirmaRequestStatus {
  SENT_TO_FIRMA = 0,
  PENDING_SIGNATURE = 1,
  SIGNED = 2,
  SIGNED_WITH_AUDIT = 3,
  CANCEL = 4,
  EMAIL_SENDED = 5,
  EMAIL_CERTIFIED = 6,
  SMS_SENDED = 7,
  SMS_CERTIFIED = 8,
  DOCUMENT_CERTIFIED = 9
}

export function FirmaStatusText(status: FirmaRequestStatus) {
  switch (status) {
    case FirmaRequestStatus.SENT_TO_FIRMA:
      return i18n.t('components.firma_status.send_to_firma')
    case FirmaRequestStatus.PENDING_SIGNATURE:
      return i18n.t('components.firma_status.pending_signature')
    case FirmaRequestStatus.SIGNED:
      return i18n.t('components.firma_status.signed')
    case FirmaRequestStatus.SIGNED_WITH_AUDIT:
      return i18n.t('components.firma_status.signed_with_audit')
    case FirmaRequestStatus.CANCEL:
      return i18n.t('components.firma_status.cancel')
    default:
      return ''
  }
}

export interface Recipient {
  recipient: string
  idDocumentAudit: number
}

export interface FirmaAudit {
  id: number
  guid: string
  idType: number
  idStatus: number
  dateEPOCH: number
  recipients: Recipient[]
  idDocumentSigned: number
  idDocumentOriginal: number
  initialFile: string
}

interface MapType {
  [index: string]: string
}

export interface ParsedFirmaAudit extends FirmaAudit {
  documents: MapType
}

export const firmaMutationTypes = {
  RESET_FIRMA_STATE: 'RESET_FIRMA_STATE',
  SET_FIRMA_TOKEN: 'SET_FIRMA_TOKEN',
  SET_MONITORED_AUDITS: 'SET_MONITORED_AUDITS',
  SET_ONE_AUDIT_INFO: 'SET_ONE_AUDIT_INFO',
  SET_SIGN_INFO: 'SET_SIGN_INFO'
}
