<template lang="pug">

PrintOrSaveInvoicesStepComponent(
  :stepNumber="stepNumber"
  :title="texts.title"
  class="invoice-configuration-container"
)

  div(class="row-1" v-if="canSave")
    LexonSwitchComponent(
      v-model="actions.save"
      class="switch-field"
      id="save"
      :label="texts.saveLabel"
      :disabled="disableConfiguration"
      hide-details
      @input="configButtons"
    )
    p(class="info-text") {{ texts.saveInfo }}

  div(class="row-2")
    LexonSwitchComponent(
      v-model="actions.print"
      class="switch-field"
      id="print"
      :label="texts.printLabel"
      :disabled="disableConfiguration"
      hide-details
      @input="configButtons"
    )
    p(class="info-text") {{ texts.printInfo }}

</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import LexonSwitchComponent from '@/components/forms/fields/LexonSwitch/LexonSwitchComponent.vue'
import PrintOrSaveInvoicesStepComponent from '@/components/billing/PrintOrSaveInvoicesDialog/PrintOrSaveInvoicesStepComponent.vue'
import { Icons } from '@/icons/icons'
import { PrintOrSaveInvoicesSteps } from './types/PrintOrSaveInvoicesDialogTypes'
import LexonTooltipComponent from '@/components/forms/fields/LexonTooltip/LexonTooltipComponent.vue'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { PermissionVerbs } from '@/store/modules/auth/authTypes'

const authModule: string = ModuleNamespaces.AUTH

@Component({
  components: {
    LexonSwitchComponent,
    LexonTooltipComponent,
    PrintOrSaveInvoicesStepComponent
  }
})
export default class InvoiceConfigurationComponent extends Vue {
  @Getter('getPermission', { namespace: authModule })
  checkEntityPermissionsGetter: (entityId: number) => PermissionVerbs

  @Prop({
    type: Number,
    required: true
  })
  selectedInvoicesLength!: number

  @Prop({
    type: Boolean,
    required: true,
    default: true
  })
  disableConfiguration!: boolean

  @Prop({
    type: [Number, String],
    required: true
  })
  idEntityType!: number

  printInvoices = false

  stepNumber = PrintOrSaveInvoicesSteps.CONFIGURE_OPTIONS

  actions = {
    print: true,
    save: false
  }

  texts = {
    printInfo: this.$t('components.invoice_configuration.print_switch_info'),
    printLabel: this.$t('components.invoice_configuration.print'),
    saveInfo: this.$t('components.invoice_configuration.save_switch_info'),
    saveLabel: this.$t('components.invoice_configuration.save'),
    title: this.$t('components.invoice_configuration.title')
  }

  icons = {
    bill: Icons.BILL,
    check: Icons.CHECK_ROUND_FULL
  }

  tooltipText = this.$t('components.tooltip.selected_files')

  get canSave() {
    const { canSave } = this.checkEntityPermissionsGetter(this.idEntityType)
    return canSave
  }

  @Emit('configButtons')
  configButtons() {
    return this.actions
  }
}
</script>

<style lang="scss" scoped>
::v-deep .tooltip-icon {
  top: -1.5px;
  margin-left: 0;
}

.invoice-configuration-container {
  padding: 0 20px;

  .info-text {
    font-family: $secondary-font;
    font-size: 13px;
    color: $blue-03;
    margin-bottom: 0;
  }

  .row-1,
  .row-2 {
    margin-top: 10px;

    .switch-field {
      ::v-deep .lf-switch-container {
        height: 40px;
      }
    }
  }
}
</style>
