<template lang="pug">

  div(class="firma-dialog-container")

    //- HEADER
    CustomDialogHeaderComponent(:headerTitle="texts.title")

    p(class="explanation") {{ texts.explanation }}

    //- BUTTONS
    CustomDialogActionButtonsComponent(
      :disabledButtons="false"
      :mainAction="signFirma"
      :primaryButtonText="texts.primaryButtonText"
    )

    //- SPINNER
    SpinnerLayerComponent(v-if="loadingSpinner" class="spinner-layer" :loadingText="spinnerText.sending")

</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import FirmaDialogsMixin from '@/mixins/firma/FirmaDialogsMixin.vue'

@Component({
  components: {
    CustomDialogActionButtonsComponent: () =>
      import('@/components/Dialog/CustomDialog/CustomDialogActionButtonsComponent.vue'),
    CustomDialogHeaderComponent: () => import('@/components/Dialog/CustomDialog/CustomDialogHeaderComponent.vue'),
    SpinnerLayerComponent: () => import('@/components/Spinner/SpinnerLayerComponent.vue')
  }
})
export default class CertifyDocumentFirmaDialogComponent extends Mixins(FirmaDialogsMixin) {
  texts = {
    explanation: this.$t('components.firma_dialogs.certify_document.explanation'),
    primaryButtonText: this.$t('action_buttons.certify'),
    title: this.$t('components.firma_dialogs.certify_document.title')
  }
  showInfoBar = false
}
</script>

<style lang="scss" scoped>
.firma-dialog-container {
  position: relative;

  .explanation {
    @include milano-regular-14;
    margin: 20px 10px;
  }

  .switch-field {
    margin-bottom: 200px;

    ::v-deep .v-input--selection-controls {
      margin-top: 8px;
    }

    &.error-type {
      ::v-deep .v-label,
      ::v-deep .lf-icon-switch-right {
        color: $error-color !important;
      }
    }
  }

  .error-message {
    position: absolute;
    bottom: 40px;
    margin-bottom: 14px;
  }

  .custom-dialog-action-buttons-container {
    margin-top: 40px;
  }

  ::v-deep .lf-select-container.show-all-chips .v-select__slot {
    padding: 2px 10px 2px 4px;
  }

  .spinner-layer {
    --spinner-layer-min-height: 90%;
    position: absolute;
    top: 41px;
    left: 0;
    right: 0;
    background-color: $white-01;
  }
}
</style>
