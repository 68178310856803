import { Icons } from '@/icons/icons'
import { URLS } from '@/router/routes/urlRoutes'
import { router } from '@/router/router'
import {
  ActionEntityId,
  ContactEntityId,
  ExpedientEntityId,
  InvoiceEntityId,
  MaintenanceEntityId,
  agendaEntityId,
  appointmentsEntityId,
  documentEntityId,
  driveEntityId,
  signatureEntityId,
  generalEntityId,
  reportsEntityId,
  templateEntityId
} from '@/general/entityIds'
import { normalizeText } from '@/helpers/helpers'

export const entity = {
  actions: {
    type: ActionEntityId.ALL,
    alias: 'actions',
    icon: Icons.ACTIONS,
    route: {
      url: URLS.ACTIONS
    },
    calls: {
      type: ActionEntityId.CALL,
      alias: 'calls',
      icon: Icons.CALL,
      route: {
        url: URLS.ACTION_CALLS,
        hasId: true
      }
    },
    meetings: {
      type: ActionEntityId.MEETING,
      alias: 'meetings',
      icon: Icons.MEETING,
      route: {
        url: URLS.ACTION_MEETINGS,
        hasId: true
      }
    },
    procedures: {
      type: ActionEntityId.PROCEDURE,
      alias: 'procedures',
      icon: Icons.PROCEDURES,
      route: {
        url: URLS.ACTION_PROCEDURES,
        hasId: true
      }
    },
    emails: {
      type: ActionEntityId.EMAIL,
      alias: 'emails',
      icon: Icons.EMAIL,
      route: {
        url: URLS.ACTION_EMAILS,
        hasId: true
      }
    },
    lexnet: {
      type: ActionEntityId.LEXNET,
      alias: 'lexnet',
      icon: Icons.LEXNET,
      route: {
        url: URLS.ACTION_LEXNET,
        hasId: true
      }
    },
    others: {
      type: ActionEntityId.OTHER,
      alias: 'actions_others',
      icon: Icons.ACTIONS,
      route: {
        url: URLS.ACTION_OTHERS,
        hasId: true
      }
    },
    task: {
      type: ActionEntityId.TASK,
      alias: 'task',
      icon: Icons.TASK,
      route: {
        url: URLS.ACTION_TASKS,
        hasId: true
      }
    },
    internalManagement: {
      type: ActionEntityId.GEST,
      alias: 'internal-management',
      icon: Icons.INTERNAL_MANAGEMENT,
      route: {
        url: URLS.ACTION_INTERNAL_MANAGEMENT,
        hasId: true
      }
    }
  },
  contacts: {
    type: ContactEntityId.CONTACTS,
    alias: 'contacts',
    icon: Icons.CONTACTS,
    route: {
      url: URLS.CONTACTS,
      hasId: true
    },
    all:{
      type: ContactEntityId.CONTACTS,
      alias: 'all',
      icon: Icons.CONTACTS,
      route: {
        url: URLS.CONTACTS_ALL,
        hasId: true
      },
    }
  },
  documents: {
    type: documentEntityId,
    alias: 'documents',
    route: {
      url: URLS.REPOSITORY,
      hasId: true
    }
  },
  expedients: {
    type: ExpedientEntityId.EXPEDIENTS,
    alias: 'expedients',
    icon: Icons.EXPEDIENT,
    route: {
      url: URLS.EXPEDIENT,
      hasId: true
    },
    interveners: {
      type: ExpedientEntityId.INTERVENERS,
      alias: 'interveners'
    },
    courts: {
      type: ExpedientEntityId.COURTS,
      alias: 'courts'
    }
  },
  invoices: {
    type: InvoiceEntityId.INVOICES,
    alias: 'invoices',
    icon: Icons.BILLING,
    route: {
      url: URLS.BILLING,
      hasId: true
    },
    provisions: {
      type: InvoiceEntityId.PROVISIONS,
      alias: 'provisions',
      icon: Icons.BILLING,
      route: {
        url: URLS.PROVISIONS,
        hasId: true,
        customKeyName: 'description',
        customKeyId: 'id'
      }
    },
    proforma: {
      type: InvoiceEntityId.PROFORMA_INVOICES,
      alias: 'proforma',
      icon: Icons.BILLING,
      route: {
        customKeyName: 'description',
        url: URLS.PROFORMA_INVOICES,
        hasId: true,
        customKeyId: 'id'
      }
    },
    customers: {
      type: InvoiceEntityId.CUSTOMER_INVOICES,
      alias: 'customers',
      icon: Icons.BILLING,
      route: {
        url: URLS.CUSTOMER_INVOICES,
        hasId: true
      }
    },
    customerBankdraft: {
      type: InvoiceEntityId.CUSTOMER_BAKDRAFTS,
      alias: 'customerBankdrafts',
      icon: Icons.BILLING,
      route: {
        url: URLS.CUSTOMER_BAKDRAFTS,
        hasId: true
      }
    },
    orderForms: {
      type: InvoiceEntityId.ORDER_FORMS,
      alias: 'orderForms',
      icon: Icons.BILLING,
      route: {
        url: URLS.ORDER_FORMS,
        hasId: true,
        customKeyName: 'description',
        customKeyId: 'id'
      }
    },
    providers: {
      type: InvoiceEntityId.PROVIDER_INVOICES,
      alias: 'providers',
      icon: Icons.BILLING,
      route: {
        url: URLS.PROVIDER_INVOICES,
        hasId: true
      }
    },
    providerBankdraft: {
      type: InvoiceEntityId.PROVIDER_BANKDRAFTS,
      alias: 'providerBankdrafts',
      icon: Icons.BILLING,
      route: {
        url: URLS.PROVIDER_BANKDRAFTS,
        hasId: true
      }
    }
  },
  maintenance: {
    type: MaintenanceEntityId.MAINTENANCE,
    alias: 'configurationPanel',
  },
  configurationPanel: {
    type: MaintenanceEntityId.MAINTENANCE,
    alias: 'configurationPanel',
  },
  professionals: {
    type: MaintenanceEntityId.PROFESSIONALS,
    alias: 'professionals'
  },
  users: {
    type: MaintenanceEntityId.USERS,
    alias: 'users'
  },
  general: {
    type: generalEntityId,
    alias: 'general',
    route: {
      url: URLS.REPOSITORY
    }
  },
  firma: {
    type: signatureEntityId,
    alias: 'firma',
    route: {
      url: URLS.REPOSITORY
    }
  },
  template: {
    type: templateEntityId,
    alias: 'template',
    route: {
      url: normalizeText(`${URLS.MAINTENANCE}/${URLS.MAINTENANCE_DOCUMENTS}`)
    }
  },
  report: {
    type: reportsEntityId,
    alias: 'report',
    route: {
      url: URLS.REPORTS,
      hasId: true
    }
  },
  drive: {
    type: driveEntityId,
    alias: 'drive',
    route: {
      url: URLS.REPOSITORY
    }
  },
  appointments: {
    type: appointmentsEntityId,
    alias: 'appointments',
    route: {
      url: URLS.AGENDA,
      hasId: true
    }
  },
  agenda: {
    type: agendaEntityId,
    alias: 'agenda'
  }
}

export interface EntityRoute {
  url: string
  hasId?: boolean
}
export class Entity {
  id: number
  alias: string
  type: number
  parent: {
    alias: string
    type: number
    icon: string
    route: EntityRoute
  }
  keyName: string
  keyId: string
  icon: string
  route: EntityRoute

  getRoute(params: any = {}): string {
    let route = `/${this.route.url}`

    if (this.parent && this.parent.type !== this.type && this.parent.route.url) {
      route = `/${this.parent.route.url}${route}`
    }

    if (this.route.hasId && this.id) {
      route = `${route}/${this.id}`
    }

    if (Object.keys(params).length !== 0) {
      const queryString: string = Object.keys(params)
        .filter((key) => params[key])
        .map((key) => `${key}=${params[key]}`)
        .join('&')
      route = `${route}?${queryString}`
    }

    return route
  }

  navigate(queryString: string) {
    router.push(this.getRoute(queryString))
  }
}

interface EntityType {
  [context: string]: Entity
}

export interface EntityState {
  entity: EntityType
  idEntityType: number
  selectedEntityName: string
  menuParentCategoryName: string
}

export const entitiesMutationTypes = {
  SAVE_ENTITY: 'SAVE_ENTITY',
  SAVE_ID_ENTITY: 'SAVE_ID_ENTITY',
  SAVE_ID_ENTITY_TYPE: 'SAVE_ID_ENTITY_TYPE',
  SAVE_SELECTED_ENTITY_NAME: 'SAVE_SELECTED_ENTITY_NAME',
  SAVE_MENU_PARENT_CATEGORY_NAME: 'SAVE_MENU_PARENT_CATEGORY_NAME'
}

export const entityKeyFields = {
  contacts: {
    id: 'id',
    name: 'name'
  },
  actions: {
    id: 'actionId',
    name: 'subject'
  },
  documents: {
    id: 'IdDocumento',
    name: 'Nombre'
  },
  expedients: {
    id: 'idExpedient',
    name: 'idOwnFile'
  },
  maintenance: {
    id: 'id',
    name: 'name'
  },
  invoices: {
    id: 'idInvoice',
    name: 'numberInvoice'
  },
  professionals: {
    id: 'id',
    name: 'name'
  },
  users: {
    id: 'id',
    name: 'name'
  }
}
