import useSelectedRegister from "@/composables/useSelectedRegister"
import store from "@/store/store"
import { ContextName, ModuleNamespaces } from "@/store/types/storeGlobalTypes"
import { Ref, computed, ref } from "vue"

interface ResponseUseExpedientCourts {
  courts: Ref<any[]>
  read: () => void
  save: (data: any) => Promise<boolean>
  remove: (id: string) => Promise<boolean>
  open: () => void
}

export default function useExpedientCourts(): ResponseUseExpedientCourts {

  const courts = ref([])
  const expedientStage = computed(() => store.getters[`${ModuleNamespaces.EXPEDIENTS}/getExpedientStageState`])
  const { selectedRegisterId } = useSelectedRegister({entityName: ContextName.EXPEDIENTS})

  const read = async () => {
    const filter = {
      ...(expedientStage.value && { idStage: expedientStage.value })
    }
    await store.dispatch(`${ModuleNamespaces.EXPEDIENTS}/fetchExpedientsCourt`, {selectedRegisterId: selectedRegisterId.value, filter})
    courts.value = await store.getters[`${ModuleNamespaces.LIST_ITEMS}/getListItemsWithFormattedDates`]
  }

  const remove = async (id: string): Promise<boolean> => {
    const response = await store.dispatch(`${ModuleNamespaces.EXPEDIENTS}/deleteExpedientCourt`, id)
    return Boolean(response)
  }

  const save = async (data: any) => {
    const formData = {
      ...data,
      idExpedient: selectedRegisterId.value,
      public: data.public || 0
    }
    const response = await store.dispatch(`${ModuleNamespaces.EXPEDIENTS}/saveExpedientCourt`, formData)
    return Boolean(response.id)
  }

  read()

  return {
    courts,
    read,
    save,
    remove,
    open
  }
}
