<template>
  <div class="lf-counter-container">
    <div class="lf-counter-wrapper" v-for="({ detail, totalAmount }, index) in items" :key="index">
      <div class="lf-counter">
        <span :class="getDetailClass(detail)">{{ detail }}</span>
        <span class="number">{{ totalAmount }}</span>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { PropType } from 'vue'

interface TotalsItemModel {
  alias: string
  detail: string
  totalAmount: string
}

enum UppercaseEnum {
  IRPF = 'IRPF'
}

// DEFINE PROPS  ======================================================================================================
defineProps({
  items: {
    type: Array as PropType<TotalsItemModel[]>,
    default: () => []
  }
})

function getDetailClass (detail: string): string {
  return Object.values(UppercaseEnum).includes(detail as UppercaseEnum)
    ? 'uppercaseText' : 'capitalizeText'
}

</script>
<style lang="scss">
$gap-size: 10px;
.lf-counter-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $gap-size;
  margin-bottom: 20px;

  .lf-counter-wrapper {
    width: 100%;

    .lf-counter {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      border-radius: $cornerRadius-md;
      background: $main-050;
      padding: $spacing-md $spacing-sm;

      .uppercaseText {
        @include milano-regular-16;
        text-transform: uppercase;
      }

      .capitalizeText {
        text-transform: capitalize;
        @include milano-regular-16;
        text-transform: lowercase;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      .number {
        @include milano-bold-24;
        text-align: center;
        color: $main-1000;
      }
    }
  }
}

@for $i from 2 through 12 {
  .lf-counter-wrapper {
    &:nth-last-child(#{$i}) {
      width: calc((100% / #{$i}) - #{$gap-size} + #{$gap-size / $i});
    }

    &:nth-last-child(#{$i}) ~ .lf-counter-wrapper {
      width: calc((100% / #{$i}) - #{$gap-size});
    }
  }
}
</style>
