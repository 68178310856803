import useRouter from "@/composables/useRouter"
import { URLS } from "@/router/routes/urlRoutes"
import store from "@/store/store"
import { ModuleNamespaces } from "@/store/types/storeGlobalTypes"
import { Ref, ref } from "vue"

interface ResponseUseContactProvisions {
  provisions: Ref<any[]>,
  read: (selectedRegisterId: string) => Promise<void>
  open: (id: string, target?: '_self' | '_blank') => void
}

interface PropsUseContactProvisions {
  listName: string
}

export default function useContactProvisions(props: PropsUseContactProvisions): ResponseUseContactProvisions {
  const router = useRouter()
  const provisions = ref([])

  const read = async (selectedRegisterId: string) => {
    await store.dispatch(`${ModuleNamespaces.INVOICES}/fetchInvoices`, {
      selectedRegisterId,
      listName: props.listName
    })
    provisions.value = store.getters[`${ModuleNamespaces.LIST_ITEMS}/getListItemsWithFormattedDates`]
  }

  const open = (selectedRegisterId: string, target = '_self') => {
    const urlName = `${URLS.BILLING}-${URLS.PROVISIONS}`
    const routeData = router.resolveRoute({
      name: urlName,
      params: { selectedRegisterId }
    })
    if (target === '_self') {
      router.goToRoute((routeData as any).resolved)
    } else {
      window.open((routeData as any).href, '_blank')
    }
  }

  return {
    provisions,
    read,
    open
  }
}
