import { Module } from 'vuex'
import { getters } from '@/store/modules/menus/menusGetters'
import { actions } from '@/store/modules/menus/menusActions'
import { mutations } from '@/store/modules/menus/menusMutations'
import { MenusState } from '@/store/modules/menus/menusTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: MenusState = {
  clientMenu: [],
  configurationMenu: [],
  drawerInMiniStatus: false,
  siteMenu: [],
  configurationDrawerstatus: false,
  renderNavigation: false
}

const namespaced: boolean = true

export const menusModule: Module<MenusState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
