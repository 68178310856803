import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { computed, ComputedRef } from 'vue'
import { CounterItem, ListStatus } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import VueRouter from 'vue-router'

interface UseCounterWidgetInterface {
  widgetList: ComputedRef<CounterItem[]>,
  status: ComputedRef<ListStatus>,
  loadWidgets: (router: VueRouter) => Promise<void>
}

export default function useCounterWidget(): UseCounterWidgetInterface {
  const widgetList: ComputedRef<CounterItem []> = computed(() => store.getters[`${ ModuleNamespaces.DASHBOARD_WIDGETS }/getCounterWidgetList`])
  const status: ComputedRef<ListStatus> = computed(() => store.getters[`${ ModuleNamespaces.DASHBOARD_WIDGETS }/getCounterWidgetStatus`])
  const loadWidgets = (router: VueRouter) => store.dispatch(`${ ModuleNamespaces.DASHBOARD_WIDGETS }/loadCountersWidget`, {router})
  return {
    widgetList,
    status,
    loadWidgets
  }
}
