<template>
  <div class="sub-action-container">
    <LfGridComponent
      v-if="gridConfiguration"
      :gridConfiguration="gridConfiguration"
      :itemData="subActionList"
      :toolbarOptions="toolbarOptions"
      :commandClick="onCommandClick"
      :allowPaging="true"
      :contextMenuItems="contextMenuItems"
      @rowClick="onRowClick"
      @contextMenuClicked="onContextMenuClicked"
    ></LfGridComponent>

    <PromptDialog
      :width="modalWidth"
      :title="modalTitle"
      :isOpened="modalOpened"
      :context="context"
      :formSchema="subActionFormSchema"
      :formFieldValues="subActionFormValues"
      @execute-action="onPromptDialogExecuteAction"
      @changeFormField="onChangeFormField"
    ></PromptDialog>
  </div>
</template>
<script lang="ts" setup>
import LfGridComponent from '@/components/grids/LfGrid/LfGridComponent.vue'
import { useI18n } from 'vue-i18n-composable'
import { computed, onBeforeMount, ref } from 'vue'
import {
  CommandClickEventArgs,
  ContextMenuClickEventArgs,
  ContextMenuItemModel,
  RecordClickEventArgs
} from '@syncfusion/ej2-vue-grids'
import { Icons } from '@/icons/icons'
import store from '@/store/store'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ConfigurationAlias, ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import PromptDialog from '@/components/Dialog/PromptDialogComponent.vue'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import {
  ActionName as GridActionName,
  commandButtonType,
  CommandModel,
  ItemModel
} from '@/components/grids/LfGrid/LfGridTypes'
import { CustomDialogComponentName, DialogData, DialogTypes } from '@/store/modules/dialog/dialogTypes'
import useGridConfiguration from '@/composables/useGridConfiguration'
import { ActionTypes, getActionRouteNameByAction } from '@/store/modules/actions/actionsTypes'
import { ActionFields } from '@/views/actions/Actions/types/ActionDetailViewTypes'
import useFeedback from '@/composables/useFeedback'
import moment from 'moment/moment'
import usePermissions from '@/composables/usePermissions'
import useRouter from '@/composables/useRouter'
import { SubAction, SUBACTION_ERRORS } from '@/store/modules/sub-actions/subActionsTypes'
import { SubActionsService } from '@/services/SubActionsService'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'
import { AxiosError } from 'axios'

const { t } = useI18n()
const texts = {
  modal: {
    create: t('components.dialog.sub_actions_create_title').toString(),
    edit: t('components.dialog.sub_actions_edit_title').toString()
  },
  contextMenuItems: {
    edit: t('components.context_menu.edit').toString(),
    openWindow: t('components.context_menu.open_window').toString(),
    remove: t('components.context_menu.remove').toString()
  },
  dialog: {
    saveOk: t('components.dialog.sub_actions_create_ok').toString(),
    editOk: t('components.dialog.sub_actions_edit_ok').toString(),
    saveError: t('components.dialog.sub_actions_create_error').toString(),
    responseError: t('components.dialog.sub_actions_relate_response_error').toString(),
    editError: t('components.dialog.sub_actions_edit_error').toString(),
    loadError: t('components.dialog.sub_actions_load_error').toString()
  },
  button: {
    add_sub_action: t('action_buttons.add_sub_action').toString(),
    add: t('action_buttons.add').toString(),
    remove: t('action_buttons.remove').toString(),
    new: t('action_buttons.new_sub_action').toString(),
    bind: t('action_buttons.bind_sub_action').toString(),
    unbind: t('action_buttons.unbind').toString()
  }
}
const flagGetCodeInProcess = ref(false)
const modalWidth: number = 1000
const subActionFormSchema = ref<[]>([])
const subActionFormValues = ref<any>({})
const modalOpened = ref(false)
const modalTitle = ref<string>(texts.modal.create)
const context = ContextName.SUB_ACTION

const props = defineProps({
  parentActionId: {
    type: Number,
    required: true
  }
})

const commandButtons: CommandModel[] = [
  {
    id: GridActionName.UNLINK,
    type: commandButtonType.NONE,
    title: texts.button.unbind,
    buttonOption: {
      iconCss: Icons.UNLINK,
      cssClass: 'custombutton'
    }
  }
]

// COMPOSABLES
const { openRouteInNewWindow } = useRouter()
const { viewPermission } = usePermissions()
const { gridConfiguration } = useGridConfiguration({
  context,
  listName: 'listSubActuaciones',
  commandButtons
})

const { showFeedbackSuccess, showFeedbackError } = useFeedback()

// COMPUTED
const subActionList = computed(() =>
  formatFieldsTypeDateEPOCH(store.getters[`${ModuleNamespaces.SUB_ACTIONS}/getSubActionList`])
)

const authenticatedUserId = computed(() => store.getters[`${ModuleNamespaces.AUTH}/getUserId`])

const toolbarOptions = computed((): ItemModel[] => {
  return [
    {
      id: 'search',
      type: 'Input',
      text: 'Search',
      align: 'Left'
    },
    {
      id: GridActionName.CUSTOM_TOOLBAR_CONTEXT_MENU,
      text: texts.button.add_sub_action,
      align: 'Right',
      tooltipText: texts.button.add_sub_action,
      cssClass: 'lf-btn-model4',
      contextMenuItems: [
        {
          id: ActionName.ADD,
          iconCss: Icons.ADD_ROUND,
          text: texts.button.new
        },
        {
          id: ActionName.BIND,
          iconCss: Icons.LINK,
          text: texts.button.bind
        }
      ]
    }
  ]
})

const contextMenuItems = computed((): ContextMenuItemModel[] => {
  const { canDelete } = viewPermission.value
  return [
    {
      id: ActionName.EDIT,
      text: texts.contextMenuItems.edit,
      iconCss: Icons.EDIT
    },
    {
      id: ActionName.OPEN_WINDOW,
      text: texts.contextMenuItems.openWindow,
      iconCss: Icons.OPEN_WINDOW
    },
    ...(canDelete
      ? [
          {
            id: ActionName.REMOVE,
            text: texts.contextMenuItems.remove,
            iconCss: Icons.REMOVE
          }
        ]
      : [])
  ]
})

const parentAction = computed(() => store.getters[`${ModuleNamespaces.SUB_ACTIONS}/getParentAction`])

async function openModalEditForm(id: string) {
  await fetchsubActionForm()
  await fetchsubActionFormValues(id)
  subActionFormSchema.value.forEach((node: any, index: number) => {
    if (node.id === ActionFields.ACTION_TYPE && node.propItems) {
      const hasTypeId = node.propItems.some((item: any) => item.id === subActionFormValues.value.typeId)
      if (!hasTypeId) {
        subActionFormSchema.value.splice(index, 1)
      }
    }
    if (node.id === ActionFields.CODE) {
      subActionFormSchema.value.splice(index, 1)
    }
  })
  modalTitle.value = texts.modal.edit
  modalOpened.value = true
}

async function openModalCreateForm() {
  await fetchsubActionForm()
  subActionFormSchema.value.forEach((node: any, index: number) => {
    if (node.id === ActionFields.ACTION_TYPE && node.propItems) {
      const hasLEXN = node.propItems.some((item: any) => item.id === ActionTypes.LEXNET)
      if (hasLEXN) {
        subActionFormSchema.value.splice(index, 1)
      }
    }
  })

  resetSubAction()
  subActionFormValues.value.userId = String(authenticatedUserId.value)
  modalTitle.value = texts.modal.create
  modalOpened.value = true
}

function closeModalForm() {
  modalOpened.value = false
  resetSubAction()
}

function openSelectorDialog() {
  store.dispatch(`${ModuleNamespaces.DIALOG}/showCustomDialog`, {
    name: CustomDialogComponentName.SUB_ACTION_SELECTOR,
    styles: {
      maxWidth: '750px'
    },
    props
  })
}
async function fetchParentAction(subAction: SubAction) {
  await store.dispatch(`${ModuleNamespaces.SUB_ACTIONS}/fetchParentAction`, subAction)
}

async function fetchsubActionForm() {
  await store.dispatch(`${ModuleNamespaces.CONFIGURATION}/fetchCurrentViewConfiguration`, {
    objectType: ConfigurationTypes.VIEW,
    alias: ConfigurationAlias.SUB_ACTION_FORM,
    context
  })
  subActionFormSchema.value = store.getters[`${ModuleNamespaces.CONFIGURATION}/getCurrentViewConfiguration`](context)
}

async function fetchsubActionFormValues(subActionId: string) {
  await store.dispatch(`${ModuleNamespaces.SUB_ACTIONS}/fetchSubAction`, subActionId)
  subActionFormValues.value = store.getters[`${ModuleNamespaces.SUB_ACTIONS}/getSubAction`]
}

function resetSubAction() {
  store.dispatch(`${ModuleNamespaces.SUB_ACTIONS}/resetSubAction`)
  Object.keys(subActionFormValues.value).forEach((key: string) => (subActionFormValues.value[key] = null))
}

// EVENTS
async function onChangeFormField(schema: any, formData: any, field: any, value: any) {
  if (ActionFields.ACTION_TYPE === field.id) {
    schema.forEach((node: any) => {
      if (node.id === ActionFields.CATEGORY) {
        const activeCategoryField = ActionTypes.OTHERS === value
        node.disabled = node.hidden = !activeCategoryField
      }
    })
  }

  if (ActionFields.CODE === field.id && !flagGetCodeInProcess.value) {
    const code = formData[ActionFields.CODE]
    if (!code) {
      resetSubAction()
      return
    } else {
      flagGetCodeInProcess.value = true
      const predefinedSubAction: any = await new SubActionsService().getPredefineSubAction(code)
      flagGetCodeInProcess.value = false
      Object.keys(predefinedSubAction[0]).forEach((key: string) => {
        if (key !== ActionFields.CODE) {
          if (key === ActionFields.RELATIONS || key === ActionFields.COLOR) {
            predefinedSubAction[0][key] = JSON.parse(predefinedSubAction[0][key])
          }
          if (key === ActionFields.START) {
            formData[ActionFields.END] = null
          }
          formData[key] = predefinedSubAction[0][key]
        }
      })
    }
  }

  if ((ActionFields.START === field.id || ActionFields.END === field.id) && !value) {
    formData[ActionFields.DURATION] = null
  }

  if ((ActionFields.START === field.id || ActionFields.END === field.id) && value) {
    const start = formData[ActionFields.START]
    const end = formData[ActionFields.END]
    if (end) {
      const dateStart = moment(start, 'YY-MM-DD hh:mm')
      const dateEnd = moment(end, 'YY-MM-DD hh:mm')
      if (dateStart <= dateEnd) {
        formData[ActionFields.DURATION] = dateEnd.diff(dateStart, 'minutes')
      }
    }
  }
}

async function onPromptDialogExecuteAction(actionName: string) {
  if (ActionName.CLOSE === actionName) {
    closeModalForm()
    return
  }
  modalTitle.value === texts.modal.create ? await createSubAction() : await updateSubAction()
  closeModalForm()
}

function onContextMenuClicked({ item, rowInfo }: ContextMenuClickEventArgs) {
  const subAction: SubAction = rowInfo?.rowData as SubAction
  switch (item.id) {
    case ActionName.EDIT:
      openModalEditForm(subAction.id)
      break
    case ActionName.OPEN_WINDOW:
      openSubActionInNewWindow(subAction)
      break
    case ActionName.REMOVE:
      showConfirmDelete(subAction)
      break
    case ActionName.ADD:
      openModalCreateForm()
      break
    case ActionName.BIND:
      openSelectorDialog()
      break
  }
}

function onCommandClick(args: CommandClickEventArgs) {
  const title = args.commandColumn?.title
  if (texts.button.unbind === title) {
    showConfirmUnbind(args.rowData as SubAction)
  }
}

async function onRowClick(args: RecordClickEventArgs) {
  const { id } = args.rowData as any
  try {
    await openModalEditForm(id)
  } catch (error) {
    showDialog({
      type: DialogTypes.ERROR,
      message: texts.dialog.loadError
    })
  }
}

// CRUD
async function createSubAction() {
  try {
    await store.dispatch(`${ModuleNamespaces.SUB_ACTIONS}/createSubAction`, {
      subActionForm: subActionFormValues.value,
      parentActionId: props.parentActionId
    })
    showDialog({
      type: DialogTypes.SUCCESS,
      message: texts.dialog.saveOk
    })
  } catch (error) {
    errorHandler(error)
  }
}

function errorHandler(error: any): void {
  if (
    error instanceof AxiosError &&
    error.response &&
    error.response.data &&
    error.response.data.exceptionDesc &&
    error.response.data.exceptionDesc.includes(SUBACTION_ERRORS.RESPONSE_ERROR)
  ) {
    showDialog({
      type: DialogTypes.ERROR,
      message: texts.dialog.responseError
    })
  } else {
    showDialog({
      type: DialogTypes.ERROR,
      message: texts.dialog.saveError
    })
  }
}

async function updateSubAction() {
  try {
    await store.dispatch(`${ModuleNamespaces.SUB_ACTIONS}/updateSubAction`, {
      subActionForm: subActionFormValues.value
    })
    showDialog({
      type: DialogTypes.SUCCESS,
      message: texts.dialog.editOk
    })
  } catch (error) {
    showDialog({
      type: DialogTypes.ERROR,
      message: texts.dialog.editError
    })
  }
}

async function showConfirmUnbind(subAction: SubAction) {
  if(String((parentAction.value as any)?.id) !== subAction.parentId) {
    await fetchParentAction(subAction)
  }
  const { id, subject } = subAction
  const parentActionSubject = parentAction.value.subject
  showDialog({
    type: DialogTypes.INFO,
    message: t('components.dialog.sub_actions_unrelate_prompt', {
      parentActionSubject,
      subActionSubject: subject
    }).toString(),
    action: () => unrelateSubAction(id, subject)
  })
}

function showConfirmDelete(subAction: SubAction) {
  const subActionSubject = subAction.subject
  showDialog({
    type: DialogTypes.INFO,
    message: t('components.dialog.sub_actions_delete_prompt', { subActionSubject }).toString(),
    action: async () => await deleteSubAction(subAction)
  })
}

async function deleteSubAction(subAction: SubAction) {
  const subActionSubject = subAction.subject
  try {
    await store.dispatch(`${ModuleNamespaces.SUB_ACTIONS}/deleteSubAction`, subAction)
    showFeedbackSuccess({
      message: t('components.dialog.sub_actions_delete_ok', { subActionSubject }).toString()
    })
  } catch (error) {
    showFeedbackError({
      message: t('components.dialog.sub_actions_delete_error', { subActionSubject }).toString()
    })
  }
}

async function unrelateSubAction(subActionId: string, subActionSubject: string) {
  try {
    await store.dispatch(`${ModuleNamespaces.SUB_ACTIONS}/unrelateSubAction`, {
      parentActionId: props.parentActionId,
      subActionId: subActionId
    })
    showFeedbackSuccess({
      message: t('components.dialog.sub_actions_unrelate_ok', { subActionSubject }).toString()
    })
  } catch (error) {
    showFeedbackError({
      message: t('components.dialog.sub_actions_unrelate_error', { subActionSubject }).toString()
    })
  }
}

function showDialog({ type, message, action }: DialogData) {
  store.dispatch(`${ModuleNamespaces.DIALOG}/showDialog`, { type, message, action })
}

function openSubActionInNewWindow(action: SubAction): void {
  openRouteInNewWindow({
    name: getActionRouteNameByAction(action.typeId as Exclude<ActionTypes, ActionTypes.ALL>),
    params: { selectedRegisterId: action.id }
  })
}

// HOOKS
onBeforeMount(async () => {
  await store.dispatch(`${ModuleNamespaces.SUB_ACTIONS}/fetchSubActionList`, {
    parentActionId: props.parentActionId?.toString()
  })
})
</script>
<style>
.sub-action-selector-dialog-container {
  width: 100% !important;
}
</style>
