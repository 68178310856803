interface TabOptionByEntity {
  [idEntity: number]: TabOption[]
}
export interface TabsState {
  disabledTabs: boolean
  currentTabInfo: SearchTab
  currentSearchTabInfo: SearchTab
  tabSelectorOptions: TabOptionByEntity
}
export interface SearchTab {
  name: string
  index: number
}

export enum TabNames {
  ACTIONS = 'actions',
  BILLING = 'invoices',
  CONTACTS = 'contacts',
  DOCUMENTS = 'documents',
  GENERAL_DATA = 'generalData',
  EXPEDIENTS = 'expedients',
  EXPEDIENT_PERIODIC_BILLING = 'periodicBillingTab',
  SUB_ACTION = 'subAction',
  FILES = 'files',
  LEGAL_SEARCH = 'online',
  TANDEM = 'tandem',
  TEMPLATES = 'templates'
}

export const tabsMutationTypes = {
  CHANGE_DISABLED_TABS: 'CHANGE_DISABLED_TABS',
  SET_CURRENT_SEARCH_TAB_INFO: 'SET_CURRENT_SEARCH_TAB_INFO',
  SET_CURRENT_TAB_INFO: 'SET_CURRENT_TAB_INFO',
  FETCH_TAB_SELECTOR_OPTIONS: 'FETCH_TAB_SELECTOR_OPTIONS'
}

export interface TabOption {
  id: number
  alias: string
  label: string
  value: 0 | 1
}
export interface Tab {
  name: string
  items: []
  label: string
  idMenu: number
  numOrder: string
}
