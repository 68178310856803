<template lang="pug">
div
  GridTable(
    v-if="Object.keys(listConfiguration).length && Object.keys(serverSideData).length > 0 && !showSpinnerLayer"
    ref="grid"
    :gridConfiguration="configList"
    :title="$t('views.contacts.title')"
    :toolbarOptions="toolbarOptionsCustom"
    :contextMenuItems="contextMenuItems"
    :listName="listConfiguration['Alias']"
    :context="context"
    :itemsData="serverSideData"
    :itemsDataExport="serverSideExportData"
    :useServerPagination="useServerPagination"
    :commandClick="commandClick"
    @cellClicked="onCellClicked"
    @gridActionChange="gridActionChange"
    @gridExportData="gridExportData"
    @contextMenuClicked="onContextMenuClicked"
    @customToolbarContextMenuClick="onContactAddClick"
  )

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ContactTypes, ContactPersonTypeId, ContactItem } from '@/store/modules/contacts/contactsTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import { ListNames, ListConfiguration } from '@/store/modules/configuration/configurationTypes'
import { Action, Getter } from 'vuex-class'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { CommandClickEventArgs, DataResult } from '@syncfusion/ej2-vue-grids'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'
import { Icons } from '@/icons/icons'
import { ContactEntityId } from '@/general/entityIds'
import useGridSaveUserConf, { RequestParams } from '@/composables/useGridSaveUserConf'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import {
  columnsChooserToolbarItem,
  filterToolbarItem,
  kebakMenuToolbarItem,
  searchToolbarItem
} from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import useContacts from '@/views/contacts/composables/useContacts'
import { gridConfigurationWithActionColumn } from '@/helpers/grid'
import { CommandModel } from '@/components/grids/LfGrid/LfGridTypes'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'

const contactsModule = ModuleNamespaces.CONTACTS
const configurationModule = ModuleNamespaces.CONFIGURATION
const dialogModule = ModuleNamespaces.DIALOG

const { parseUserConfig } = useGridSaveUserConf()
const { openContact } = useContacts()

@Component({
  components: {
    GridTable
  }
})
export default class AllContactsView extends mixins(ListViewMixin) {
  useServerPagination: boolean = true

  serverSideData: DataResult | object = {}
  serverSideExportData: DataResult | object = {}

  @Action('showDialog', { namespace: dialogModule })
  showDialogAction: ({}) => {}

  @Action('deleteContact', { namespace: contactsModule })
  deleteContact: ({ contactType, idContact }: { contactType: string; idContact: string }) => boolean

  icons = {
    natural_person: Icons.USER,
    legal_entity: Icons.COMPANY
  }

  get context() {
    return ContextName.CONTACT_ALL
  }

  get configList() {
    const config = JSON.parse(this.listConfiguration['Config'])
    const { canDelete } = this.viewPermission
    const commandBtns: CommandModel[] = canDelete
      ? [
          {
            title: this.$t('action_buttons.open_new_window').toString(),
            type: 'None',
            buttonOption: {
              iconCss: Icons.OPEN_WINDOW,
              cssClass: 'custombutton'
            }
          },
          {
            title: this.$t('action_buttons.remove').toString(),
            type: 'None',
            buttonOption: {
              iconCss: Icons.REMOVE,
              cssClass: 'custombutton'
            }
          }
        ]
      : [
          {
            title: this.$t('action_buttons.open_new_window').toString(),
            type: 'None',
            buttonOption: {
              iconCss: Icons.OPEN_WINDOW,
              cssClass: 'custombutton'
            }
          }
        ]

    const gridConfiguration = gridConfigurationWithActionColumn(config, commandBtns)
    return JSON.stringify(gridConfiguration)
  }

  get contactsContextMenu() {
    return [
      ...(this.checkEntityPermissionsGetter(entity.contacts.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.natural_person'),
              iconCss: this.icons.natural_person,
              contactPersonType: ContactPersonTypeId.NATURAL,
              entityId: ContactEntityId.CONTACTS
            }
          ]
        : []),
      ...(this.checkEntityPermissionsGetter(entity.actions.meetings.type).canSave
        ? [
            {
              text: this.$t('navigation.drawer-menu-items.legal_entity'),
              iconCss: this.icons.legal_entity,
              contactPersonType: ContactPersonTypeId.LEGAL,
              entityId: ContactEntityId.CONTACTS
            }
          ]
        : [])
    ]
  }

  @Action('fetchContactsFilter', { namespace: contactsModule })
  fetchContactsFilter: ({ contactType, params }: { contactType: string; params: RequestParams }) => []

  @Getter('getPaginateContacts', { namespace: contactsModule })
  contactsDataPaginated: []

  @Getter('getListConfig', { namespace: configurationModule })
  listConfig: ListConfiguration

  async created() {
    await this.fetchCurrentListConfiguration(ListNames.CONTACTS)
    await this.loadData()

    this.saveSelectedEntityName(ContactTypes.CONTACTS_ALL)
  }

  async loadData() {
    try {
      if (this.useServerPagination) {
        const params = await parseUserConfig(this.listConfig)
        await this.fetchContactsFilter({ contactType: ContactTypes.CONTACTS_ALL, params })
        this.serverSideData = this.formatDataPaginated(this.contactsDataPaginated)
      } else {
        await this.fetchContacts(ContactTypes.CONTACTS_ALL)
        this.serverSideData = this.contactsDataPaginated
      }
    } catch (error) {
    } finally {
      this.hideSpinnerLayerAction()
    }
  }

  formatDataPaginated(data: any) {
    const items = formatFieldsTypeDateEPOCH(data.data)
    const gridResult: DataResult = { result: items, count: parseInt(data.pagination.totalRecs.toString(), 10) }
    return gridResult
  }

  async gridActionChange(serverSideParams: string) {
    const params = JSON.parse(serverSideParams)
    await this.fetchContactsFilter({ contactType: ContactTypes.CONTACTS_ALL, params })
    this.serverSideData = this.formatDataPaginated(this.contactsDataPaginated)
  }

  async gridExportData(serverSideParams: string) {
    const params = JSON.parse(serverSideParams)
    await this.fetchContactsFilter({ contactType: ContactTypes.CONTACTS_ALL, params })
    const { result } = this.formatDataPaginated(this.contactsDataPaginated)
    this.serverSideExportData = result
  }

  async onContactAddClick(args: any) {
    trackEvent(TrackerEvents.CREATE_CONTACT)
    await openContact(args.item.contactPersonType)
  }

  async onContextMenuClicked(args: any, selectedRegister: any) {
    if (args.item) {
      if (args.item.text === this.$t('components.context_menu.edit')) {
        args.cancel = true
        trackEvent(TrackerEvents.EDIT_CONTACT)
        await openContact(selectedRegister.personTypeId, selectedRegister.id)
      }
      if (args.item.text === this.$t('components.context_menu.open_window')) {
        args.cancel = true
        trackEvent(TrackerEvents.EDIT_CONTACT)
        await openContact(selectedRegister.personTypeId, selectedRegister.id, '_blank')
      }
    }
  }

  async onCellClicked(selectedRegister: any, cancel: boolean) {
    if (!cancel) {
      cancel = true
      trackEvent(TrackerEvents.EDIT_CONTACT)
      await openContact(selectedRegister.personTypeId, selectedRegister.id)
    }
  }

  get toolbarOptionsCustom() {
    const { canSave } = this.viewPermission
    return [
      searchToolbarItem,
      filterToolbarItem,
      ...(canSave
        ? [
            {
              id: 'customToolbarContextMenu',
              text: this.$t('components.grid_table.tooltip.new_contact'),
              align: 'right',
              tooltipText: this.$t('components.grid_table.tooltip.new_contact'),
              cssClass: 'lf-btn-model2',
              contextMenuItems: this.contactsContextMenu
            }
          ]
        : [{}]),
      columnsChooserToolbarItem,
      kebakMenuToolbarItem
    ]
  }

  commandClick(args: CommandClickEventArgs): void {
    const action = args.commandColumn?.title
    const { id, name, personTypeId } = args.rowData as ContactItem
    switch (action) {
      case this.$t('action_buttons.remove'):
        this.deleteItem(id, name)
        break
      case this.$t('action_buttons.open_new_window'):
        trackEvent(TrackerEvents.EDIT_CONTACT)
        openContact(personTypeId, id, '_blank')
        break
      default:
        break
    }
  }

  deleteItem(id: string, name: string) {
    this.showDialogAction({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_register_text', {
        register: name,
        text: this.$t('components.dialog.contacts_literal')
      }),
      action: () => {
        this.confirmDelete(id, name)
      },
      mainButtonText: this.$t('action_buttons.remove'),
      secondaryButtonText: this.$t('action_buttons.cancel')
    })
  }
  async confirmDelete(idContact: string, name: string) {
    const data = await this.deleteContact({ contactType: ContactTypes.CONTACTS_ALL, idContact })
    if (!data) {
      return
    }
    this.showDialogAction({
      type: DialogTypes.SUCCESS,
      message: this.$t('components.dialog.success_removed_register', {
        register: name,
        text: this.$t('components.dialog.contacts_literal')
      })
    })
    ;(this as any).$refs.grid.refresh()
  }
}
</script>
