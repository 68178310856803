import useRouter from "@/composables/useRouter"
import { Action, ActionTypes, getActionRouteNameByAction } from "@/store/modules/actions/actionsTypes"
import store from "@/store/store"
import { ModuleNamespaces } from "@/store/types/storeGlobalTypes"
import { Ref, ref } from "vue"

interface ResponseUseContactActions {
  actions: Ref<Action[]>,
  read: (selectedRegisterId: string) => Promise<void>
  open: ({ actionId, typeId }: { actionId: string, typeId: Exclude<ActionTypes, ActionTypes.ALL> }, target?: string) => void
}

interface PropsUseContactActions {
  listName: string
}

interface PropsOpen {
  actionId: string
  typeId: Exclude<ActionTypes, ActionTypes.ALL>
}

export default function useContactActions(props: PropsUseContactActions): ResponseUseContactActions {
  const router = useRouter()
  const actions = ref([])
  const contactType = 'contacts'

  const read = async (selectedRegisterId: string) => {
    await store.dispatch(`${ModuleNamespaces.ACTIONS}/fetchContactActions`, {
      selectedRegisterId,
      contactType,
      listName: props.listName
    })
    actions.value = store.getters[`${ModuleNamespaces.LIST_ITEMS}/getListItemsWithFormattedDates`]
  }

  const open = ({ actionId, typeId }: PropsOpen, target = '_self') => {
    const actionUrl = getActionRouteNameByAction(typeId) || ''
    const routeData = router.resolveRoute({
      name: actionUrl,
      params: { selectedRegisterId: actionId }
    })
    if (target === '_self') {
      router.goToRoute((routeData as any).resolved)
    } else {
      window.open((routeData as any).href, '_blank')
    }
  }

  return {
    actions,
    read,
    open
  }
}
