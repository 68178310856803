export const arrayHasItems = (data: any) => {
  return Array.isArray(data) && data.length > 0
}

/**
 * Divide the first level of the array into items
 * Example splitArray([1,2,3,4,5], 2)
 * [{
 *  0: [1,2],
 *  1: [3,4],
 *  2: [5]
 * }]
 * @param data Array
 * @param numberItems Number the items are divided
 * @returns Array items
 */
export const splitArray = (data: any, numberItems: number) => {
  const items: any[][] = []
  let index = 0

  for (let i = 0; i < data.length; i++) {
    if (i % numberItems === 0) {
      items[index] = []
      index++
    }
    items[index - 1].push(data[i])
  }

  return items
}

export const arrayHasValue = (array: any, values: any) => {
  return values.some((value: any) => array.includes(value))
}

export const addItemInArrayByIndex = (array: any[], item: any, index: number): any[] => {
  array.splice(index, 0, item)
  return array
}

export const arrayIsListObjects = (array: any): Boolean => {

  if (!Array.isArray(array) || array.length <= 0 || typeof array[0] !== 'object') {
    return false
  }

  return true
}

export const getArrayValuesByProp = (array: any[], prop: string): any[] => {
  return array.map(objeto => objeto[prop])
}

export const arrayValuesToString = (array: any[]): string => {
  let itemsStr = ''
  array.forEach((item) => {
    itemsStr += item + ','
  })
  itemsStr = itemsStr.substring(0, itemsStr.length - 1)
  return itemsStr
}

export const getListStringByProp = (array: any[], prop: string, separator: string = ', '): string => {
  if (array.length === 1) {
    return array[0][prop]
  }

  const list: string[] = array.map(obj => obj[prop])
  const listString = list.join(separator)

  return listString
}

export const getArrayIndex = (
  array: any[], prop: string, value: any
): number => array.findIndex((item: any): boolean => item[prop] === value)
