<template lang="pug">

div(class="template-generator-component")

  FileManagerComponent(
    ref="fileManager"
    v-if="!showTemplateGenerator"
    height="650"
    :basePath="basePath"
    :rootBasePath="path"
    :idEntityTypeBase="idEntityTypeBase"
    :context="context"
    :mainFolderDescription="mainFolder"
    :canDropFilesOnMainFolder="false"
    :canCreateFoldersOnMainFolder="false"
    :canDropFolder="canDropFolder"
    :mode="fileManagerMode"
    :initialBasePath="initialBasePath"
    :initialPath="initialPath"
  )

  SpinnerLayerComponent(v-if="loadingData" class="spinner-layer")

  template(v-show="!loadingData")

    PromptDialogComponent(
      class="dialog-form"
      :title="texts.saveTemplateAs"
      :isOpened="openedSaveAsDialog"
      :formSchema="saveAsDialogFormSchema"
      :context="context"
      :formFieldValues="formFieldValues"
      @execute-action="executeDialogAction"
    )

    section(
      v-show="showTemplateGenerator"
      class="template-generator-container"
    )

      //- Header toolbar
      section(class="header-toolbar")

        //- Title and description
        aside(v-if="template" class="header-info-container")
          h1(class="header-title") {{ template.name }}
          p(class="header-description") {{ template.description }}

        v-spacer
        //- Buttons
        LfActionsBarComponent(
            class="action-buttons"
            :buttons="buttons"
            @execute-action="executeAction"
          )

      //- Main area
      main(class="main-area")
        section(class="tree-view-container")
          div(class="tree-view-title") {{ texts.selectFields }}
          ejs-treeview(
            class="tree-view"
            :fields="treeviewFields"
            :nodeSelected="onTreeViewNodeSelected"
          )

        ejs-documenteditorcontainer(
          ref="documentEditor"
          class="document-editor"
          :enableToolbar="true"
          :height="documentEditorHeight"
          :toolbarItems="toolbarItems"
          :toolbarClick="onToolbarClick"
          :enableComment="false"
          :serviceUrl="serviceUrl"
          :contentChange="onContentChange"
          :documentChange="onDocumentChange"
        )

</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator'
import { DocumentEditorContainerPlugin, Toolbar } from '@syncfusion/ej2-vue-documenteditor'
import { TreeViewPlugin } from '@syncfusion/ej2-vue-navigations'
import { Icons } from '@/icons/icons'
import { Action, Getter, Mutation } from 'vuex-class'
import { ModuleNamespaces, ContextName } from '@/store/types/storeGlobalTypes'
import FileManagerComponent from '@/components/FileManager/FileManagerComponent.vue'
import PromptDialogComponent from '@/components/Dialog/PromptDialogComponent.vue'
import { Template, DropdownElement, templateBasePath } from '@/store/modules/template/templateTypes'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { fileManagerMode } from '@/store/modules/fileManager/fileManagerTypes'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { entity } from '@/store/modules/entities/entitiesTypes'
import LfActionsBarComponent from '@/components/ActionsBar/LfActionsBarComponent.vue'
import { LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'

Vue.use(DocumentEditorContainerPlugin)
Vue.use(TreeViewPlugin)

const fileManagerModule: string = ModuleNamespaces.FILE_MANAGER
const templateModule: string = ModuleNamespaces.TEMPLATE
const dialogModule: string = ModuleNamespaces.DIALOG

@Component({
  provide: {
    DocumentEditorContainer: [Toolbar]
  },
  components: {
    FileManagerComponent,
    PromptDialogComponent,
    SpinnerLayerComponent,
    LfActionsBarComponent
  }
})
export default class TemplateGeneratorComponent extends Vue {
  @Action('fetchTemplateFields', { namespace: templateModule })
  fetchTemplateFieldsAction: ({}: { idEntity: number }) => []
  @Action('showDialog', { namespace: dialogModule })
  showDialogAction: ({}) => {}
  @Action('openFile', { namespace: fileManagerModule })
  openFile: (id: number) => Blob
  @Action('fetchTemplate', { namespace: templateModule })
  fetchTemplate: (templateId: number) => Template
  @Action('convertingToSFDT', { namespace: templateModule })
  convertingToSFDT: (formData: FormData) => {}
  @Action('deleteTemplate', { namespace: templateModule })
  deleteTemplate: (templateId: number) => void
  @Action('saveDocument', { namespace: templateModule })
  saveDocument: (formData: FormData) => void
  @Action('saveTemplateAndDocument', { namespace: templateModule })
  saveTemplateAndDocument: (formData: FormData) => void

  @Getter('getTemplateFields', { namespace: templateModule })
  templateFieldsGetter: []
  @Getter('showTemplateGenerator', { namespace: templateModule })
  showTemplateGenerator: boolean
  @Getter('getTemplate', { namespace: templateModule })
  template: Template
  @Getter('getTemplateId', { namespace: templateModule })
  templateId: number
  @Getter('getCurrentPath', { namespace: fileManagerModule })
  currentBasePath: string
  @Getter('getFile', { namespace: fileManagerModule })
  blobFile: Blob
  @Getter('getPath', { namespace: fileManagerModule })
  currentPath: string

  @Mutation('SHOW_HIDE_TEMPLATE_GENERATOR', { namespace: templateModule })
  showHideTemplateGenerator: (show: boolean) => void
  @Mutation('RESET_TEMPLATE', { namespace: templateModule })
  resetTemplate: () => void

  $refs!: {
    documentEditor: any
    fileManager: any
  }

  documentEditorHeight: number = 0

  formFieldValues = {}

  texts = {
    changeCaseTooltip: this.$t('components.template_generator.change_case_tooltip'),
    dropdownNoneOption: this.$t('components.template_generator.dropdown_none_option'),
    dropdownUppercaseOption: this.$t('components.template_generator.dropdown_uppercase_option'),
    findText: this.$t('components.template_generator.find_text'),
    findTooltip: this.$t('components.template_generator.find_tooltip'),
    imageToolbarOptionTooltip: this.$t('components.template_generator.image_toolbar_option_tooltip'),
    removeTemplate: this.$t('components.dialog.remove_template'),
    saveTemplateAs: this.$t('components.template_generator.save_template_as'),
    selectFields: this.$t('components.template_generator.select_fields'),
    template: this.$t('components.template_generator.template'),
    zoomOptionsTooltip: this.$t('components.template_generator.zoom_tooltip')
  }

  get buttons() {
    return [
      {
        icon: Icons.CLOSE,
        tooltip: this.$t('action_buttons.close'),
        action: ActionName.CLOSE,
        enabled: true,
        variant: LabelButtonVariant.TEXT
      },
      {
        icon: Icons.CHECK,
        tooltip: this.$t('action_buttons.save'),
        action: ActionName.SAVE,
        enabled: true,
        variant: LabelButtonVariant.OUTLINED
      },
      {
        icon: Icons.SAVE_AS,
        tooltip: this.$t('action_buttons.save_as'),
        action: ActionName.SAVE_AS,
        enabled: true,
        isContextMenuOption: true
      },
      {
        icon: Icons.DOWNLOAD,
        tooltip: this.$t('action_buttons.download'),
        action: ActionName.DOWNLOAD,
        enabled: true,
        isContextMenuOption: true
      },
      {
        icon: Icons.PRINT,
        tooltip: this.$t('action_buttons.print'),
        action: ActionName.PRINT,
        enabled: true,
        isContextMenuOption: true
      },
      {
        icon: Icons.REMOVE,
        tooltip: this.$t('action_buttons.remove'),
        action: ActionName.REMOVE,
        enabled: true,
        isContextMenuOption: true
      }
    ]
  }

  executeAction(actionName: string) {
    switch (actionName) {
      case ActionName.CLOSE:
        return this.goToFileManager()
      case ActionName.SAVE:
        return this.saveTemplate()
      case ActionName.SAVE_AS:
        return this.saveTemplateAs()
      case ActionName.DOWNLOAD:
        return this.downloadTemplate()
      case ActionName.PRINT:
        return this.printTemplate()
      case ActionName.REMOVE:
        return this.showRemoveTemplateDialog()
    }
  }

  idEntityTypeBase = entity.template.type
  basePath: string = templateBasePath
  path: string = templateBasePath
  mainFolder: string = templateBasePath
  context: string = ContextName.TEMPLATE
  addContextMenuItems = ''
  fileManagerMode: number = fileManagerMode.TEMPLATE
  canDropFolder: boolean = false
  initialBasePath: string = ''
  initialPath: string = ''

  serviceUrl: string = process.env.VUE_APP_SYNCFUSION_TEMPLATE_API

  startFieldTag: string = process.env.VUE_APP_SYNCFUSION_TEMPLATE_START_FIELD_TAG
  endFieldTag: string = process.env.VUE_APP_SYNCFUSION_TEMPLATE_END_FIELD_TAG

  toolbarItems = [
    'Undo',
    'Redo',
    'Separator',
    'Image',
    'Table',
    'Hyperlink',
    'TableOfContents',
    'Separator',
    'Header',
    'Footer',
    'PageSetup',
    'PageNumber',
    'Break',
    'Separator',
    {
      prefixIcon: 'e-de-ctnr-find',
      tooltipText: this.texts.findTooltip,
      text: this.texts.findText,
      id: 'CustomFind'
    }
  ]

  openedSaveAsDialog: boolean = false

  loadingData: boolean = false

  saveAsDialogFormSchema = [
    {
      id: 'name',
      name: 'name',
      type: 'text',
      label: 'Nombre',
      fieldType: 'LexonTextFieldComponent',
      validationRules: 'required|max:200',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      'offset-xs': 0,
      'offset-sm': 0,
      'offset-md': 0,
      'offset-lg': 0,
      'offset-xl': 0
    },
    {
      id: 'description',
      name: 'description',
      type: 'text',
      label: 'Descripción',
      fieldType: 'LexonTextAreaComponent',
      maxlength: 300,
      validationRules: 'max:300',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      'offset-xs': 0,
      'offset-sm': 0,
      'offset-md': 0,
      'offset-lg': 0,
      'offset-xl': 0
    }
  ]

  @Watch('showTemplateGenerator', { deep: true })
  templateGeneratorInit(show: boolean) {
    if (show) {
      this.loadingData = true
      this.initTemplateGenerator()
    } else {
      this.clear()
    }
  }

  get treeviewFields() {
    return {
      dataSource: this.templateFieldsGetter,
      id: 'id',
      text: 'name',
      child: 'subChild',
      parentID: 'parentID'
    }
  }

  get documentEditorInstance() {
    return this.$refs.documentEditor.ej2Instances ? this.$refs.documentEditor.ej2Instances.documentEditor : null
  }

  get fileManagerInstance() {
    return this.$refs.fileManager
  }

  mounted() {
    this.addDragAndDropFunctionality()
    this.changeDefaultTooltipText()
    this.createUntranslatedDropdownTexts()
    if (this.documentEditorInstance) {
      this.documentEditorInstance.element.addEventListener('click', this.onEditorClick)
    }

    const documentEditorElement: HTMLElement | null = this.$el.querySelector('#_editor_viewerContainer')
    documentEditorElement!.addEventListener('click', this.hidePasteContextMenu)
    documentEditorElement!.addEventListener('contextmenu', this.hidePasteContextMenu)
    documentEditorElement!.addEventListener('dblclick', this.hidePasteContextMenu)
    this.calculateDocumentEditorHeight()
  }

  onDocumentChange() {
    this.calculateDocumentEditorHeight()
  }

  // TO-DO: Revisar menu contextual al copiar cuando se actualicen las librerias de syncfusion

  hidePasteContextMenu() {
    const pasteContextMenu = this.$el.querySelector('#_editor_iconsplitbtn')
    if (pasteContextMenu) {
      pasteContextMenu!.parentElement!.remove()
    }
  }

  onContentChange(_args: any) {
    this.hidePasteContextMenu()
  }

  onEditorClick(_ev: any) {
    const selection = this.documentEditorInstance.selection
    let start = selection.startOffset.split(';')[2]

    let offset = 0
    selection.start.currentWidget.children.map((widget: any) => {
      if (widget.constructor.name === 'TextElementBox') {
        offset += widget.width
        if (start < offset) {
          start = 1000000
          if (widget.text.startsWith('##') && widget.text.endsWith('##') && widget.text.length > 5) {
            selection.selectField()
          }
        }
      }
    })
  }

  calculateDocumentEditorHeight() {
    const container = document.querySelector('.main-area')
    if (container) {
      const height = container.getBoundingClientRect().height
      if (height > 0) {
        this.documentEditorHeight = height
      }
    }
  }

  async initTemplateGenerator() {
    // cambia la miga
    this.$emit('change-breadcrumbs', this.showTemplateGenerator)
    // buscamos el objecto de la plantilla
    await this.fetchTemplate(this.templateId)

    this.fetchTemplateFieldsAction({ idEntity: this.template.idEntityType })
    // buscamos el documento
    await this.openFile(Number(this.template.idDocument))

    this.convertToSFDT()
  }

  async convertToSFDT() {
    if (this.blobFile.size === 0) {
      this.documentEditorInstance.openBlank()
      setTimeout(() => {
        this.documentEditorInstance.selection.sectionFormat.pageWidth = 595.3
        this.documentEditorInstance.selection.sectionFormat.pageHeight = 841.9
      })
    } else {
      const formData: FormData = new FormData()
      formData.append('files', this.blobFile)
      const templateSFDT = await this.convertingToSFDT(formData)
      await this.documentEditorInstance.open(templateSFDT)
    }
    this.loadingData = false
  }

  clear() {
    this.resetTemplate()
  }

  async addDragAndDropFunctionality() {
    await this.$nextTick()
    const treeView = this.$el.querySelector('.tree-view')
    const documentEditor = this.$el.querySelector('.document-editor')

    if (treeView && documentEditor) {
      treeView.addEventListener('dragstart', (event: any) => {
        const ids = {
          fieldId: event.target.getAttribute('data-id'),
          parentId: event.target.getAttribute('data-parent-id'),
          grandParentId: event.target.getAttribute('data-grandparent-id')
        }
        event.dataTransfer.setData('Id', JSON.stringify(ids))
      })

      documentEditor.addEventListener('dragover', (event) => {
        event.preventDefault()
      })

      documentEditor.addEventListener('drop', (e: any) => {
        const nodeIds = JSON.parse(e.dataTransfer.getData('Id'))
        this.documentEditorInstance.selection.select({ x: e.offsetX, y: e.offsetY, extend: false })
        const tagName = this.getTagName(nodeIds)
        this.insertField(tagName)
      })
    }
  }

  saveTemplate() {
    this.documentEditorInstance
      .saveAsBlob('Docx')
      .then(async (fileData: Blob) => {
        const document = JSON.stringify({
          id: this.template.idDocument
        })

        const formData = new FormData()
        formData.append('document', document)
        formData.append('fileData', fileData, `${this.template.name}.docx`)
        this.saveDocument(formData)
      })
      .then(() => {
        this.goToFileManager()
      })
  }

  saveTemplateAs() {
    this.openedSaveAsDialog = true
  }

  downloadTemplate() {
    this.documentEditorInstance.save(this.template.name, 'Docx') // Sfdt | Docx
  }

  printTemplate() {
    this.documentEditorInstance.print()
  }

  async goToFileManager() {
    this.initialBasePath = this.currentBasePath
    this.initialPath = this.currentPath
    this.showHideTemplateGenerator(false)
    this.$emit('change-breadcrumbs', false)
    this.documentEditorInstance.openBlank()
  }

  onTreeViewNodeSelected(args: any) {
    const nodeData = args.nodeData
    const nodeHTML = args.node

    if (!nodeData.hasChildren) {
      const nodeIds = {
        fieldId: nodeData.id,
        parentId: nodeHTML.attributes['data-parent-id'].nodeValue,
        grandParentId: nodeHTML.attributes['data-grandparent-id']
          ? nodeHTML.attributes['data-grandparent-id'].nodeValue
          : null
      }
      const tagName = this.getTagName(nodeIds)
      this.insertField(tagName)
    }
  }

  getTagName(ids: { fieldId: string; parentId: string; grandParentId: string }) {
    const { fieldId, parentId, grandParentId } = ids
    let parentNodeSubChild = []

    if (grandParentId) {
      // Node has THREE levels
      const grandParentNode = this.templateFieldsGetter.filter(
        (node: any) => node.id === grandParentId
      )[0] as unknown as any
      if (grandParentNode.subChild) {
        const grandParentNodeSubChild = grandParentNode.subChild
        parentNodeSubChild = grandParentNodeSubChild.filter((parentNode: any) => parentNode.id === parentId)[0].subChild
      }
    } else {
      // Node has TWO levels
      const parentNode = this.templateFieldsGetter.filter(
        (parentNode: any) => parentNode.id === parentId
      )[0] as unknown as any
      if (parentNode.subChild) {
        parentNodeSubChild = parentNode.subChild
      }
    }

    return parentNodeSubChild.filter((node: any) => node.id === fieldId)[0].tag
  }

  insertField(fieldTag: any) {
    const templateTag = fieldTag.replace(/\n/g, '').replace(/\r/g, '').replace(/\r\n/g, '')
    const templateCode = `MERGEFIELD  ${templateTag}  \\* MERGEFORMAT `
    this.documentEditorInstance.editor.insertField(
      templateCode,
      `${this.startFieldTag}${templateTag}${this.endFieldTag}`
    )
    this.documentEditorInstance.focusIn()
  }

  async changeDefaultTooltipText() {
    await this.$nextTick()
    const zoom = this.$el.querySelector('.e-de-statusbar-zoom')
    const changeCase = this.$el.querySelector('#_editor_font_properties_rightDiv2')
    const imageToolbarOption = this.$el.querySelector('#_toolbar_image')
    const attribute = 'title'

    if (zoom) {
      zoom.setAttribute(attribute, this.texts.zoomOptionsTooltip as string)
    }

    if (changeCase) {
      changeCase.setAttribute(attribute, this.texts.changeCaseTooltip as string)
    }

    if (imageToolbarOption) {
      imageToolbarOption!.setAttribute(attribute, this.texts.imageToolbarOptionTooltip as string)
    }
  }

  changeDropdownText(event: any) {
    const dropdownOption = document.querySelector(event.currentTarget.dropdownOption)
    if (dropdownOption) {
      dropdownOption.textContent = (this as any).texts[event.currentTarget.textKey]
    }
  }

  createSelectors(dropdownElements: DropdownElement[]) {
    dropdownElements.forEach((element) => {
      element.selector = this.$el.querySelector(element.idBtn)
    })
    return dropdownElements
  }

  async createUntranslatedDropdownTexts() {
    await this.$nextTick()
    const changedDropdownsInfo = [
      {
        idBtn: '#_editor_font_properties_rightDiv2',
        dropdownOption: '#_editor_font_properties_rightDiv2_changeCase_dropdownBtn-popup li',
        textKey: 'dropdownUppercaseOption',
        selector: null
      },
      {
        idBtn: '#_editor_font_properties_bullet_dropdownbtn',
        dropdownOption: '#_editor_font_properties_bullet_dropdownbtn-popup .e-de-ctnr-bullet-none',
        textKey: 'dropdownNoneOption',
        selector: null
      },
      {
        idBtn: '#_editor_font_properties_numberingList_dropdownbtn',
        dropdownOption: '#_editor_font_properties_numberingList_dropdownbtn-popup .e-de-bullets',
        textKey: 'dropdownNoneOption',
        selector: null
      },
      {
        idBtn: '#_editor_font_properties_numberingList_dropdownbtn',
        dropdownOption: '#_editor_font_properties_numberingList_dropdownbtn-popup .e-de-bullets',
        textKey: 'dropdownNoneOption',
        selector: null
      }
    ]
    const dropdownElements = this.createSelectors(changedDropdownsInfo)

    dropdownElements.forEach((element: any) => {
      if (element && element.selector) {
        element.selector.addEventListener('click', this.changeDropdownText, false)
        element.selector.dropdownOption = element.dropdownOption
        element.selector.textKey = element.textKey
      }
    })
  }

  executeDialogAction(actionName: string) {
    switch (actionName) {
      case ActionName.CLOSE:
        return this.closeSaveAsDialog()
      case ActionName.SAVE:
        return this.savingTemplateAs()
    }
  }

  async savingTemplateAs() {
    this.documentEditorInstance
      .saveAsBlob('Docx')
      .then(async (fileData: Blob) => {
        const newTemplate = {
          name: (this as any).formFieldValues.name,
          description: (this as any).formFieldValues.description,
          idEntityType: this.template.idEntityType,
          path: this.currentBasePath
        }

        const formData = new FormData()
        formData.append('json', JSON.stringify(newTemplate))
        formData.append('fileData', fileData, `${this.template.name}.docx`)
        await this.saveTemplateAndDocument(formData)
      })
      .then(() => {
        this.closeSaveAsDialog()
        this.formFieldValues = {}
        this.goToFileManager()
      })
  }

  closeSaveAsDialog() {
    this.openedSaveAsDialog = false
  }

  showRemoveTemplateDialog() {
    this.showDialogAction({
      type: DialogTypes.INFO,
      message: this.texts.removeTemplate,
      action: async () => {
        await this.deleteTemplate(this.templateId)
        this.goToFileManager()
      }
    })
  }

  async calculateSearchContainerHeight() {
    await this.$nextTick()
    const setHeight = (e: any) => {
      const resultList = this.$el.querySelector('.e-de-result-list-block') as HTMLElement
      if (resultList) {
        if (e.currentTarget.position === 0) {
          resultList.style.maxHeight = '218px'
        } else {
          resultList.style.maxHeight = '155px'
        }
      }
    }
    const searchContainerTabs = [
      this.$el.querySelector('#e-item-_editor_findTabDiv_0'),
      this.$el.querySelector('#e-item-_editor_findTabDiv_1')
    ]

    if (searchContainerTabs && searchContainerTabs.length) {
      searchContainerTabs.forEach((tab: any, index: number) => {
        tab!.addEventListener('click', setHeight)
        tab.position = index
      })
    }
  }

  onToolbarClick(args: any) {
    switch (args.item.id) {
      case 'CustomFind':
        this.calculateSearchContainerHeight()
        this.documentEditorInstance.showOptionsPane()
        break
    }
  }

  destroyed() {
    this.showHideTemplateGenerator(false)
  }
}
</script>

<style lang="scss" scoped>
@import '~@syncfusion/ej2-vue-documenteditor/styles/material.css';

.template-generator-component {
  position: relative;

  .spinner-layer {
    --spinner-layer-min-height: 100%;
    position: absolute;
    width: 100%;
    background: $white-01;
    z-index: 10;
  }

  .template-generator-container {
    .header-toolbar {
      @include flex($justify-content: flex-end, $align-items: center);
      height: 50px;
      padding: 0px $spacing-xs 0px $cornerRadius-md;
      background-color: $neutral-grey-100;
      margin-bottom: $spacing-md;

      .header-info-container {
        align-self: center;
        padding-left: 18px;

        .header-title {
          @include ellipsis;
          @include milano-bold-16;
          color: $neutral-grey-600;

          &::first-letter {
            text-transform: uppercase;
          }
        }

        .header-description {
          @include ellipsis;
          @include milano-regular-14;
          margin-bottom: 0;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
    }

    .main-area {
      @include flex($align-items: flex-start);
      min-height: 700px;
      background-color: $main-050;

      .tree-view-container {
        @include flex($flex-direction: column);
        align-self: flex-start;
        width: 20% !important;

        .tree-view-title {
          @include milano-bold-18;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 55px;
          width: 100%;
          color: $main-1000;
          padding: $spacing-xs;
        }

        .tree-view {
          @include scroll-styles;
          overflow: auto;
          width: 100%;
          max-height: 621px;

          ::v-deep .e-list-parent.e-ul {
            padding-left: 10px;
          }

          ::v-deep > .e-list-parent.e-ul {
            padding: 0 10px;

            &:first-of-type .e-list-item .e-fullrow {
              background-color: transparent;
              border: none;
            }

            .e-icon-expandable::before {
              font-family: $lf-icons-font;
              font-size: 12px;
              color: $main-1000;
              content: '\e90b' !important;
            }

            .e-icon-collapsible::before {
              font-family: $lf-icons-font;
              font-size: 12px;
              color: $main-1000;
              content: '\e90c' !important;
            }

            .e-list-item[aria-expanded='true'] > .e-text-content {
              border-bottom: 1px solid $main-400;
            }

            .e-list-text {
              color: $neutral-grey-800 !important;
            }

            .e-level-1 {
              @include arial-regular-14;
              background-color: $main-050;

              .e-text-content .e-list-text {
                padding: 5px 0;
              }
            }

            .e-level-2 {
              @include arial-regular-14;
              background-color: $main-050;

              .e-text-content .e-list-text::first-letter {
                text-transform: uppercase;
              }
            }

            .e-level-3 {
              background-color: $main-050;
            }
          }
        }
      }

      ::v-deep .document-editor.e-documenteditorcontainer {
        width: 80% !important;
        border-left: $spacing-sm solid $neutral-white-000;

        .e-de-pane .e-tab .e-icons {
          color: $main-1000;
        }

        .e-de-ctnr-toolbar,
        .e-de-tlbr-wrapper,
        .e-toolbar-items,
        .e-de-showhide-btn,
        .tree-view-title {
          height: 38px;
        }

        .e-de-tool-ctnr-properties-pane {
          padding-top: $spacing-lg;
        }

        .e-de-ctn {
          background-color: $main-050;
        }

        .e-de-tlbr-wrapper {
          width: calc(100% - 255px);
        }

        .e-tab .e-tab-header .e-indicator {
          background: $corporate-color;
        }

        .e-toolbar-items,
        .e-toolbar {
          background-color: $main-050;
        }

        .e-toolbar-item {
          background-color: $main-050;

          .e-tbtn-txt {
            height: 100%;
            background-color: $main-050;

            &:hover {
              background-color: $main-050;

              .e-btn-icon {
                background-color: $main-050;
              }
            }
          }

          .e-btn-icon {
            font-size: 20px !important;
            font-family: $lf-icons-font;
            background-color: $gray-04;

            &.e-de-ctnr- {
              &undo::before {
                color: $corporate-color;
                content: '\ea82';
              }

              &redo {
                transform: scaleX(-1);

                &::before {
                  color: $corporate-color;
                  content: '\ea82';
                }
              }

              &image::before {
                color: $corporate-color;
                content: '\e970';
              }

              &table::before {
                color: $corporate-color;
                content: '\eab5';
              }

              &link::before {
                color: $corporate-color;
                content: '\e98f';
              }

              &tableofcontent::before {
                color: $corporate-color;
                content: '\eade';
              }

              &header::before {
                color: $corporate-color;
                content: '\e9bc';
              }

              &footer {
                transform: scaleY(-1);
                position: relative;
                top: 3px;

                &::before {
                  color: $corporate-color;
                  content: '\e9bc';
                }

                + span {
                  position: relative;
                  top: 1px;
                }
              }

              &pagesetup::before {
                color: $corporate-color;
                content: '\eaf0';
              }

              &pagenumber::before {
                color: $corporate-color;
                content: '\eaef';
              }

              &break::before {
                color: $corporate-color;
                content: '\eaee';
              }

              &find::before {
                color: $corporate-color;
                content: '\eae4';
              }
            }
          }

          .e-tbar-btn-text {
            display: none;
          }
        }

        .e-de-ctnr-properties-pane-btn {
          .e-de-showhide-btn {
            background-color: $main-050;
            opacity: 1 !important;

            &::before {
              @include milano-medium-16;
              color: $neutral-grey-800;
              content: 'Cerrar panel de propiedades';
              margin-right: 8px;
              text-transform: none;
            }
          }

          &.e-de-pane-disable-clr .e-de-showhide-btn {
            &::before {
              display: none;
            }

            &::after {
              @include milano-medium-16;
              color: $neutral-grey-800;
              content: 'Mostrar panel de propiedades';
              margin-left: 10px;
              text-transform: none;
            }
          }

          .e-de-ctnr-showhide {
            font-family: $lf-icons-font;
            transform: scaleX(-1);

            &.e-pane-disabled::before {
              position: relative;
              left: -4px;
              font-size: 20px;
              content: '\e90d';
            }

            &.e-pane-enabled {
              transform: rotateX(180deg);

              &::before {
                font-size: 20px;
                content: '\e90d';
              }
            }
          }
        }

        #_editor_viewerContainer {
          @include scroll-styles;
        }

        .e-de-status-bar {
          .e-de-ctnr-pg-no-spellout,
          .e-btn-icon {
            color: $corporate-color;
          }

          .e-btn-toggle {
            background-color: $corporate-color !important;

            .e-btn-icon {
              color: $white-01;
            }
          }
        }

        .e-de-prop-pane {
          @include scroll-styles;
        }

        #_editor_propertyTabDiv {
          .e-toolbar-items {
            background-color: transparent;
          }

          .e-toolbar-item {
            background-color: transparent;
          }

          .e-tab-header {
            background-color: transparent;

            .e-tab-wrap {
              background-color: transparent;
            }

            .e-toolbar-item.e-active {
              @include border;

              .e-tab-text {
                color: $corporate-color;
              }
            }
          }
        }

        #toc_checkboxDiv,
        #header_footer_properties_optionsDiv,
        .e-de-op-more-less,
        #_editor_aspectRatioDiv,
        #_editor_fontEffectsDiv {
          .e-icons {
            border: none;

            &::before {
              font-size: 16px;
              font-family: $lf-icons-font;
              content: '\e91f';
              color: $corporate-color;
            }
          }

          .e-check {
            background-color: $white-01;

            &::before {
              font-size: 16px;
              font-family: $lf-icons-font;
              content: '\e92b';
              color: $corporate-color;
            }
          }
        }

        #footerDiv {
          @include flex($flex-direction: row-reverse);

          .btn-update {
            @include main-action-button--rectangle;
            box-shadow: none;
          }

          .e-de-btn-cancel {
            @include secondary-action-button--rectangle;
            box-shadow: none;
          }
        }

        .e-documenteditor-optionspane {
          & * {
            @include scroll-styles;
          }

          .e-de-op-header {
            color: $corporate-color;
          }

          .e-tab-header,
          .e-toolbar-items,
          .e-toolbar-item {
            background: transparent;
          }

          .e-toolbar-item {
            &.e-active {
              .e-tab-text {
                color: $corporate-color;
              }
            }
          }

          .e-indicator {
            background-color: $corporate-color;
          }

          .e-checkbox-wrapper label {
            left: 0;
            margin-bottom: 10px;
          }
        }

        .e-de-cntr-pane-padding .e-de-ctnr-segment .e-numerictextbox {
          margin-left: 18px;
        }

        .e-de-search-tab-content {
          .e-de-result-list-block {
            @include scroll-styles;
            max-height: 218px;
          }

          .e-de-op-replacetabcontentdiv {
            margin-top: 0;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
#_toolbar_image_local {
  .e-de-ctnr-upload::before {
    position: relative;
    top: -4px;
    padding-bottom: 10px;
  }
}

#_editor_font_properties_bullet_dropdownbtn-popup {
  .e-de-ctnr-bullet-none {
    font-size: 12px !important;
    position: relative;
    top: 5px;
  }

  .e-de-ctnr-bullet-none::before {
    display: none;
  }
}

ul#_editore-de-contextmenu-list li#_editor_contextmenu_paste,
ul#_editore-de-contextmenu-list li#_editor_contextmenu_cut,
ul#_editore-de-contextmenu-list li#_editor_contextmenu_copy,
ul#_editore-de-contextmenu-list .e-separator {
  display: none !important;
}
</style>
