<template lang="pug">

ValidationProvider(
  :rules="validatorRules"
  ref="autocompleteValidator"
  :name="validationName"
  :vid="validationName"
  v-slot="{ errors }"
  tag="div"
)
  div(
    :class="[{ 'error-styles': errors[0] }, { 'hidden-field': hidden }, { 'disabled-field': disabled },  'lf-autocomplete-container']"
  )
    span(:class="[{ 'required-field': requiredField }, 'label-text']") {{ $attrs.label }}
    v-autocomplete(
      ref="vAutocomplete"
      :value="innerValue"
      :height="height"
      :items="loadedItems"
      :chips="chips"
      single-line
      :deletable-chips="deletableChip"
      :multiple="multiple"
      :class="[{ 'error-styles': errors[0] }, 'lf-autocomplete']"
      :append-icon="icons.arrowDown"
      :error-messages="errors"
      v-on="$listeners"
      hide-details
      :attach="attach"
      :clearable="clearable"
      :id="$attrs.id"
      :name="$attrs.name"
      :placeholder="placeholder"
      :item-text="itemName"
      :item-value="itemValue"
      :return-object="hasRelatedField || returnObject"
      :clear-icon="icons.clearIcon"
      :filter="customFilter"
      :search-input.sync="search"
      autocomplete="off"
      :disabled="disabled"
      @keyup.enter="update"
      :key="componentKey"
    )

      template(v-if="chips" v-slot:selection="{ item, index }")
        v-chip(v-if="index < visibleChips" close @click:close="removechip(item)")
          span(:title="item[itemName]") {{ item[itemName] }}
        span(
          v-if="index === ( visibleChips)"
          class="others"
        ) +{{ value.length - visibleChips }} {{ $t('components.autocomplete.other') }}


      template(v-slot:no-data)
        p(class="no-results-message") {{ $t('components.no_results.no_search_results_text') }}

      template(v-if="showSelectAll" v-slot:prepend-item)
        v-list-item(@click="toggleSelectAll" class="select-all")
          v-list-item-action
            v-icon(class="select-all-icon") {{ allSelected ? icons.close : icons.selectAllEmpty }}
          v-list-item-content
            v-list-item-title {{ selectAllText }}
        v-divider

    div(class="messages-container")
      span(v-if="errors.length" class="alert-message") {{ errors[0] }}
      span(v-else class="help-message") {{ helpText }}

</template>

<script lang="ts">
import FieldBehaviorsMixin from '@/mixins/FieldBehaviorsMixin.vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { EndpointEntity } from '@/store/modules/endpoint/endpointTypes'
import { MainService } from '@/services/MainService'
import { Icons } from '@/icons/icons'
import { ValidationProvider } from 'vee-validate'
import { mixins } from 'vue-class-component'
import { clone } from '@/helpers/object'
import { i18n } from '@/plugins/vue-i18n'

@Component({
  components: {
    ValidationProvider
  }
})
export default class LexonAutocompleteComponent extends mixins(FieldBehaviorsMixin) {
  @Prop({
    type: [Object, String, Array, Number]
  })
  value!: string

  @Prop({
    type: Array
  })
  propItems!: string[]

  @Prop({
    type: String
  })
  items!: string

  @Prop({
    type: String
  })
  helpText!: string

  @Prop({
    type: String
  })
  itemName!: string

  @Prop({
    type: String
  })
  itemValue!: string

  @Prop({
    type: String
  })
  endPoint!: string

  @Prop({
    type: Object
  })
  endPointEntity!: EndpointEntity

  @Prop({
    type: String
  })
  placeholder!: string

  @Prop({
    type: Boolean
  })
  chips!: boolean

  @Prop({
    type: Boolean
  })
  multiple!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  hasRelatedField!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  returnObject!: boolean

  @Prop({
    type: String
  })
  itemRelatedField!: string

  @Prop({
    type: String
  })
  relatedField!: string

  @Prop({
    type: Boolean,
    default: false
  })
  initializeFirst!: boolean

  @Prop({
    type: String
  })
  filterField!: string

  @Prop({
    type: String
  })
  filteredValue!: string

  @Prop({
    type: Boolean,
    default: false
  })
  hidden!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  attach!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  clearable!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  showSelectAll!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  addNew!: boolean

  @Prop({
    type: String,
    default: i18n.t('action_buttons.select_all')
  })
  selectAllText!: string

  $refs!: {
    autocompleteValidator: InstanceType<typeof ValidationProvider>
    vAutocomplete: any
  }

  loadedItems: string[] = []
  originalItems: string[] = []

  icons = {
    clearIcon: Icons.CLOSE,
    arrowDown: Icons.ANGLE_DOWN,
    selectAllEmpty: Icons.BOX_INACTIVE,
    close: Icons.CLOSE_FULL
  }

  innerValue: number | object | string | string[] = ''
  search = ''

  deletableChip = false

  height = '39'

  initialValue: any = this.value

  componentKey = 0

  visibleChips = 3

  @Watch('innerValue')
  changedValue(newVal: string | string[]) {
    this.validateField()
    this.$emit('input', newVal)
    this.calculateChipsToFit()
  }

  @Watch('propItems')
  changedPropItems(newVal: string[]) {
    this.loadedItems = newVal
  }

  @Watch('value')
  changeInnerValue(newVal: string | string[]) {
    this.validateField()
    this.innerValue = newVal
  }

  @Watch('filteredValue')
  changedFilteredValue(filteredValue: string | number) {
    if (this.filterField) {
      this.loadedItems = this.originalItems.filter((item) => (item as any)[this.filterField] === filteredValue)
    }
  }

  get requiredField() {
    return this.validationRules && this.validationRules.includes('required') ? true : false
  }

  get allSelected() {
    return (this as any).innerValue.length === this.loadedItems.length
  }

  mounted() {
    this.initValidator(this.$refs.autocompleteValidator)
    window.addEventListener('resize', this.calculateChipsToFit)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.calculateChipsToFit)
  }

  async created() {
    await this.fetchItems()

    if (this.chips) {
      this.deletableChip = true
      this.multiple = true
      this.height = 'auto'
    }

    if (this.value) {
      this.innerValue = this.regularizeDataTypes(this.value)
    }

    if (!this.disabled) {
      this.doInitializeFirst()
    }
  }

  async reloadComponent() {
    await this.fetchItems()
    this.componentKey += 1
    this.innerValue = ''
  }

  onChangeDisabled(disabled: boolean) {
    if (!disabled) {
      this.doInitializeFirst()
    } else if (!this.initialValue) {
      this.innerValue = ''
    }
  }

  doInitializeFirst() {
    if (
      this.initializeFirst &&
      (this.innerValue === '' ||
        this.innerValue === undefined ||
        (typeof this.innerValue === 'object' && (this as any).innerValue.id === undefined))
    ) {
      let field = 'id'

      if (this.itemValue) {
        field = this.itemValue
      }

      if (this.loadedItems.length && (this.loadedItems[0] as any)[field]) {
        this.innerValue = (this.loadedItems[0] as any)[field]
      }
    }
  }

  regularizeDataTypes(value: any) {
    // El tipo de de value comparado con el tipo de datos del campo [itemValue] de la lista de items loadedItems[0]
    // TODO: Método a eliminar cuando se devuelva tipado los datos de los procedimietos almacenados
    if (this.chips && typeof value === 'string') {
      return value.split(',')
    }
    if (['string', 'number', 'boolean'].indexOf(typeof value) >= 0 && this.loadedItems.length) {
      const listType = typeof (this as any).loadedItems[0][this.itemValue]
      if (typeof this.value !== listType) {
        switch (listType) {
          case 'string':
            return value.toString()
          case 'number':
            return Number(value)
          case 'boolean':
            return value === 1 || value === '1'
        }
      }
    }
    return value
  }

  async fetchItems() {
    try {
      if (this.propItems) {
        this.loadedItems = this.propItems
      } else {
        let url = ''
        if (this.endPointEntity) {
          const endPointEntityParsed = this.parseEndpointEntity(clone(this.endPointEntity))
          if (endPointEntityParsed.method === 'GET') {
            const { data } = await new MainService().getData(endPointEntityParsed.url)
            this.loadedItems = data
          } else {
            const { data } = await new MainService().postData(endPointEntityParsed.url, endPointEntityParsed.params)
            this.loadedItems = data
          }
        } else {
          url = `general/data/${this.items}`
          if (this.endPoint) {
            url = this.endPoint.toString()
          }
          const { data } = await new MainService().getData(url)
          this.loadedItems = data
        }
      }

      this.originalItems = this.loadedItems
    } catch (error) {}
  }

  customFilter(item: { name: string }, queryText: string, itemText: string | any[]) {
    if (item && item.name) {
      itemText = item.name
        .toLocaleLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
      queryText = queryText
        .toLocaleLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
      return itemText.indexOf(queryText) > -1
    }
    return false
  }

  async update() {
    if (!this.addNew) {
      return
    }
    await this.$nextTick()
    if (this.chips && this.search) {
      const items = this.search.split(',')
      const itemsObject: any = items.map((str) => ({ name: str }))
      this.loadedItems.push(...itemsObject)
      if (this.innerValue === '') {
        this.innerValue = []
      }
      ;(this as any).innerValue.push(...items)
      this.$nextTick(() => {
        this.search = ''
      })
    }
  }

  toggleSelectAll() {
    if (this.allSelected) {
      this.innerValue = []
    } else {
      this.innerValue = this.loadedItems
    }
  }

  async calculateChipsToFit(): Promise<void> {
    await this.$nextTick()
    const chips = this.getChips()

    if (chips.length === 0) return

    this.applyWidthToChips(chips, this.autocompleteWidth())
  }

  getChips(): NodeListOf<Element> {
    const autocompleteRef: HTMLElement = this.$refs.vAutocomplete.$el
    return autocompleteRef.querySelectorAll('.v-chip__content')
  }

  autocompleteWidth(): number {
    const autocompleteRef: HTMLElement = this.$refs.vAutocomplete.$el
    return autocompleteRef.clientWidth
  }

  applyWidthToChips(chips: NodeListOf<Element>, width: number): void {
    const style = this.chipWidthStyle(chips.length, width)
    chips.forEach((chip) => this.applyWidthToChip(chip as HTMLElement, style))
  }

  chipWidthStyle(numOfChips: number, totalWidth: number): string {
    const width = this.$vuetify.breakpoint.width > 1536 ? 6 : 9
    return numOfChips > 2 ? `${Math.floor(totalWidth / width)}px` : 'auto'
  }

  applyWidthToChip(chip: HTMLElement, width: string): void {
    chip.style.width = width
  }

  removechip(item: any) {
    if (typeof this.innerValue === 'object') {
      this.innerValue = (this.innerValue as any[]).filter((p: any) => {
        return p !== item[this.itemValue]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.lf-autocomplete-container {
  @include textfield-messages;
  width: 100%;

  ::v-deep div.v-list-item__action {
    @include flex;

    & > .v-simple-checkbox {
      padding-bottom: 9px !important;
    }
  }

  ::v-deep .select-all-icon {
    font-size: 18px !important;

    &.lf-icon-close-full {
      color: $corporate-color;
    }
  }

  ::v-deeo .theme--light.v-list-item--active::before {
    opacity: 0 !important;
  }

  ::v-deep .v-menu__content.menuable__content__active .v-list-item--active .v-list-item__title {
    color: $gray-01;
  }

  &.disabled-field {
    .lf-autocomplete:hover {
      border-color: $gray-02;
      background-color: $gray-04;
    }

    .lf-autocomplete {
      background-color: $gray-04;
    }

    .label-text,
    .messages-container .help-message {
      color: $gray-02;
    }

    .required-field.label-text::after {
      color: $gray-02;
    }
  }

  &.hidden-field {
    display: none;
  }

  ::v-deep .v-text-field > .v-input__control > .v-input__slot:after {
    border: none;
  }

  .label-text {
    @include label-text;
  }

  .lf-autocomplete {
    @include textfield-styles;
    padding: 0;
    margin-top: 0;

    ::v-deep .error--text {
      color: $main-1000 !important;
    }

    .v-list {
      max-height: 220px;
    }

    &:hover ::v-deep .v-select__slot {
      .v-input__icon--clear {
        opacity: 1;
      }
    }

    &.v-input--is-focused {
      border: none;

      ::v-deep .v-select__slot {
        @include borders;
        border-radius: $cornerRadius-sm;
        background-color: $neutral-white-000;

        .v-input__icon--clear {
          opacity: 1;
        }
      }
    }

    &.error-styles {
      ::v-deep &.v-input {
        @include borders($color: $error-color);

        .v-select__slot {
          height: 33px;

          .v-input__append-inner {
            margin-top: 0;
          }
        }

        &:hover .v-select__slot {
          background-color: $main-050;
        }
      }

      &.v-input--is-focused {
        background-color: $neutral-white-000;

        ::v-deep &.v-input {
          &:hover .v-select__slot {
            background-color: $neutral-white-000;
          }
        }

        ::v-deep .v-select__slot {
          border: none;
          background-color: $neutral-white-000;

          .v-input__append-inner {
            margin-top: 0;
          }
        }
      }
    }

    ::v-deep .v-simple-checkbox .v-icon {
      top: 3.3px;
      font-size: 18px;
    }

    ::v-deep .v-list-item__action:first-child {
      margin-right: 8px;
    }

    ::v-deep .v-list-item__content {
      padding: 10px 0;

      .v-list-item__title {
        white-space: normal;
      }
    }

    ::v-deep .v-input__control div::before {
      border: none;
    }

    ::v-deep .v-select__slot {
      height: 100%;
      padding: 0 8px 0 10px;

      .v-select__selections {
        min-height: $input-size;

        .others {
          @include arial-regular-12;
        }
      }

      .v-input__icon--clear {
        opacity: 0;
        padding-top: 1px;
      }

      .v-input__append-inner {
        cursor: pointer;
        padding-top: 2px;
        padding-left: 0;

        .v-input__icon--append {
          position: relative;

          &:before {
            display: block;
            position: absolute;
            left: -5px;
            min-width: 1px;
            min-height: $icon-size;
            background-color: $neutral-grey-400;
            content: ' ';
          }

          .v-icon {
            margin-top: 2px;
          }
        }

        .v-input__icon--clear .v-icon {
          position: relative;
          font-size: 12px;

          &::before {
            position: absolute;
            top: -7px;
            right: 2px;
            font-weight: bold;
          }
        }
      }

      input {
        min-height: 30px;
        color: $neutral-grey-800;
        padding: 0;

        &::placeholder {
          @include placeholder;
        }
      }

      .v-icon {
        font-size: $icon-size;
        padding-bottom: 0;
        color: $blue-01;
      }
    }
  }
  .v-select--chips {
    height: auto;

    ::v-deep .v-chip--select {
      @include arial-regular-12;
      background-color: $main-050;
      border-radius: $cornerRadius-sm;
      color: $neutral-grey-800;
      text-align: center;
      height: 22px;
    }

    ::v-deep .v-chip {
      height: 22px;
      border-radius: $spacing-xxs;
      background: $main-050;
      padding-left: $spacing-xs;
      padding-right: $spacing-xs;

      .v-chip__content {
        @include ellipsis;
        display: block;
        max-width: 128px;
        padding-right: 16px;
        line-height: 22px;
        background: $main-050;
      }

      .v-chip__close {
        font-size: 13px !important;
        position: absolute;
        right: 12px;
        top: 4.5px;

        &::before {
          color: $neutral-grey-600;
        }
      }
    }
  }
}
</style>
