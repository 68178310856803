import { Route } from 'vue-router'
import { URLS } from '@/router/routes/urlRoutes'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

export const loginGuard = (to: Route, from: Route, next: any): void => {
  const userIsAuthenticated = store.getters[`${ModuleNamespaces.AUTH}/userIsAuthenticated`]
  if (to.matched.some((record) => record.meta.requiresAuth) && !userIsAuthenticated) {
    store.dispatch(`${ModuleNamespaces.ROUTER}/setNextUrl`, to.fullPath)
    next(`/${URLS.LOGIN}`)
  } else if (
    from.matched.some((record) => record.meta.requiresAuth) &&
    to.name === URLS.LOGIN &&
    to.params &&
    !to.params.nextUrl
  ) {
    store.dispatch(`${ModuleNamespaces.ROUTER}/setNextUrl`, from.fullPath)
  }
  next()
}


