import { GetterTree } from 'vuex'
import { RootState } from '@/store/types/storeGlobalTypes'
import { SubActionsState } from '@/store/modules/sub-actions/subActionsTypes'

export const getters: GetterTree<SubActionsState, RootState> = {
  getSubActionList(state) {
    return state.subActionlist?.data || []
  },
  getActionList(state) {
    return state.actionlist?.data
  },
  getSubAction(state) {
    return state.subAction ?? {}
  },
  getParentAction(state) {
    return state.parentAction ?? {}
  },
  getSelectedSubActionType(state) {
    return state.selectedSubActionType
  },
  getChronometerNewActionInfo(state) {
    return state.chronometerNewActionInfo
  }
}
