<template>
  <div>
    <GridChipsFiltersComponent
      v-if="chipsFilter"
      :filters="filters"
      @removeFilter="onRemoveFilter"
    ></GridChipsFiltersComponent>
    <ejs-grid
      ref="grid"
      width="100%"
      class="grid-table"
      :allowPaging="allowPaging"
      :allowSorting="allowSorting"
      :allowFiltering="allowFiltering"
      :allowExcelExport="true"
      :allowReordering="true"
      :allowPdfExport="true"
      :allowResizing="true"
      :contextMenuItems="contextMenuItems"
      :contextMenuModule="true"
      :dataSource="itemData"
      :enablePersistance="true"
      :selectionSettings="selectionOptions"
      :showColumnChooser="showColumnChooser"
      :rowHeight="40"
      :childGrid="childGrid"
      :toolbar="toolbarOptionsParsed"
      :pageSettings="pageSettings"
      :filterSettings="filterSettings"
      :searchSettings="searchSettings"
      :columnChooserSettings="columnChooserSettings"
      @contextMenuClick="onContextMenuClicked"
      @contextMenuOpen="onContextMenuBeforeOpen"
      @commandClick="onCommandClicked"
      @rowSelected="onRowSelected"
      @rowDeselected="onRowSelected"
      @recordClick="onRowClick"
      @rowDataBound="rowDataBound"
      @pdfExportComplete="onPdfExportComplete"
      @pdfQueryCellInfo="onPdfQueryCellInfo"
      @toolbarClick="onToolbarClicked"
    >
      <e-columns>
        <e-column
          v-for="(column, index) in columns"
          :filter="filter(column)"
          :clipMode="getClipMode(column)"
          :allowFiltering="column.allowFiltering"
          :allowReordering="column.allowReordering"
          :allowResizing="column.allowFiltering"
          :allowSorting="column.allowSorting"
          :commands="column.commands"
          :field="column.field"
          :filterModule="column.filterModule"
          :format="column.format"
          :headerTemplate="column.headerTemplate"
          :headerText="column.headerText"
          :headerTextAlign="column.headerTextAlign"
          :isPrimaryKey="column.isPrimaryKey ? true : false"
          :key="`column-${index}`"
          :minWidth="column.minWidth"
          :maxWidth="column.maxWidth"
          :textAlign="column.textAlign"
          :type="column.type"
          :visible="column.visible"
          :width="column.width"
          :showInColumnChooser="column.showInColumnChooser"
          :template="getTemplate(column)"
          :freeze="childGrid ? undefined : column.freeze"
        >
        </e-column>
      </e-columns>
    </ejs-grid>
    <ejs-contextmenu
      id="customToolbarContextMenu"
      ref="customToolbarContextMenu"
      :items="customToolbarContextMenuItems"
      @select="customToolbarContextMenuClick"
    ></ejs-contextmenu>
  </div>
</template>
<script lang="ts">
import {
  ColumnChooser,
  CommandColumn,
  ContextMenu,
  DetailRow,
  ExcelExport,
  Filter,
  Freeze,
  Toolbar,
  Page,
  PdfExport,
  Reorder,
  Resize,
  Search,
  Sort
} from '@syncfusion/ej2-vue-grids'
export default {
  provide: {
    grid: [
      ColumnChooser,
      CommandColumn,
      ContextMenu,
      DetailRow,
      ExcelExport,
      Filter,
      Freeze,
      Toolbar,
      Page,
      PdfExport,
      Reorder,
      Resize,
      Search,
      Sort
    ]
  }
}
</script>
<script lang="ts" setup>
import {
  GridComponent as EjsGrid,
  ColumnsDirective as EColumns,
  ColumnDirective as EColumn,
  ContextMenuClickEventArgs,
  ContextMenuItemModel,
  SelectionSettings,
  RecordClickEventArgs,
  IFilter,
  FilterSettings,
  GridComponent,
  RowInfo,
  RowSelectEventArgs,
  RowDeselectEventArgs,
  ClipMode,
  SearchSettings,
  ColumnChooserSettingsModel,
  ContextMenuOpenEventArgs
} from '@syncfusion/ej2-vue-grids'
import { ToolbarItemModel } from '@syncfusion/ej2-vue-filemanager'
import {
  ActionName,
  ClickEventArgs,
  ColumnModel,
  CommandClickEventArgs,
  GridConfiguration,
  ItemModel,
  pageSettingsDefault
} from './LfGridTypes'
import { filterSettingsTypesOperator } from '@/components/grids/GridTable/types/GridTableTypes'
import { PropType, Ref, computed, ref } from 'vue'
import GridChipsFiltersComponent from '@/components/grids/GridChipsFiltersComponent.vue'
import { defineEmits, defineProps } from 'vue'

import useGridMethods from './composables/useGridMethods'
import useGridTemplates from './composables/useGridTemplates'
import useGridFilters from './composables/useGridFilters'
import useGridContextMenu from './composables/useGridContextMenu'

const emit = defineEmits(['rowClick', 'contextMenuClicked', 'toolbarClicked', 'rowsSelected', 'contextMenuBeforeOpen'])

const grid: Ref<InstanceType<typeof GridComponent> | null> = ref(null)
const customToolbarContextMenu: Ref<InstanceType<typeof ContextMenu> | null> = ref(null)
const selectedItem: Ref<any> = ref(null)

const props = defineProps({
  gridConfiguration: {
    type: Object as PropType<GridConfiguration>,
    required: true
  },
  itemData: {
    type: [Array, Object],
    required: true
  },
  contextMenuItems: {
    type: Array as PropType<ContextMenuItemModel[]>,
    default: () => []
  },
  toolbarOptions: {
    type: Array as PropType<ToolbarItemModel[]> | null,
    default: null
  },
  selectionOptions: {
    type: Object as PropType<SelectionSettings>,
    default: () => ({ mode: 'Row', type: 'Single' })
  },
  toolbarClick: {
    type: Function as PropType<(args: ClickEventArgs) => void>,
    default: () => {}
  },
  commandClick: {
    type: Function as PropType<(args: CommandClickEventArgs) => void>,
    default: () => {}
  },
  allowPaging: {
    type: Boolean,
    default: true
  },
  allowSorting: {
    type: Boolean,
    default: true
  },
  allowFiltering: {
    type: Boolean,
    default: true
  },
  filterSettings: {
    type: Object as PropType<FilterSettings>,
    default: () => ({
      type: 'Menu',
      ignoreAccent: true,
      columns: [],
      operators: filterSettingsTypesOperator
    })
  },
  searchSettings: {
    type: Object as PropType<SearchSettings>,
    default: () => ({
      ignoreCase: true,
      ignoreAccent: true
    })
  },
  columnChooserSettings: {
    type: Object as PropType<ColumnChooserSettingsModel>,
    default: () => ({
      ignoreAccent: true,
      operator: 'contains'
    })
  },
  showColumnChooser: {
    type: Boolean,
    default: true
  },
  chipsFilter: {
    type: Boolean,
    default: false
  },
  childGrid: {
    type: Object,
    default: null
  },
  rowDataBound: {
    type: Function as PropType<(...args: any[]) => void>,
    default: (_args: any[]) => {}
  },
  exportName: {
    type: String,
    default: 'Export'
  }
})

const customToolbarContextMenuItems = ref<ContextMenuItemModel[]>([])

// COMPOSABLES
const { excelExport, exportToPDF, getSelectedRecords, onPdfQueryCellInfo, onPdfExportComplete, search } =
  useGridMethods({
    gridRef: grid,
    exportName: props.exportName
  })
const { clearFilter, hasFilters, filters, removeFilterByField } = useGridFilters({ gridRef: grid })
const { getTemplate } = useGridTemplates()
const { contextMenuBeforeOpen } = useGridContextMenu({ gridRef: grid })

// COMPUTED
const columns = computed(() => props.gridConfiguration.columns)
const pageSettings = computed(() => props.gridConfiguration.pageSettings || pageSettingsDefault)

const toolbarOptionsParsed = computed(() => {
  if (!props.toolbarOptions) {
    return
  }
  return props.toolbarOptions.map((item) => {
    if (item.id === ActionName.FILTER) {
      return { ...item, visible: hasFilters.value }
    }
    return item
  })
})

// EVENTS
function onCommandClicked(args: CommandClickEventArgs) {
  const { commandColumn, target, rowData } = args
  if (commandColumn.id === ActionName.OPEN_KEBAK_MENU) {
    const { x, y } = target?.getClientRects()[0] as DOMRect
    selectedItem.value = rowData
    openCustomToolbarContextMenu(props.contextMenuItems, y, x)
    return
  }
  props.commandClick(args)
}

function onRowClick(args: RecordClickEventArgs) {
  const { rowData, rowIndex, column } = args
  if (column!.commands !== undefined) {
    return
  }
  emit('rowClick', { rowData, rowIndex })
}

function onRemoveFilter({ filter }: any) {
  const { field } = filter
  removeFilterByField(field)
}

function onContextMenuBeforeOpen(args: ContextMenuOpenEventArgs) {
  const argsContext = contextMenuBeforeOpen(args)
  emit('contextMenuBeforeOpen', argsContext)
}

function onContextMenuClicked(args: ContextMenuClickEventArgs) {
  const { event, item, rowInfo } = args
  emit('contextMenuClicked', { event, item, rowInfo })
}

function onToolbarClicked(args: ClickEventArgs) {
  const { originalEvent, item } = args
  const { id, contextMenuItems } = item
  const { x, pageY: y, target } = originalEvent as PointerEvent
  switch (id) {
    case ActionName.CUSTOM_TOOLBAR_CONTEXT_MENU:
      if (contextMenuItems) {
        openCustomToolbarContextMenu(contextMenuItems, y, x)
      }
      break
    case ActionName.EXPORT:
      excelExport()
      break
    case ActionName.PRINT:
      exportToPDF()
      break
    case ActionName.FILTER:
      clearFilter()
      break
    case ActionName.SEARCH:
      search({ target })
      break
    case ActionName.ADD:
      disabledClickButton(item)
      props.toolbarClick(args)
      emit('toolbarClicked', args)
      break
    default:
      props.toolbarClick(args)
      emit('toolbarClicked', args)
      break
  }
}

function disabledClickButton(item: ItemModel) {
  item.disabled = true

  setTimeout(() => {
    item.disabled = false
  }, 2000)
}

function onRowSelected(_args: RowSelectEventArgs | RowDeselectEventArgs) {
  const selectedRecords = getSelectedRecords()
  emit('rowsSelected', selectedRecords)
}

const customToolbarContextMenuClick = (args: ContextMenuClickEventArgs) => {
  const { id } = args.item

  args.rowInfo = {
    rowData: selectedItem.value
  } as RowInfo

  switch (id) {
    case ActionName.EXPORT:
      excelExport()
      break
    case ActionName.PRINT:
      exportToPDF()
      break
    default:
      emit('contextMenuClicked', args)
      return
  }
}

// METHODS
function filter(column: ColumnModel): IFilter {
  if (column.isCheckboxType) {
    return { type: 'CheckBox' }
  }
  return props.filterSettings
}

function openCustomToolbarContextMenu(contextMenuItems: ContextMenuItemModel[], top: number, left: number) {
  if (customToolbarContextMenu.value) {
    customToolbarContextMenuItems.value = contextMenuItems
    ;(customToolbarContextMenu.value as any).open(top, left)
  }
}

const getClipMode = (column: ColumnModel): ClipMode | undefined => {
  if (!column.headerText) {
    return
  }
  return 'EllipsisWithTooltip'
}
</script>
