export type File = {
  name: string
  status: string
}

export enum FileStatus {
  LOADING = 'Loading',
  ERROR = 'Error',
  LOADED = 'Loaded'
}
