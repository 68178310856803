<template lang="pug">

WidgetBaseComponent(
  class="expedient-summary-widget"
  :loadedData="allDataLoaded && !showSpinner"
)
  //- HEADER
  template(v-slot:header)
    h2 {{ expedientSummaryData.title }}

  //- BODY
  template(v-slot:body)
    section(class="expedient-summary-widget-container")

      div(class="expedient-info")

        div(class="row-1")
          div(v-for="(item, index) in expedientSummaryData.stage" :key="index")
            div(class="stage-container")
              span(class="text") {{ item.label }}
              span(
                v-if="item.data"
                :class="index === 0 ? 'status' : 'stage-name'") {{ item.data }}

        div(class="info-container")
          div(class="row-2")
            div(v-for="(item, index) in stageItems" :key="index" class="info-item")
              LexonTooltipComponent(
                v-if="isTruncatedRowStage[index]"
                :message="item.data"
                position="top"
                class="tooltip-activator caso1"
                isWidthAuto
              )
                div( slot="activator-element" class="row-4")
                  span(:class="[icon.lead, 'lf-icon__md', 'lead-icon']")
                  span(class="info-item-label") {{ `${item.label}:` }}
                  div(ref="infoItemStage" class="info-item-data-large") {{ item.data }}
              div(v-else  class="row-4")
                span(:class="[icon.lead, 'lf-icon__md', 'lead-icon']")
                span(class="info-item-label") {{ `${item.label}:` }}
                div(ref="infoItemStage" class="info-item-data-large") {{ item.data }}

          div(class="row-3")
            div(v-for="(item, index) in infoItems" :key="index" class="info-item")
              LexonTooltipComponent(
                v-if="isTruncatedRowInfo[index]"
                :message="item.data"
                position="top"
                class="tooltip-activator caso1"
                isWidthAuto
              )
                div( slot="activator-element" class="row-4")
                  span(class="info-item-label") {{ `${item.label}:` }}
                  div(ref="infoItemInfo" class="info-item-data-large") {{ item.data }}
              div(v-else  class="row-4")
                span(class="info-item-label") {{ `${item.label}:` }}
                div(ref="infoItemInfo" class="info-item-data-large") {{ item.data }}


      div(class="resumes-container")
        div(v-for="(counter, index) in parseResumeCounters" :key="`counter-${index}`" class="resume-item")
          span(:class="[renderIcon(index), 'lf-icon__md']")
          div(class="resume-item-container")
            span(class="resume-item-title") {{ counter.title }}
            span(class="resume-item-qty") {{ counter.qty }}

</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import WidgetBaseComponent from '@/components/widgets/WidgetBaseComponent.vue'
import { Icons } from '@/icons/icons'
import { Action, Getter, Mutation } from 'vuex-class'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Endpoint } from '@/store/modules/endpoint/endpointTypes'
import { Entity } from '@/store/modules/entities/entitiesTypes'
import LexonTooltipComponent from '@/components/forms/fields/LexonTooltip/LexonTooltipComponent.vue'

const endpointModule = ModuleNamespaces.ENDPOINT
const entitiesModule = ModuleNamespaces.ENTITIES
const expedientsModule = ModuleNamespaces.EXPEDIENTS
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    WidgetBaseComponent,
    LexonTooltipComponent
  }
})
export default class ExpedientSummaryWidget extends Vue {
  @Action('fetchSelectedRegisterData', { namespace: selectedRegisterModule })
  fetchSelectedRegisterDataAction: ({}) => Promise<{}>

  @Mutation('REMOVE_SELECTED_REGISTER_DATA_CONTEXT', { namespace: selectedRegisterModule })
  removeSelectedRegisterDataContextMutation: (context: string) => void

  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterDataGetter: (context: string) => {}
  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterIdGetter: (context: string) => number
  @Getter('getEndpoints', { namespace: endpointModule })
  endpoints: (entityType: number) => Endpoint
  @Getter('getEntity', { namespace: entitiesModule })
  entity: (context: string) => Entity
  @Getter('getExpedientStageState', { namespace: expedientsModule })
  expedientStageStateGetter: string

  icon = {
    lead: Icons.LEAD,
    bill: Icons.BILL,
    money: Icons.BILLING,
    clock: Icons.CLOCK
  }

  contexts = {
    expedientSummary: ContextName.EXPEDIENT_SUMMARY_WIDGET,
    expedientContext: ContextName.EXPEDIENTS
  }

  showSpinner = true

  isTruncatedRowInfo: boolean[] = []
  isTruncatedRowStage: boolean[] = []

  get selectedExpedientId() {
    return this.selectedRegisterIdGetter(this.contexts.expedientContext)
  }

  get expedientSummaryData() {
    return (this as any).selectedRegisterDataGetter(this.contexts.expedientSummary)[this.contexts.expedientSummary]
  }

  get stageItems() {
    if (!this.expedientSummaryData) return []
    return this.expedientSummaryData.body.slice(0, 2)
  }

  get infoItems() {
    if (!this.expedientSummaryData) return []
    return this.expedientSummaryData.body.slice(2)
  }

  get allDataLoaded() {
    if (!this.expedientSummaryData) return false
    return !!(this.expedientSummaryData && Object.keys(this.expedientSummaryData).length)
  }

  get parseResumeCounters() {
    return this.expedientSummaryData.counters.map((item: any) => ({
      title: item.label,
      qty: item.data
    }))
  }

  @Watch('expedientStageStateGetter')
  updateData() {
    this.fetchSummaryInfo()
  }

  @Watch('stageItems', { immediate: true, deep: true })
  onStageItemsChanged(newVal: any) {
    new Promise((resolve) => {
      const checkExist = setInterval(() => {
        if (this.$refs.infoItemStage) {
          clearInterval(checkExist)
          resolve(true)
        }
      }, 100)
    }).then(() => {
      if (this.isTruncatedRowStage.length === 0) {
        this.isTruncatedRowStage = newVal.map((_item: any, index: any) => {
          const element: HTMLDivElement = (this.$refs as any).infoItemStage[index]
          return element && element.scrollWidth > element.offsetWidth
        })
      }
    })
  }

  @Watch('infoItems', { immediate: true, deep: true })
  onInfoItemsChanged(newVal: any) {
    new Promise((resolve) => {
      const checkExist = setInterval(() => {
        if (this.$refs.infoItemInfo) {
          clearInterval(checkExist)
          resolve(true)
        }
      }, 100)
    }).then(() => {
      if (this.isTruncatedRowInfo.length === 0) {
        this.isTruncatedRowInfo = newVal.map((_item: any, index: any) => {
          const element: HTMLDivElement = (this.$refs as any).infoItemInfo[index]
          return element && element.scrollWidth > element.offsetWidth
        })
      }
    })
  }

  async created() {
    await this.fetchSummaryInfo()
  }

  beforeDestroy() {
    this.removeSelectedRegisterDataContextMutation(this.contexts.expedientSummary)
  }

  async fetchSummaryInfo() {
    this.showSpinner = true

    const endpoint: string = this.endpoints(this.entity(this.contexts.expedientContext).type).get

    await this.fetchSelectedRegisterDataAction({
      endpoint,
      context: this.contexts.expedientSummary,
      post: {
        idExpedient: this.selectedExpedientId,
        source: this.contexts.expedientSummary,
        idStage: this.expedientStageStateGetter
      }
    })

    this.showSpinner = false
  }

  renderIcon(index: number) {
    switch (index) {
      case 0:
        return this.icon.clock
      case 1:
        return this.icon.bill
      default:
        return this.icon.money
    }
  }
}
</script>

<style lang="scss" scoped>
.expedient-summary-widget {
  min-height: 306px;
  border-radius: $cornerRadius-md;
  border: 1px solid $main-300;
  min-height: 146px;

  .expedient-summary-widget-container {
    display: flex;
    align-items: center;
    gap: $spacing-xs;

    .expedient-info {
      display: flex;
      padding: $spacing-sm;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      flex: 1;
      background: $neutral-grey-050;

      .row-1 {
        display: flex;
        padding: $spacing-xxs 0px;
        align-items: center;
        align-content: center;
        gap: $spacing-xs $spacing-xxl;
        align-self: stretch;
        flex-wrap: wrap;

        .stage-container,
        .status-container {
          display: flex;
          align-items: center;
          gap: $spacing-xxs;

          .text {
            @include milano-bold-16;
            color: $neutral-grey-800;
            padding: 0 $spacing-xxs;
          }

          .stage-name,
          .status {
            @include arial-regular-12;
            display: flex;
            padding: $spacing-xxs $spacing-xs;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            color: $neutral-grey-800;
            text-align: center;
            text-overflow: ellipsis;
            border-radius: $cornerRadius-sm;
            background: $tertiary-byzantineBlue-050;
          }

          .status {
            background: $tertiary-seaGreen-050;
          }
        }
      }

      .info-container {
        display: flex;
        padding: $spacing-xxs 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: $spacing-md;
        align-self: stretch;

        .row-2,
        .row-3 {
          display: flex;
          padding: $spacing-xxs 0px;
          align-items: center;
          align-content: center;
          gap: $spacing-xs $spacing-xxl;
          align-self: stretch;
          flex-wrap: wrap;

          .info-item {
            display: flex;
            align-items: center;
            gap: $spacing-xxxs;
            .row-4 {
              display: flex;
              padding: $spacing-xxs 0px;
              align-items: center;
              align-content: center;
              gap: $spacing-xs $spacing-xxxs;
              align-self: stretch;
              flex-wrap: wrap;
            }
            .info-item-data,
            .info-item-data-large {
              @include ellipsis;
              @include milano-regular-16;
              color: $neutral-grey-800;
              min-width: 100px;
              max-width: 125px;
            }

            .info-item-data-large {
              max-width: 200px;
            }

            .lead-icon {
              color: $neutral-grey-800;
            }

            .info-item-label {
              @include milano-bold-16;
              color: $neutral-grey-800;
              padding: 0 $spacing-xxs;
            }
          }
        }
      }
    }

    .resumes-container {
      display: flex;
      width: 514px;
      align-items: flex-start;
      gap: 8px $spacing-xs;
      flex-wrap: wrap;
      height: 146px;

      .resume-item {
        display: flex;
        align-items: flex-start;
        width: 49%;
        height: 47%;
        gap: $spacing-xs;
        padding: $spacing-xs $spacing-sm;
        border-radius: $cornerRadius-sm;
        border: 1px solid $main-200;

        .resume-item-container {
          display: flex;
          flex-direction: column;

          .resume-item-title {
            @include milano-regular-14;
            color: $neutral-grey-800;
            margin-bottom: 4px;
          }

          .resume-item-qty {
            @include milano-bold-16;
            color: $main-1000;
          }
        }
      }
    }
  }
}

@include desktopSuperLarge {
  .expedient-summary-widget {
    .expedient-summary-widget-container {
      flex-direction: column;

      .expedient-info {
        width: 100%;
      }

      .resumes-container {
        width: 100%;
        height: auto;

        .resume-item {
          width: calc(25% - 7px);
        }
      }
    }
  }
}
</style>
