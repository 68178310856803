<template>
  <div class="not-found-container">
    <HeaderComponent />
    <section class="info-section">
      <img class="vector" src="@/assets/images/svg/not-found.svg" alt="Not found" />
      <div class="row-1">
        <span class="oops">{{ text.oops }}</span>
        <span class="something-wrong">{{ text.somethingWrong }}</span>
      </div>
      <div class="row-2">
        <span>{{ text.errorText }}</span>
      </div>
      <div class="row-3">
        <LfLabelButtonComponent :text="text.goToDashboard" :action="() => gotodashboard()" />
      </div>
      <div class="row-4">
        <span class="help-text">{{ text.needHelp }}</span>
        <div class="info-container">
          <div class="phone-container">
            <span :class="[icon.phone, 'icon']" />
            <span class="info-text">{{ text.phones }}</span>
          </div>
          <div class="email-container">
            <span :class="[icon.email, 'icon']" />
            <a class="info-text" href="mailto:clientes@lefebvre.es">{{ text.email }}</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>
import useRouter from '@/composables/useRouter'
import { useI18n } from 'vue-i18n-composable'
import { Icons } from '@/icons/icons'
import { URLS } from '@/router/routes/urlRoutes'
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import LfLabelButtonComponent from '@/components/forms/buttons/label/LfLabelButtonComponent.vue'

// TRANSLATIONS
const { t } = useI18n()
const text = {
  email: t('views.error_page.email'),
  errorText: t('views.error_page.error_text'),
  goToDashboard: t('views.error_page.go_to_dashboard'),
  needHelp: t('views.error_page.need_help'),
  oops: t('views.error_page.oops'),
  phones: t('views.error_page.phones'),
  somethingWrong: t('views.error_page.something_wrong')
}

// DATA
const icon = {
  email: Icons.EMAIL,
  phone: Icons.CALL
}

// ROUTER
const { goToRoute } = useRouter()

// METHODS
function gotodashboard() {
  goToRoute({
    name: URLS.DASHBOARD
  })
}
</script>

<style lang="scss" scoped>
.not-found-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .info-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .row-1 {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      color: $main-500;
      text-align: center;
      font-family: $corporate-font;
      font-size: 64px;
      font-style: normal;
      line-height: 72px;
      padding: $spacing-md 0px;
      margin-top: $spacing-xxl;

      .oops {
        font-weight: 700;
      }

      .something-wrong {
        font-weight: 400;
        margin-left: 8px;
      }
    }

    .row-2 {
      @include milano-regular-24;
      color: $neutral-grey-800;
      margin-top: $spacing-md;
    }

    .row-3 {
      @include flex;
      height: 68px;
      margin: $spacing-xxl;
    }

    .row-4 {
      display: flex;
      width: 425px;
      padding: $spacing-sm;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: $spacing-xs;
      border-radius: $cornerRadius-sm;
      background: $main-050;

      .help-text {
        @include milano-medium-20;
        color: $neutral-grey-800;
      }

      .info-container {
        @include flex;
        flex-direction: column;
        gap: $spacing-xxs;

        .icon {
          font-size: 22px;
          color: $neutral-grey-600;
        }

        .phone-container,
        .email-container {
          @include flex;
          @include milano-regular-16;
          padding: $spacing-nule;
          gap: $spacing-xs;
        }

        .phone-container .info-text {
          color: $neutral-grey-800;
        }

        .email-container .info-text {
          text-decoration: none;
          color: $tertiary-byzantineBlue-200;
        }
      }
    }
  }
}
</style>
