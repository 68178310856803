import { render, staticRenderFns } from "./PaginationComponent.vue?vue&type=template&id=44eb7f14&scoped=true&lang=pug"
import script from "./PaginationComponent.vue?vue&type=script&lang=ts"
export * from "./PaginationComponent.vue?vue&type=script&lang=ts"
import style0 from "./PaginationComponent.vue?vue&type=style&index=0&id=44eb7f14&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44eb7f14",
  null
  
)

export default component.exports