import { createElement } from '@syncfusion/ej2-base'
import { DateRangePicker } from '@syncfusion/ej2-vue-calendars'
import { i18n } from '@/plugins/vue-i18n'
import { dateValue, setParsedFilterSettingsFlag } from '@/components/grids/CustomFilters/sharedVariables'
import { IFilter } from '@syncfusion/ej2-grids'
import { Grid } from '@syncfusion/ej2-vue-grids'

let dateRangeInstance: DateRangePicker
let _gridInstance: Grid
let _gridElement: Grid

const init = (gridInstance: Grid, gridElement: Grid) => {
  _gridInstance = gridInstance
  _gridElement = gridElement
}

const customFilterDate: IFilter = {
  ui: {
    create: (args: any) => {
      if (!dateValue[args.column.field]) {
        dateValue[args.column.field] = _gridInstance.filterSettings
          .columns!.filter((item: any) => item.field === args.column.field)
          .map((item: any) => (item.value ? item.value : false))
      }
      let showInstance = false
      const parentHTMLElement = args.getOptrInstance.dropOptr.element.parentElement
      parentHTMLElement.parentElement.style.display = 'none'
      parentHTMLElement.parentElement.nextElementSibling.style.paddingTop = 0
      dateRangeInstance = new DateRangePicker({
        value: dateValue[args.column.field] ? dateValue[args.column.field] : undefined,
        format: 'dd/MM/yyyy',
        strictMode: true,
        showClearButton: false,
        serverTimezoneOffset: 2,
        change: (e: any) => {
          if (e.value) {
            dateValue[args.column.field] = e.value
            dateValue[args.column.field] = [e.startDate, e.endDate]
            _gridElement.filterByColumn(args.column.field, 'greaterthanorequal', dateValue[args.column.field][0])
          }
        },
        focus: (_e: any) => {
          if (showInstance) {
            dateRangeInstance.show()
          }
          showInstance = true
        },
        cleared: (_e: any) => {
          _gridInstance.clearFiltering([args.column.field])
          delete dateValue[args.column.field]
          setParsedFilterSettingsFlag(true)
        }
      })
      const flValInput = createElement('input', { className: 'flm-input' })
      flValInput.setAttribute('placeholder', i18n.t('components.grid_table.date_search').toString())
      args.target.appendChild(flValInput)
      dateRangeInstance.appendTo(flValInput)
    },
    write: (_args: any) => {},
    read: (_args: any) => {}
  }
}

export default function getCustomFilterDate(gridInstance: any, gridElement: Grid) {
  init(gridInstance, gridElement)
  return customFilterDate
}
