<template>
  <v-menu bottom left nudge-bottom="45" nudge-left="20" content-class="navigation-context-menu">
    <template v-slot:activator="{ on, attrs }">
      <div class="avatar" v-bind="attrs" v-on="on">
        <img class="avatar-svg" src="@/assets/images/svg/Avatar_Action.svg" alt="Avatar icon" />
        <span class="avatar-name">{{ userName.toLowerCase() }}</span>
      </div>
    </template>

    <v-list class="list">
      <v-list-item class="list-title">
        <div class="user-info-container">
          <span class="avatar-name">{{ userName.toLowerCase() }}</span>
          <span class="company-name">{{ companyName }}</span>
        </div>
      </v-list-item>
      <v-list-item v-if="!isPortalUser" class="list-item">
        <span class="sub-menu-activator" @click="redirect()">{{ text.account }} </span>
      </v-list-item>
      <v-list-item class="list-item" @click="showCloseSessionDialog">
        <span :class="icon.close" />
        <span>{{ text.closeSession }}</span>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { computed, onMounted } from 'vue'
import store from '@/store/store'
import { useI18n } from 'vue-i18n-composable'
import useRouter from '@/composables/useRouter'
import { Icons } from '@/icons/icons'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { DialogData, DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { resetTracker } from '@/plugins/tracker'

const authModule = ModuleNamespaces.AUTH
const dialogModule: string = ModuleNamespaces.DIALOG

// TRANSLATIONS
const { t } = useI18n()
const text = {
  account: t('navigation.avatar_menu.account'),
  cancel: t('action_buttons.cancel'),
  closeDialogText: t('navigation.avatar_menu.close_dialog_text'),
  closeSession: t('navigation.avatar_menu.close_session')
}

// DATA
const myAccountUrl = process.env.VUE_APP_AREA_PERSONAL_URL
const icon = {
  arrow: Icons.ANGLE_RIGHT,
  close: Icons.CLOSE_SESSION
}

// GETTERS
const userName = computed(() => store.getters[`${authModule}/getUserName`])
const companyName = computed(() => store.getters[`${authModule}/getCompanyName`])
const isPortalUser = computed(() => store.getters[`${authModule}/getIsPortalUser`])
const encryptedIdProUser = computed<string>(() => store.getters[`${authModule}/getEncryptedIdProUser`])

// METHODS
async function redirect() {
  await getEncryptIdProUser()
  window.open(`${myAccountUrl}${encryptedIdProUser.value}`, '_blank')
}

function closeSession() {
  resetTracker()
  store.dispatch(`${authModule}/logout`)
}

async function showCloseSessionDialog() {
  const dialogData: DialogData = {
    action: () => closeSession(),
    mainButtonText: text.closeSession,
    message: text.closeDialogText as string,
    notMoveToTop: true,
    secondaryButtonText: text.cancel,
    type: DialogTypes.INFO
  }
  await store.dispatch(`${dialogModule}/showDialog`, dialogData)
}

async function getEncryptIdProUser() {
  await store.dispatch(`${authModule}/encryptIdProUser`)
}
</script>

<style lang="scss" scoped>
@import '~@/components/Navigation/AppBarToolbarItems/contextMenus/styles/contextmenu.scss';

.navigation-context-menu {
  --menu-with: 240px;

  .user-info-container {
    display: flex;
    flex-direction: column;

    .avatar-name {
      @include ellipsis;
      text-transform: capitalize;
      max-width: 225px;
    }

    .company-name {
      @include milano-regular-16;
      @include ellipsis;
      color: $neutral-grey-800;
      padding: $spacing-xs 0;
      max-width: 200px;
    }
  }

  .list-title {
    height: 44px !important;
    min-height: 44px !important;
    max-height: 44px !important;
  }

  .sub-menu-activator {
    @include milano-medium-16;
    width: 100%;
    color: $main-1000;
  }
}

.avatar {
  @include flex;
  color: $neutral-grey-800;

  .avatar-name {
    @include milano-medium-16;
    @include ellipsis;
    color: $neutral-grey-800;
    margin-left: $spacing-xs;
    text-transform: capitalize;
    max-width: 220px;
  }
}
</style>
