<template lang="pug">

div(class="breadcrumbs-container")
  router-link(
      :to="dashboardRoute"
      class="go-dashoboard"
    )
    span(:class="[homeIcon, 'lf-icon__md']")
  span(class="'separator") >
  div(
    v-for="(item, index) in items"
    :key="index"
    :class="['breadcrumb', {'to-lower-case': item.lowerCase}]"
  )
    router-link(
      v-if="item.url"
      :to="item.url"
      class="breadcrumb-link"
    ) {{ item.text | replaceDash }}
    span(v-else class="breadcrumb-text") {{ item.text | replaceDash }}
    span(v-if="(index + 1) < numberOfItems" class="separator") >

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { URLS } from '@/router/routes/urlRoutes'

@Component({
  filters: {
    replaceDash(value: string) {
      return value.replace(/-/g, ' ')
    }
  }
})
export default class LexonBreadcrumbsComponent extends Vue {
  @Prop({
    type: Array
  })
  items!: []

  homeIcon = Icons.HOME

  get numberOfItems() {
    return this.items.length
  }

  get dashboardRoute() {
    return `/${URLS.DASHBOARD}`
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs-container {
  @include flex($justify-content: flex-start);
  width: 100%;
  height: 26px;
  color: $neutral-grey-800;
  gap: $spacing-xxs;

  .go-dashoboard {
    color: $neutral-grey-800;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    top: -2px;
  }

  .breadcrumb {
    @include flex;
    @include milano-regular-16;
    gap: $spacing-xxs;

    .breadcrumb-text {
      @include milano-regular-16;
      @include ellipsis;
      max-width: 300px;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .breadcrumb-link {
    color: $neutral-grey-800;
    text-decoration: none;
    margin-left: $spacing-xxs;

    &::first-letter {
      text-transform: capitalize;
    }

    ~ .separator {
      margin-left: $spacing-xxs;
    }
  }

  .separator {
    margin: 0 $spacing-xxs;
  }
}
</style>
