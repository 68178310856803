<template>
  <div :class="['login-form-container', { 'is-mobile': isMobileBreakpoint }]">
    <div class="wrapper">
      <div class="login-container">
        <header class="title-container">
          <p class="text" v-html="text.intro" />
        </header>
        <FormComponent />
      </div>
      <HelpContainerComponent class="help-container" />
    </div>
    <div class="img-degraded">
      <img v-if="isValid" alt="easter-egg" src="@/assets/images/spinner.webp" />
      <template v-else>
        <img class="vector" src="@/assets/images/svg/form-vector.svg" alt="Lefebvre vector" />
        <img class="image" src="@/assets/images/woman-image.png" alt="Lefebvre login" />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n-composable'
import FormComponent from '@/components/forms/loginForm/FormComponent.vue'
import useEasterEgg from '@/composables/useEasterEgg'
import HelpContainerComponent from '@/components/forms/loginForm/HelpInfoComponent.vue'
import { useVuetify } from '@/composables/useVuetify'

// TRANSLATIONS
const { t } = useI18n()
const text = {
  intro: t('views.login.intro'),
  product: t('views.login.product')
}

// COMPOSABLES
const { isMobileBreakpoint } = useVuetify()

const { isValid } = useEasterEgg({
  code: 'lexondevBetterCallSaul!'
})
</script>

<style lang="scss" scoped>
.login-form-container {
  display: flex;
  width: 870px;
  align-items: flex-start;
  gap: $spacing-xl;
  padding: $spacing-xl;

  .login-container {
    display: flex;
    width: 490px;
    flex-direction: column;
    align-items: flex-start;
    gap: $spacing-lg;
    flex-shrink: 0;
    border-radius: $cornerRadius-md;
    background: $neutral-white-000;
    box-shadow: $shadow-md;
    padding: $spacing-xl;
    margin-bottom: $spacing-xxl;

    .title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      align-self: stretch;

      .text {
        @include milano-regular-20;
        color: $main-1000;
        font-family: $milano-font;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        margin: 0;
      }
    }
  }

  .img-degraded {
    position: relative;

    .vector {
      position: absolute;
      left: 75px;
      top: 13px;
    }

    .image {
      position: absolute;
      top: -31px;
    }
  }

  &.is-mobile {
    width: 100%;
    padding: 0;

    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .login-container {
        width: 80%;
        max-width: 600px;
      }

      .help-container {
        width: 80%;
        max-width: 600px;
        align-self: center;
      }
    }

    .img-degraded {
      display: none;
    }
  }
}
</style>

<style lang="scss">
.lexon-app-title {
  @include milano-bold-24;
  color: $main-1000;
}
</style>
