import { Module } from 'vuex'
import { getters } from '@/store/modules/alerts/alertsGetters'
import { actions } from '@/store/modules/alerts/alertsActions'
import { mutations } from '@/store/modules/alerts/alertsMutations'
import { AlertsState } from '@/store/modules/alerts/alertsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: AlertsState = {
  showAlert: false,
  alertMode: null,
  alertData: null
}

const namespaced: boolean = true

export const alertsModule: Module<AlertsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
