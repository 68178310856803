import { MutationTree } from 'vuex'
import { ActionItem, DashboardWidgetsState, ListStatus } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import { CounterInfo } from '@/components/counters/types/CounterTypes'

export const mutations: MutationTree<DashboardWidgetsState> = {
  SET_COME_FROM_DASHBOARD_WIDGET_FLAG(state, status) {
    state.comeFromDashboardWidgetFlag = status
  },
  SET_ACTION_WIDGET_LIST(state, list: ActionItem []) {
    state.actionWidget.list = list
    state.actionWidget.status = list.length ? ListStatus.OK : ListStatus.EMPTY
  },
  SET_COUNTER_WIDGET_LIST(state, list: CounterInfo []) {
    state.counterWidget.list = list
    state.counterWidget.status = list.length ? ListStatus.OK : ListStatus.EMPTY
  },
  SET_ACTION_WIDGET_ERROR_STATUS(state) {
    state.actionWidget.list = []
    state.actionWidget.status = ListStatus.ERROR
  },
  SET_COUNTER_WIDGET_ERROR_STATUS(state) {
    state.counterWidget.list = []
    state.counterWidget.status = ListStatus.ERROR
  },
}
