<template>
  <div>
    <Grid
      v-if="gridConfiguration"
      :gridConfiguration="gridConfiguration"
      :itemData="expedients"
      :commandClick="onCommandClick"
      :contextMenuItems="contextMenuItems"
      @rowClick="onRowClick"
      @contextMenuClicked="onContextMenuClicked"
    ></Grid>
  </div>
</template>
<script lang="ts" setup>
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { computed } from 'vue'
import Grid from '@/components/grids/LfGrid/LfGridComponent.vue'
import { CommandClickEventArgs, CommandColumnModel, ContextMenuClickEventArgs, ContextMenuItemModel, RecordClickEventArgs } from '@syncfusion/ej2-vue-grids'
import { useI18n } from 'vue-i18n-composable'
import { ActionName } from '@/components/grids/LfGrid/LfGridTypes'
import useContactExpedients from './composables/useContactExpedients'
import { Icons } from '@/icons/icons'
import useGridConfiguration from '@/composables/useGridConfiguration'

const { t } = useI18n()

const props = defineProps({
  listName: {
    type: String,
    required: true,
    default: 'listContactExpedients'
  },
  context: {
    type: String,
    default: 'contactExpedients'
  }
})

const { expedients, read, open } = useContactExpedients({ listName: props.listName })

const texts = {
  buttons: {
    remove: t('action_buttons.remove').toString()
  },
  contextMenuItems: {
    edit: t('action_buttons.edit').toString(),
    open_new_window: t('action_buttons.open_new_window').toString(),
  }
}

const commandsBts: CommandColumnModel[] = [
  {
    title: t('action_buttons.open_new_window').toString(),
    type: 'None',
    buttonOption: {
      iconCss: Icons.OPEN_WINDOW,
      cssClass: 'custombutton'
    }
  }
]

// STORE ACTIONS ======================================================================================================
const { gridConfiguration } = useGridConfiguration({
  listName: props.listName,
  context: props.context,
  commandButtons: commandsBts
})

// STORE GETTERS ======================================================================================================
const selectedEntityName = computed(() => store.getters[`${ModuleNamespaces.ENTITIES}/getSelectedEntityName`])

const selectedRegisterId = computed(() =>
  store.getters[`${ModuleNamespaces.SELECTED_REGISTER}/getSelectedRegisterId`](selectedEntityName.value).toString()
)

// COMPUTEDS ==========================================================================================================
const contextMenuItems = computed((): ContextMenuItemModel[] => {
  return [
    {
      id: ActionName.EDIT,
      text: texts.contextMenuItems.edit,
      iconCss: Icons.EDIT
    },
    {
      id: ActionName.OPEN_NEW_TAB,
      text: texts.contextMenuItems.open_new_window,
      iconCss: Icons.OPEN_WINDOW
    }
  ]
})

// EVENTS =============================================================================================================
const onRowClick = (args: RecordClickEventArgs) => {
  const { rowData } = args
  const { idExpedient } = rowData as any
  open(idExpedient)
}

const onCommandClick = (args: CommandClickEventArgs) => {
  const { rowData } = args
  const { idExpedient } = rowData as any
  open(idExpedient, '_blank')
}

const onContextMenuClicked = ({ rowInfo, item }: ContextMenuClickEventArgs) => {
  const { rowData } = rowInfo as any
  const { idExpedient } = rowData as any
  const { id } = item
  if (id === ActionName.EDIT) {
    open(idExpedient)
    return
  }
  if (id === ActionName.OPEN_NEW_TAB) {
    open(idExpedient, '_blank')
  }
}

// HOOKS ==============================================================================================================
async function init() {
  await read(selectedRegisterId.value)
}

init()
</script>
