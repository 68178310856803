import { ActionTree } from 'vuex'
import { ConfigurationPanelState } from '@/store/modules/configurationPanel/configurationPanelTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { MainService } from '@/services/MainService'
import { AxiosError } from 'axios'


export const actions: ActionTree<ConfigurationPanelState, RootState> = {
  async fetchConfigurationPanel({}, url) {
    try {
      const { data } = await new MainService().getData(url)
      return data
    } catch (error) {}
  },
  async saveConfigurationPanel({}, { url, formData }) {
    try {
      const {data} = await new MainService().postData(url, formData)
      return data
    } catch (error) {}
  },
  async deleteConfigurationPanel({}, url) {
    try {
      const { data } = await new MainService().deleteData(url)
      return { success: data.result === 'OK'}
    } catch (error: any) {
      const { response } = error as AxiosError
      const { data } = response as any
      return { success: false, message: data?.exceptionDesc}
    }
  }

}


