export enum ExpedientIntervenerFields {
  ID_STAGE = 'idStage',
  ID_INTERVENER = 'idIntervener',
  INTERVENER_TYPE = 'intervenerType',
  OTHER_INTERVENER = 'idOtherCategory'
}

export const OTHER_INTERVENER_TYPE_ID = '8'

export interface ExpedientIntervener {
  id: string
  idIntervener: string
  idPersonType: string
  idOtherCategory: string
  name: string
}
