import { MutationTree } from 'vuex'
import { MenusState } from '@/store/modules/menus/menusTypes'

export const mutations: MutationTree<MenusState> = {
  FETCH_SITE_MENU(state, menuItems) {
    state.siteMenu = menuItems
  },
  FETCH_CONFIGURATION_MENU(state, menuItems) {
    state.configurationMenu = menuItems
  },
  FETCH_CLIENT_MENU(state, menuItems) {
    state.clientMenu = menuItems
  },
  TOOGLE_DRAWER_STATUS(state) {
    state.drawerInMiniStatus = !state.drawerInMiniStatus
  },
  TOOGLE_CONFIG_DRAWER_STATUS(state) {
    state.configurationDrawerstatus = !state.configurationDrawerstatus
  },
  CONFIG_DRAWER_STATUS(state, status) {
    state.configurationDrawerstatus = status
  },
  RENDER_NAVIGATION(state) {
    state.renderNavigation = true
  }
}
