import useFormSchema from '@/composables/useFormSchema'
import { RenderedForm } from '@/components/billing/InvoicesTab/types/InvoicesTabComponentTypes'

export default function usePartialbankdraftForm() {
  const partialbankdraftFormContext = RenderedForm.CUSTOMER_PARTIALBANKDRAFTS_FORM
  const { formSchema, getFormSchema } = useFormSchema({
    formAlias: partialbankdraftFormContext,
    context: partialbankdraftFormContext
  })
  const partialbankdraftFormSchema = formSchema
  const loadPartialbankdraftFormSchema = getFormSchema
  return {
    partialbankdraftFormContext,
    partialbankdraftFormSchema,
    loadPartialbankdraftFormSchema
  }
}
