<template lang="pug">

div
  LfHeaderTitleComponent(:title="$t('views.billing_provider_invoices.title')" class="header-title")
  LfTotalsComponent(
    v-if="totals && totals.length"
    :items="totals"
  )
  GridTable(
    v-if="renderGrid && !showSpinnerLayer"
    :itemsData="billingDataFormatted"
    :gridConfiguration="listConfig"
    :listName="listName"
    :toolbarOptions="toolbarOptionsCustom"
    :contextMenuItems="invoiceContextMenuItems"
    :context="context"
    :archivedFilter="archivedSelectedOption"
    :activeOnClickCell="selectedOnlyOneInvoice"
    :checkboxSelectColumn="hasCompanyTemplateInvoice"
    :showFirstColumn="!hasCompanyTemplateInvoice"
    :frozenColumns="isPortalUser ? 1 : 3"
    @contextMenuClicked="onContextMenuClicked"
    @gridTableFilter="onGridTableFilter"
    @toolbarClicked="onToolbarClicked"
    @selectedRecords="onSelectedRecords"
    @contextMenuBeforeOpen="onContextMenuBeforeOpen"
    @customToolbarContextMenuClick="onCustomToolbarContextMenuClick"
    ref="grid"
    excelExportedFileName="Facturas Proveedores"
  )

  ejs-contextmenu(
    :id="idContextMenu"
    class="context-menu-component e-contextmenu-archived"
    :items="invoicesArchivedContextMenuItemsParsed"
    :select="onInvoicesArchivedClick"
  )

  PrintOrSaveInvoicesDialog(
    :showDialog="showTemplateDialog"
    :idEntityType="idEntityType"
    @closePrintOrSaveInvoiceDialog="closeTemplateDialog"
    @generateTemplateDialog="generateTemplateDialog"
  )

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import GridTable from '@/components/grids/GridTable/GridTableComponent.vue'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ListViewMixin from '@/mixins/ListViewMixin.vue'
import ArchivedFilterMixin from '@/mixins/ArchivedFilterMixin.vue'
import { Action, Getter, Mutation } from 'vuex-class'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { BillingTypes, TotalItem } from '@/store/modules/billing/billingTypes'
import PrintInvoiceContextMenuMixin from '@/mixins/PrintInvoiceContextMenuMixin.vue'
import { entity } from '@/store/modules/entities/entitiesTypes'
import PrintOrSaveInvoicesDialog from '@/components/billing/PrintOrSaveInvoicesDialog/PrintOrSaveInvoicesDialog.vue'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { InvoiceGenerated, InvoiceStatus } from '@/store/modules/invoices/invoicesTypes'
import { MenuItem, MenuItemModel } from '@syncfusion/ej2-vue-navigations'
import { MAX_SELECTED_INVOICE_PRINT } from '@/components/billing/PrintOrSaveInvoicesDialog/types/PrintOrSaveInvoicesDialogTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import LfHeaderTitleComponent from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'
import LfTotalsComponent from '@/components/LfTotals/LfTotalsComponent.vue'
import { ActionName, CommandModel } from '@/components/grids/LfGrid/LfGridTypes'
import { Icons } from '@/icons/icons'
import { gridConfigurationWithActionColumn } from '@/helpers/grid'
import { URLS } from '@/router/routes/urlRoutes'
import {
  columnsChooserToolbarItem,
  filterToolbarItem,
  searchToolbarItem
} from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import { ContextMenuClickEventArgs } from '@syncfusion/ej2-vue-grids'
import { resetLoadedCustomMultiselectFlags } from '@/components/grids/CustomFilters'
import { InvoiceMaxNumberFormFields } from '@/mixins/InvoicesMaxNumberFormFieldsLogic/types/InvoicesMaxNumberFormFieldsLogicMixinTypes'

const billingModule: string = ModuleNamespaces.BILLING
const entitiesModule: string = ModuleNamespaces.ENTITIES
const invoicesModule = ModuleNamespaces.INVOICES
const authModule = ModuleNamespaces.AUTH

@Component({
  components: {
    GridTable,
    LfHeaderTitleComponent,
    LfTotalsComponent,
    PrintOrSaveInvoicesDialog
  }
})
export default class ProviderInvoicesView extends mixins(
  ListViewMixin,
  ArchivedFilterMixin,
  PrintInvoiceContextMenuMixin
) {
  @Action('fetchProviderInvoiceList', { namespace: billingModule })
  fetchProviderInvoiceList: (filter: {}) => Promise<void>

  @Action('fetchProviderInvoiceTotals', { namespace: billingModule })
  fetchProviderInvoiceTotals: (filter: {}) => Promise<void>

  @Action('saveSelectedIdEntityType', { namespace: entitiesModule })
  saveSelectedIdEntityType: (type: number) => void

  @Action('billingRemove', { namespace: billingModule })
  removeProviderInvoice: (url: string) => { success: boolean; message: string }

  @Getter('getTotals', { namespace: billingModule })
  totals: TotalItem[]

  @Getter('getCompanyTemplateInvoices', { namespace: authModule })
  hasCompanyTemplateInvoice: boolean

  @Mutation('ADD_GENERATE_INVOICE', { namespace: invoicesModule })
  addGenerateInvoice: (selectedInvoices: InvoiceGenerated[]) => void

  idContextMenu: string = 'invoicesArchivedContextMenuComponent'

  idEntity: number | string = 0
  idEntityType: number = entity.invoices.providers.type
  gridFilters: any = {}

  selectedInvoices = []

  contextMenuItemsText = {
    openNewWindow: this.$t('components.context_menu.open_window'),
    print: this.$t('action_buttons.print'),
    printOrSave: this.$t('components.context_menu.generate_invoice'),
    rectifyCustomerInvoice: this.$t('components.context_menu.rectify_customer_invoice')
  }

  get context() {
    return ContextName.BILLING
  }

  get listConfig() {
    const config = JSON.parse((this as any).listConfiguration['Config'])
    const index = config.columns.findIndex(({ editType }: any) => editType === 'booleanedit')
    if (index && index !== -1) {
      config.columns[index].visible = this.hasCompanyTemplateInvoice
    }
    const commandButtons: CommandModel[] = [
      {
        id: ActionName.OPEN_KEBAK_MENU,
        type: 'None',
        title: 'Open',
        buttonOption: {
          iconCss: Icons.KEBAB,
          cssClass: 'custombutton'
        }
      }
    ]
    const gridConfiguration = gridConfigurationWithActionColumn(config, commandButtons)
    return JSON.stringify(gridConfiguration)
  }

  get toolbarOptionsCustom() {
    const { filters } = this.toolbarOptionsTooltipTexts
    return [
      searchToolbarItem,
      filterToolbarItem,
      {
        id: 'add',
        text: this.$t('components.grid_table.tooltip.new_provider_invoice'),
        tooltipText: this.$t('components.grid_table.tooltip.new_provider_invoice'),
        cssClass: 'lf-btn-model2',
        align: 'Right'
      },
      columnsChooserToolbarItem,
      {
        id: ActionName.CUSTOM_TOOLBAR_CONTEXT_MENU,
        align: 'Right',
        cssClass: 'lf-btn-kebak',
        prefixIcon: Icons.KEBAB,
        contextMenuItems: [
          ...(!this.isPortalUser
            ? [
                {
                  id: ActionName.ARCHIVED,
                  iconCss: Icons.FILTER,
                  text: filters,
                  items: this.selectArchivedOption
                }
              ]
            : []),
          {
            id: ActionName.PRINT,
            iconCss: Icons.PRINT,
            text: this.$t('components.grid_table.tooltip.print').toString()
          },
          {
            id: ActionName.EXPORT,
            iconCss: Icons.DOWNLOAD,
            text: this.$t('components.grid_table.tooltip.export').toString()
          }
        ]
      }
    ]
  }

  get listName() {
    return (this as any).listConfiguration['Alias']
  }

  get selectedOnlyOneInvoice() {
    return this.selectedInvoices.length <= 1
  }

  get invoiceContextMenuItems() {
    const items = this.contextMenuItemsDefault
    const getPrintContextMenuItems = this.getPrintContextMenuItems(items)
    // Cambia el literal del menu por defecto de IMPRIMIR a IMPRIMIR/GUARDAR
    this.changePrintContextMenuLiteral(getPrintContextMenuItems)

    return getPrintContextMenuItems
  }

  changePrintContextMenuLiteral(contextMenuItems: MenuItem[]) {
    contextMenuItems.forEach((option: MenuItem) => {
      if (option.text === this.contextMenuItemsText.print) {
        option.iconCss = Icons.DOCUMENT_VALIDATE
        option.text = this.contextMenuItemsText.printOrSave as string
      }
    })
  }

  get filters() {
    return {
      ...this.gridFilters,
      archived: this.archivedSelectedOption
    }
  }

  async created() {
    await this.fetchCurrentListConfiguration(ListNames.PROVIDER_INVOICES)
    this.initializeArchivedSelectedOption({ selectedOption: 1, contextMenu: this.idContextMenu })
    await this.fetchGridItems()
    this.saveSelectedEntityName(BillingTypes.PROVIDER_INVOICES)
    this.renderGrid = true
    this.hideSpinnerLayerAction()
  }

  async fetchGridItems() {
    await this.fetchProviderInvoiceList({ archived: this.archivedSelectedOption })
  }

  onContextMenuClicked(args: any, selectedRegister: any) {
    if (args.item.text === this.contextMenuItemsText.printOrSave) {
      args.cancel = this.contextMenuPrintOrSave(selectedRegister)
    }
  }

  contextMenuPrintOrSave(selectedRegister: any) {
    if (this.selectedInvoices.length > MAX_SELECTED_INVOICE_PRINT) {
      this.showDialog({
        type: DialogTypes.WARNING,
        hideSecondaryButton: true,
        message: this.$t('components.dialog.exceeded_number_of_selected_invoices', {
          selectedInvoicesLength: this.selectedInvoices.length,
          maxInvoiceSelected: MAX_SELECTED_INVOICE_PRINT
        })
      })
      return true
    }
    if (this.hasCompanyTemplateInvoice) {
      this.idEntity = selectedRegister.id
      this.showTemplateDialog = true
      const selectedInvoices: InvoiceGenerated[] = this.selectedInvoices.map(({ idInvoice, numberInvoice }) => {
        return {
          idEntity: idInvoice,
          idEntityType: this.idEntityType,
          numberInvoice,
          status: InvoiceStatus.LOADING
        }
      })
      this.addGenerateInvoice(selectedInvoices)
    } else {
      this.printInvoiceOld(selectedRegister.id, entity.invoices.providers.alias)
    }
    return false
  }

  generateTemplateDialog(templateSelected: any) {
    this.printInvoice(templateSelected, this.idEntity, this.idEntityType, ComponentWhereIsRendered.GRID_TABLE)
  }

  onGridTableFilter(filters: any) {
    this.gridFilters = filters
    this.fetchProviderInvoiceTotals(this.filters)
  }

  onToolbarClicked(args: any) {
    const target = args.originalEvent.target.closest('button')
    if (target && target.id === 'archived') {
      this.toggleArchivedContextMenu(args.originalEvent, this.idContextMenu)
      args.cancel = true
    }
  }

  async onInvoicesArchivedClick(args: any) {
    this.archivedSelectedOption = args.item.actionType
    this.changeContextMenuIcons(args.item.actionType, this.idContextMenu)
    await this.saveConfig(this.archivedSelectedOption)
    await this.fetchGridItems()
    this.fetchProviderInvoiceTotals(this.filters)
  }

  onSelectedRecords(selectedItems: any) {
    this.selectedInvoices = Array.isArray(selectedItems) ? selectedItems : ([] as any)
  }

  onContextMenuBeforeOpen(contextMenuModule: any, contextMenuGridObject: any, _selectedRegister: any) {
    contextMenuGridObject.items.forEach((item: MenuItemModel) => {
      const enable = !(item.text !== this.contextMenuItemsText.printOrSave && !this.selectedOnlyOneInvoice)
      contextMenuModule.enableItems([item.text], enable)
      contextMenuGridObject.enableItems([item.text], enable)
    })
  }

  closeTemplateDialog() {
    this.showTemplateDialog = false
  }

  // onCommandClick({ commandColumn, rowData }: CommandClickEventArgs) {
  //   const action = commandColumn?.id
  //   const { id, description } = rowData as any
  //   console.log({ id, description, action, rowData })
  //   switch (action) {
  //     case ActionName.REMOVE:
  //       this.confirmDelete(id, description)
  //       break
  //     case ActionName.GENERATE_INVOICE:
  //       this.print(id)
  //       break
  //     case ActionName.OPEN_NEW_TAB:
  //       this.open(id, '_blank')
  //       break
  //   }
  // }

  open(id: string, target = '_self') {
    const routeData = this.$router.resolve({
      name: `${URLS.BILLING}-${URLS.PROVIDER_INVOICES}`,
      params: { selectedRegisterId: id }
    })
    if (routeData && target === '_blank') {
      window.open((routeData as any).href, '_blank')
    } else {
      this.$router.push((routeData as any).resolved)
    }
  }

  print(id: string) {
    if (this.hasCompanyTemplateInvoice) {
      this.idEntity = id
      this.showTemplateDialog = true
      const selectedInvoices: InvoiceGenerated[] = this.selectedInvoices.map(({ idInvoice, numberInvoice }) => {
        return {
          idEntity: idInvoice,
          idEntityType: this.idEntityType,
          numberInvoice,
          status: InvoiceStatus.LOADING
        }
      })
      this.addGenerateInvoice(selectedInvoices)
    } else {
      this.printInvoiceOld(id, entity.invoices.providers.alias)
    }
  }

  confirmDelete(id: string, name: string) {
    this.showDialogAction({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_register_text', {
        register: name,
        text: this.$t('components.dialog.provider_invoices_literal')
      }),
      action: async () => {
        await this.remove(id, name)
        ;(this as any).$refs.grid.refresh()
      },
      mainButtonText: this.$t('action_buttons.remove'),
      secondaryButtonText: this.$t('action_buttons.cancel')
    })
  }

  async remove(id: string, name: string) {
    const url = `invoices/providers/${id}/delete`
    const { success } = await this.removeProviderInvoice(url)
    if (success) {
      this.showDialogAction({
        type: DialogTypes.SUCCESS,
        message: this.$t('components.dialog.success_removed_register', {
          text: this.$t('components.dialog.provider_invoices_literal'),
          register: name
        })
      })
    } else {
      this.showDialogAction({
        type: DialogTypes.ERROR,
        message: this.$t('components.dialog.error_remove_provider_invoice')
      })
    }
  }

  onCustomToolbarContextMenuClick(args: ContextMenuClickEventArgs) {
    const { item } = args
    const { id } = item

    if (id?.includes(ActionName.ARCHIVED) && !this.isArchiveContextMenuFirstLevel(id)) {
      this.onInvoicesArchivedClick(args)
      return
    }
  }

  beforeDestroy() {
    resetLoadedCustomMultiselectFlags([InvoiceMaxNumberFormFields.SEQUENCE])
  }
}
</script>
