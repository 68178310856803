import { render, staticRenderFns } from "./LfInfoComponent.vue?vue&type=template&id=48b823db&scoped=true"
import script from "./LfInfoComponent.vue?vue&type=script&setup=true&lang=ts"
export * from "./LfInfoComponent.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./LfInfoComponent.vue?vue&type=style&index=0&id=48b823db&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48b823db",
  null
  
)

export default component.exports