<template lang="pug">

div(v-if="tabsData.length > 0")

  LexonBreadcrumbsComponent(:items="breadcrumbsItems")
  LfHeaderTitle(
    :title="getHeaderTitle" 
    class="header-title"
    :buttons="buttons"
    :executeAction="onExecuteAction"
  )

  TabsComponent(
    ref="tabsComponent"
    :tabs="tabsData"
    :context="this.context"
    :autoRefreshForm="autoRefreshForm"
    :entity="entity"
    :forceSaveProp="false"
    @changed-tab="onTabChange"
  )

</template>

<script lang="ts">
import { Vue, Prop, Component, Emit } from 'vue-property-decorator'
import { UsersMaintenanceTabs } from '@/components/maintenance/Users/types/UsersMaintenanceComponentTypes'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { Action, Getter, Mutation } from 'vuex-class'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import { MaintenanceEntityId } from '@/general/entityIds'
import LfHeaderTitle from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'
import { ActionBarButton } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'
import LexonBreadcrumbsComponent from '@/components/forms/fields/LexonBreadcrumbs/LexonBreadcrumbsComponent.vue'
import { URLS } from '@/router/routes/urlRoutes'

const configurationModule: string = ModuleNamespaces.CONFIGURATION
const formsModule: string = ModuleNamespaces.FORMS
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER
const usersModule: string = ModuleNamespaces.USERS

@Component({
  components: {
    TabsComponent: () => import('@/components/tabs/TabsComponent/TabsComponent.vue'),
    LexonBreadcrumbsComponent,
    LfHeaderTitle
  }
})
export default class UserManintenanceTabsComponent extends Vue {
  @Prop({
    type: String
  })
  headerTitle!: string 

  @Prop({
    default: {}
  })
  userData!: { id: string; name: string }

  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfiguration: ({}) => Promise<{}>

  @Action('savePermissions', { namespace: usersModule })
  savePermissions: () => void

  @Getter('checkIfFormIsValid', { namespace: formsModule })
  checkIfFormIsValid: (context: string) => boolean

  @Getter('getCurrentViewTabs', { namespace: configurationModule })
  getCurrentViewTabs: (context: string) => []

  @Getter('getRegisterFormData', { namespace: formsModule })
  getFormData: () => { name: string }

  @Getter('permissionsHaveChanged', { namespace: usersModule })
  permissionsHaveChanged: boolean

  @Mutation('FETCH_SELECTED_REGISTER_DATA', { namespace: selectedRegisterModule })
  saveSelectedRegisterData: ({}) => Promise<void>

  @Mutation('RESET_PERMISSIONS', { namespace: usersModule })
  resetPermissionsMutation: () => Promise<void>

  autoRefreshForm = false

  entity = {
    alias: 'maintenance',
    id: MaintenanceEntityId.MAINTENANCE
  }

  tabsData = []

  selectedTab = UsersMaintenanceTabs.GENERAL_DATA

  loadedTabsFlag: number | null = null

  texts = {
    buttons: {
      cancel: this.$t('action_buttons.cancel'),
      save: this.$t('action_buttons.save')
    }
  }

  get context() {
    return this.selectedTab === UsersMaintenanceTabs.GENERAL_DATA
      ? ContextName.MAINTENANCE_USERS_CONFIG
      : ContextName.MAINTENANCE_USERS_RELATED_FORM
  }

  get buttons(): ActionBarButton[] {
    return [
      {
        tooltip: this.texts.buttons.cancel,
        class: 'red-color',
        action: ActionName.CLOSE,
        enabled: true,
        variant: LabelButtonVariant.OUTLINED
      },
      {
        tooltip: this.texts.buttons.save,
        class: 'green-color',
        action: ActionName.SAVE,
        enabled:
          (this.checkIfFormIsValid(this.context) && this.selectedTab === UsersMaintenanceTabs.GENERAL_DATA) ||
          this.selectedTab === UsersMaintenanceTabs.CUSTOM_PERMISSIONS
      }
    ]
  }

  get getHeaderTitle() {
    return this.formData.name ?? this.headerTitle
  }

  get formData(): any {
    return {
      ...this.getFormData,
      id: this.userData.id
    }
  }

  get breadcrumbsItems() {
    return [
      {
        text: URLS.CONFIGURATION_PANEL_USUARIOS,
        url: `/${URLS.CONFIGURATION_PANEL_USUARIOS}`
      }
    ]
  }

  @Emit('closeTabsEvent')
  closeTabsEvent() {}

  @Emit('saveFormEvent')
  saveFormEvent(formData: object) {
    return formData
  }

  @Emit('saveOnFormTabChangeEvent')
  saveOnFormTabChangeEvent(formData: object) {
    return formData
  }

  @Emit('hideFatherComponentSpinnerEvent')
  hideFatherComponentSpinnerEvent() {}

  created() {
    this.init()
    this.saveSelectedRegisterData({ selectedRegisterData: this.userData, context: this.context })
  }

  beforeDestroy() {
    this.resetPermissionsMutation()
  }

  async init() {
    await this.fetchCurrentViewConfiguration({
      objectType: ConfigurationTypes.VIEW,
      alias: this.context,
      context: this.context
    })
    this.tabsData = this.getCurrentViewTabs(this.context)
    this.hideFatherComponentSpinnerEvent()
  }

  async save() {
    if (this.selectedTab === UsersMaintenanceTabs.CUSTOM_PERMISSIONS) {
      this.savePermissionsIfHaveChanged()
      this.$emit('closeTabsEvent')
      return
    }
    this.saveFormEvent(this.formData)
  }

  onTabChange(tabIndex: number, _tab: any) {
    // Realiza un SAVE del registro si sale de la tab del formulario
    if (this.loadedTabsFlag === 0) {
      this.saveSelectedRegisterData({ selectedRegisterData: this.formData, context: this.context })
      this.saveOnFormTabChangeEvent(this.formData)
    }
    this.savePermissionsIfHaveChanged()
    this.loadedTabsFlag = this.selectedTab = tabIndex
  }

  savePermissionsIfHaveChanged(): void {
    if (this.permissionsHaveChanged) {
      this.savePermissions()
    }
  }

  async onExecuteAction(actionName: string) {
    switch (actionName) {
      case ActionName.CLOSE:
        this.$emit('closeTabsEvent')
        break
      case ActionName.SAVE:
        this.save()
        break
      default:
        break
    }
  }
}
</script>
