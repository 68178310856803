import {
  ColumnModel as ColumnModelGrid,
  CommandClickEventArgs as CommandClickEventArgsGrid,
  CommandColumnModel,
  ContextMenuItemModel,
  PageSettingsModel,
  PdfExportProperties
} from '@syncfusion/ej2-vue-grids'
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export'
import { mttMilano } from '@/fonts/mtt-milano'
import { ClickEventArgs as ClickEventArgsGrid, ItemModel as ItemModelGrid } from '@syncfusion/ej2-vue-navigations'
import { Icons } from '@/icons/icons'
import { i18n } from '@/plugins/vue-i18n'
import { OpenMSOffice } from '@/components/FileManager/types/FileManagerTypes'

export interface ColumnModel extends ColumnModelGrid {
  cellIndex?: number
  sortDirection?: string
  templateName?: string
  filterModule?: string
  isCheckboxType?: boolean
}

export interface GridConfiguration {
  columns: ColumnModel[]
  pageSettings?: PageSettingsModel
  filterSettings?: any
  groupSettings?: any
  searchSettings?: any
  sortSettings?: any
  openMSOffice?: OpenMSOffice
}

export enum ActionName {
  ADD = 'add',
  PARTIALBANKDRAFT_ADD = 'partialbandraftAdd',
  ARCHIVED = 'archived',
  ASSOCIATE_ACTION = 'associateAction',
  CANCEL = 'cancel',
  CLOSE = 'close',
  COLUMNS_CHOOSER = 'ColumnChooser',
  CUSTOM_TOOLBAR_CONTEXT_MENU = 'customToolbarContextMenu',
  DELETE = 'delete',
  DOWNLOAD = 'download',
  DUPLICATE_EXPEDIENT = 'duplicateExpeient',
  EDIT = 'edit',
  EXPORT = 'export',
  EXPORT_SAGE = 'exportSage',
  FILTER = 'filter',
  GENERATE_INVOICE = 'generateInvoice',
  LINK = 'link',
  MOVE_ACTION = 'moveAction',
  OPEN = 'open',
  OPEN_IN_LOCATION = 'openInLocation',
  OPEN_KEBAK_MENU = 'openKebakMenu',
  OPEN_NEW_TAB = 'openNewTab',
  OPEN_WINDOW = 'openWindow',
  PRINT = 'print',
  RECTIFY_INVOICE = 'rectifyInvoice',
  REMOVE = 'remove',
  SAVE = 'save',
  SEARCH = 'Search',
  TITLE = 'title',
  UNLINK = 'unlink',
  DOWNLOAD_ORIGINAL_DOCUMENT = 'downloadOriginalDocument',
  OPEN_AUDIT = 'openAudit'
}

export interface CommandModel extends CommandColumnModel {
  id?: ActionName | 'string'
}

export enum commandButtonType {
  NONE = 'None'
}

export enum AlignItem {
  LEFT = 'Left',
  CENTER = 'Center',
  RIGHT = 'Right'
}

export interface CommandClickEventArgs extends CommandClickEventArgsGrid {
  commandColumn: CommandModel
}

export interface ItemModel extends ItemModelGrid {
  contextMenuItems?: ContextMenuItemModel[]
}

export interface ClickEventArgs extends ClickEventArgsGrid {
  item: ItemModel
}

export const columnActions: ColumnModel = {
  freeze: "Right",
  maxWidth: 60,
  headerText: "",
  allowResizing: false,
  allowEditing: false,
  allowSearching: false,
  allowReordering: false,
  textAlign: "Center",
  customAttributes: {
    class: "commands-columns"
  },
  showInColumnChooser: false
}

export const columnWidth = [
  0, 37, 60
]

export const getPdfExportProperties = (title: string): PdfExportProperties => {
  return {
    pageSize: 'A4',
    pageOrientation: 'Landscape',
    exportType: 'CurrentPage',
    fileName: title,
    header: {
      fromTop: 0,
      height: 30,
      contents: [
        {
          type: 'Text',
          value: title,
          position: { x: 0, y: 10 },
          style: { textBrushColor: '#000000', fontSize: 16 }
        }
      ]
    },
    theme: {
      caption: { font: new PdfTrueTypeFont(mttMilano, 12) },
      header: { font: new PdfTrueTypeFont(mttMilano, 10) },
      record: { font: new PdfTrueTypeFont(mttMilano, 9) }
    }
  }
}


export const rowContextMenu: CommandModel = {
  id: ActionName.OPEN_KEBAK_MENU,
  type: 'None',
  title: 'Open',
  buttonOption: {
    iconCss: Icons.KEBAB,
    cssClass: 'custombutton'
  }
}

export const removeButton: CommandModel = {
  id: ActionName.REMOVE,
  type: 'None',
  title: i18n.t('action_buttons.remove').toString(),
  buttonOption: {
    iconCss: Icons.REMOVE,
    cssClass: 'custombutton'
  }
}

export const openNewTabButton: CommandModel = {
  id: ActionName.OPEN_NEW_TAB,
  type: 'None',
  title: i18n.t('action_buttons.open_new_window').toString(),
  buttonOption: {
    iconCss: Icons.OPEN_WINDOW,
    cssClass: 'custombutton'
  }
}

export const pageSettingsDefault = {
  "pageSize": 10,
  "pageCount": 5,
  "pageSizes": [
    "5",
    "10",
    "25",
    "50"
  ],
  "currentPage": 1,
}


