<template lang="pug">

  div(
    v-if="showTabSelectorComponent && clonedItems && clonedItems.length"
    class="tab-selector-component"
  )
    button(
      :title="this.text.tooltip"
      :class="['tab-selector-button', buttonIcon]"
      @click="btnClick"
      :disabled="disabled"
    )
    ejs-contextmenu(
      id="tab-selector-contextmenu"
      class="tab-selector-contextmenu"
      :items="clonedItems"
      :beforeOpen="beforeOpen"
      :beforeClose="beforeClose"
    )

</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import { EJ2Instance } from '@syncfusion/ej2-kanban'
import { TabOption } from '@/store/modules/tabs/tabsTypes'
import { Vue, Component, Inject, Prop } from 'vue-property-decorator'
import { createCheckBox } from '@syncfusion/ej2-buttons'
import { createElement, closest } from '@syncfusion/ej2-base'
import { BeforeOpenCloseMenuEventArgs, MenuEventArgs } from '@syncfusion/ej2-vue-navigations'
import { Action, Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'

const tabsModule = ModuleNamespaces.TABS

@Component
export default class TabSelectorComponent extends Vue {
  @Inject({
    default: false
  })
  showTabSelectorComponent!: boolean

  @Prop({
    type: Number,
    default: 1
  })
  idEntity: number

  @Prop({
    type: Boolean
  })
  disabled: boolean
  @Getter('getTabSelectorOptions', { namespace: tabsModule })
  tabOptions: (idEntity: number) => TabOption[]

  @Action('fetchTabSelectorOptions', { namespace: tabsModule })
  fetchTabSelectorOptionsAction: (idEntity: number) => TabOption[]

  @Action('saveSelectedTabOptions', { namespace: tabsModule })
  saveSelectedTabOptionsAction: (data: { tabs: TabOption[] | null; idEntity: number }) => Promise<void>

  buttonIcon = Icons.CONFIGURATION

  text = {
    cancelBtn: this.$t('action_buttons.cancel') as string,
    selectBtn: this.$t('action_buttons.select') as string,
    tooltip: this.$t('components.tab_selector.selector_tooltip') as string,
    selectAllCheckbox: this.$t('action_buttons.select_all') as string
  }

  clonedItems: TabOption[] | null = null

  selectedOptions: number[] = []

  async created() {
    await this.fetchTabSelectorOptionsAction(this.idEntity)
    this.cloneItems()
  }

  cloneItems() {
    this.clonedItems = JSON.parse(JSON.stringify(this.tabOptions(this.idEntity)))
  }

  btnClick(event: PointerEvent) {
    const btn = event.target as HTMLElement
    const rect = btn.getBoundingClientRect()
    const contextMenu = document.getElementById('tab-selector-contextmenu')

    if (null !== contextMenu) {
      ;(contextMenu as EJ2Instance).ej2_instances[0].open(rect.top + 40, rect.left + 40)
      this.$emit('onTabSelectorOpen')
    }
  }

  itemRender(args: MenuEventArgs) {
    const element = args.element
    element.innerHTML = ''
  }

  async saveSelectedItems() {
    const data = {
      idEntity: this.idEntity,
      tabs: this.clonedItems
    }

    this.$emit('beforeSave', data)

    await this.saveSelectedTabOptionsAction(data)

    await this.fetchTabSelectorOptionsAction(this.idEntity)

    this.cloneItems()

    this.$emit('afterSave', data)
  }

  selectAll() {
    const checkBoxAll = document.getElementById('select-all-tabs')
    let checked = false
    if (checkBoxAll) {
      checkBoxAll.querySelector('.e-checkbox-wrapper .e-frame')?.classList.toggle('e-check')
      checked = checkBoxAll.querySelector('.e-checkbox-wrapper .e-check') !== null
    }
    const checkBoxes = document.querySelectorAll('.e-checkbox-wrapper .e-frame')
    checkBoxes.forEach((checkbox: any) => {
      if (checkbox.parentNode.id !== 'select-all-tabs') {
        if (checked && !checkbox.classList.contains('e-check')) {
          checkbox.classList.add('e-check')
        } else if (!checked && checkbox.classList.contains('e-check')) {
          checkbox.classList.remove('e-check')
        }
      }
    })

    this.clonedItems!.map((item) => {
      item.value = checked ? 1 : 0
      return true
    })
  }

  updateSelectAllCheck(event: any) {
    const checkBoxAll = document.getElementById('select-all-tabs')
    if (!checkBoxAll) {
      return
    }

    if (event.target.classList.contains('e-menu-item')) {
      return
    }

    if (this.allTabsSelected(event)) {
      if (!checkBoxAll.querySelector('.e-checkbox-wrapper .e-frame')?.classList.contains('e-check')) {
        checkBoxAll.querySelector('.e-checkbox-wrapper .e-frame')?.classList.add('e-check')
      }
    } else {
      if (checkBoxAll.querySelector('.e-checkbox-wrapper .e-frame')?.classList.contains('e-check')) {
        checkBoxAll.querySelector('.e-checkbox-wrapper .e-frame')?.classList.remove('e-check')
      }
    }
  }

  allTabsSelected(_event: any): boolean {
    let selected = true
    const target = _event.target.parentNode.querySelector('.e-frame')

    if (target && target.classList.contains('e-check')) {
      // El propio elemento se ha deseleccioando (ya que todavía tiene e-check), así que devolvemos false
      return false
    }

    target.itsMe = true

    const selectedElem: NodeList = document.querySelectorAll(
      '#tab-items-container > .e-menu-item > .e-checkbox-wrapper > .e-frame '
    )

    selectedElem.forEach((item: Node, _key: number) => {
      if (!(item as any).itsMe && !(item as any).classList.contains('e-check')) {
        selected = false
      }
      if ((item as any).itsMe) {
        delete (item as any).itsMe
      }
    })

    return selected
  }

  createNewItemsDOMElement(args: BeforeOpenCloseMenuEventArgs) {
    const itemsContainer = createElement('div', { className: 'items-container', attrs: { id: 'tab-items-container' } })
    args.items.forEach((item: any) => {
      const itemFinal = createElement('span', { className: 'e-menu-item', attrs: { id: item.id } })
      const check = createCheckBox(createElement, false, {
        label: item.label,
        checked: item.value
      })
      itemFinal.appendChild(check)
      itemFinal.addEventListener('click', (event) => {
        itemFinal.classList.add('item-selected')
        this.updateSelectAllCheck(event)
      })

      if (item.value) {
        const mainNode = itemFinal.closest('items-container')
        if (mainNode) {
          mainNode.classList.add('selected')
        }
      }
      itemsContainer.appendChild(itemFinal)
    })

    return itemsContainer
  }

  createSelectAllDOMElement() {
    const itemsContainer = createElement('div', { className: 'select-all-container' })
    const itemFinal = createElement('span', { className: 'e-menu-item', attrs: { id: 'select-all-tabs' } })
    const checked = this.clonedItems !== null && !this.clonedItems.some((item) => item.value === 0)
    const check = createCheckBox(createElement, false, {
      label: this.$t('action_buttons.select_all').toString(),
      checked
    })
    itemFinal.appendChild(check)
    itemFinal.addEventListener('click', () => {
      this.selectAll()
    })
    itemsContainer.appendChild(itemFinal)

    return itemsContainer
  }

  ceateButtonsDOMElement() {
    const buttonsContainer = createElement('div', { className: 'buttons-container e-menu-item' })
    const cancelButton = createElement('button', { className: 'cancel-btn', innerHTML: this.text.cancelBtn })
    const selectButton = createElement('button', { className: 'select-btn', innerHTML: this.text.selectBtn })
    selectButton.addEventListener('click', () => {
      this.saveSelectedItems()
    })
    buttonsContainer.appendChild(cancelButton)
    buttonsContainer.appendChild(selectButton)

    return buttonsContainer
  }

  beforeOpen(args: BeforeOpenCloseMenuEventArgs) {
    args.element.appendChild(this.createSelectAllDOMElement())
    args.element.appendChild(this.createNewItemsDOMElement(args))
    args.element.appendChild(this.ceateButtonsDOMElement())

    const mainNodeList = document.querySelectorAll('#tab-items-container .e-menu-item')

    if (mainNodeList === null) {
      return null
    }

    mainNodeList.forEach((item: Element) => {
      if (item.querySelector('.e-check')) {
        item.classList.add('selected')
      }
    })
  }

  checkOrUncheckItem(id: string, status: 0 | 1) {
    this.clonedItems!.forEach((item) => {
      if (item.id === Number(id)) {
        item.value = status
      }
    })
  }

  beforeClose(args: any) {
    if (
      args.event.target.closest('.e-menu-item') &&
      !args.event.target.classList.contains('cancel-btn') &&
      !args.event.target.classList.contains('select-btn')
    ) {
      args.cancel = true
      const selectedElem = args.element.querySelectorAll('.item-selected')
      selectedElem.forEach((_elem: HTMLElement, index: number) => {
        const ele = selectedElem[index]
        ele.classList.remove('item-selected')
      })
      const checkbox = closest(args.event.target, '.e-checkbox-wrapper')
      const frame = checkbox && checkbox.querySelector('.e-frame')

      if (selectedElem[0] === undefined) {
        return
      }

      const selectedElemId = selectedElem[0].id
      const parent = selectedElem[0].closest('.e-menu-item')

      if (checkbox && frame && frame.classList.contains('e-check')) {
        frame.classList.remove('e-check')
        const index = this.selectedOptions.indexOf(Number(selectedElemId))
        if (index > -1) {
          this.selectedOptions.splice(index, 1)
        }
        this.checkOrUncheckItem(selectedElemId, 0)
        parent.classList.remove('selected')
      } else if (checkbox && frame) {
        frame.classList.add('e-check')
        this.selectedOptions.push(Number(selectedElemId))
        this.checkOrUncheckItem(selectedElemId, 1)
        parent.classList.add('selected')
      }
    } else {
      this.cloneItems()
      args.element.removeChild(args.element.querySelector('.select-all-container'))
      args.element.removeChild(args.element.querySelector('.buttons-container'))
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-selector-component {
  .tab-selector-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 36px;
    font-size: 22px;
    color: $corporate-color;
  }
  .tab-selector-button[disabled='disabled'] {
    color: $gray-02;
  }
}
</style>

<style lang="scss">
.tab-selector-contextmenu {
  width: 258px !important;
  padding: 0 0 10px !important;

  > .e-menu-item:not(.buttons-container) {
    display: none !important;
  }

  .select-all-container {
    border-bottom: 1px solid $gray-03;
    .e-check {
      width: 18px;
      &::before {
        content: '\e938' !important;
        font-size: 12px;
      }
    }
    .e-selectall.e-uncheck {
      width: 20px;
    }
  }

  .select-all-container,
  .items-container {
    @include scroll-styles;
    max-height: 310px;
    overflow-y: auto;
    margin-bottom: 10px;

    .e-menu-item {
      padding: 0 !important;
      padding-left: 10px !important;
    }
  }

  .items-container {
    .e-menu-item {
      &.selected {
        background-color: $blue-07;
      }
    }
  }

  .e-checkbox-wrapper {
    width: 100%;
  }

  .e-checkbox-wrapper .e-frame.e-check,
  .e-css.e-checkbox-wrapper .e-frame.e-stop,
  .e-checkbox-wrapper .e-frame.e-check,
  .e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: $corporate-color;
    border-color: transparent;
    color: $white-01;
  }

  .e-checkbox-wrapper .e-check::before,
  .e-css.e-checkbox-wrapper .e-stop::before,
  .e-checkbox-wrapper .e-check::before,
  .e-css.e-checkbox-wrapper .e-check::before {
    font-family: $lf-icons-font;
    content: '\e92e';
  }

  .e-checkbox-wrapper .e-check::before,
  .e-css.e-checkbox-wrapper .e-stop::before {
    content: '';
  }

  .buttons-container.e-menu-item {
    @include border($direction: top);
    position: sticky;
    bottom: 0;
    justify-content: space-between !important;
    background-color: $white-01 !important;
    padding: 10px 10px 0 10px !important;

    .cancel-btn {
      @include secondary-action-button--rectangle;
      margin: 0;
      width: 50%;
    }

    .select-btn {
      @include main-action-button--rectangle;
      width: 50%;
    }
  }
}
</style>
