import ActionsView from '@/views/actions/Actions/ActionsView.vue'
import ActionsPanelView from '@/views/actions/ActionsPanel/ActionsPanelView.vue'
import AllActionsView from '@/views/actions/AllActions/AllActionsView.vue'
import { URLS } from '@/router/routes/urlRoutes'
import { ContextName } from '@/store/types/storeGlobalTypes'
import ActionDetailView from '@/views/actions/Actions/ActionDetailView.vue'
import { ConfigurationAlias, ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import { ActionTypes } from '@/store/modules/actions/actionsTypes'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { ActionEntityId } from '@/general/entityIds'

export const actionsRoutes = [
  {
    path: `/${URLS.ACTIONS}`,
    redirect: { name: URLS.ACTION_ALL },
    name: URLS.ACTIONS,
    component: ActionsView,
    meta: {
      title: URLS.ACTIONS,
      requiresAuth: true,
      entityType: ActionEntityId.ALL
    },
    children: [
      {
        path: `${URLS.ACTION_ALL}`,
        name: URLS.ACTION_ALL,
        component: AllActionsView,
        meta: {
          title: URLS.ACTION_ALL,
          parentUrl: URLS.ACTIONS,
          requiresAuth: true,
          entityType: ActionEntityId.ALL
        },
        props: () => ({
          context: ContextName.ACTION,
          listNames: ListNames.ACTION_ALL,
          actionType: ActionTypes.ALL,
          actionMenu: ActionTypes.ALL
        })
      },
      {
        path: `${URLS.NEW}`,
        name: `${URLS.ACTIONS}-${URLS.NEW}`,
        component: ActionDetailView,
        props: () => ({
          menuName: URLS.ACTIONS,
          context: ContextName.ACTION,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.ACTION
          },
          actionType: ActionTypes.ALL,
          entityType: ActionEntityId.ALL,
          entityId: 0
        }),
        meta: {
          parentUrl: URLS.ACTIONS,
          title: URLS.NEW,
          requiresAuth: true,
          entityType: ActionEntityId.ALL
        }
      },
      {
        path: `${URLS.CALLS}/:selectedRegisterId`,
        name: `${URLS.ACTIONS}-${URLS.CALLS}`,
        component: ActionDetailView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.ACTIONS,
          context: ContextName.ACTION,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.ACTION
          },
          actionType: ActionTypes.CALLS,
          entityType: ActionEntityId.CALL,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.ACTIONS,
          title: URLS.ACTION_CALLS,
          entityType: ActionEntityId.CALL,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.MEETINGS}/:selectedRegisterId`,
        name: `${URLS.ACTIONS}-${URLS.MEETINGS}`,
        component: ActionDetailView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.ACTIONS,
          context: ContextName.ACTION,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.ACTION
          },
          actionType: ActionTypes.MEETINGS,
          entityType: ActionEntityId.MEETING,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.ACTIONS,
          title: URLS.ACTION_MEETINGS,
          entityType: ActionEntityId.MEETING,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.PROCEDURES}/:selectedRegisterId`,
        name: `${URLS.ACTIONS}-${URLS.PROCEDURES}`,
        component: ActionDetailView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.ACTIONS,
          context: ContextName.ACTION,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.ACTION
          },
          actionType: ActionTypes.PROCEDURES,
          entityType: ActionEntityId.PROCEDURE,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.ACTIONS,
          title: URLS.ACTION_PROCEDURES,
          entityType: ActionEntityId.PROCEDURE,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.ACTION_OTHERS}/:selectedRegisterId`,
        name: `${URLS.ACTIONS}-${URLS.ACTION_OTHERS}`,
        component: ActionDetailView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.ACTIONS,
          context: ContextName.ACTION,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.ACTION
          },
          actionType: ActionTypes.OTHERS,
          entityType: ActionEntityId.OTHER,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.ACTIONS,
          title: URLS.ACTION_OTHERS,
          entityType: ActionEntityId.OTHER,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.ACTION_LEXNET}/:selectedRegisterId`,
        name: `${URLS.ACTIONS}-${URLS.ACTION_LEXNET}`,
        component: ActionDetailView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.ACTIONS,
          context: ContextName.ACTION,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.ACTION
          },
          actionType: ActionTypes.LEXNET,
          entityType: ActionEntityId.LEXNET,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.ACTIONS,
          title: URLS.ACTION_LEXNET,
          entityType: ActionEntityId.LEXNET,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.ACTION_EMAILS}/:selectedRegisterId`,
        name: `${URLS.ACTIONS}-${URLS.ACTION_EMAILS}`,
        component: ActionDetailView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.ACTIONS,
          context: ContextName.ACTION,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.ACTION
          },
          actionType: ActionTypes.EMAILS,
          entityType: ActionEntityId.EMAIL,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.ACTIONS,
          title: URLS.ACTION_EMAILS,
          entityType: ActionEntityId.EMAIL,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.ACTIONS_PANEL}`,
        name: URLS.ACTIONS_PANEL,
        component: ActionsPanelView,
        meta: {
          title: URLS.ACTIONS_PANEL,
          parentUrl: URLS.ACTIONS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.ACTION_TASKS}/:selectedRegisterId`,
        name: `${URLS.ACTIONS}-${URLS.ACTION_TASKS}`,
        component: ActionDetailView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.ACTIONS,
          context: ContextName.ACTION,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.ACTION
          },
          actionType: ActionTypes.TASK,
          entityType: ActionEntityId.TASK,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.ACTIONS,
          title: URLS.ACTION_TASKS,
          entityType: ActionEntityId.TASK,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.ACTION_INTERNAL_MANAGEMENT}/:selectedRegisterId`,
        name: `${URLS.ACTIONS}-${URLS.ACTION_INTERNAL_MANAGEMENT}`,
        component: ActionDetailView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.ACTIONS,
          context: ContextName.ACTION,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.ACTION
          },
          actionType: ActionTypes.INTERNAL_MANAGEMENT,
          entityType: ActionEntityId.GEST,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.ACTIONS,
          title: URLS.ACTION_INTERNAL_MANAGEMENT,
          entityType: ActionEntityId.GEST,
          requiresAuth: true
        }
      }
    ]
  }
]
