<template lang="pug">

div(class="lex-on-app")
  v-app(full-height)
    SpinnerComponent
    SpinnerLayerComponent(
      v-if="showSpinnerLayer"
      class="spinner-layer-component"
    )
    DialogComponent
    CustomDialogComponent
    v-main(:class="{'remove-padding': removeMainPadding }")
      div(class="alerts-container")
        AlertComponent(
          :whereIsRendered="componentWhereIsRendered"
        )
      v-container(fluid pa-0 class="app-container")
        NavigationContainerComponent(
          v-if="!hideNavigation && renderNavigationGetter"
          :isFooterVisible="isFooterVisible"
          :drawerHeight="drawerHeight"
        )
        router-view(:key="ckeckIfReloadURL")
    LefebvreFooterComponent(v-show="isDashboardView" ref="footer")

</template>

<script lang="ts">
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { to } from '@/router/types/routerTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { Getter, Mutation } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import CustomDialogComponent from '@/components/Dialog/CustomDialog/CustomDialogComponent.vue'
import DialogComponent from '@/components/Dialog/DialogComponent.vue'
import LefebvreFooterComponent from '@/components/Footer/LefebvreFooterComponent.vue'
import NavigationContainerComponent from '@/components/Navigation/NavigationContainer/NavigationContainerComponent.vue'
import SpinnerComponent from '@/components/Spinner/SpinnerComponent.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import AlertComponent from '@/components/Alert/AlertComponent.vue'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'

const expedientsModule = ModuleNamespaces.EXPEDIENTS
const spinnerModule = ModuleNamespaces.SPINNER
const menusModule = ModuleNamespaces.MENUS

@Component({
  components: {
    AlertComponent,
    CustomDialogComponent,
    DialogComponent,
    NavigationContainerComponent,
    SpinnerComponent,
    SpinnerLayerComponent,
    LefebvreFooterComponent
  }
})
export default class App extends Vue {
  @Getter('getDuplicateExpedientId', { namespace: expedientsModule })
  duplicateExpedientId: number

  @Getter('getShowSpinnerLayer', { namespace: spinnerModule })
  showSpinnerLayer: boolean

  @Getter('getDrawerStatus', { namespace: menusModule })
  drawerInMiniStatus: boolean

  @Getter('getRenderNavigation', { namespace: menusModule })
  renderNavigationGetter: boolean

  @Mutation('RENDER_NAVIGATION', { namespace: menusModule })
  renderNavigationMutation: () => []

  isFooterVisible = false

  componentWhereIsRendered = ComponentWhereIsRendered.MAIN

  scrollListenerAdded = false

  drawerHeight = '100%'

  @Watch('showSpinnerLayer')
  async checkFooterStatus() {
    if (this.isDashboardView) {
      await this.$nextTick()
      this.handleScroll()
    } else {
      this.drawerHeight = '100%'
    }
  }

  @Watch('$route')
  async onRouteChange(to: to) {
    if (to.name !== URLS.LOGIN && this.renderNavigationGetter === false) {
      this.renderNavigationMutation()
    }
  }

  get hideNavigation(): boolean {
    return [
      URLS.AGENDA,
      URLS.EMAIL,
      `${URLS.EMAIL}-${URLS.EDIT}`,
      `${URLS.EMAIL}-${URLS.ACTIONS}`,
      `${URLS.EMAIL}-${URLS.NEW}`,
      URLS.DOUBLE_OPTIN,
      URLS.LEXNET,
      URLS.LOGIN,
      URLS.NAVISION_LOGIN,
      URLS.NOT_FOUND_PAGE,
      URLS.REMEMBER_PASSWORD,
      URLS.SELECT_COMPANY,
      `${URLS.ANOTHER_APPLICATIONS}-${URLS.SSO_PAYMENTS}`,
      `${URLS.ANOTHER_APPLICATIONS}-${URLS.SSO_UNPAID}`,
      URLS.ONLINE,
      // TODO FIGMA: Eliminar rutas temporales
      '/label-buttons',
      '/icon-buttons',
      '/counters'
    ].includes(this.$route.name!)
  }

  get ckeckIfReloadURL() {
    if (
      this.$route.name === `${URLS.EXPEDIENTS}-${URLS.EXPEDIENTS}` &&
      (this.$route.query.action || this.duplicateExpedientId)
    ) {
      return Number(this.$route.query)
    }
    return 0
  }

  get isDashboardView() {
    return this.$route.name === URLS.DASHBOARD
  }

  get isViewWithoutPadding() {
    const views = [URLS.LOGIN, URLS.SELECT_COMPANY]
    return views.includes(this.$route.name!)
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown
  }

  get removeMainPadding() {
    return this.isViewWithoutPadding && this.isMobile
  }

  updated() {
    this.updateScrollListener()
  }

  updateScrollListener() {
    if (this.isDashboardView) {
      if (!this.scrollListenerAdded) {
        window.addEventListener('scroll', this.handleScroll)
        this.scrollListenerAdded = true
      }
    } else {
      this.isFooterVisible = false
      if (this.scrollListenerAdded) {
        window.removeEventListener('scroll', this.handleScroll)
        this.scrollListenerAdded = false
      }
    }
  }

  handleScroll() {
    const footer = this.$refs.footer

    if (footer && this.isDashboardView) {
      const footerRect = (footer as any).$el.getBoundingClientRect()
      this.isFooterVisible = footerRect.top < window.innerHeight
      // Calculamos la altura del menú dinamicamente
      this.isFooterVisible ? (this.drawerHeight = `${footerRect.top}px`) : (this.drawerHeight = '100%')
    }
  }
}
</script>

<style lang="scss">
@import '~@syncfusion/ej2-base/styles/material.css';
@import '~@syncfusion/ej2-buttons/styles/material.css';
@import '~@syncfusion/ej2-calendars/styles/material.css';
@import '~@syncfusion/ej2-dropdowns/styles/material.css';
@import '~@syncfusion/ej2-inputs/styles/material.css';
@import '~@syncfusion/ej2-navigations/styles/material.css';
@import '~@syncfusion/ej2-popups/styles/material.css';
@import '~@syncfusion/ej2-splitbuttons/styles/material.css';
@import '~@syncfusion/ej2-vue-grids/styles/material.css';
@import '~@syncfusion/ej2-vue-richtexteditor/styles/material.css';

html.overflow-y-hidden {
  overflow-y: auto !important;
}

.lex-on-app .theme--light.v-application,
body {
  background-color: $neutral-white-000;
  font-family: $secondary-font;
  color: $gray-01;
  width: 100vw;
}

.v-main {
  min-height: calc(100vh - 120px);
}

.app-container {
  display: flex;
  height: 100%;
}

.v-application--wrap {
  z-index: 201;
  min-height: 100%;
}

.popover {
  z-index: 9999;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $neutral-grey-800;
  box-shadow: 0 0 1px 1000px $white-01 inset;
  -webkit-box-shadow: 0 0 1px 1000px $white-01 inset;
  transition: none;
}
input:-webkit-autofill:hover {
  box-shadow: 0 0 1px 1000px $white-01 inset;
}

.stage-selector-input {
  .v-input__append-inner {
    margin-top: 4px;
  }

  .v-menu__content.menuable__content__active.v-autocomplete__content {
    top: 40px !important;
  }
}

.lexon-view-container {
  width: 100%;
  padding: 120px 30px 15px;
}

.spinner-layer-component {
  --spinner-layer-min-height: 100vh;
  --spinner-layer-position: absolute;
}

.alerts-container {
  margin-top: 65px;
  margin-left: 25px;
  width: 85%;
  z-index: 999999;
  position: fixed;
  width: calc(100% - 300px);
}

.hidden-contextmenu-option {
  display: none !important;
}

@include tabletAndMore {
  .lexon-view-container {
    padding-top: $app-bar-height + 14px;
  }
}

@media (max-width: 960px) {
  .v-main:not(.remove-padding) {
    padding-left: 53px !important;
  }
}
</style>
