import { ActionTree } from 'vuex'
import {
  alertsConfigurationMutationTypes,
  AlertsConfigurationState
} from '@/store/modules/alertsConfiguration/alertsConfigurationTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { MainService } from '@/services/MainService'

export const actions: ActionTree<AlertsConfigurationState, RootState> = {
  async fetchAlertsConfiguration({ commit }) {
    const { data } = await new MainService().getData(`v2/alert-configuration`)
    commit(alertsConfigurationMutationTypes.UPDATE_ALERTS_CONFIGURATION, data)
  },
  async updateAlertsConfiguration(_context, data ) {
    const formData: FormData = new FormData()
    formData.append('json', JSON.stringify(data))
    await new MainService().putFormData(`v2/alert-configuration`, formData)
  }
}
