<template>
  <div class="item-container" @click="goToAction">
    <div class="item">
      <div class="info-1">
        <LexonTooltipComponent
          v-if="isTruncated"
          :message="action.subject"
          position="top"
          class="tooltip-activator caso1"
          isWidthAuto
        >
          <div slot="activator-element" class="row-1">
            <span :class="[icon, 'lf-icon__md']"></span>
            <span class="name">{{ action.subject }}</span>
          </div>
        </LexonTooltipComponent>
        <div v-else class="row-1 caso2" ref="textContainerElement">
          <span :class="[icon, 'lf-icon__md']"></span>
          <span class="name" ref="textElement">{{ action.subject }}</span>
        </div>
        <div class="row-2">
          <div class="description-container">
            <span class="description">{{ action.description ?? '-' }}</span>
          </div>
          <span class="name">{{ action.expedientName ?? text.noExpedient }}</span>
        </div>
      </div>
      <div class="info-2">
        <div class="text-container">
          <span v-show="action.customerName" class="text">{{ action.customerName }} </span>
        </div>
        <v-spacer />
        <span v-show="action.dueDate" class="date">{{ renderDate(action.dueDate) }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, PropType, ref, onMounted } from 'vue'
import { ActionTypes, checkActionIcon, getActionRouteNameByAction } from '@/store/modules/actions/actionsTypes'
import useRouter from '@/composables/useRouter'
import { changeDateFormat } from '@/helpers/dateTime'
import { useI18n } from 'vue-i18n-composable'
import { ActionItem } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import LexonTooltipComponent from '@/components/forms/fields/LexonTooltip/LexonTooltipComponent.vue'

const { action } = defineProps({
  action: {
    type: Object as PropType<ActionItem>,
    required: true
  }
})

// TRANSLATIONS
const { t } = useI18n()

const text = {
  noExpedient: t('components.widgets.actions.no_expedient'),
  end: t('components.widgets.actions.end')
}

// ROUTER
const router = useRouter()

// DATA
// Referencias para comprobar si se renderiza una elipsis en el nombre
const textContainerElement = ref<Element | null>(null)
const textElement = ref<Element | null>(null)
const isTruncated = ref(false)

// COMPUTED
const icon = computed(() => {
  return checkActionIcon(action.actionType) || checkActionIcon('default')
})

// HOOKS
onMounted(() => {
  checkIfTruncated()
})

// METHODS
function goToAction() {
  const actionUrl = getActionRouteNameByAction(action.actionType as Exclude<ActionTypes, ActionTypes.ALL>) || ''
  const selectedRegisterId = action?.idAction
  const routeData = router.resolveRoute({
    name: actionUrl,
    params: { selectedRegisterId }
  })
  router.goToRoute((routeData as any).resolved)
}

function renderDate(date: string) {
  return `${text.end} ${changeDateFormat(date, 'YYYY-MM-DD', 'DD/MM/YYYY')}`
}

function checkIfTruncated() {
  if (textContainerElement.value && textElement.value) {
    const container = textContainerElement.value
    const text = textElement.value
    isTruncated.value = text.scrollWidth > container.clientWidth
  }
}
</script>

<style lang="scss" scoped>
.item-container {
  @include borders($width: 0.5px, $color: $main-100);
  padding: $spacing-xxs;
  gap: 10px;
  flex: 1 0 0;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    border: 0.5px solid $main-400;
    box-shadow: $shadow-md;
  }

  &:active .item {
    background-color: $neutral-grey-200;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $spacing-xs;
    gap: $spacing-xl;
    align-self: stretch;
    background-color: $neutral-grey-100;
    border-radius: $cornerRadius-sm;

    .info-1 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: $spacing-xxs;
      align-self: stretch;

      .tooltip-activator {
        align-self: stretch;
      }

      .row-1 {
        display: flex;
        align-items: flex-start;
        gap: $spacing-xs;
        align-self: stretch;
        color: $main-1000;

        .name {
          @include milano-bold-14;
          @include ellipsis;
        }
      }

      .row-2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: $spacing-xxxs;
        color: $neutral-grey-800;
        align-self: stretch;

        .description-container {
          display: inline-grid;

          .description {
            @include milano-regular-12;
            @include ellipsis;
          }
        }

        .name {
          @include milano-bold-12;
          @include ellipsis;
          align-self: stretch;
        }
      }
    }

    .info-2 {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      min-height: 18px;

      .text-container {
        @include flex;
        max-width: 150px;
        height: 22px;

        .text {
          @include arial-regular-12;
          @include ellipsis;
          padding: $spacing-xxs;
          color: $neutral-grey-800;
          text-align: center;
          border-radius: $spacing-xxs;
          border: 0.5px solid $main-400;
        }
      }

      .date {
        @include milano-regular-14;
        display: flex;
        align-items: center;
        gap: $spacing-xxs;
        color: $neutral-grey-600;
      }
    }
  }
}
</style>
