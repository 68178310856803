import { render, staticRenderFns } from "./LfLabelButtonComponent.vue?vue&type=template&id=0118837a&scoped=true"
import script from "./LfLabelButtonComponent.vue?vue&type=script&lang=ts&setup=true"
export * from "./LfLabelButtonComponent.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./LfLabelButtonComponent.vue?vue&type=style&index=0&id=0118837a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0118837a",
  null
  
)

export default component.exports