import { Module } from 'vuex'
import { getters } from '@/store/modules/forms/formsGetters'
import { mutations } from '@/store/modules/forms/formsMutations'
import { actions } from '@/store/modules/forms/formsActions'
import { FormsState } from '@/store/modules/forms/formsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: FormsState = {
  registerFormData: {},
  formIsValid: {},
  showRelatedForm: {},
  showAditionalForm: {},
  validateFormFlag: false,
  loadingFormData: true,
  relatedData: {},
  customValidateField: {},
  newRegister: {}
}

const namespaced: boolean = true

export const formsModule: Module<FormsState, RootState> = {
  namespaced,
  actions,
  state,
  getters,
  mutations
}
