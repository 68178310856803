export interface DownloadZipResult {
  wrongFiles: string[]
  zip: string
}

export enum documentType {
  UNDEFINED,
  DOCUMENT,
  TEMPLATE
}

export enum fileManagerMode {
  DOCUMENT = 1,
  TEMPLATE = 2
}

export const invalidFileCause = {
  EXTENSION: 'extension',
  SIZE: 'size',
  UNKNOWN: 'unknown',
  UPLOAD_ERROR: 'upload-error'
}

export interface FileManagerFolderGet {
  action: string
  path: string
  basePath: string
  idEntity: number
  idEntityType: number
  idEntityTypeBase?: number
  idEntityTypeAdditional?: number
  idStage?: number
  searchString?: string
}

export interface FileManagerCreateFolder {
  action: string
  basePath: string
  name: string
}

export interface FileManagerDeleteFolder {
  action: string
  basePath: string
  data: object[]
}

export interface UploadFolderResume {
  numFoldersToUpload: number
  numFilesToUpload: number
  numFoldersUploaded: number
  numFilesUploaded: number
  numInvalidFiles: number
  failedNodes: FolderNode[]
  hasFolders: boolean
  hasFiles: boolean,
  items: string[]
}

export interface UploadResult {
  error: string
  errorDesc: string
}

export interface FolderNode {
  entry: any
  id: string
  idParent: string
  name: string
  parentDirectoryId: string
  nodes: FolderNode[]
  isDirectory: boolean
  isFile: boolean
  isSelected: boolean
  isProcessing: boolean
  isProcessed: boolean
  isValid: boolean
  isDisabled: boolean
  isOpen: boolean
  hasSubFolders: boolean
  data: any
  mimeType: string | null
  size: number
  sizeFormatted: string
  icon: string
  extension: string | undefined
  invalidFileCause: string
  invalidFileCauseText: string
  uploadResult: UploadResult
  folderId: number
  path: string
  basePath: string
  level: number
}

export interface Folder {
  idFile: string | null
}

export interface MaxFileUploadSize {
  size: number
  sizeFormatted: string
}

export interface FileState {
  file: Blob | null
  maxFileUploadSize: MaxFileUploadSize
  allowedExtensions: string
  document: {}
  path: string
  currentPath: string
  downloadZipResult: DownloadZipResult
  fileManagerItem: {}
  refreshFileManagerCounter: number
  isOpenFileManagerEditComponent: boolean
}

export interface DocumentPathInfo {
  basePath: string
  path: string
}

export enum fileManagerFolderType {
  DOCUMENT = 1,
  VIEW_ALL = 2
}

export const foldersMutationTypes = {
  BLOB_FILE: 'BLOB_FILE',
  REFRESH_FILE_MANAGER: 'REFRESH_FILE_MANAGER'
}

export const fileMutationTypes = {
  SAVE_ALLOWED_EXTENSIONS: 'SAVE_ALLOWED_EXTENSIONS',
  SAVE_DOCUMENT: 'SAVE_DOCUMENT',
  SAVE_FILE_MANAGER_ITEM: 'SAVE_FILE_MANAGER_ITEM',
  SAVE_MAX_FILE_UPLOAD_SIZE: 'SAVE_MAX_FILE_UPLOAD_SIZE',
  SAVE_PATH: 'SAVE_PATH',
  SAVE_ZIP_RESULT: 'SAVE_ZIP_RESULT'
}

export const fileManagerOpers = {
  CANCEL_UPLOAD_FOLDERS: 'cancelUploadFolders',
  CONVERT_TO_PDF: 'convertToPdf',
  COPY: 'copy',
  CREATE: 'create',
  CREATE_INTERVENER: 'createIntervener',
  DELETE: 'delete',
  DETAILS: 'details',
  DOWNLOAD_AS_ZIP: 'downloadAsZip',
  FINISH_UPLOAD_FOLDERS: 'finishUploadFolders',
  MOVE: 'move',
  MOVEFOLDERS: 'movefolders',
  OPEN_FIRMA_SERVICE: 'openFirmaService',
  OPEN_MAIL_SERVICE: 'openMailService',
  PUBLISH: 'publish',
  PUBLISHFOLDER: 'publishFolder',
  READ: 'read',
  RENAME: 'rename',
  SEARCH: 'search',
  SELECT: 'select',
  SEND_EMAIL: 'send_email',
  UNPUBLISH: 'unpublish',
  UNPUBLISHFOLDER: 'unpublishFolder',
  UNSELECT: 'unselect',
  UPDATE: 'update',
  UPDATE_INTERVENER: 'updateIntervener',
  UPDATE_FOLDER: 'updateFolder',
  UPLOAD: 'Upload'
}

export const fileManagerFields = {
  STAGE: 'idStage'
}

export interface File {
  uid: number
  basePath: any
  filterPath?: string
  name: string
  idEntity: number
  idEntityType: number
  idEntityTypeAdditional?: number
  isFile: boolean
  isDroppable?: boolean
  canCreateDocument?: boolean
  canCreateFolder?: boolean
  canCreateTemplate?: boolean
  canDelete?: boolean
  canRename?: boolean
  canSearch?: boolean
  idEntityTypeOld?: number
  source?: string
}

export interface Document {
  IdDocumento?: number
  IdSupraDocumento?: number
  IdExpedienteEtapa?: number
  idStage?: number
  Fecha?: string
  FechaActualizacion?: string
  Nombre?: string
  Descripcion?: string
  IdTipoDoc?: string
  Ruta?: string
  Archivo?: string
  NumeroDocumento?: string
  ArchivoInicial?: string
  Principal?: number
  GDriveIdDocument?: string
  GDriveTipo?: string
  ODriveIdDocument?: string
  ODriveTipo?: string
  Publico?: string
  folder_id?: 1856
  relative_path?: string
  modified_date?: string
  created_date?: string
  created_by?: number
  modified_by?: number
  IdUser?: number
  IdCategory?: number
  StartEffectiveDate?: string
  EndEffectiveDate?: string
  IsEffect?: number
  Version?: string
  Path?: string
  IdEntityType?: number
  IdEntity?: number
  idUsersPermissions?: []
  tags?: []
}

export enum EditDocumentMode {
  wordOnlineNew,
  excelOnlineNew,
  powerPointOnlineNew,
  uploadNew,
  uploadEdit,
  templateNew
}
