export interface SiteMenuItem {
  text: string
  url: string
  children: SiteMenuItem[]
  icon?: string
  internalUrl?: boolean
  target?: string

}

export enum ParentCategory {
  ACTIONS = 'actions',
  BILLING = 'billing',
  CONTACTS = 'contacts',
  EXPEDIENTS = 'expedients',
  MAINTENANCE = 'maintenance',
  PROFESSIONALS = 'professionals',
  REPORTS = 'reports',
  USERS = 'users'
}

export interface MenusState {
  clientMenu: SiteMenuItem[]
  configurationMenu: SiteMenuItem[]
  drawerInMiniStatus: boolean
  siteMenu: SiteMenuItem[]
  configurationDrawerstatus: boolean
  renderNavigation: boolean
}

export const menusMutationTypes = {
  FETCH_CLIENT_MENU: 'FETCH_CLIENT_MENU',
  FETCH_CONFIGURATION_MENU: 'FETCH_CONFIGURATION_MENU',
  FETCH_SITE_MENU: 'FETCH_SITE_MENU',
  RENDER_NAVIGATION: 'RENDER_NAVIGATION',
  TOOGLE_CONFIG_DRAWER_STATUS: 'TOOGLE_CONFIG_DRAWER_STATUS',
  TOOGLE_DRAWER_STATUS: 'TOOGLE_DRAWER_STATUS',
  CONFIG_DRAWER_STATUS: 'CONFIG_DRAWER_STATUS'
}
