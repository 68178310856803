import useFormSchema from '@/composables/useFormSchema'
import { RenderedForm } from '@/components/billing/InvoicesTab/types/InvoicesTabComponentTypes'

export default function useBankdraftGeneratorForm() {
  const generatorFormContext = RenderedForm.CUSTOMER_BANKDRAFTS_FORM
  const { formSchema, getFormSchema } = useFormSchema({
    formAlias: generatorFormContext,
    context: generatorFormContext
  })
  const generatorFormSchema = formSchema
  const loadGeneratorFormSchema = getFormSchema

  return {
    generatorFormContext,
    generatorFormSchema,
    loadGeneratorFormSchema
  }
}
