import { Route } from 'vue-router'
import { URLS } from '@/router/routes/urlRoutes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import store from '@/store/store'

export const loginNoRequiresAuth = (to: Route, _from: Route, next: any): void => {
  const loggedUser = localStorage.getItem('auth-data')
  const preLoggedUser = localStorage.getItem('pre-auth-data')
  const userIsAuthenticated = store.getters[`${ModuleNamespaces.AUTH}/userIsAuthenticated`]
  const name = to.name ?? ''
  const noRequiresAuth = [
    URLS.LOGIN,
    URLS.SELECT_COMPANY
  ].includes(name)
  
  if (noRequiresAuth && loggedUser && userIsAuthenticated) {
    next(`/${URLS.DASHBOARD}`)
    return
  } else if (
    noRequiresAuth && 
    name !== URLS.LOGIN && 
    !loggedUser && 
    !preLoggedUser && 
    !userIsAuthenticated
  ) {
    next({ path: `/${URLS.LOGIN}` })
    return
  }
  next()
}


