import store from "@/store/store"
import { ModuleNamespaces } from "@/store/types/storeGlobalTypes"
import { computed } from "vue"

export default function useContactContacts() {

  const save = async (data: any) => {
    const id = await store.dispatch(`${ModuleNamespaces.CONTACTS}/saveContactContact`, data)
    return id
  }

  const remove = async (id: string) => {
    const response = await store.dispatch(`${ModuleNamespaces.CONTACTS}/deleteContactContact`, id)
    return response
  }

  const read = async (idContact: string) => {
    await store.dispatch(`${ModuleNamespaces.CONTACTS}/fetchContactsContact`, {
      contactType: 'contacts',
      idContact
    })
  }

  const contacts = computed(() => store.getters[`${ModuleNamespaces.LIST_ITEMS}/getListItemsWithFormattedDates`])

  return {
    save, remove, read, contacts
  }
}

