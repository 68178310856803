import ContactsView from '@/views/contacts/Contacts/ContactsView.vue'
import AllContactsView from '@/views/contacts/AllContacts/AllContactsView.vue'
import { ContactPersonTypeId } from '@/store/modules/contacts/contactsTypes'
import ContactsDetailView from '@/views/contacts/Contacts/ContactsDetailView.vue'
import { URLS } from '@/router/routes/urlRoutes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { to, from, next } from '@/router/types/routerTypes'
import { ConfigurationAlias, ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import { ContactEntityId } from '@/general/entityIds'
import store from "@/store/store"

export const contactsRoutes = [
  {
    path: `/${URLS.CONTACTS}`,
    redirect: { name: URLS.CONTACTS_ALL },
    name: URLS.CONTACTS,
    component: ContactsView,
    meta: {
      title: URLS.CONTACTS,
      requiresAuth: true
    },
    children: [
      {
        path: `${URLS.CONTACTS_ALL}`,
        name: URLS.CONTACTS_ALL,
        component: AllContactsView,
        meta: {
          title: URLS.CONTACTS_ALL,
          parentUrl: URLS.CONTACTS,
          entityType: ContactEntityId.CONTACTS,
          requiresAuth: true
        }
      },
      {
        path: `:selectedRegisterId`,
        name: `${URLS.CONTACTS}-REDIRECT`,
        component: ContactsDetailView,
        beforeEnter: async (to: to, _from: from, next: next<any>) => {
          const contactData = await store.dispatch(`${ModuleNamespaces.CONTACTS}/fetchContact`, {
            contactType: 'contacts',
            params: {
              idContact: to.params.selectedRegisterId
            }
          })
          let contactTypePath = URLS.CONTACTS_ALL;
          if (contactData.idPersonType === ContactPersonTypeId.NATURAL) {
            contactTypePath = `${URLS.CONTACTS_NATURAL}/${to.params.selectedRegisterId}`;
          } else if (contactData.idPersonType === ContactPersonTypeId.LEGAL) {
            contactTypePath = `${URLS.CONTACTS_LEGAL}/${to.params.selectedRegisterId}`;
          }
          next({
            path: `/${URLS.CONTACTS}/${contactTypePath}`,
            query: to.query
          });
        }
      },
      {
        path: `${URLS.CONTACTS_NATURAL}/:selectedRegisterId`,
        name: `${URLS.CONTACTS}-${URLS.CONTACTS_NATURAL}`,
        component: ContactsDetailView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.CONTACTS,
          context: ContextName.CONTACT_NATURAL,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.CONTACT_NATURAL
          },
          contactPersonType: ContactPersonTypeId.NATURAL,
          entityType: entity.contacts.all.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.CONTACTS_NATURAL,
          title: URLS.CONTACTS,
          entityType: ContactEntityId.CONTACTS,
          requiresAuth: true
        }
      },
      {
        path: `${URLS.CONTACTS_LEGAL}/:selectedRegisterId`,
        name: `${URLS.CONTACTS}-${URLS.CONTACTS_LEGAL}`,
        component: ContactsDetailView,
        props: (route: { params: { selectedRegisterId: any } }) => ({
          menuName: URLS.CONTACTS,
          context: ContextName.CONTACT_LEGAL,
          configuration: {
            type: ConfigurationTypes.VIEW,
            alias: ConfigurationAlias.CONTACT_LEGAL
          },
          contactPersonType: ContactPersonTypeId.LEGAL,
          entityType: entity.contacts.all.type,
          entityId: !isNaN(parseInt(route.params.selectedRegisterId, 10))
            ? parseInt(route.params.selectedRegisterId, 10)
            : 0
        }),
        meta: {
          parentUrl: URLS.CONTACTS_LEGAL,
          title: URLS.CONTACTS,
          entityType: ContactEntityId.CONTACTS,
          requiresAuth: true
        }
      }
    ]
  }
]

export const contactsUrl = [
  `${URLS.CONTACTS}`,
  `${URLS.CONTACTS}-${URLS.CONTACTS_ALL}`,
  `${URLS.CONTACTS}-${URLS.CONTACTS_NATURAL}`,
  `${URLS.CONTACTS}-${URLS.CONTACTS_LEGAL}`
]
