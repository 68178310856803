import { ActionTree } from 'vuex'
import { ModuleNamespaces, RootState } from '@/store/types/storeGlobalTypes'
import { foldersMutationTypes, FileState, fileMutationTypes } from '@/store/modules/fileManager/fileManagerTypes'
import { RepositoryService } from '@/services/RepositoryService'
import { listItemsMutationTypes } from '@/store/modules/listItems/listItemsTypes'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { MainService } from '@/services/MainService'

export const actions: ActionTree<FileState, RootState> = {
  async openFile({ commit }, idFile) {
    const url = `repository/file/open/${idFile}/get`
    const { data } = await new RepositoryService().downloadBlob(url)
    commit(foldersMutationTypes.BLOB_FILE, data)
  },
  async downloadFile({ commit }, idFile) {
    commit(foldersMutationTypes.BLOB_FILE, null)
    try {
      const url = `repository/file/${idFile}/get`
      const { data } = await new RepositoryService().downloadBlob(url)
      commit(foldersMutationTypes.BLOB_FILE, data)
      return data
    } catch {
      commit(foldersMutationTypes.BLOB_FILE, null)
    }
  },
  async fetchMaxFileUploadSize({ commit }) {
    try {
      const url = 'repository/file/upload/maxsize/get'
      const { data } = await new RepositoryService().post(url)
      commit(fileMutationTypes.SAVE_MAX_FILE_UPLOAD_SIZE, data)
    } catch (error) {}
  },
  async fetchAllowedExtensions({ commit }, mode) {
    try {
      const url = 'repository/file/allowed-extensions/get'
      const params = new URLSearchParams()
      params.append('mode', mode.toString())
      const { data } = await new RepositoryService().post(url, params)
      commit(fileMutationTypes.SAVE_ALLOWED_EXTENSIONS, data.allowedExtensions)
    } catch (error) {}
  },
  async fetchDocument({ commit }, idDocument) {
    try {
      const url = `/repository/document/${idDocument}/get`
      const { data } = await new RepositoryService().post(url)
      commit(fileMutationTypes.SAVE_DOCUMENT, data)
    } catch (error) {}
  },
  async fetchFolder({ commit }, idFolder) {
    try {
      const url = `/repository/folder/${idFolder}/get`
      const { data } = await new RepositoryService().post(url)
      commit(fileMutationTypes.SAVE_DOCUMENT, data)
    } catch (error) {}
  },
  async fetchOpenOfficeIn({ rootGetters }) {
    try {
      const userId = rootGetters[`${ModuleNamespaces.AUTH}/getUserId`]
      const url = `configuration/user/lists/${userId}/${ListNames.FILE_MANAGER}/get`
      const { data } = await new MainService().getData(url)
      return JSON.parse(data[0]['Config'])['openMSOffice']
    } catch (error) {}
  },
  async fetchInterveners({ commit }, idDocument) {
    try {
      const url = `/repository/document/${idDocument}/interveners/get`
      const { data } = await new RepositoryService().post(url)
      commit(`${ModuleNamespaces.LIST_ITEMS}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {}
  },
  async fetchPathByBasePath({ commit }, basePath) {
    try {
      const url = `/repository/document/path/get`
      const params = new URLSearchParams()
      params.append('basePath', basePath)
      const { data } = await new MainService().postBody(url, params)
      commit(fileMutationTypes.SAVE_PATH, data)
    } catch (error) {}
  },
  async downloadZipResult({ commit }, { files, name }) {
    const url = `/repository/zip/download`
    const params = new URLSearchParams()
    params.append('files', files)
    params.append('name', name)
    const { data } = await new MainService().postBody(url, params)
    commit(fileMutationTypes.SAVE_ZIP_RESULT, data)
  },
  async downloadAsZip({ commit }, zipTmpName) {
    const url = `repository/zip/${zipTmpName}/download`
    const { data } = await new RepositoryService().downloadBlob(url)
    commit(foldersMutationTypes.BLOB_FILE, data)
  },
  async insertDocument({}, { document, fileData }) {
    try {
      const params = new FormData()
      params.append('document', document)
      params.append('fileData', fileData)
      await new RepositoryService().insertDocument(params)
    } catch (error) {}
  },
  async fetchDocumentPathInfo(_context, idDocument) {
    try {
      const url = `/repository/document/path-info/${idDocument}/get`
      const { data } = await new MainService().getData(url)
      return data
    } catch (error) {}
  },
  async deleteDocument(_context, idDocument) {
    try {
      const url = `/repository/document/${idDocument}/delete`
      await new MainService().deleteData(url)
    } catch (error) {}
  }
}
