<template lang="pug">

div(:class="['total-item', { 'accordion-item': isAccordionType }, item.alias]")
  div(class="total-label")
    span(:class="[getIcon, 'total-icon', 'lf-icon__md']")
    div(class="total-title") {{ item.detail }}
  div(class="total-quantity") {{ renderAmount(item.totalAmount) }}

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TotalItem } from '@/store/modules/billing/billingTypes'
import { Icons } from '@/icons/icons'

@Component
export default class BillingTotalItemComponent extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  item!: TotalItem

  @Prop({
    type: Boolean,
    default: false
  })
  isAccordionType!: boolean

  get getIcon() {
    if (this.isAccordionType) {
      return Icons.IMPORTANT
    } else {
      switch (this.item.alias) {
        case 'totalInvoicesTaxBase':
          return Icons.COIN
        case 'totalInvoicesTaxes':
          return Icons.MONEY_LOCALIZATION
        case 'totalInvoicesIRPF':
          return Icons.MONEY_BAG
        case 'totalInvoices':
          return Icons.BILL
        case 'totalInvoicesPending':
          return Icons.HAND_SPHERE
        case 'totalBankDrafstPending':
          return Icons.HAND_SPHERE
        case 'totalBankDraftsToCharge':
          return Icons.BILL
        case 'totalInvoicesCharged':
          return Icons.COIN
        case 'totalBankDraftsToPay':
          return Icons.COIN
        case 'totalInvoicesPaid':
          return Icons.BILL
        default:
          return Icons.CIRCLE_O
      }
    }
  }

  renderAmount(amount: string) {
    return amount === '-' ? '0,00 €' : amount
  }
}
</script>

<style lang="scss" scoped>
.total-item {
  @include flex($justify-content: space-between);
  height: $input-size;
  padding: $spacing-xs $spacing-xxs;
  background: $main-800;
  color: $neutral-white-000;

  .total-label {
    @include flex;

    .total-icon {
      margin-left: $spacing-xs;
    }

    .total-title {
      @include arial-regular-16;
      margin-left: $spacing-xxs;
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .total-quantity {
    @include arial-bold-16;
    @include ellipsis;
    margin-right: 36px;
  }

  &.accordion-item {
    background-color: $main-050;
    color: $neutral-grey-800;
    padding-left: $spacing-xl;

    .total-label {
      opacity: 1;
    }
  }

  &.totalInvoicesTaxBase,
  &.totalInvoicesCharged,
  &.totalBankDraftsToPay,
  &.totalInvoicesTaxes,
  &.totalInvoicesIRPF {
    background-color: $main-800;
  }

  &.totalInvoices,
  &.totalBankDraftsToCharge,
  &.totalInvoicesPaid {
    background-color: $main-900;
  }

  &.totalInvoicesPending,
  &.totalBankDrafstPending {
    background-color: $highlight-100;
  }
}
</style>
