import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { computed } from 'vue'

export interface FormDataToSaveInterface {
  endpoint: string,
  idSelectedRegister: string,
  context: string
}

export default function useFormData() {

  const prepareFormData = (formData: any) => store.dispatch(
    `${ ModuleNamespaces.FORMS }/prepareRegisterFormDataToSave`, formData
  )

  const registerFormData = computed(() => store.getters[
    `${ ModuleNamespaces.FORMS }/getRegisterFormData`]
  )

  const isValidFormData = (context: string) => store.getters[
    `${ ModuleNamespaces.FORMS }/checkIfFormIsValid`
    ](context)

  const saveQuietRegisterFormData = (formDataToSave: FormDataToSaveInterface) => store.dispatch(
    `${ ModuleNamespaces.FORMS }/saveQuietRegisterFormData`, formDataToSave
  )

  return {
    saveQuietRegisterFormData,
    prepareFormData,
    isValidFormData,
    registerFormData
  }
}
