import { extend } from 'vee-validate';
import { i18n } from '@/plugins/vue-i18n';

extend('lexnetNig', {
  validate: value => {
    return /^[\d]{19}$/.test(value);
  },
  message: i18n.t('validations.nig') as string
});

extend('lexnetProceeding', {
  validate: value => {
    return /^[\d]{11}$/.test(value);
  },
  message: i18n.t('validations.proceeding') as string
});
