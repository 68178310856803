import { Module } from 'vuex'
import { getters } from '@/store/modules/alertsMessages/alertsMessagesGetters'
import { actions } from '@/store/modules/alertsMessages/alertsMessagesActions'
import { mutations } from '@/store/modules/alertsMessages/alertsMessagesMutations'
import {
  AlertsMessagesState,
  defaultFilter, defaultList,
} from '@/store/modules/alertsMessages/alertsMessagesTypes'
import { RootState } from '@/store/types/storeGlobalTypes'

export const state: AlertsMessagesState = {
  alertsMessagesList: defaultList,
  filteredList: defaultList,
  filter: defaultFilter
}

const namespaced: boolean = true

export const alertsMessagesModule: Module<AlertsMessagesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
