<template lang="pug">
header(class="tab-header-container")
  div(class="header-title-container")
    p(class="header-title" v-html="title")
  v-spacer
  LfActionsBarComponent(
    class="action-buttons"
    :buttons="buttons"
    :showKebabMenu="showKebabMenu"
    @execute-action="executeAction"
  )
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import LfActionsBarComponent from '@/components/ActionsBar/LfActionsBarComponent.vue'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'

@Component({
  components: {
    LfActionsBarComponent
  }
})
export default class TabHeaderComponent extends Vue {
  @Prop({
    type: String,
    required: true
  })
  title!: string

  @Prop({
    type: Array,
    required: true
  })
  buttons!: []

  @Prop({
    type: Boolean,
    default: false
  })
  showKebabMenu!: boolean

  close() {
    this.$emit('closeForm')
  }

  save() {
    this.$emit('saveForm')
  }

  remove() {
    this.$emit('removeRelated')
    this.$emit('removeItem')
  }

  executeAction(actionName: string) {
    switch (actionName) {
      case ActionName.CLOSE:
        return this.close()
      case ActionName.SAVE:
        return this.save()
      case ActionName.REMOVE:
        return this.remove()
    }
    this.$emit('executeAction', actionName)
  }
}
</script>

<style lang="scss" scoped>
.tab-header-container {
  display: flex;
  height: 50px;
  padding: 0px $spacing-xs 0px $cornerRadius-md;
  justify-content: space-between;
  align-items: center;
  background: $main-100;
  margin-bottom: $spacing-md;

  .header-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0px $spacing-md;

    .header-title {
      @include milano-bold-16;
      color: $main-800;
      margin: 0;
    }
  }
}

.action-buttons {
  display: flex;
  padding-right: $spacing-xs;
  align-items: center;
}
</style>
