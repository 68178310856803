import { Icons } from '@/icons/icons'

export interface TableStructure {
  headers: TableHeader[]
  items: object[]
  name: string
  summary: TableSummary[]
}

interface TableHeader {
  key: string
  title: string
  type: string
}

export interface TableSummary {
  key: string
  value: string | number
  type?: string
}

export enum ItemTypeEnum {
  STRING = 'string',
  BUTTON = 'button',
  CURRENCY = 'currency'
}

export interface ItemButton {
  icon: Icons,
  callback: Function
}
