export interface AlertsConfigurationState {
  configuration: AlertsConfiguration
}

export interface AlertsConfiguration {
}


export const alertsConfigurationMutationTypes = {
  UPDATE_ALERTS_CONFIGURATION: 'UPDATE_ALERTS_CONFIGURATION',
}
