<template>
  <div class="card">
    <div class="title-container">{{ text.title }}</div>
    <div class="text-container">
      <span :class="[icon.chat, 'chat-icon']" />
      <p>{{ text.contact }}</p>
      <p class="timetable">{{ text.timetable }}</p>
    </div>
    <button class="js-chat-opener">{{ text.start }}</button>
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n-composable'
import { Icons } from '@/icons/icons'
import { addInlineJS } from '@/helpers/html'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import store from '@/store/store'

// TRANSLATIONS
const { t } = useI18n()
const text = {
  title: t('components.dialog.support_service.chat_online.title'),
  timetable: t('components.dialog.support_service.chat_online.timetable'),
  start: t('components.dialog.support_service.chat_online.start'),
  contact: t('components.dialog.support_service.chat_online.contact')
}

// DATA
const icon = {
  chat: Icons.CHAT
}

// HOOKS
onMounted(() => {
  addInlineJS(process.env.VUE_APP_CHAT_URL, '', {
    id: 'lf-chatservice-lefebvre',
    'data-hb': '1',
    'data-hm': '0',
    'data-align': 'br',
    'data-o': 'lex-on',
    'data-u': store.getters[`${ModuleNamespaces.AUTH}/getProUserId`],
    'data-s': 'Chat_SAC_LEX-ON'
  })
})
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  padding: $spacing-sm;
  flex-direction: column;
  align-items: center;
  gap: $spacing-sm;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: $cornerRadius-md;
  border: 1px solid $main-300;
  background: $neutral-white-000;

  .title-container {
    @include milano-bold-24;
    padding: $spacing-xs;
    color: $highlight-200;
    text-align: center;
  }

  .text-container {
    @include milano-regular-16;
    display: flex;
    padding: $spacing-xs;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 8px;
    align-self: stretch;
    color: $neutral-grey-800;
    margin-top: 15px;

    p {
      margin: 0;
    }

    .timetable {
      color: $neutral-grey-600;
    }

    .chat-icon {
      font-size: 62px;
      color: $main-1000;
    }
  }

  button {
    @include milano-bold-16;
    display: flex;
    height: 37px;
    align-items: center;
    padding: $spacing-xs $spacing-sm;
    justify-content: center;
    align-items: center;
    color: $main-1000;
  }
}
</style>
