export enum ExpedientCourtsFields {
  ID_COURT = "idCourt",
  ID_STAGE = "idStage",
  CURRENT_STAGE = "currentStage",
  WRIT_NUMBER = "writNumber",
  NUMBER_EXECUTION = "numberExecution",
  NUMBER_APPEAL = "numberAppeal",
  NUMBER_EXPEDIENT = "numberExpedient",
  NOTES = "notes",
  MAIN_INTERVENER = "mainIntervener",
  PUBLIC = "public"
}
