<template>
  <v-menu
    v-if="expedientHasStages"
    bottom
    :close-on-content-click="true"
    nudge-bottom="42"
    nudge-right="34"
    content-class="stage-selector-wrapper"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="filter-button" v-bind="attrs" v-on="on">
        <span :class="[renderedIcon, 'lf-icon__md']"/>
        <span class="filter-text">{{ title }}</span>
      </div>
    </template>
    <LfRadioGroupComponent
      :value="selectedStageId"
      :options="finalItems"
      itemKey="id"
      itemText="stage"
      @update-value="setState"
    >
      <template v-slot:default="{ item }">
        <LexonTooltipComponent v-if="Number(item.current)" :message="texts.currentStage" position="top">
          <div slot="activator-element" class="actual-stage-bullet"></div>
        </LexonTooltipComponent>
      </template>
    </LfRadioGroupComponent>
  </v-menu>
</template>
<script lang="ts" setup>
import { watch } from 'vue'
import LfRadioGroupComponent from '@/components/forms/fields/LfRadioGroupComponent.vue'
import LexonTooltipComponent from '@/components/forms/fields/LexonTooltip/LexonTooltipComponent.vue'
import { computed, onBeforeUnmount} from 'vue'
import { useI18n } from 'vue-i18n-composable'
import useStageSelector from './composable/useStageSelector'
import { Stage } from './types/StageSelectorTypes'
import { Icons } from '@/icons/icons'
import { TranslateResult } from 'vue-i18n'
import useCompany from '@/composables/useCompany'

const { t } = useI18n()

const texts = {
  currentStage: t('components.stage_selector.current_stage'),
  filterStages: t('components.stage_selector.filter_stages'),
  selectAll: t('components.stage_selector.select_all').toString()
}

const allStageItem:Stage = {
  current: 0,
  ended: 0,
  id: '0',
  notes: '',
  stage: texts.selectAll
}

// COMPOSABLES   ======================================================================================================
const { stages, setSelectedStage, selectedStage, expedientHasStages, resetStages, currentStageId } = useStageSelector()
const { defaultStage } = useCompany()

// COMPUTEDS ==========================================================================================================
const selectedStageId = computed<string>(() => defaultStage.value ? currentStageId.value : allStageItem.id)

const finalItems = computed<Stage[]>(() => [allStageItem, ...stages.value])

const isInitialStage = computed<boolean>(() => selectedStage.value === allStageItem.id || selectedStage.value === '')

const title = computed<TranslateResult | undefined>(() => {
  const defaultTitle = texts.filterStages
  const selectedStageTitle = finalItems.value.find((item) => String(item.id) === selectedStage.value)?.stage
  return isInitialStage.value ? defaultTitle : selectedStageTitle
})

const renderedIcon = computed<string>(() => (isInitialStage.value ? Icons.FILTER : Icons.FILTER_APPLIED))

// EVENTS =============================================================================================================
const setState = (stageId: string = allStageItem.id) => {
  setSelectedStage(stageId)
}

// HOOKS =============================================================================================================
onBeforeUnmount(() => {
  resetStages()
})

// WATCH
watch(currentStageId, () => {
  if (defaultStage.value) {
    setSelectedStage(currentStageId.value)
  }
})
</script>
<style lang="scss" scoped>
.stage-selector-wrapper {
  @include context-menu;
  --activator-align-self: center;

  .actual-stage-bullet {
    width: 10px;
    height: 10px;
    background-color: $main-1000;
    border-radius: 50%;
  }
}

.filter-button {
  display: flex;
  height: 38px;
  justify-content: flex-start;
  align-items: center;
  padding: $spacing-xs $spacing-sm;
  gap: $spacing-xxs;
  color: $main-1000;

  .filter-text {
    @include milano-bold-16;
    @include ellipsis;
    display: block;
    max-width: 300px;
    color: $main-1000;
    margin-left: 2px;
  }
}
</style>
