<script lang="ts">
import { Action, Getter, Mutation } from 'vuex-class'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { Component, Prop } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { mixins } from 'vue-class-component'
import ActionsMixin from '@/mixins/ActionsMixin.vue'
import LexnetMixin from '@/mixins/LexnetMixin.vue'
import TabsView from '@/views/Tabs/TabsView.vue'
import RemoveActionMixin from '@/mixins/RemoveActionMixin.vue'
import { Action as ActionInterface, setBasePathByActionType } from '@/store/modules/actions/actionsTypes'
import { TabNames } from '@/store/modules/tabs/tabsTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'

const actionsModule: string = ModuleNamespaces.ACTIONS

@Component({
  mixins: [ActionsMixin]
})
export default class ActionDetailView extends mixins(RemoveActionMixin, LexnetMixin, TabsView) {
  @Prop({
    type: String
  })
  actionType!: string

  @Action('saveSelectedActionType', { namespace: actionsModule })
  selectedActionType: (type: string) => void

  @Getter('getSelectedActionType', { namespace: actionsModule })
  getSelectedActionType: string

  @Getter('getSelectedRegisterId', { namespace: ModuleNamespaces.SELECTED_REGISTER })
  selectedRegisterId: (context: string) => any

  @Mutation('RESET_CHRONOMETER_NEW_ACTION_INFO', { namespace: actionsModule })
  resetChronometerNewActionInfoMutation: () => void

  @Mutation('ENABLE_OR_DISABLE_TAB', { namespace: ModuleNamespaces.CONFIGURATION })
  enableOrDisableSubActionTab: ({
    context,
    tabName,
    status
  }: {
    context: string
    tabName: string
    status: boolean
  }) => void

  get selectedAction() {
    return this.selectedRegisterData(ContextName.ACTION)
  }

  async onInitTabView() {
    this.selectedActionType(this.actionType)
    const selectedRegisterId = this.selectedRegisterId(ContextName.ACTION)
    const selectedAction = this.selectedAction
    const isLexnetType = entity.actions.lexnet.type === this.entityType
    const isEmailType = entity.actions.emails.type === this.entityType
    const isSubAction = selectedAction.parentId && selectedAction.id && selectedRegisterId
    this.enableOrDisableSubActionTab({
      context: ContextName.ACTION,
      tabName: TabNames.SUB_ACTION,
      status: !isSubAction
    })
    if (isLexnetType || isEmailType) {
      await this.fetchSelectedRegisterData({
        endpoint: this.endpoints(this.permissionsEntity).get,
        context: this.getContext
      })
    }

    if (isLexnetType) {
      this.extraButtons = [
        {
          icon: Icons.LEXNET,
          tooltip: this.$t('components.context_menu.open_lexnet'),
          action: ActionName.OPEN_IN_LEXNET,
          enabled: true,
          isContextMenuOption: true
        }
      ]
    }
  }

  destroyed() {
    this.resetChronometerNewActionInfoMutation()
  }

  executeExtraActions(actionName: string): void {
    if (ActionName.OPEN_IN_LEXNET === actionName) {
      this.openInLexnet(this.selectedRegisterData(this.getContext))
    }
  }

  onBeforeSetComponentProperties(component: any, _tab: any, _tabIndex: number) {
    component.basePath = setBasePathByActionType(this.getSelectedActionType)
  }

  executeAction(actionName: string) {
    switch (actionName) {
      case ActionName.CLOSE:
        return this.close()
      case ActionName.SAVE:
        return this.save()
      case ActionName.REMOVE:
        this.trackClickRemove()
        return this.confirmRemove(this.selectedAction)
    }
  }

  confirmRemove(action: ActionInterface) {
    this.showDialog({
      type: DialogTypes.INFO,
      message: this.getRemoveConfirmMessageFromAction(action),
      action: async () => {
        await this.removingAction(action)
        this.close()
      },
      mainButtonText: this.$t('action_buttons.remove'),
      secondaryButtonText: this.$t('action_buttons.cancel')
    })
  }
}
</script>
