<template>
  <div>
    <p class="px-4">{{ texts.info }}</p>
    <LfGridComponent
      v-if="gridConfiguration"
      :gridConfiguration="gridConfiguration"
      :itemData="professionals"
      :toolbarOptions="toolbarOptions"
      :contextMenuItems="contextMenuItems"
      :commandClick="onCommandClick"
      @toolbarClicked="onToolbarClick"
      @contextMenuClicked="onContextMenuClicked"
    ></LfGridComponent>
    <PromptDialogComponent
      :title="modalTitle"
      :formSchema="formSchema"
      :formFieldValues="formData"
      :isOpened="openedPrompt"
      :width="940"
      :context="props.formAlias"
      @execute-action="onExecuteAction"
    ></PromptDialogComponent>
  </div>
</template>
<script lang="ts" setup>
import PromptDialogComponent from '@/components/Dialog/PromptDialogComponent.vue'
import LfGridComponent from '@/components/grids/LfGrid/LfGridComponent.vue'
import { searchToolbarItem } from '@/components/grids/LfGrid/components/ToolbarContextMenu/ToolbarContextMenuType'
import { ActionName, CommandClickEventArgs, removeButton } from '@/components/grids/LfGrid/LfGridTypes'
import useGridConfiguration from '@/composables/useGridConfiguration'
import { ToolbarClickEventArgs, ToolbarItemModel } from '@syncfusion/ej2-vue-filemanager'
import { ref } from 'vue'
import useFormSchema from '@/composables/useFormSchema'
import useProfessionalAccess from '../composables/useProfessionalAccess'
import useDialog from '@/composables/useDialog'
import useFeedback from '@/composables/useFeedback'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import { Icons } from '@/icons/icons'
import { ContextMenuClickEventArgs } from '@syncfusion/ej2-vue-grids'
import { useI18n } from 'vue-i18n-composable'

const { t } = useI18n()

// DEFINE PROPS  ======================================================================================================
const props = defineProps({
  listName: {
    type: String,
    required: true
  },
  context: {
    type: String,
    required: true
  },
  formAlias: {
    type: String,
    required: true
  }
})
const commandButtons = [removeButton]

const texts = {
  info: t('views.professionals.info'),
  buttons: {
    add: t('action_buttons.add_access').toString()
  },
  contextMenuItems: {
    remove: t('components.context_menu.remove')
  },
  modal: {
    title_new: t('views.professionals.modal.title_new'),
    removeConfirm: (professionalName: string) =>
      t('views.professionals.modal.remove_confirm', { professionalName }).toString()
  },
  remove: {
    ok: (professionalName: string) => t('views.professionals.remove.ok', { professionalName }).toString(),
    fail: 'No se ha podido eliminar el profesional'
  },
  save: {
    ok: 'Profesional se ha guardado correctamente',
    fail: 'No se ha podido guardar el profesional'
  }
}

const toolbarOptions: ToolbarItemModel[] = [
  searchToolbarItem,
  {
    id: ActionName.ADD,
    text: texts.buttons.add,
    align: 'Right',
    tooltipText: texts.buttons.add,
    cssClass: 'lf-btn-model4'
  }
]
const openedPrompt = ref(false)
const formData = ref({})
const editMode = ref(false)
const modalTitle = ref(texts.modal.title_new)

const contextMenuItems = [
  {
    id: ActionName.REMOVE,
    text: texts.contextMenuItems.remove,
    iconCss: Icons.REMOVE
  }
]

// COMPOSABLES   ======================================================================================================
const { gridConfiguration } = useGridConfiguration({
  listName: props.listName,
  context: props.context,
  commandButtons
})

const { formSchema, getFormSchema } = useFormSchema({
  formAlias: props.formAlias,
  context: props.context
})

const { getDialogInfo, getDialogSuccess, getDialogError } = useDialog()
const { showFeedbackSuccess } = useFeedback()

const { professionals, saveProfessionalAccess, fetchProfessionalAccess, removeProfessionalAccess } =
  useProfessionalAccess()

// EVENTS =============================================================================================================
const onToolbarClick = (args: ToolbarClickEventArgs) => {
  const { id } = args.item
  if (ActionName.ADD === id) {
    openedPrompt.value = true
    editMode.value = false
    formData.value = {}
  }
}

const onCommandClick = ({ rowData }: CommandClickEventArgs) => {
  const { idConfiguracion, name } = rowData as any
  confirmDelete({
    id: idConfiguracion,
    name
  })
}

const onExecuteAction = async (actionName: string) => {
  if (ActionName.CLOSE === actionName) {
    openedPrompt.value = false
    return
  }
  if (ActionName.SAVE === actionName) {
    await savingProfessionalAccess()
    fetchProfessionalAccess()
    openedPrompt.value = false
    return
  }
}

const onContextMenuClicked = ({ rowInfo, item }: ContextMenuClickEventArgs) => {
  const { rowData } = rowInfo as any
  const { id } = item
  if (id === ActionName.REMOVE) {
    confirmDelete({
      id: rowData.idConfiguracion,
      name: rowData.name
    })
    return
  }
}

// METHODS ============================================================================================================
async function savingProfessionalAccess() {
  const { success, message } = await saveProfessionalAccess(formData.value)
  if (!success) {
    const errorMessage = message || texts.save.fail
    getDialogError({ message: errorMessage })
    return
  }
  showFeedbackSuccess({ message: texts.save.ok, componentWhereIsRendered: ComponentWhereIsRendered.TABS_VIEW })
}

function confirmDelete(props: { id: string; name: string }) {
  const { id, name } = props
  getDialogInfo({
    message: texts.modal.removeConfirm(name),
    action: async () => {
      await remove({ id, name })
      fetchProfessionalAccess()
    }
  })
}

async function remove(props: { id: string; name: string }) {
  const { id, name } = props
  const { success, message } = await removeProfessionalAccess(id)
  if (!success) {
    const errorMessage = message || texts.remove.fail
    getDialogError({ message: errorMessage })
    return
  }
  getDialogSuccess({ message: texts.remove.ok(name) })
}

// HOOKS =============================================================================================================
getFormSchema()
</script>
