<template>
  <div class="counters-wrapper">
    <div v-if="ListStatus.OK !== status" class="counters">
      <LfWhiteCounterComponent
        v-for="(counter, index) in loadingData"
        v-if="counter.hasPermission"
        :key="`loading-counter-${index}`"
        :info="counter"
        :showSpinner="true"
      />
    </div>
    <div v-else class="counters">
      <LfWhiteCounterComponent
        v-for="(counter, index) in widgetList"
        v-if="counter.hasPermission"
        :key="`counter-${index}`"
        :info="counter"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n-composable'
import usePermissions from '@/composables/usePermissions'
import { CounterInfo } from '@/components/counters/types/CounterTypes'
import LfWhiteCounterComponent from '@/components/counters/LfWhiteCounterComponent.vue'
import useCounterWidget from '@/components/widgets/DashboardWidgets/counters/composable/useCounterWidget'
import { ListStatus } from '@/store/modules/dashboardWidgets/dashboardWidgetsTypes'
import useRouter from '@/composables/useRouter'
import VueRouter from 'vue-router'

// ROUTER
const { router } = useRouter()

// TRANSLATIONS
const { t } = useI18n()
const text = {
  invoicesReceivable: t('components.widgets.counters.invoices_receivable'),
  lastModifiedActions: t('components.widgets.counters.last_modified_actions'),
  lastModifiedExpedients: t('components.widgets.counters.last_modified_expedients'),
  timeReport: t('components.widgets.counters.time_report')
}

// COMPOSABLES
const { status, widgetList, loadWidgets } = useCounterWidget()
const {
  userHasPermissionToViewActions,
  userHasPermissionToViewExpedients,
  userHasPermissionToViewAgenda,
  userHasPermissionToViewCustomerInvoices
} = usePermissions()

// COMPUTED
const loadingData = computed<CounterInfo[]>(() => {
  return [
    {
      title: text.lastModifiedExpedients,
      qty: '00',
      hasPermission: userHasPermissionToViewExpedients.value
    },
    {
      title: text.lastModifiedActions,
      qty: '00',
      hasPermission: userHasPermissionToViewActions.value
    },
    {
      title: text.timeReport,
      qty: '0h 0m',
      hasPermission: userHasPermissionToViewAgenda.value
    },
    {
      title: text.invoicesReceivable,
      qty: '00',
      hasPermission: userHasPermissionToViewCustomerInvoices.value
    }
  ]
})

// HOOKS
onMounted(() => loadWidgets(router as VueRouter))
</script>

<style lang="scss" scoped>
.counters {
  @include flex;
  margin: $spacing-xl 0;
  gap: $spacing-xl;
}
</style>
