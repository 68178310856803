<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import TabsView from '@/views/Tabs/TabsView.vue'
import { mixins } from 'vue-class-component'
import ActionsMixin from '@/mixins/ActionsMixin.vue'

const configurationModule: string = ModuleNamespaces.CONFIGURATION
const entitiesModule: string = ModuleNamespaces.ENTITIES
const formsModule: string = ModuleNamespaces.FORMS
const maintenanceModule: string = ModuleNamespaces.MAINTENANCE
const tabsModule = ModuleNamespaces.TABS

@Component({
  mixins: [ActionsMixin]
})
export default class ConfigurationPanelView extends mixins(TabsView) {
  @Action('prepareRegisterFormDataToSave', { namespace: formsModule })
  prepareFormData: ({}) => void

  @Mutation('SAVE_MENU_PARENT_CATEGORY_NAME', { namespace: entitiesModule })
  saveMenuParentCategoryName: (parentCategoryName: string) => void

  @Mutation('RESET_VIEW_CONFIGURATION', { namespace: configurationModule })
  resetViewConfigurationMutation: (alias: string) => void

  @Mutation('CHANGE_DISABLED_TABS', { namespace: tabsModule })
  changeDisplayTabs: (status: boolean) => void

  @Getter('getMaintenanceStageState', { namespace: maintenanceModule })
  maintenanceStageState: []

  @Getter('getRegisterFormData', { namespace: formsModule })
  getFormData: () => object

  profitabilityHourCost = 0

  @Watch('$route')
  changeRoute() {
    this.everythingLoaded = false
    this.init()
    this.componentKey += 1
  }

  @Watch('everythingLoaded')
  hideSpinnerLayer(status: boolean) {
    if (status) {
      this.hideSpinnerLayerAction()
      setTimeout(() => {
        ;(this as any).$refs.tabsComponent.disabledTabs = false
      }, 500)
    }
  }

  @Watch('$route', { immediate: true })
  resetStoreConfigOnRouteChange() {
    this.resetViewConfigurationMutation(this.configuration.alias)
  }

  get buttons() {
    return []
  }

  get breadcrumbsText() {
    return this.menuName
  }

  onCloseSubForm() {
    this.profitabilityHourCost = 0
  }
}
</script>
