import { getUrlByIdEntity, redirectTo } from '@/helpers/routes'
import store from "@/store/store"
import { ModuleNamespaces } from "@/store/types/storeGlobalTypes"
import { Ref, ref } from "vue"

interface ResponseUseContactInvoices {
  invoices: Ref<any[]>
  read: (selectedRegisterId: string) => Promise<void>
  open: (idEntity: number, selectedRegisterId: string, target?: string) => void
}

interface PropsUseContactInvoices {
  listName: string
}

export default function useContactInvoices(props: PropsUseContactInvoices): ResponseUseContactInvoices {
  const invoices = ref([])

  const read = async (selectedRegisterId: string) => {
    await store.dispatch(`${ModuleNamespaces.INVOICES}/fetchContactInvoice`, {
      selectedRegisterId,
      listName: props.listName
    })
    invoices.value =  store.getters[`${ModuleNamespaces.LIST_ITEMS}/getListItemsWithFormattedDates`]
  }

  const open = (idEntity: number, selectedRegisterId: string, target = '_self') => {
    const urlInvoice = getUrlByIdEntity(idEntity, selectedRegisterId)
    redirectTo(urlInvoice, target)
  }

  return {
    invoices,
    read,
    open
  }
}
