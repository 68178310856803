import { TranslateResult } from 'vue-i18n'

export enum PrintOrSaveInvoicesSteps {
  SELECT_INVOICES = 1,
  CONFIGURE_OPTIONS = 2,
  GENERATION_PROGRESS = 3,
  FINALIZE_PROCESS = 4
}

export enum PrintInvoiceEvents {
  CANCEL = 'cancelPrintOrSaveInvoiceDialog',
  GENERATE = 'generatePrintOrSaveInvoiceDialog',
  CLOSE = 'closePrintOrSaveInvoiceDialog',
  FINISH = 'finishPrintOrSaveInvoiceDialog',
  CONFIGURE_OPTIONS = 'configurePrintOrSaveInvoiceDialog',
  SELECT_INVOICES = 'selectPrintOrSaveInvoiceDialog'
}

export const MAX_SELECTED_INVOICE_PRINT = 100

export interface TranslationTexts {
  title: {
    [key: number]: TranslateResult
  },
  selectedFile: TranslateResult
  selectedFiles: TranslateResult
}
