<template>
  <v-menu
    bottom
    left
    nudge-bottom="48"
    nudge-right="18"
    class="alerts-context-menu"
    content-class="navigation-context-menu"
  >
    <template v-slot:activator="{ on, attrs }">
      <span :class="[iconAlerts, 'main-icon', { 'exist-unread-alerts': existUnreadAlerts }]" v-bind="attrs" v-on="on" />
    </template>
    <SpinnerLayerComponent v-if="ListStatus.LOADING === status" class="spinner-layer-container"></SpinnerLayerComponent>
    <AlertsListComponent v-else />
  </v-menu>
</template>

<script lang="ts" setup>
import { computed, onMounted } from 'vue'
import { Icons } from '@/icons/icons'
import useActionsAlertsMessages from '@/components/Navigation/AppBarToolbarItems/contextMenus/composables/useActionsAlertsMessages'
import { AlertMessageVisibility, ListStatus } from '@/store/modules/alertsMessages/alertsMessagesTypes'
import AlertsListComponent from './AlertsListComponent.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'

// DATA
const iconAlerts = Icons.ALERT

// COMPOSABLES
const { status, alertsMessagesList, loadAlertsMessagesList } = useActionsAlertsMessages()

// COMPUTED
const existUnreadAlerts = computed(
  () =>
    ListStatus.OK === status.value &&
    alertsMessagesList.value.some((alert) => alert.visibility === AlertMessageVisibility.NO_READ)
)

// HOOKS
onMounted(() => {
  init()
})

// METHODS
async function init() {
  if (ListStatus.OK !== status.value) {
    await loadAlertsMessagesList()
  }
}
</script>

<style lang="scss" scoped>
@import '~@/components/Navigation/AppBarToolbarItems/contextMenus/styles/contextmenu.scss';

.alerts-context-menu {
  display: block;
}

.navigation-context-menu {
  --menu-with: 377px;
}

.main-icon {
  position: relative;

  &.exist-unread-alerts::after {
    display: block;
    position: absolute;
    right: -2px;
    top: -2px;
    width: 10px;
    height: 10px;
    background-color: $tertiary-persianRed-200;
    border-radius: 50%;
    content: '';
    box-shadow: $shadow-md;
  }
}
</style>
