<template lang="pug">

  div(class="firma-dialog-container")

    //- HEADER
    CustomDialogHeaderComponent(:headerTitle="texts.title")

    p(class="explanation") {{ texts.explanation }}

    InfoBarComponent(
      :infoText="texts.infoText"
    )

    span(class="required-fields-advice-base") {{ $t('components.form_generator.required_fields') }}
    
    ReceiverFieldSelectComponent(
      :source="smsSource"
      @emit-receiver="setReceiver"
    )

    LexonSwitchComponent(
      v-model="firmaSwitch"
      :class="['switch-field', { 'error-type': showErrorBar }]"
      id="switch"
      :label="texts.firmaSwitchLabel"
      name="switch"
      hide-details
      @input="checkIfRenderErrorStyles"
    )

    StaticAlertComponent(
      v-if="showErrorBar" 
      :alert="alert" 
      class="static-alert"
    )

    //- BUTTONS
    CustomDialogActionButtonsComponent(
      :disabledButtons="disabledActionButtonSMS"
      :mainAction="signFirma"
      :primaryButtonText="texts.primaryButtonText"
    )

    //- SPINNER
    SpinnerLayerComponent(v-if="loadingSpinner" class="spinner-layer" :loadingText="spinnerText.sending")

</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import FirmaDialogsMixin from '@/mixins/firma/FirmaDialogsMixin.vue'
import { InfoBarTypes } from '@/components/InfoBar/types/InfoBarComponentTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { sendTypeSource } from '@/components/Dialog/customDialogs/firma/ReceiverFieldSelectComponent.vue'
import StaticAlertComponent from '@/components/Alert/StaticAlertComponent.vue'
import { AlertsData, AlertsTypes } from '@/store/modules/alerts/alertsTypes'
import { URLS } from '@/router/routes/urlRoutes'

const authModule = ModuleNamespaces.AUTH
const dialogModule: string = ModuleNamespaces.DIALOG

@Component({
  components: {
    CustomDialogActionButtonsComponent: () =>
      import('@/components/Dialog/CustomDialog/CustomDialogActionButtonsComponent.vue'),
    CustomDialogHeaderComponent: () => import('@/components/Dialog/CustomDialog/CustomDialogHeaderComponent.vue'),
    InfoBarComponent: () => import('@/components/InfoBar/InfoBarComponent.vue'),
    LexonSwitchComponent: () => import('@/components/forms/fields/LexonSwitch/LexonSwitchComponent.vue'),
    ReceiverFieldSelectComponent: () =>
      import('@/components/Dialog/customDialogs/firma/ReceiverFieldSelectComponent.vue'),
    SpinnerLayerComponent: () => import('@/components/Spinner/SpinnerLayerComponent.vue'),
    StaticAlertComponent
  }
})
export default class SendCertifiedSMSFirmaDialogComponent extends Mixins(FirmaDialogsMixin) {
  @Getter('getUserMobilePhone', { namespace: authModule })
  mobilePhone: string

  @Action('closeCustomDialog', { namespace: dialogModule })
  closeDialog: () => {}

  texts = {
    explanation: this.$t('components.firma_dialogs.send_certified_SMS.explanation'),
    firmaSwitchLabel: this.$t('components.firma_dialogs.send_certified_SMS.firma_switch'),
    primaryButtonText: this.$t('action_buttons.send'),
    title: this.$t('components.firma_dialogs.send_certified_SMS.title'),
    infoText: this.$t('components.firma_dialogs.send_certified_SMS.info_text'),
    errorText: this.$t('components.firma_dialogs.send_certified_SMS.error_text'),
    redirectButton: this.$t('components.firma_dialogs.send_certified_SMS.go_to_settings'),
    closeButton: this.$t('components.firma_dialogs.send_certified_SMS.no_thanks')
  }

  smsSource = sendTypeSource.SMS

  errorBar = InfoBarTypes.ERROR

  showErrorBar = false

  alert: AlertsData = {
    type: AlertsTypes.WARNING,
    message: this.texts.errorText,
    acceptLink: {
      tooltip: this.texts.redirectButton,
      text: this.texts.redirectButton,
      callback: () => this.goToConfiguration()
    },
    cancelLink: {
      tooltip: this.texts.closeButton,
      text: this.texts.closeButton,
      callback: () => this.closeDialog()
    }
  }

  checkIfRenderErrorStyles() {
    if ((this as any).firmaSwitch === '1') {
      const regexPattern = new RegExp('^[+,0-9]+$')
      this.showErrorBar = !regexPattern.test(this.mobilePhone)
    } else {
      this.showErrorBar = false
    }
  }

  get disabledActionButtonSMS() {
    return !(!!this.selectedReceiver.length && !this.showErrorBar)
  }

  goToConfiguration() {
    this.$router.push({
      name: `${URLS.CONFIGURATION_PANEL}-${URLS.CONFIGURATION_PANEL_USUARIOS}`
    })
    this.closeDialog()
  }
}
</script>
