import { MutationTree } from 'vuex'
import { AppointmentsState } from '@/store/modules/appointments/appointmentsTypes'

export const mutations: MutationTree<AppointmentsState> = {
  FETCH_APPOINTMENT(state, appointment) {
    state.appointment = appointment
  },
  FETCH_CALENDAR_APPOINTMENTS(state, appointments) {
    state.calendarAppointments = appointments
  },
  FETCH_EXPEDIENT_GLOBAL_VISION_WIDGET_APPOINTMENTS(state, widgetData) {
    state.expedientGlobalVisionWidgetAppointments = widgetData
  },
  RESET_EXPEDIENT_GLOBAL_VISION_WIDGET_APPOINTMENTS(state) {
    state.expedientGlobalVisionWidgetAppointments = null
  }
}
