<template lang="pug">

WidgetBaseComponent(
  class="expedient-profitability-widget"
  :loadedData="renderWidget && !showSpinner"
)
  //- HEADER
  template(v-slot:header)
    div(class="title-and-tooltip")
      h2 {{ title }}
      LexonTooltipComponent(
        v-if="userHasPermissionToInvoice"
        class="tooltip"
        :icon="icons.tooltip"
        :message="texts.tooltip"
        position="bottom"
      )
    button(
      v-if="userHasPermissionToInvoice"
      :class="[icons.openWindow, 'open-window-button', 'lf-icon__md']"
      @click="launchDialog"
    )

  //- BODY
  template(v-slot:body)
    div(class="resumes-container")
      LfWhiteCounterComponent(v-for="(counter, index) in counters" :key="`counter-${index}`" :info="counter")

</template>

<script lang="ts">
import { Component, Mixins, Watch } from 'vue-property-decorator'
import WidgetBaseComponent from '@/components/widgets/WidgetBaseComponent.vue'
import { Icons } from '@/icons/icons'
import { Action, Getter, Mutation } from 'vuex-class'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { CustomDialogComponentName, CustomDialogData } from '@/store/modules/dialog/dialogTypes'
import { Endpoint } from '@/store/modules/endpoint/endpointTypes'
import { Entity } from '@/store/modules/entities/entitiesTypes'
import { ProfitabilityDetail } from '@/store/modules/expedients/expedientsTypes'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'
import LfWhiteCounterComponent from '@/components/counters/LfWhiteCounterComponent.vue'
import LexonTooltipComponent from '@/components/forms/fields/LexonTooltip/LexonTooltipComponent.vue'

const dialogModule: string = ModuleNamespaces.DIALOG
const endpointModule = ModuleNamespaces.ENDPOINT
const entitiesModule = ModuleNamespaces.ENTITIES
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER
const expedientsModule = ModuleNamespaces.EXPEDIENTS

@Component({
  components: {
    LexonTooltipComponent,
    WidgetBaseComponent,
    LfWhiteCounterComponent
  }
})
export default class ExpedientProfitabilityWidget extends Mixins(PermissionsMixin) {
  @Action('fetchSelectedRegisterData', { namespace: selectedRegisterModule })
  fetchSelectedRegisterDataAction: ({}) => Promise<{}>

  @Action('showCustomDialog', { namespace: dialogModule })
  showCustomDialog: ({}: CustomDialogData) => {}

  @Action('fetchExpedientProfitabilityDetail', { namespace: expedientsModule })
  fetchExpedientProfitabilityDetailAction: (id: number) => Promise<any>

  @Mutation('REMOVE_SELECTED_REGISTER_DATA_CONTEXT', { namespace: selectedRegisterModule })
  removeSelectedRegisterDataContextMutation: (context: string) => void

  @Mutation('RESET_EXPEDIENT_PROFITABILITY_DETAIL', { namespace: expedientsModule })
  resetExpedientProfitabilityDetail: () => void

  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterDataGetter: (context: string) => {}

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterIdGetter: (context: string) => number

  @Getter('getEndpoints', { namespace: endpointModule })
  endpoints: (entityType: number) => Endpoint

  @Getter('getEntity', { namespace: entitiesModule })
  entity: (context: string) => Entity

  @Getter('getExpedientProfitabilityDetail', { namespace: expedientsModule })
  expedientProfitabilityDetailGetter: ProfitabilityDetail

  @Getter('getExpedientStageState', { namespace: expedientsModule })
  expedientStageStateGetter: string

  icons = {
    tooltip: Icons.INFO_FULL,
    visibleIcon: Icons.VISIBLE,
    openWindow: Icons.OPEN_WINDOW
  }

  texts = {
    details: this.$t('components.widgets.global_vision_profitability.details'),
    tooltip: this.$t('components.tooltip.profitability_global_vision_warning')
  }

  contexts = {
    profitabilityContext: ContextName.EXPEDIENT_GLOBAL_VISION_PROFITABILITY,
    expedientContext: ContextName.EXPEDIENTS
  }

  showSpinner = true

  get renderWidget(): boolean {
    return !!this.selectedExpedientProfitabilityInfo
  }

  get selectedExpedientId(): number {
    return this.selectedRegisterIdGetter(this.contexts.expedientContext)
  }

  get selectedExpedientProfitabilityInfo() {
    return (this as any).selectedRegisterDataGetter(this.contexts.profitabilityContext).globalVisionExpProfitability
  }

  get title() {
    return this.selectedExpedientProfitabilityInfo.title
  }

  get counters() {
    return this.selectedExpedientProfitabilityInfo.counters
  }

  @Watch('expedientStageStateGetter')
  updateData() {
    this.fetchGlobalVisionProfitabilityInfo()
  }

  async created() {
    await this.fetchExpedientProfitabilityDetailAction(this.selectedExpedientId)
    await this.fetchGlobalVisionProfitabilityInfo()
  }

  beforeDestroy() {
    this.removeSelectedRegisterDataContextMutation(this.contexts.profitabilityContext)
    this.resetExpedientProfitabilityDetail()
  }

  async fetchGlobalVisionProfitabilityInfo() {
    this.showSpinner = true

    const endpoint: string = this.endpoints(this.entity(this.contexts.expedientContext).type).get

    await this.fetchSelectedRegisterDataAction({
      endpoint,
      context: this.contexts.profitabilityContext,
      post: {
        idExpedient: this.selectedExpedientId,
        source: this.contexts.profitabilityContext,
        idStage: this.expedientStageStateGetter
      }
    })

    this.showSpinner = false
  }

  launchDialog() {
    this.showCustomDialog({
      name: CustomDialogComponentName.GLOBAL_VISION_PROFITABILITY,
      styles: {
        maxWidth: '940px',
        minHeight: '200px'
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.expedient-profitability-widget {
  --lf-counter-height: auto;
  --lf-counter-padding-top: $spacing-xs;
  min-height: 150px;
  border-radius: $cornerRadius-md;
  border: 1px solid $main-300;

  .open-window-button {
    color: $main-1000;
  }

  .title-and-tooltip {
    display: flex;

    ::v-deep .tooltip-icon {
      font-size: 16px;
    }
  }

  .resumes-container {
    display: flex;
    align-items: flex-start;
    gap: $spacing-sm;
    align-self: stretch;
  }
}
</style>
