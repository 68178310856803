<template>
  <section class="summary-info">
    <div class="row">
      <div>
        <span class="label">{{ summary[0].label }} (G):</span>
        <span class="data">{{ summary[0].value }}</span>
      </div>
      <div>
        <span class="label">{{ summary[1].label }} (I):</span>
        <span class="data">{{ summary[1].value }}</span>
      </div>
      <LfGrayCounterComponent :info="rentabilityCounter" />
    </div>
    <div class="row">
      <LfGrayCounterComponent v-for="(counter, index) in parseCounters.slice(-6, -3)" :info="counter" :key="index" />
    </div>
    <div class="row">
      <LfGrayCounterComponent v-for="(counter, index) in parseCounters.slice(-3)" :info="counter" :key="index" />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { CounterInfo } from '@/components/counters/types/CounterTypes'
import LfGrayCounterComponent from '@/components/counters/LfGrayCounterComponent.vue'

// import { ProfitabilitySummaryItem } from '@/components/Dialog/customDialogs/globalVisionProfitability/types/globalVisionProfitabilityDialogTypes'
// import { splitArray } from '@/helpers/array'

type Data = {
  label: string
  value: string
}

const { summary } = defineProps({
  summary: {
    type: Array as PropType<Array<Data>>,
    required: true
  }
})

// COMPUTED
const rentabilityCounter = computed<CounterInfo>(() => {
  return {
    title: `${summary[2].label} (G/I)`,
    qty: summary[2].value
  }
})

const parseCounters = computed(() => {
  return summary.map((item: any) => ({
    title: item.label,
    qty: item.value
  }))
})
</script>

<style lang="scss" scoped>
.summary-info {
  width: 100%;

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $spacing-xl;
    margin: $spacing-xl 0;

    &:last-of-type {
      margin-bottom: 0;
    }

    .label {
      @include milano-bold-16;
      color: $neutral-grey-800;
    }

    .data {
      @include milano-regular-16;
      color: $neutral-grey-800;
      margin-left: 8px;
    }
  }
}
</style>
