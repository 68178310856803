<template>
  <div class="note" @click="editNote(note)">
    <span :class="[icons.note, 'note-icon', 'lf-icon__md']" />
    <div class="info-container">
      <span class="note-name">{{ note.note.title }}</span>
      <p class="note-subject">{{ note.note.subject }}</p>
    </div>
    <span :class="[icons.remove, 'remove-icon', 'lf-icon__sm']" @click.stop="() => showRemoveDialog(note)" />
  </div>
</template>

<script lang="ts" setup>
import { Icons } from '@/icons/icons'
import { Note } from './ExpedientReminderNotesComponentTypes'
import { PropType } from 'vue/types/v3-component-props'

// PROPS
const { note } = defineProps({
  note: {
    type: Object as PropType<Note>,
    required: true
  }
})

const icons = {
  note: Icons.TASKS,
  remove: Icons.REMOVE
}

// EVENTS
const emit = defineEmits(['edit-note', 'show-remove-dialog'])

// METHODS
function editNote(note: Note) {
  emit('edit-note', note)
}

function showRemoveDialog(note: Note) {
  emit('show-remove-dialog', note)
}
</script>

<style lang="scss" scoped>
.note {
  width: 100%;
  display: flex;
  padding: $spacing-xxs $spacing-xs;
  align-items: flex-start;
  gap: $spacing-xs;
  align-self: stretch;
  border-left: 2px solid $main-1000;
  background: $neutral-grey-050;
  margin-bottom: $spacing-xs;
  cursor: pointer;

  .info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $spacing-xxs;
    flex: 1 0 0;
    width: 100%;

    .note-name {
      @include milano-bold-14;
      color: $main-1000;
    }

    .note-subject {
      @include ellipsis;
      @include milano-regular-14;
      color: $neutral-grey-800;
      padding: 0;
      margin: 0;
      max-width: 450px;
    }

    .note-icon,
    .remove-icon {
      color: $main-1000;
    }

    .remove-icon {
      cursor: pointer;
    }
  }
}
</style>
