<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { ActionName } from '@/components/grids/LfGrid/LfGridTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action, Getter } from 'vuex-class'

const authModule = ModuleNamespaces.AUTH
const configurationModule = ModuleNamespaces.CONFIGURATION

@Component
export default class ArchivedFilterMixin extends Vue {
  @Getter('getUserId', { namespace: authModule }) userId: string

  @Action('saveUserCustomConfigurationList', { namespace: configurationModule })
  saveListConfiguration: ({}) => void

  archivedSelectedOption: number | null = 0

  renderGrid: boolean = false

  actionsArchivedContextMenuItems = [
    {
      id: `${ActionName.ARCHIVED}_0`,
      text: this.$i18n.t('navigation.drawer-menu-items.actions_not_archived'),
      iconCss: Icons.RADIO_ACTIVE,
      actionType: 0
    },
    {
      id: `${ActionName.ARCHIVED}_1`,
      text: this.$i18n.t('navigation.drawer-menu-items.actions_archived'),
      iconCss: Icons.CIRCLE_O,
      actionType: 1
    },
    {
      id: `${ActionName.ARCHIVED}_2`,
      text: this.$i18n.t('navigation.drawer-menu-items.actions_all'),
      iconCss: Icons.CIRCLE_O,
      actionType: 2
    }
  ]

  expedientsArchivedContextMenuItems = [
    {
      id: `${ActionName.ARCHIVED}_0`,
      text: this.$i18n.t('navigation.drawer-menu-items.expedient_not_archived'),
      iconCss: Icons.RADIO_ACTIVE,
      actionType: 0
    },
    {
      id: `${ActionName.ARCHIVED}_1`,
      text: this.$i18n.t('navigation.drawer-menu-items.expedient_archived'),
      iconCss: Icons.CIRCLE_O,
      actionType: 1
    },
    {
      id: `${ActionName.ARCHIVED}_2`,
      text: this.$i18n.t('navigation.drawer-menu-items.expedient_all'),
      iconCss: Icons.CIRCLE_O,
      actionType: 2
    }
  ]

  invoicesArchivedContextMenuItems = [
    {
      id: `${ActionName.ARCHIVED}_0`,
      text: this.$i18n.t('navigation.drawer-menu-items.invoices_this_tear'),
      iconCss: Icons.CIRCLE_O,
      actionType: 0
    },
    {
      id: `${ActionName.ARCHIVED}_1`,
      text: this.$i18n.t('navigation.drawer-menu-items.invoices_last_two'),
      iconCss: Icons.RADIO_ACTIVE,
      actionType: 1
    },
    {
      id: `${ActionName.ARCHIVED}_2`,
      text: this.$i18n.t('navigation.drawer-menu-items.invoices_tree'),
      iconCss: Icons.CIRCLE_O,
      actionType: 2
    },
    {
      id: `${ActionName.ARCHIVED}_3`,
      text: this.$i18n.t(`navigation.drawer-menu-items.invoices_show_all`),
      iconCss: Icons.CIRCLE_O,
      actionType: 3
    }
  ]

  get invoicesArchivedContextMenuItemsParsed() {
    return this.invoicesArchivedContextMenuItems
  }

  get selectArchivedOption() {
    return this.invoicesArchivedContextMenuItems.map((item) => ({
      ...item,
      iconCss: item.actionType === this.archivedSelectedOption ? Icons.RADIO_ACTIVE : Icons.CIRCLE_O
    }))
  }

  get selectExpedientArchiveOptions() {
    return this.expedientsArchivedContextMenuItems.map((item) => ({
      ...item,
      iconCss: item.actionType === this.archivedSelectedOption ? Icons.RADIO_ACTIVE : Icons.CIRCLE_O
    }))
  }

  get selectActionsArchiveOptions() {
    return this.actionsArchivedContextMenuItems.map((item) => ({
      ...item,
      iconCss: item.actionType === this.archivedSelectedOption ? Icons.RADIO_ACTIVE : Icons.CIRCLE_O
    }))
  }

  async initializeArchivedSelectedOption({
    selectedOption,
    contextMenu
  }: {
    selectedOption: number
    contextMenu: string
  }) {
    if ((this as any).listConfiguration['Config']) {
      const config = JSON.parse((this as any).listConfiguration['Config'])
      this.archivedSelectedOption = Number(config.hasOwnProperty('archived') ? config.archived : selectedOption)
      await this.$nextTick()
      this.changeContextMenuIcons(this.archivedSelectedOption, contextMenu)
    }
  }

  changeContextMenuIcons(actionType: number | null, idContextMenu: string) {
    const elem: any = document.getElementById(idContextMenu)
    if (null !== elem) {
      elem.ej2_instances[0].items.map((item: any) => {
        if (item.actionType === actionType) {
          item.iconCss = Icons.RADIO_ACTIVE
        } else {
          item.iconCss = Icons.CIRCLE_O
        }
      })
    }
  }

  isArchiveContextMenuFirstLevel(name: string | undefined) {
    return name && name === ActionName.ARCHIVED ? true : false
  }

  async saveConfig(selectedOption: number | null) {
    const config = JSON.parse((this as any).listConfiguration['Config'])
    const listName = (this as any).listConfiguration['Alias']
    config['archived'] = selectedOption

    const dataToSend = {
      IdUser: this.userId,
      Alias: listName,
      Config: config
    }

    await this.saveListConfiguration(dataToSend)
  }
}
</script>
