<template lang="pug">

section(v-if="allLoaded" class="dashboard-container")
  LfHeaderTitleComponent(:title="$t('navigation.drawer-menu-items.home')")
  DashboardActionsWidget(v-if="hasActionsPermission")
  DashboardCountersWidget
  LexboxCalendarComponent(v-if="hasAgendaPermission")

</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import DashboardActionsWidget from '@/components/widgets/DashboardWidgets/actions/DashboardActionsWidget.vue'
import DashboardCountersWidget from '@/components/widgets/DashboardWidgets/counters/DashboardCountersWidget.vue'
import LfHeaderTitleComponent from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'
import LexboxCalendarComponent from '@/components/lexbox/LexboxCalendar/LexboxCalendarComponent.vue'
import { from, next, to } from '@/router/types/routerTypes'
import { Getter } from 'vuex-class'
import { PermissionVerbs } from '@/store/modules/auth/authTypes'
import { entity } from '@/store/modules/entities/entitiesTypes'
import { URLS } from '@/router/routes/urlRoutes'

const authModule: string = ModuleNamespaces.AUTH
const configurationModule: string = ModuleNamespaces.CONFIGURATION
const expedientsModule: string = ModuleNamespaces.EXPEDIENTS
const menusModule: string = ModuleNamespaces.MENUS
const spinnerModule: string = ModuleNamespaces.SPINNER

@Component({
  components: {
    DashboardActionsWidget,
    DashboardCountersWidget,
    LexboxCalendarComponent,
    LfHeaderTitleComponent
  }
})
export default class DashboardView extends Vue {
  @Getter('getPermission', { namespace: authModule })
  checkEntityPermissionsGetter: (entityId: number) => PermissionVerbs

  @Watch('allLoaded')
  checkIfHideSpinnerLayer(value: boolean) {
    if (value) {
      store.dispatch(`${spinnerModule}/hideSpinnerLayer`)
    }
  }

  allLoaded = false

  get hasActionsPermission() {
    return this.checkEntityPermissionsGetter(entity.actions.type).canView
  }

  get hasAgendaPermission() {
    return this.checkEntityPermissionsGetter(entity.agenda.type).canView
  }

  async beforeRouteEnter(to: to, _from: from, next: next<DashboardView>) {
    localStorage.removeItem('pre-auth-data')

    await store.dispatch(`${configurationModule}/fetchUserProfileConfiguration`)

    if (store.getters[`${ModuleNamespaces.MENUS}/getConfigurationMenu`].length === 0) {
      await store.dispatch(`${menusModule}/fetchConfigurationMenu`)
    }

    if (store.getters[`${ModuleNamespaces.MENUS}/getSiteMenu`].length === 0) {
      await store.dispatch(`${menusModule}/fetchSiteMenu`)
    }

    if (store.getters[`${ModuleNamespaces.EXPEDIENTS}/getExpedientSectionsToDuplicate`].length === 0) {
      store.dispatch(`${expedientsModule}/fetchExpedientSectionsToDuplicate`)
    }

    if (store.getters[`${ModuleNamespaces.AUTH}/getIsLexonDmsUser`] && to.name === URLS.DASHBOARD) {
      next(`/${URLS.REPOSITORY}`)
    }

    if (store.getters[`${ModuleNamespaces.AUTH}/getIsPortalUser`] && to.name === URLS.DASHBOARD) {
      next(`/${URLS.EXPEDIENTS}`)
    }

    next()
  }

  mounted() {
    this.allLoaded = true
  }
}
</script>

<style lang="scss" scoped>
.dashboard-container {
  width: 100%;
  height: 100%;
  padding: 167px 30px 15px;
}

@include tabletAndMore {
  .dashboard-container {
    padding-top: $app-bar-height + 14px;
  }
}

@include desktopLarge {
  .dashboard-container {
    grid-template-columns: 49% 49%;
  }
}
</style>
