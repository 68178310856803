<template lang="pug">
section(class="lexbox-redirect-container")
  HeaderComponent
  section(class="lexbox-redirect-section")
  PromptDialogComponent(
      :width="753"
      :title="texts.dialogTitle"
      :subTitle="texts.dialogSubTitle"
      :isOpened="showDialog"
      :formSchema="nifSchema"
      context="sso-payments"
      :formFieldValues="formFieldValues"
      @execute-action="executeDialogAction"
      :texts="texts.buttons"
    )
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import PromptDialogComponent from '@/components/Dialog/PromptDialogComponent.vue'
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import { MainService } from '@/services/MainService'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { URLS } from '@/router/routes/urlRoutes'

const authModule = ModuleNamespaces.AUTH
const spinnerModule = ModuleNamespaces.SPINNER

@Component({
  components: {
    HeaderComponent,
    PromptDialogComponent
  }
})
export default class SsoPaymentsView extends Vue {
  @Action('saveUserNif', { namespace: authModule })
  saveUserNif: ({}) => Promise<{ result: string; code: number }>
  @Action('refreshToken', { namespace: authModule })
  refreshToken: () => void

  @Mutation('SHOW_SPINNER', { namespace: spinnerModule })
  showSpinner: (showText: boolean) => void

  @Getter('getUserNif', { namespace: authModule })
  nif: string
  @Getter('getAuthToken', { namespace: authModule })
  accessToken: string

  showDialog = false
  formFieldValues = {}
  texts = {
    dialogTitle: this.$t('components.dialog.sso_paymens.title'),
    dialogSubTitle: this.$t('components.dialog.sso_paymens.sub_title'),
    nifLabel: this.$t('components.dialog.sso_paymens.dni_label'),
    buttons: {
      closeButton: this.$t('action_buttons.cancel'),
      saveButton: this.$t('action_buttons.save')
    }
  }

  nifSchema = [
    {
      id: 'nif',
      name: 'nif',
      type: 'nif',
      label: this.texts.nifLabel,
      fieldType: 'LexonTextFieldComponent',
      validationRules: 'required|max:200',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      'offset-xs': 0,
      'offset-sm': 0,
      'offset-md': 0,
      'offset-lg': 0,
      'offset-xl': 0,
      props: { maxlength: '15' }
    }
  ]

  created() {
    if (!this.nif) {
      this.showDialog = true
    } else {
      this.showSpinner(true)
      this.redirectToService()
    }
  }

  executeDialogAction(actionName: string) {
    switch (actionName) {
      case ActionName.CLOSE:
        this.showDialog = false
        window.location.replace(`/${URLS.DASHBOARD}`)
        break
      case ActionName.SAVE:
        return this.saveNif()
    }
  }

  async saveNif() {
    const nif = (this as any).formFieldValues.nif
    const { code } = await this.saveUserNif(nif)
    await this.refreshToken()
    if (code === 0) {
      this.redirectToService()
    } else {
      window.location.replace(`/${URLS.DASHBOARD}`)
    }
  }

  async redirectToService() {
    const url = `/sso-payments/url-login/get`
    const { data } = await new MainService().refreshAccessToken(this.accessToken).getData(url)
    window.location.replace(data)
  }
}
</script>

<style lang="scss" scoped>
.lexbox-redirect {
  &-container {
    @include external-view-layout;
  }

  &-section {
    @include flex;
    flex-direction: column;
    margin: 30px;
  }

  &-card {
    @include flex($flex-direction: column, $justify-content: flex-start);
    @include borders;
    width: 450px;
    padding-top: 50px;
  }
}
</style>
