<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action, Getter, Mutation } from 'vuex-class'
import { TaxesFormFields } from '@/mixins/TaxesDynamicFormFields/types/TaxesDynamicFormFieldsMixinTypes'
import { InvoiceTypes } from '@/store/modules/invoices/invoicesTypes'

const expedientsModule = ModuleNamespaces.EXPEDIENTS
const invoicesModule = ModuleNamespaces.INVOICES

@Component
export default class TaxesDynamicFormFieldsMixin extends Vue {
  @Getter('getTaxesItems', { namespace: expedientsModule })
  taxesItems: []
  @Getter('getInvoiceType', { namespace: invoicesModule })
  invoiceType: string

  @Action('fetchEconomicDataTaxesItems', { namespace: expedientsModule })
  fetchEconomicDataTaxesItems: ({}) => Promise<void>

  @Action('fetchProviderTaxesItems', { namespace: expedientsModule })
  fetchProviderTaxesItems: ({}) => Promise<void>

  @Mutation('RESET_TAXES_ITEMS', { namespace: expedientsModule })
  resetTaxesItems: () => void

  lastDynamicFields = []

  executingAPICall: boolean = false

  isExpedientPeriodicBillingTab: boolean = false

  periodicBillingFieldset = []

  periodicBillingFieldsetInitialFieldsNumber: number = 6

  mixinTexts = {
    chargeDate: this.$t('components.expedient_economic_data.charge_date'),
    charged: this.$t('components.expedient_economic_data.charged'),
    dueDate: this.$t('components.expedient_economic_data.due_date')
  }

  get chargeDateField() {
    return {
      id: 'chargeDate',
      name: 'chargeDate',
      label: this.mixinTexts.chargeDate,
      fieldType: 'LexonDatePickerComponent',
      inputFormat: 'YYYY-MM-DD',
      placeholder: this.mixinTexts.chargeDate,
      outputFormat: 'YYYY-MM-DD',
      'offset-md': this.invoiceType ? 1 : 0,
      'offset-lg': this.invoiceType ? 1 : 0,
      'offset-closed-menu-lg': 0,
      hidden: Number((this as any).formData.charged) ? false : true,
      validationRules: 'required',
      disabled: false
    }
  }

  get provissionFields() {
    return [
      {
        id: 'dueDate',
        name: 'dueDate',
        label: this.mixinTexts.dueDate,
        fieldType: 'LexonDatePickerComponent',
        inputFormat: 'YYYY-MM-DD',
        placeholder: this.mixinTexts.dueDate,
        outputFormat: 'YYYY-MM-DD',
        'offset-md': this.invoiceType ? 1 : 0,
        'offset-lg': this.invoiceType ? 1 : 0,
        'offset-closed-menu-lg': 0,
        disabled: false
      },
      {
        id: 'charged',
        name: 'charged',
        label: this.mixinTexts.charged,
        fieldType: 'LexonSwitchComponent',
        'offset-md': !this.isExpedientPeriodicBillingTab ? 1 : 0,
        'offset-lg': !this.isExpedientPeriodicBillingTab ? 1 : 0,
        'offset-closed-menu-lg': 0,
        disabled: false
      }
    ]
  }

  get dynamicTypeFieldsCreated() {
    const newFields = this.taxesItems.map((item: any, index: number) => {
      const offset = this.invoiceType ? (index % 2 ? 0 : 1) : index % 2 ? 1 : 0
      return {
        id: String(item.id),
        label: item.group,
        name: item.group,
        itemName: 'name',
        itemValue: 'id',
        valueHasToBeTypeNumber: true,
        attach: false, 
        fieldType: 'LexonAutocompleteComponent',
        propItems: item.taxes,
        'offset-md': !this.isExpedientPeriodicBillingTab ? offset : 0,
        'offset-lg': !this.isExpedientPeriodicBillingTab ? offset : 0,
        'offset-closed-menu-lg': 0,
        validationRules: 'required',
        disabled: false
      }
    })
    return newFields
  }

  setDynamicFormDataFieldsValues() {
    this.removeLastDynamicFields()
    this.taxesItems.forEach((item: any) => {
      const tax = item.taxes.find((tax: any) => tax.default === 1)
      if ('undefined' !== typeof tax) {
        const fieldValue = (this as any).formData[item.group] || tax.id
        Vue.set((this as any).formData, item.group, fieldValue)
      }
    })
  }

  removeLastDynamicFields() {
    if (this.lastDynamicFields.length) {
      this.lastDynamicFields.forEach((item: any) => {
        Vue.delete((this as any).formData, item.group)
      })
    }
  }

  async addTaxesFields(
    schema: any,
    value: any,
    initialFormFieldsLength: number = 4,
    isExpedientPeriodicBillingTab = false
  ) {
    if (isExpedientPeriodicBillingTab) {
      this.isExpedientPeriodicBillingTab = true
      this.periodicBillingFieldset = schema[1].fields
    } else {
      this.isExpedientPeriodicBillingTab = false
      this.periodicBillingFieldset = []
    }
    if (!this.executingAPICall) {
      const typeId =
        (this as any).formData[TaxesFormFields.PERIODIC_BILLING_ID_ECONOMIC_DATA_TYPE] ||
        (this as any).formData[TaxesFormFields.TYPE].id ||
        (this as any).formData[TaxesFormFields.TYPE]

      const isProvision = value.isProvision || (this as any).formData[TaxesFormFields.IS_PROVISION]

      if (this.isExpedientPeriodicBillingTab) {
        if (this.periodicBillingFieldset.length > this.periodicBillingFieldsetInitialFieldsNumber) {
          const finalFormFieldsLength = this.periodicBillingFieldset.length + this.taxesItems.length
          for (let i = this.periodicBillingFieldset.length; i < finalFormFieldsLength; i++) {
            Vue.delete(this.periodicBillingFieldset, this.periodicBillingFieldsetInitialFieldsNumber)
          }
        }
      } else {
        if (schema.length > initialFormFieldsLength) {
          const finalFormFieldsLength = initialFormFieldsLength + this.taxesItems.length
          for (let i = initialFormFieldsLength; i < finalFormFieldsLength; i++) {
            Vue.delete(schema, initialFormFieldsLength)
          }
        }
      }

      // Selected provission type
      if (typeof isProvision !== 'undefined' && typeId && isProvision.toString() === '1' && !this.invoiceType) {
        this.removeLastDynamicFields()
        this.provissionFields.forEach((_field: any, index: number) => {
          this.provissionFields[index].disabled = false
          if (this.isExpedientPeriodicBillingTab) {
            Vue.set(
              this.periodicBillingFieldset,
              this.periodicBillingFieldsetInitialFieldsNumber + index,
              this.provissionFields[index]
            )
          } else {
            Vue.set(schema, initialFormFieldsLength + index, this.provissionFields[index])
          }
        })
        return
      }

      if (typeId) {
        this.resetTaxesItems()
        Vue.delete((this as any).formData, 'dueDate')
        this.executingAPICall = true
        if (this.invoiceType === InvoiceTypes.PROVIDER) {
          await this.fetchProviderTaxesItems(typeId)
        } else {
          await this.fetchEconomicDataTaxesItems(typeId)
        }
        this.executingAPICall = false
        this.setDynamicFormDataFieldsValues()
        this.lastDynamicFields = this.taxesItems
        await this.$nextTick()
        this.dynamicTypeFieldsCreated.forEach((_field: any, index: number) => {
          if (this.isExpedientPeriodicBillingTab) {
            Vue.set(
              this.periodicBillingFieldset,
              this.periodicBillingFieldsetInitialFieldsNumber + index,
              this.dynamicTypeFieldsCreated[index]
            )
          } else {
            Vue.set(schema, initialFormFieldsLength + index, this.dynamicTypeFieldsCreated[index])
          }
        })
      }
    }
  }

  addChargedDate(schema: [], value: any, fieldPosition: number = 6) {
    if (this.isExpedientPeriodicBillingTab) {
      if (Number(value)) {
        Vue.set(this.periodicBillingFieldset, fieldPosition, this.chargeDateField)
      } else {
        Vue.delete(this.periodicBillingFieldset, fieldPosition)
        Vue.set((this as any).formData, 'chargeDate', null)
      }
    } else {
      if (Number(value)) {
        Vue.set(schema, fieldPosition, this.chargeDateField)
      } else {
        Vue.delete(schema, fieldPosition)
        Vue.set((this as any).formData, 'chargeDate', null)
      }
    }
  }
}
</script>
