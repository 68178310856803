export enum LabelButtonSize {
  SM = 'sm',
  MD = 'md'
}

export enum LabelButtonVariant {
  SOLID = 'solid',
  OUTLINED = 'outlined',
  TEXT = 'text',
  GHOST = 'ghost'
}

export enum IconButtonSize {
  XL = 'xl',
  LG = 'lg',
  MD = 'md',
  SM = 'sm',
  XS = 'xs'
}

export enum IconButtonVariant {
  SOLID = 'solid',
  OUTLINED = 'outlined',
  JUST = 'just',
  JUST_WHITE = 'just-white'
}
