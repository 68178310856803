import { ActionTree } from 'vuex'
import { AppointmentsState, appointmentsMutationTypes } from '@/store/modules/appointments/appointmentsTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { AppointmentsService } from '@/services/AppointmentsService'
import { MainService } from '@/services/MainService'

export const actions: ActionTree<AppointmentsState, RootState> = {
  async fetchAppointment({ commit }, idAppointment) {
    try {
      const url = `appointments/${idAppointment}/get`
      const { data } = await new AppointmentsService().getData(url)
      commit(appointmentsMutationTypes.FETCH_APPOINTMENT, data[0])
    } catch (error) {}
  },
  async fetchCalendarAppointments({ commit }, dates) {
    try {
      const { end, start } = dates
      const url = `appointments/my/${start}/${end}`
      const { data } = await new AppointmentsService().getData(url)
      commit(appointmentsMutationTypes.FETCH_CALENDAR_APPOINTMENTS, data)
    } catch (error) {}
  },
  async deleteAppointment({}, idAppointment) {
    try {
      const url = `/appointments/${idAppointment}/delete`
      await new AppointmentsService().deleteData(url)
    } catch (error) {}
  },
  async fetchExpedientGlobalVisionAppointments({ commit }, filter) {
    try {
      const url = 'appointments/get'
      const { data } = await new MainService().postData(url, filter)
      commit(appointmentsMutationTypes.FETCH_EXPEDIENT_GLOBAL_VISION_WIDGET_APPOINTMENTS, data)
    } catch (error) {}
  }
}
