<template lang="pug">

  transition(name="fade" appear)
    component(
      :is="renderCustomAlert"
      :whereIsRendered="whereIsRendered"
      :durationTime="durationTime"
    )

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { AlertMode } from '@/store/modules/alerts/alertsTypes'
import FullAlert from '@/components/Alert/CustomAlert/FullAlert.vue'
import IconAlert from '@/components/Alert/CustomAlert/IconAlert.vue'
import MessageAlert from '@/components/Alert/CustomAlert/MessageAlert.vue'
import SubMessageAlert from '@/components/Alert/CustomAlert/SubMessageAlert.vue'

const namespace: string = ModuleNamespaces.ALERTS
const customAlertComponents: any = {
  [AlertMode.FULL]: FullAlert,
  [AlertMode.ICON]: IconAlert,
  [AlertMode.MESSAGE]: MessageAlert,
  [AlertMode.SUBMESSAGE]: SubMessageAlert
}
@Component({
  components: customAlertComponents
})
export default class AlertComponent extends Vue {
  @Prop({
    type: String,
    required: true
  })
  whereIsRendered!: string

  @Prop({
    type: Number,
    default: 5000
  })
  durationTime!: number

  @Getter('getAlertMode', { namespace }) alertMode: AlertMode

  get renderCustomAlert() {
    return customAlertComponents[this.alertMode]
  }
}
</script>

<style lang="scss" scoped>
@include fade-transition;
</style>
