import { Ref, computed } from 'vue';
import { getPdfExportProperties } from '../LfGridTypes';
import { GridComponent as EjsGrid, PdfExportCompleteArgs, PdfQueryCellInfoEventArgs } from '@syncfusion/ej2-vue-grids'
import { capitalizeFirstLetter } from '@/helpers/strings';

interface PropsUseGridMethods {
  gridRef: Ref<InstanceType<typeof EjsGrid> | null>
  exportName?: string
}

interface ResponseUseGridMethods {
  getSelectedRecords: () => any[]
  openColumnChooser: (x: number, y: number) => void
  exportToPDF: () => void
  excelExport: () => void
  onPdfQueryCellInfo: (args: PdfQueryCellInfoEventArgs) => void
  onPdfExportComplete: (args: PdfExportCompleteArgs) => void
  search: (value?: any) => void
  searchClear: () => void
}

export default function useGridMethods(props: PropsUseGridMethods): ResponseUseGridMethods {
  const { gridRef, exportName = 'export' } = props

  const excelExport = () => {
    if (gridRef.value) {
      const fileName = `${capitalizeFirstLetter(exportName)}.xlsx`
      const excelExportProperties = {
        fileName
      }
        ; (gridRef.value as any).excelExport(excelExportProperties)
    }
  }

  const onPdfExportComplete = async (args: PdfExportCompleteArgs) => {
    const { promise } = args
    const { blobData } = await promise as any
    const blobURL = URL.createObjectURL(blobData)
    window.open(blobURL)
  }

  const onPdfQueryCellInfo = (args: PdfQueryCellInfoEventArgs) => {
    if (args.column?.format === 'C2' && args.value === '') {
      args.value = '00,00 €'
    }
  }

  const exportToPDF = () => {
    if (gridRef.value) {
      const pdfExportProperties = getPdfExportProperties(capitalizeFirstLetter(exportName))
      ; (gridRef.value as any).pdfExport(pdfExportProperties, null, null, true)
    }
  }

  const openColumnChooser = (x: number, y: number) => {
    if (gridRef.value) {
      ; (gridRef.value as any).openColumnChooser(x, y)
    }
  }

  const getSelectedRecords = () => {
    if (gridRef.value) {
      const records = (gridRef.value as any).getSelectedRecords()
      return records
    }
  }

  const search = (args?: any) => {
    const { target, value } = args
    if (target.id.includes('clearbutton')) {
      searchClear()
    }

    if (!target.id.includes('searchbutton')) {
      return
    }

    const searchText = value || searchKey.value

    if (gridRef.value) {
      ; (gridRef.value as any).search(searchText)
    }
  }

  const searchClear = () => {
    if (gridRef.value) {
      ;(gridRef.value as any).ej2Instances.searchSettings.key = ''
      const inputText = getInputSearch()
      inputText.value = ''
    }
  }

  const searchKey = computed(() => {
    if (gridRef.value) {
      const inputText = getInputSearch()
      ;(gridRef.value as any).ej2Instances.searchSettings.key = inputText.value
      return inputText.value
    }
  })

  const getInputSearch = () => {
    return document.getElementById((gridRef.value as any).$el.id + "_searchbar") as any
  }

  return {
    getSelectedRecords,
    openColumnChooser,
    excelExport,
    exportToPDF,
    onPdfQueryCellInfo,
    onPdfExportComplete,
    search,
    searchClear
  }
}
