import { createElement } from '@syncfusion/ej2-base'
import { TextBox } from '@syncfusion/ej2-inputs'
import { i18n } from '@/plugins/vue-i18n'
import { inputInstance, setInputInstance, setParsedFilterSettingsFlag } from '@/components/grids/CustomFilters/sharedVariables'
import { IFilter } from '@syncfusion/ej2-grids'

const customFiler: IFilter = {
  ui: {
    create: (args: any) => {
      const flValInput = createElement('input', { className: 'flm-input' })
      flValInput.setAttribute('placeholder', i18n.t('components.grid_table.enter_search').toString())
      args.target.appendChild(flValInput)
      const inputInstance = new TextBox({})
      inputInstance.appendTo(flValInput)
      setInputInstance(inputInstance)
    },
    write: (args: any) => {
      inputInstance.value = args.filteredValue ? args.filteredValue : ''
    },
    read: (args: any) => {
      setParsedFilterSettingsFlag(true)
      if (args.fltrObj.value && args.fltrObj.value.length > 0) {
        args.fltrObj.clearFiltering([args.column.field])
      }
      args.fltrObj.filterByColumn(args.column.field, args.operator, inputInstance.value)
    }
  }
}

export default function getCustomFiler() {
  return customFiler
}
