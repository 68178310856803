<template>
  <section class="help-container">
    <div class="help-title">
      <span class="help-text">{{ text.help }}</span>
    </div>
    <div class="help-info">
      <div>
        <span class="call-us">{{ text.callUs }}</span>
      </div>
      <div>
        <span><a class="email" href="mailto:clientes@lefebvre.es">clientes@lefebvre.es</a></span>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n-composable'

// TRANSLATIONS
const { t } = useI18n()
const text = {
  callUs: t('views.login.call_us') as string,
  help: t('views.login.help') as string,
  writeUs: t('views.login.write_us') as string
}
</script>

<style lang="scss" scoped>
.help-container {
  display: flex;
  padding: $spacing-md;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-xs;
  align-self: stretch;
  background: $main-050;

  .help-title {
    display: flex;
    align-items: center;
    gap: $spacing-xs;

    .help-text {
      @include poppins-semibold-18;
      color: $neutral-grey-700;
    }
  }

  .help-info {
    @include flex;
    @include poppins-regular-16;
    color: $neutral-grey-700;

    .call-us {
      @include poppins-regular-14;
      display: inline-block;
      position: relative;
      color: $neutral-grey-800;

      &::after {
        content: ' ';
        display: inline-block;
        position: absolute;
        width: 1px;
        height: 20px;
        background-color: $neutral-grey-400;
        margin-left: 6px;
      }
    }

    .email {
      text-decoration: none;
      margin-left: 12px;
      margin-top: $spacing-xxs;
      color: $tertiary-byzantineBlue-200;
    }
  }
}
</style>
