export interface SpinnerState {
  spinner: {
    message: boolean
    show: boolean
  }
  showSpinnerLayer: boolean
}

export const spinnerMutationTypes = {
  HIDE_SPINNER: 'HIDE_SPINNER',
  HIDE_SPINNER_LAYER: 'HIDE_SPINNER_LAYER',
  SHOW_SPINNER: 'SHOW_SPINNER',
  SHOW_SPINNER_LAYER: 'SHOW_SPINNER_LAYER'
}
