<script lang="ts">
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Vue, Component } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { FirmaSignInfo, FirmaRequestTypes } from '@/store/modules/firma/firmaTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'

const dialogModule: string = ModuleNamespaces.DIALOG
const fileManagerModule: string = ModuleNamespaces.FILE_MANAGER
const firmaModule: string = ModuleNamespaces.FIRMA

@Component
export default class FirmaDialogsMixin extends Vue {
  @Action('signFirmaStart', { namespace: firmaModule })
  signFirmaStartAction: (firmaData: any) => Promise<string>

  @Action('signFirmaOpen', { namespace: firmaModule })
  openFirmaStartAction: (firmaData: any) => Promise<string>

  @Action('closeCustomDialog', { namespace: dialogModule })
  closeCustomDialog: () => {}

  @Action('showDialog', { namespace: dialogModule })
  showDialogAction: ({}) => {}

  @Mutation('REFRESH_FILE_MANAGER', { namespace: fileManagerModule })
  refreshFileManager: () => void

  @Getter('getSignInfo', { namespace: firmaModule })
  signInfoGetter: FirmaSignInfo

  @Getter('getFirmaToken', { namespace: firmaModule })
  firmaTokenGetter: string

  selectedReceiver = []

  loadingSpinner = false

  spinnerText = {
    sending: this.$t('components.spinner.sending_data'),
    receiving: this.$t('components.spinner.receiving_data')
  }

  firmaSwitch = false

  get disabledActionButton() {
    return !this.selectedReceiver.length
  }

  async signFirma() {
    try {
      this.loadingSpinner = true
      const firmaData = {
        idType: this.signInfoGetter.selectedRequestType,
        idDocument: this.signInfoGetter.selectedFileId,
        env: process.env.VUE_APP_LEXBOX_ENV,
        receivers: this.parseReceivers(),
        firmaSwitch: this.firmaSwitch
      }
      await this.signFirmaStartAction(firmaData)
      this.showDialogAction({
        type: DialogTypes.SUCCESS,
        message: this.getSuccessMessage(this.signInfoGetter.selectedFileName)
      })
      if (this.signInfoGetter.selectedRequestType !== FirmaRequestTypes.CERTIFIED_DOCUMENT) {
        window.open(`${process.env.VUE_APP_LEXBOX_SIGNATURE}/${this.firmaTokenGetter}`)
      }
    } catch (error) {
      this.showDialogAction({
        type: DialogTypes.ERROR,
        message: this.$t('errors.generic_error')
      })
    } finally {
      this.refreshFileManager()
      this.loadingSpinner = false
      this.closeCustomDialog()
    }
  }

  async seeDetails(selectedRegister: any) {
    try {
      this.loadingSpinner = true
      const firmaData = {
        idType: selectedRegister.idType,
        guid: selectedRegister.guid
      }
      await this.openFirmaStartAction(firmaData)
      window.open(`${process.env.VUE_APP_LEXBOX_SIGNATURE}/${this.firmaTokenGetter}`)
    } catch (error) {
      this.showDialogAction({
        type: DialogTypes.ERROR,
        message: this.$t('errors.generic_error')
      })
    } finally {
      this.loadingSpinner = false
    }
  }

  setReceiver(receiver: any) {
    this.selectedReceiver = receiver
  }

  getSuccessMessage(data: any) {
    switch (this.signInfoGetter.selectedRequestType) {
      case FirmaRequestTypes.CERTIFIED_EMAIL:
        return this.$t('components.firma_dialogs.success_email_cert_message')
      case FirmaRequestTypes.CERTIFIED_SMS:
        return this.$t('components.firma_dialogs.success_sms_cert_message')
      case FirmaRequestTypes.CERTIFIED_DOCUMENT:
        return this.$t('components.firma_dialogs.success_document_cert_message', {doc: data})
      default:
        return this.$t('components.firma_dialogs.success_firma_message', {doc: data})
    }
  }

  parseReceivers() {
    const finalReceivers = this.selectedReceiver.map((receiver: any) => {
      return {
        email:
          this.signInfoGetter.selectedRequestType === FirmaRequestTypes.CERTIFIED_SMS
            ? receiver.mobilePhone
            : receiver.email
      }
    })
    return finalReceivers
  }
}
</script>

<style lang="scss" scoped>
.firma-dialog-container {
  position: relative;

  .explanation {
    @include milano-regular-14;
    margin-top: 30px;
  }

  .switch-field {

    ::v-deep .v-input--selection-controls {
      margin-top: 8px;
    }

    &.error-type {
      ::v-deep .v-label,
      ::v-deep .lf-icon-switch-right {
        color: $error-color !important;
      }
    }
  }

  .error-message {
    position: absolute;
    bottom: 40px;
    margin-bottom: 14px;
  }

  .custom-dialog-action-buttons-container {
    margin-top: 40px;
  }

  ::v-deep .lf-select-container.show-all-chips .v-select__slot {
    padding: 2px 10px 2px 4px;
  }

  .spinner-layer {
    --spinner-layer-min-height: 95%;
    position: absolute;
    top: 48px;
    left: 0;
    right: 0;
    background-color: $white-01;
  }
}
</style>
