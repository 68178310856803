import { LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'
import { LocaleMessages, TranslateResult } from 'vue-i18n'

export interface ActionBarButton {
  icon?: string
  class?: string
  mode?: string
  tooltip: TranslateResult | string | LocaleMessages
  action: ActionName
  prependIcon?: string
  enabled: boolean | (() => void)
  hidden?: boolean
  variant?: LabelButtonVariant
  isContextMenuOption?: boolean
}

export enum ActionBarButtonMode {
  ROUND = 'round',
  SQUARE = 'square'
}

export enum ActionName {
  ADD = 'add',
  BIND = 'bind',
  CANCEL = 'cancel',
  CLOSE = 'close',
  CONVERT_TO_CUSTOMER_INVOICE = 'converToCustomerInvoice',
  CUSTOM_OPTION = 'customOption',
  DISCARD = 'discard',
  DOWNLOAD = 'download',
  DUPLICATE_EXPEDIENT = 'duplicateExpedient',
  EDIT = 'edit',
  FILTER_REPORT = 'filterReport',
  FINISH_INVOICE_GENERATION = 'finishInvoiceGeneration',
  GENERATE_DOCUMENT = 'generateDocument',
  NEXT = 'next',
  OPEN_IN_LEXNET = 'openInLexnet',
  OPEN_WINDOW = 'openWindow',
  PREV = 'previous',
  PRINT = 'printInvoice',
  REMOVE = 'remove',
  SAVE = 'save',
  SAVE_AS = 'saveAs',
  SEND_EMAIL = 'sendEmail',
  SEND_INTERN_NOTIFICATION = 'sendInternNotification',
  UNBIND = 'unbind',
}
