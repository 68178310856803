import { URLS } from '@/router/routes/urlRoutes'
import AlertsMessagesView from '@/views/AlertsMessages/AlertsMessagesView.vue'

export const alertsMessagesRoutes = [
  {
    path: `/${URLS.ALERT_MESSAGES}`,
    name: `${URLS.ALERT_MESSAGES}`,
    component: AlertsMessagesView,
    meta: {
      requiresAuth: true,
      title: URLS.ALERT_MESSAGES
    }
  },
]
