<template lang="pug">
  div(class="legal-search")
    div(class="legal-search-send")
      LfLabelButtonComponent(
        class="button"
        :text="texts.legalBtn"
        :title="texts.legalTooltip"
        :action="() => openSearhOnApp()"
        variant="outlined"
      )
    v-tabs(
      v-model="selectedTabIndex"
      background-color="transparent"
      hide-slider
      show-arrows
      :next-icon="icons.doubleArrowRight"
      :prev-icon="icons.doubleArrowLeft"
      class="tabs-other-styles legal-search-tabs"
      :ripple="false"
    )
      v-tab(
        v-for="(tab, tabIndex) in tabs"
        @change="onTabChange(tab)"
        :key="`tab-${tab.area}`"
        :ripple="false"
        active-class="active"
        :class="[{ 'change-tab-styles': $vuetify.breakpoint.mdAndUp }, 'tab']"
      )

        span {{ tab.title }}
        span(v-if="tab.total") ({{ tab.occurrences }})

    v-tabs-items(v-model="selectedCurrentTabIndex")
      v-tab-item(
        class="tab-content"
        :transition="false"
        :reverse-transition="false"
        v-if="showContent"
      )
        div
          div(
            v-for="(item, itemIndex) in data"
            class="legal-item"
          )
            div(class="legal-separator")
              div(class="legal-lead-in")
                span(v-if="item.leadIn" v-html="item.leadIn")
                div
                  div(class="legal-info-container")
                    div(
                      v-if="item.title"
                      v-html="item.title"
                      class="legal-title"
                      @click.stop="viewDocumentOnApp(item.nRef)"
                    )
                    LfLabelButtonComponent(
                      class="button"
                      :text="'Ver en BBDD jurídica'"
                      :title="'Navegar en ventana nueva al documento original'"
                      :action="() => viewDocumentOnApp(item.nRef)"
                      variant="text"
                      :prependIcon="icons.database"
                    )
                  div(class="legal-summary" v-if="item.summary")
                  img(class="legal-icon" src="@/assets/images/svg/quote.svg" alt="Quote icon")
                  div(class="legal-summary-text" v-html="item.summary")
        div(class="legal-footer")
          PaginationComponent(
            v-if="showContent && showPaginator"
            class="pagination-component"
            :style="cssVars"
            :totalPages="totalPages"
            :maxVisibleButtons="maxVisibleButtonsPage"
            :currentPage="numPage+1"
            @pageChanged="onPageChanged"
          )
          div(class="select-wrapper")
            div(class="legal-select-num-recs")
              v-select(
                v-if="showPaginator"
                v-model="recsPerPage"
                :items="numRecsPerPage"
                :append-icon="icons.arrowDown"
                :menu-props="{ contentClass: 'legal-paginator-list' }"
                v-on:change="refreshSearch"
              )
            div(class="recs-per-page" v-if="showPaginator") {{texts.recsPerPage}}
          div(class="legal-page-info" v-if="showPaginator") {{numPagesLiteral}}
      v-tab-item(v-else)
        SpinnerLayerComponent(class="spinner-layer")

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Icons } from '@/icons/icons'
import { LegalSearchDataType, LegalSearchTabType } from '@/store/modules/legalSearch/legalSearchTypes'
import LfLabelButtonComponent from '@/components/forms/buttons/label/LfLabelButtonComponent.vue'
import PaginationComponent from '@/components/Pagination/PaginationComponent.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { vars } from '@/styles/styleVars'
import { formatNumber } from '@/helpers/numeric'

const legalSearchModule: string = ModuleNamespaces.LEGAL_SEARCH
const authModule: string = ModuleNamespaces.AUTH

@Component({
  components: {
    LfLabelButtonComponent,
    PaginationComponent,
    SpinnerLayerComponent
  }
})
export default class LegalSearchComponent extends Vue {
  @Action('fetchSessionId', { namespace: legalSearchModule }) fetchSessionId: (encryptedIdProUser: string) => void
  @Action('fetchSearchTabs', { namespace: legalSearchModule }) fetchSearchTabs: ({}) => void
  @Action('fetchSearchData', { namespace: legalSearchModule }) fetchSearchData: ({}) => void
  @Action('cancelPendingRequests', { namespace: authModule }) cancelPendingRequests: () => {}

  @Getter('getEncryptedIdProUser', { namespace: authModule }) encryptedIdProUser: any
  @Getter('getSessionId', { namespace: legalSearchModule }) sessionId: string
  @Getter('getSearchTabs', { namespace: legalSearchModule }) tabs: LegalSearchTabType[]
  @Getter('getSearchData', { namespace: legalSearchModule }) data: LegalSearchDataType[]

  icons = {
    database: Icons.DATABASE,
    doubleArrowLeft: Icons.DOUBLE_ARROW_LEFT,
    doubleArrowRight: Icons.DOUBLE_ARROW_RIGHT,
    arrowDown: Icons.ANGLE_DOWN
  }

  texts = {
    legalBtn: this.$t('components.legal_search.legal_btn'),
    legalTooltip: this.$t('components.legal_search.legal_tooltip'),
    of: this.$t('components.pagination.of'),
    pages: this.$t('components.pagination.pages'),
    recsPerPage: this.$t('components.pagination.records_per_page'),
    registers: this.$t('components.pagination.registers')
  }

  numRecsPerPage = [5, 10, 20, 50]

  numPage: number = 0
  recsPerPage: number = 5
  totalPages: number = 0
  maxVisibleButtonsPage: number = 5

  selectedTabIndex: number = 0
  selectedCurrentTabIndex: number = 0

  showContent: boolean = true

  currentTab: LegalSearchTabType

  get searchTerm() {
    return this.$route.params.searchTerm
  }

  get showPaginator() {
    if (this.currentTab) {
      return this.currentTab.occurrences > this.recsPerPage
    }
  }

  get numPagesLiteral() {
    if (this.showPaginator) {
      return `${this.numPage + 1} ${this.texts.of} ${this.totalPages} ${this.texts.pages} (${formatNumber(
        this.currentTab.occurrences
      )} ${this.texts.registers})`
    }
    return ''
  }

  async created() {
    if (!this.tabs) {
      await this.fetchSessionId(this.encryptedIdProUser)

      await this.fetchSearchTabs({
        term: this.searchTerm,
        sessionId: this.sessionId
      })
    }
    if (this.tabs) {
      await this.onTabChange(this.tabs[this.selectedTabIndex])
    }
  }

  async onTabChange(tab: LegalSearchTabType) {
    if (!this.currentTab || tab.area !== this.currentTab.area) {
      this.numPage = 0
    }
    this.currentTab = tab
    await this.cancelPendingRequests()
    this.showContent = false
    await this.fetchSearchData({
      term: this.searchTerm,
      sessionId: this.sessionId,
      index: tab.area,
      numPage: this.numPage,
      numRecs: this.recsPerPage
    })
    this.showContent = true
    this.totalPages = Math.ceil(this.currentTab.occurrences / this.recsPerPage)
  }

  onPageChanged(page: number) {
    this.numPage = page - 1
    this.onTabChange(this.currentTab)
  }

  refreshSearch() {
    this.numPage = 0
    this.onTabChange(this.currentTab)
  }

  viewDocumentOnApp(nRef: string) {
    window.open(
      `${process.env.VUE_APP_LEGAL_SEARCH_OPEN_SERVICE}?ei=${this.encryptedIdProUser}&producto_inicial=UNIVERSAL&nref=${nRef}`,
      '_blank'
    )
  }

  openSearhOnApp() {
    if (this.currentTab) {
      window.open(
        `${process.env.VUE_APP_LEGAL_SEARCH_OPEN_SERVICE}?ei=${this.encryptedIdProUser}&producto=UNIVERSAL&orden=relevancia&universal=${this.searchTerm}&subindices=${this.currentTab.area}`,
        '_blank'
      )
    }
  }

  get cssVars() {
    return {
      '--pagination-container-margin': 0,
      '--pagination-btn-active': vars.corporate_color,
      '--pagination-btn-active-border': vars.corporate_color,
      '--pagination-icon-color': vars.corporate_color,
      '--pagination-btn-not-active-border': vars.white_01,
      '--pagination-btn-color': vars.gray_01,
      '--pagination-btn-hover-color': vars.gray_01,
      '--pagination-icon-inactive-color': vars.disabled_color
    }
  }
}
</script>

<style lang="scss" scoped>
.legal-search {
  padding: $spacing-sm;
}

.legal-item {
  display: flex;
  border-bottom: 1px solid $main-400;
  padding: $spacing-sm;
  margin: $spacing-sm;

  .legal-separator {
    width: 100%;
  }

  &:hover {
    background-color: $neutral-grey-100;
  }
}

.legal-lead-in {
  span {
    display: inline-flex;
    font-size: 14px;
    background-color: $gray-04;
    color: $blue-14;
    padding: 6px 20px;
    margin: 0 20px 10px;
    ::v-deep .term {
      color: $red-09;
      display: contents;
    }
  }
}

.legal-info-container {
  display: flex;
  justify-content: space-between;
}

.legal-title {
  @include milano-bold-14;
  cursor: pointer;
  display: flex;
  color: $main-1000;
  padding-top: 5px;

  ::v-deep .term {
    color: $tertiary-persianRed-200;
    display: contents;
  }
}

.legal-summary {
  display: flex;
}

.legal-icon {
  width: 19px;
  height: 20px;
  margin-right: 10px;
}

.legal-summary-text {
  @include milano-regular-14;
  color: $neutral-grey-800;

  ::v-deep .term {
    color: $tertiary-persianRed-200;
    display: contents;
  }
}

.spinner-layer-container {
  height: 300px;
}

.legal-search-tabs {
  border-color: $main-1000 !important;

  .tab {
    @include milano-regular-16;
    color: $neutral-grey-800;
    text-transform: inherit;

    &:not(.active):hover {
      border-bottom: none !important;
    }

    &.active {
      border-color: $main-1000 !important;
    }
  }
}

.legal-search-send {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
  padding: 0px $spacing-xs 0px $cornerRadius-md;
  background-color: $neutral-grey-100;
}

.legal-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  position: relative;
  ::v-deep .pagination-component {
    display: flex;
    width: 300px;

    .pagination-item {
      button {
        width: auto;

        &.icon {
          font-size: 10px;
          width: auto;
          min-width: 24px;
        }
      }
    }
  }

  ::v-deep .legal-select-num-recs {
    display: flex;
    width: 70px;
    max-width: 70px;
    margin: 0;
    padding: 0;

    .v-select__selection {
      font-family: $secondary-font;
      font-size: 14px;
      color: $corporate-color;
      position: relative;
      top: -3px;
    }

    .v-text-field {
      margin: 0 20px 0 0;
      padding: 4px 0;
      height: 20px;
    }

    .lf-icon-angle-down {
      padding-bottom: 0px;
      top: 4px;
      position: relative;

      &::before {
        content: '';
        border-width: 8px;
        border-style: solid;
        border-right: 8px solid transparent;
        border-color: $corporate-color transparent transparent;
        width: 0;
        height: 0;
      }
    }

    .v-input__slot {
      height: 20px;
      &::before,
      &::after {
        border: none;
      }
    }
  }

  ::v-deep .legal-page-info {
    font-family: $corporate-font;
    font-size: 14px;
    color: $corporate-color;
    display: flex;
    align-self: end;
  }

  ::v-deep .recs-per-page {
    font-family: $corporate-font;
    font-size: 14px;
    color: $corporate-color;
    display: flex;
  }

  ::v-deep .select-wrapper {
    display: flex;
  }
}

::v-deep .v-list-item__content {
  padding: 0;
}
</style>

<style lang="scss">
.legal-paginator-list {
  border-radius: 0;
  border-top: 1px solid $corporate-color !important;
  box-shadow: none !important;
}
</style>
