import { NextEventsWidgetData } from '@/components/widgets/ExpedientWidgets/ExpedientNextEvents/ExpedientNextEventsWidgetTypes'

export interface Appointment {
  id: string
}

export interface Alert {
  id: string
  subject: string
  start: string
}

export interface CalendarAppointment {
  id: string
  subject: string
  start: string
  end: string
  color: string
  name: string
  typeAppointment: string | null
  fullDay: string
}
export interface CalendarAppointmentFormatted {
  id: string
  subject: string
  title: string
  start: string
  end: string
  color?: string
  type: string | null
}

export interface AppointmentsState {
  appointment: []
  calendarAppointments: CalendarAppointment[]
  expedientGlobalVisionWidgetAppointments: NextEventsWidgetData | null
}

export const appointmentsMutationTypes = {
  FETCH_APPOINTMENT: 'FETCH_APPOINTMENT',
  FETCH_APPOINTMENTS: 'FETCH_APPOINTMENTS',
  FETCH_CALENDAR_APPOINTMENTS: 'FETCH_CALENDAR_APPOINTMENTS',
  FETCH_EXPEDIENT_GLOBAL_VISION_WIDGET_APPOINTMENTS: 'FETCH_EXPEDIENT_GLOBAL_VISION_WIDGET_APPOINTMENTS',
  RESET_EXPEDIENT_GLOBAL_VISION_WIDGET_APPOINTMENTS: 'RESET_EXPEDIENT_GLOBAL_VISION_WIDGET_APPOINTMENTS'
}
