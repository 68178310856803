<template lang="pug">
div
  div(v-if="initialRenderMode" class="numeration")
    LfHeaderTitle(
      :title="headerTitle"
      class="header-title"
    )

    NumerationGridComponent(
      @row-click="onRowClicked"
      @command-click="onCommandClick"
      @toolbar-click="onToolbarClicked"
      @context-menu-click="onContextMenuClicked"
    )

  div(v-else class="numeration_edit")
    LfHeaderTitle(
      :title="formTitle"
      class="header-title"
      :buttons="buttons"
      :executeAction="onExecuteAction"
    )

    FormGeneratorComponent(
      v-if="formSchema"
      :schema="formSchema"
      :fieldValues="formData"
      :context="context"
      showRequiredFieldsAdvice
      @mountedForm="checkDisabled"
      @saveFormData="prepareFormDataToSave"
      appendElementId="actived"
    )
      div(v-if="getCustomConfigText" slot="appendElement" class="numeration-info-container" )
        span(class="icon-code lf-icon-code lf-icon__md")
        div(class="text-container")
          p(class="info-text") {{ getCustomConfigText }}
          span(class="info-code") {{ code }}

</template>

<script lang="ts">
import { Action, Getter, Mutation } from 'vuex-class'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import GridTableComponent from '@/components/grids/GridTable/GridTableComponent.vue'
import GridToolbarOptionsMixin from '@/mixins/GridToolbarOptionsMixin.vue'
import GridContextMenuOptionsMixin from '@/mixins/GridContextMenuOptionsMixin.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { ConfigurationTypes } from '@/store/modules/configuration/configurationTypes'
import FormGeneratorComponent from '@/components/forms/FormGenerator/FormGeneratorComponent.vue'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { CustomFieldsConfig } from '@/store/modules/customFields/customFieldsTypes'
import { NumerationDetailsItem, ComponentFormFields } from './NumerationTypes'
import {
  NumerationPatternTypes,
  NumerationMaintenanceTypes
} from '@/components/forms/fields/LexonNumeration/types/LexonNumerationComponentTypes'
import NumerationGridComponent from '@/components/Numeration/NumerationGridComponent.vue'
import NumerationTitleComponent from './NumerationTitleComponent.vue'
import LfHeaderTitle from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'
import { ActionBarButton } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'
import { AlertsTypes, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'

const alertsModule: string = ModuleNamespaces.ALERTS
const configurationModule: string = ModuleNamespaces.CONFIGURATION
const customFieldsModule: string = ModuleNamespaces.CUSTOM_FIELDS
const dialogModule = ModuleNamespaces.DIALOG
const formsModule = ModuleNamespaces.FORMS
const listItemsModule = ModuleNamespaces.LIST_ITEMS
const numerationsModule: string = ModuleNamespaces.NUMERATIONS
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    FormGeneratorComponent,
    GridTableComponent,
    NumerationGridComponent,
    NumerationTitleComponent,
    SpinnerLayerComponent,
    TabHeaderComponent: () => import('@/components/tabs/TabHeader/TabHeaderComponent.vue'),
    TabsComponent: () => import('@/components/tabs/TabsComponent/TabsComponent.vue'),
    LfHeaderTitle
  }
})
export default class NumerationComponent extends Mixins(GridToolbarOptionsMixin, GridContextMenuOptionsMixin) {
  @Prop({
    type: String
  })
  headerTitle!: string

  @Prop({
    type: String,
    default: ''
  })
  title!: string

  @Prop({
    type: String,
    default: ''
  })
  listName!: string

  @Prop({
    type: Boolean,
    default: false
  })
  showGrid!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  showFilters!: boolean

  @Action('fetchNumerationMaintenance', { namespace: numerationsModule })
  fetchNumerationMaintenance: ({}) => void
  @Action('fetchNumerations', { namespace: numerationsModule })
  fetchNumerations: () => void
  @Action('fetchNumeration', { namespace: numerationsModule })
  fetchNumeration: ({}) => void
  @Action('showDialog', { namespace: dialogModule }) showDialogInfo: ({}) => {}
  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfiguration: ({}) => Promise<{}>
  @Action('prepareRegisterFormDataToSave', { namespace: formsModule })
  prepareFormData: ({}) => void
  @Action('removeNumeration', { namespace: numerationsModule })
  removeNumerationAction: ({}) => void
  @Action('saveSelectedRegisterId', { namespace: selectedRegisterModule })
  saveSelectedRegisterId: ({}) => Promise<void>
  @Action('saveNumeration', { namespace: numerationsModule })
  saveNumerationAction: ({}) => boolean
  @Action('fetchSelectedRegisterData', { namespace: selectedRegisterModule })
  fetchSelectedRegisterData: ({}) => Promise<{}>
  @Action('showAlert', { namespace: alertsModule })
  showAlert: ({}) => {}

  @Mutation('RESET_REGISTER_FORM_DATA', { namespace: formsModule })
  resetFormData: () => void
  @Mutation('RESET_CUSTOM_CONFIG_TEXT', { namespace: configurationModule })
  resetCustomConfigText: () => void
  @Mutation('SET_CUSTOM_CONFIG_TEXT', { namespace: configurationModule })
  setCustomConfigText: (value: string) => void

  @Getter('getCurrentViewConfiguration', { namespace: configurationModule })
  getCurrentViewConfiguration: (context: string) => []
  @Getter('getListItems', { namespace: listItemsModule })
  gridData: []
  @Getter('getCurrentListConfiguration', { namespace: configurationModule })
  listConfiguration: object
  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterId: (context: string) => number
  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterData: (context: string) => {}
  @Getter('checkIfFormIsValid', { namespace: formsModule })
  checkIfFormIsValid: (context: string) => boolean
  @Getter('getCustomFieldsConfig', { namespace: customFieldsModule })
  config: CustomFieldsConfig
  @Getter('getNumerationMaintenance', { namespace: numerationsModule })
  getNumerationMaintenance: (context: string) => []
  @Getter('getCustomConfigText', { namespace: configurationModule })
  getCustomConfigText: string

  texts = {
    buttons: {
      cancel: this.$t('action_buttons.cancel'),
      close: this.$t('action_buttons.close'),
      remove: this.$t('action_buttons.remove'),
      save: this.$t('action_buttons.save'),
      new: this.$t('action_buttons.new_numeration')
    },
    custom_text: {
      begin: this.$t('components.numeration.custom_text.begin'),
      type_begin: this.$t('components.numeration.custom_text.type_begin'),
      separator: this.$t('components.numeration.custom_text.separator'),
      user_begin: this.$t('components.numeration.custom_text.user_begin'),
      end: this.$t('components.numeration.custom_text.end')
    },
    dialogRemoveNumeration: this.$t('components.expedient_numerations.dialog_remove_numeration'),
    faq: {
      how_works: this.$t('components.numeration.faq.how_works'),
      how_works_ext: this.$t('components.numeration.faq.how_works_ext'),
      q1: this.$t('components.numeration.faq.q1'),
      q2: this.$t('components.numeration.faq.q2'),
      q3: this.$t('components.numeration.faq.q3')
    },
    gridTitle: this.$t('components.numeration.title'),
    formTitles: {
      editNumeration: this.$t('components.expedient_numerations.edit_numeration'),
      newNumeration: this.$t('components.expedient_numerations.new_numeration')
    },
    noResultsSecondParagraph: this.$t('components.numeration.no_results_second_paragraph')
  }

  stageField: { field: any; index: number } = { field: null, index: 0 }

  context: string = ContextName.NUMERATION

  numerationForm: boolean = true

  initialRenderMode = true

  faqExtended = true

  numerationFormInEditionMode = false

  code = ''

  @Watch('formData', { deep: true })
  changedValue(val: any) {
    if (val !== undefined) {
      this.buildCustomNumerationText(val)
    }
  }
  @Watch('numerationPattern', { deep: true })
  changedNumRefValue() {
    ;(this as any).formData.numerationReference = this.getNumerationReference()
    this.buildCustomNumerationText((this as any).formData)
  }

  get formSchema() {
    return this.getCurrentViewConfiguration(this.context)
  }

  get formData() {
    return this.selectedRegisterData(this.context)
  }

  get numerationPattern() {
    return this.config ? this.config.customFields : null
  }

  get buttons(): ActionBarButton[] {
    return [
      {
        tooltip: this.texts.buttons.cancel,
        class: 'red-color',
        action: ActionName.CANCEL,
        enabled: true,
        variant: LabelButtonVariant.OUTLINED
      },
      {
        tooltip: this.texts.buttons.save,
        class: 'green-color',
        action: ActionName.SAVE,
        enabled: true
      }
    ]
  }

  get formTitle() {
    return this.numerationFormInEditionMode ? this.texts.formTitles.editNumeration : this.texts.formTitles.newNumeration
  }

  async created() {
    await this.fetchCurrentViewConfiguration({
      objectType: ConfigurationTypes.VIEW,
      alias: ContextName.NUMERATION_FORM,
      context: this.context
    })
    await this.fetchNumerationMaintenance({ type: NumerationMaintenanceTypes.SEPARATORS })
    await this.fetchNumerationMaintenance({ type: NumerationMaintenanceTypes.FORMATS })
  }

  onRowClicked(rowData: any) {
    const { id } = rowData
    this.numerationFormInEditionMode = true
    this.saveSelectedRegisterId({
      id,
      context: this.context
    })
    this.openForm()
  }

  checkDisabled() {
    const disabledFields = [ComponentFormFields.USER, ComponentFormFields.EXPEDIENT_TYPE]
    this.formSchema.forEach((field: any) => {
      if (disabledFields.includes(field.id)) {
        this.selectedRegisterId(this.context) !== 0 ? (field.disabled = true) : (field.disabled = false)
      }
    })
  }

  onContextMenuClicked(item: any, rowInfo: any) {
    const { rowData } = rowInfo as any
    this.saveSelectedRegisterId({
      id: rowData.id,
      context: this.context
    })

    if (item.id === ActionName.REMOVE) {
      this.showDialogRemove(rowData)
      return
    }
    if (item.id === ActionName.EDIT) {
      this.numerationFormInEditionMode = true
      this.openForm()
    }
  }

  onToolbarClicked(item: any) {
    const { id } = item
    if (id === ActionName.ADD) {
      this.onAddClick()
    }
  }

  async onAddClick() {
    await this.saveSelectedRegisterId({
      id: 0,
      context: this.context
    })
    await this.openForm()
  }

  prepareFormDataToSave() {
    this.prepareFormData(this.formData)
  }

  toggleRenderMode() {
    this.initialRenderMode = !this.initialRenderMode
  }

  async openForm() {
    if (this.selectedRegisterId(this.context)) {
      await this.fetchNumeration({ selectedRegisterId: this.selectedRegisterId(this.context), context: this.context })
    }
    this.$emit('disabledTabs')
    this.$emit('openForm')
    this.toggleRenderMode()
  }

  async closeForm() {
    await this.reloadData()
    this.numerationFormInEditionMode = false
    this.initialRenderMode = true
    this.$emit('enabledTabs')
    this.$emit('closeForm')
    this.resetFormData()
    this.resetCustomConfigText()
    this.resetFormData()
  }

  showDialogRemove(args: any = null) {
    const name: string = args ? args.name : (this as any).formData.name
    this.showDialogInfo({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_register_text', {
        register: name,
        text: this.texts.dialogRemoveNumeration
      }),
      action: async () => {
        await this.removeNumerationAction({ idNumeration: this.selectedRegisterId(this.context), name })
        this.closeForm()
      },
      mainButtonText: this.$t('action_buttons.remove'),
      secondaryButtonText: this.$t('action_buttons.cancel')
    })
  }

  async saveNumeration() {
    const dataToSend = {
      ...this.formData,
      numerationReference: this.getNumerationReference(),
      id: this.numerationFormInEditionMode ? this.selectedRegisterId(this.context) : 0
    }
    const result = await this.saveNumerationAction(dataToSend)
    if (result) {
      this.showAlert({
        type: AlertsTypes.SUCCESS,
        message: this.$t('components.alerts.register_save'),
        componentWhereIsRendered: ComponentWhereIsRendered.MAIN
      })
      this.closeForm()
    }
  }

  getNumerationReference() {
    const numerationReference: NumerationDetailsItem[] = []
    if (this.numerationPattern) {
      this.numerationPattern.forEach((element: any, index: number) => {
        const newDetail: NumerationDetailsItem = {
          idNumerationType: element.idNumerationType,
          idSeparator: element.fields[3].value,
          idNumerationFormat: element.fields[1].value,
          numOrder: index,
          value: element.fields[2].value
        }
        numerationReference.push(newDetail)
      })
      return numerationReference
    }
    return null
  }

  async reloadData() {
    await this.fetchNumerations()
  }

  buildCustomNumerationText(data: any) {
    let text: string = this.texts.custom_text.begin + ' '
    let hasIdExpedientType = false
    let code: string = ''

    if (data.idExpedientType) {
      text += this.texts.custom_text.type_begin + ' ' + data.idExpedientType.name.toUpperCase() + ' '
      hasIdExpedientType = true
    }
    if (data.idUser) {
      hasIdExpedientType
        ? (text +=
            this.texts.custom_text.separator +
            ' ' +
            this.texts.custom_text.user_begin +
            ' ' +
            data.idUser.name.toUpperCase() +
            ' ')
        : (text += this.texts.custom_text.user_begin + ' ' + data.idUser.name.toUpperCase() + ' ')
    }
    if (data.numerationReference) {
      data.numerationReference.forEach((e: any) => {
        switch (e.idNumerationType) {
          case NumerationPatternTypes.CODE:
            code +=
              this.zeroPad(e.value, this.getByVal(NumerationMaintenanceTypes.FORMATS, e.idNumerationFormat)) +
              this.getByVal(NumerationMaintenanceTypes.SEPARATORS, e.idSeparator)
            break
          case NumerationPatternTypes.DATE:
            code +=
              this.getByVal(NumerationMaintenanceTypes.FORMATS, e.idNumerationFormat) +
              this.getByVal(NumerationMaintenanceTypes.SEPARATORS, e.idSeparator)
            break
          case NumerationPatternTypes.TEXT:
            code += e.value + this.getByVal(NumerationMaintenanceTypes.SEPARATORS, e.idSeparator)
            break
          case NumerationPatternTypes.CLIENT:
            code += 'CLIREF' + this.getByVal(NumerationMaintenanceTypes.SEPARATORS, e.idSeparator)
            break
          default:
            break
        }
      })
    }
    this.code = code
    text += this.texts.custom_text.end
    this.setCustomConfigText(text)
  }

  getByVal(type: NumerationMaintenanceTypes, val: string | number) {
    let v: any = this.getNumerationMaintenance(type)
    v = v.find((p: any) => p.id === String(val))
    return type === NumerationMaintenanceTypes.SEPARATORS ? v.separator : v.labelFormat
  }

  zeroPad(num: number, places: number) {
    return String(num).padStart(places, '0')
  }

  onCommandClick(rowData: any) {
    const { id } = rowData as any
    this.saveSelectedRegisterId({
      id: id,
      context: this.context
    })
    this.showDialogRemove(rowData)
  }

  async onExecuteAction(actionName: string) {
    switch (actionName) {
      case ActionName.CANCEL:
        this.closeForm()
        break
      case ActionName.SAVE:
        this.saveNumeration()
        break
      default:
        break
    }
  }
}
</script>

<style lang="scss" scoped>
.numeration {
  .spinner-layer {
    width: 100%;
    height: 100%;
    min-height: 470px;
    background-color: $white-01;
  }

  ::v-deep #file-manager_layout {
    border: 1px solid $corporate-color !important;
  }
  .faq {
    color: $blue-01;
    font-size: 15px;
    margin: 0px 10px;
    padding: 12px 0px;

    .lf-pointer {
      cursor: pointer;
    }
    .how-works,
    .how-works-ext {
      color: $blue-01;
      font-family: $corporate-font-bold;
      font-size: 14px;
      &:hover {
        color: $blue-04;
      }

      .icon {
        margin-right: 5px;
        font-size: 19px;
        position: relative;
        top: 3px;
      }

      .question {
        color: $blue-03;
        font-weight: normal;
        font-size: 13px;
        margin-top: 2px;
        cursor: default;

        .icon {
          margin-right: 5px;
          margin-left: 10px;
          font-size: 19px;
        }
      }
    }
  }

  .simple-grid-header {
    margin-top: 0px;
  }
}

.numeration_edit {
  .form {
    margin-left: 10px;
    margin-right: 10px;
  }

  ::v-deep .fieldset {
    margin-top: 16px;
  }
}

::v-deep .lf-switch-container .v-label[for='actived'] {
  height: 50px;
  width: 150px;
}

::v-deep .numeration-info-container {
  display: flex;
  padding: $spacing-xxs $spacing-xs;
  gap: $spacing-xs;

  .icon-code {
    color: $main-1000;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $spacing-xxs;
    flex: 1 0 0;

    .info-text {
      @include milano-medium-14;
      color: $main-1000;
      margin: 0;
    }

    .info-code {
      @include milano-regular-14;
      color: $neutral-grey-800;
    }
  }
}
</style>
