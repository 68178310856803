import useSelectedRegister from "@/composables/useSelectedRegister"
import { StoreResponse } from "@/store/modules/professionals/professionalsActions"
import store from "@/store/store"
import { ContextName, ModuleNamespaces } from "@/store/types/storeGlobalTypes"
import { ref } from "vue"

interface ResponseUseProfessionalAccess {
  professionals: any
  fetchProfessionalAccess: () => void
  saveProfessionalAccess: (formData: any) => Promise<StoreResponse>
  removeProfessionalAccess: (id: string) => Promise<StoreResponse>
}

export default function useProfessionalAccess(): ResponseUseProfessionalAccess {

  const { getSelectedRegisterByEntity } = useSelectedRegister({entityName: ContextName.MAINTENANCE_USERS_GRID})

  const professionals = ref([])

  const fetchProfessionalAccess = async () => {
    await store.dispatch(`${ModuleNamespaces.PROFESSIONALS}/fetchListProfessionals`, {
    })
    professionals.value = store.getters[`${ModuleNamespaces.LIST_ITEMS}/getListItemsWithFormattedDates`]
  }

  const saveProfessionalAccess = async (formData: any) => {
    const { id } = getSelectedRegisterByEntity('usersConfiguration')
    const { name } = formData
    const data = {
      idCompany: name.id,
      idUser: id
    }
    const response = await store.dispatch(`${ModuleNamespaces.PROFESSIONALS}/saveProfessionalAccess`, data)
    return response
  }

  const removeProfessionalAccess = async (id: string) => {
    const response = await store.dispatch(`${ModuleNamespaces.PROFESSIONALS}/removeProfessionalAccess`, id)
    return response
  }

  fetchProfessionalAccess()

  return {
    professionals,
    fetchProfessionalAccess,
    saveProfessionalAccess,
    removeProfessionalAccess
  }
}
