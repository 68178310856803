<template lang="pug">

ValidationProvider(
  :rules="validatorRules"
  ref="dateTimePickerValidator"
  :name="validatorName"
  :vid="$attrs.id"
  v-slot="{ errors }"
  tag="div"
)
  div(
    :class="[{ 'error-styles': errors[0] }, { 'disabled-field': disabled }, { 'hidden-field': hidden }, 'lf-datetimepicker-container']"
  )
    div(class="label-container")
      span(:class="[{ 'required-field': requiredField }, 'label-text']") {{ label }}
      span(
        v-if="showInfo"
        :class="[icons.info, 'label-icon']"
      )

    ejs-datetimepicker(
      ref="dateTimePicker"
      v-model="innerValue"
      :value="innerValue"
      :id="id"
      :name="id"
      class="lf-datetimepicker"
      format="dd/MM/yyyy HH:mm"
      placeholder="dd/mm/aaaa 00:00"
      :change="onValueChange"
      :cleared="clearField"
      :showClearButton="true"
      :strictMode="true"
      :created="onDatePickerCreated"
      :destroyed="onDatePickerDestroyed"
      :open="onDatePickerOpen"
      :close="onDatePickerClose"
    )

    div(class="messages-container")
      span(v-if="errors.length" class="alert-message") {{ errors[0] }}
      span(v-else class="help-message") {{ helpText }}

</template>

<script lang="ts">
import { mixins } from 'vue-class-component'
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { DateTimePickerPlugin, TimePickerPlugin } from '@syncfusion/ej2-vue-calendars'
import { Icons } from '@/icons/icons'
import { changeDateFormat, convertToDate } from '@/helpers/dateTime'
import FieldBehaviorsMixin from '@/mixins/FieldBehaviorsMixin.vue'
import DatePickerClearButtonMixinVue from '@/mixins/DatePickerClearButtonMixin.vue'

Vue.use(DateTimePickerPlugin)
Vue.use(TimePickerPlugin)

@Component({
  components: {
    ValidationProvider
  }
})
export default class LexonDateTimePickerComponent extends mixins(FieldBehaviorsMixin, DatePickerClearButtonMixinVue) {
  @Prop({
    type: Boolean,
    default: false
  })
  showInfo!: boolean

  @Prop({
    type: [String, Date],
    default: ''
  })
  value!: string

  @Prop({
    type: String
  })
  id!: string

  @Prop({
    type: String
  })
  name!: string

  @Prop({
    type: String
  })
  helpText!: string

  @Prop({
    type: String
  })
  label!: string

  @Prop({
    type: String
  })
  placeholder!: string

  @Prop({
    type: Boolean,
    default: false
  })
  initializeNow!: boolean

  @Prop({
    type: String
  })
  outputFormat!: string

  @Prop({
    type: String,
    default: 'DD/MM/YYYY HH:mm'
  })
  inputFormat!: string

  @Prop({
    type: Boolean,
    default: false
  })
  hidden!: boolean

  $refs!: {
    dateTimePicker: any
    dateTimePickerValidator: InstanceType<typeof ValidationProvider>
  }

  icons = {
    info: Icons.INFO_FULL
  }

  innerValue: any = null

  @Watch('value')
  changeInnerValue(newVal: string) {
    if (newVal === null) {
      this.innerValue = null
    } else {
      let newValDate = null
      if (newVal.indexOf('-') !== -1) {
        newValDate = convertToDate(newVal, 'YYYY-MM-DD HH:mm')
      } else {
        newValDate = convertToDate(newVal, this.inputFormat)
      }
      if (newValDate !== this.innerValue) {
        this.innerValue = newValDate
      }
    }
  }

  @Watch('innerValue')
  changedValue(newVal: Date | null) {
    if (newVal !== null) {
      this.validateField()
    }
    this.emitValue(newVal)
  }

  @Emit()
  emitValue(value: any) {
    if (this.outputFormat && value) {
      value = changeDateFormat(value, this.inputFormat, this.outputFormat)
    }
    return this.$emit('input', value)
  }

  mounted() {
    this.initValidator(this.$refs.dateTimePickerValidator)
  }

  created() {
    if (this.value) {
      this.innerValue = convertToDate(this.value, this.inputFormat)
    } else if (this.initializeNow) {
      this.innerValue = new Date()
    }
  }

  onValueChange(args: any) {
    const datetime = args.value
    if (!datetime) {
      this.innerValue = args.value
      this.validateField()
    }
  }

  clearField() {
    this.innerValue = null
  }
}
</script>

<style lang="scss" scoped>
.lf-datetimepicker-container {
  @include flex($flex-direction: column, $align-items: flex-start);
  @include textfield-messages;
  width: 100%;

  .label-text.required-field::after {
    right: -4px;
  }

  &.disabled-field {
    pointer-events: none;

    .label-container {
      .label-text {
        color: $neutral-grey-600;

        &.required-field::after {
          color: $neutral-grey-600;
        }
      }
    }

    .messages-container .help-message {
      color: $neutral-grey-600;
    }

    ::v-deep .e-input-group.e-datetime-wrapper {
      background-color: $gray-04;
    }

    ::v-deep .e-input-group-icon.e-time-icon::before,
    ::v-deep .e-input-group-icon.e-date-icon::before {
      color: $neutral-grey-600;
    }
  }

  &.hidden-field {
    display: none;
  }

  &.error-styles {
    ::v-deep .e-input-group.e-control-wrapper.e-datetime-wrapper {
      border-color: $error-color !important;

      &:hover {
        border-color: $error-color !important;
        background-color: $main-050;
      }

      .lf-datetimepicker::placeholder {
        color: $error-color;
      }
    }
  }

  .lf-datetimepicker::placeholder {
    @include placeholder;
  }

  .lf-datetimepicker::selection {
    background-color: $selection-color !important;
    color: $black-01 !important;
  }

  ::v-deep .e-input-group-icon.e-date-icon::before {
    font-family: $lf-icons-font;
    font-size: 20px;
    margin-top: 4px;
    color: $main-1000;
    content: '\e925';
  }

  ::v-deep .e-input-group:not(.e-filled) .e-input-group-icon.e-date-icon::after {
    content: '';
    background: $neutral-grey-400;
    width: 1px;
    height: $icon-size;
    transform: none;
    opacity: 1;
    border-radius: 0;
    top: 8.5px;
    left: -8px;
  }

  ::v-deep .e-input-group-icon.e-time-icon::before {
    font-family: $lf-icons-font;
    font-size: 20px;
    margin-top: 4px;
    color: $corporate-color;
    content: '\e930';
  }

  ::v-deep .e-input-group.e-control-wrapper .e-clear-icon::before {
    position: relative;
    left: -7px;
    font-family: $lf-icons-font;
    font-size: 14px;
    color: $corporate-color;
    content: '\e938';
    margin-right: 8px;
  }

  ::v-deep .e-input-group.e-control-wrapper.e-error .e-clear-icon::before {
    color: $error-color;
  }

  ::v-deep .e-input-group.e-control-wrapper.e-datetime-wrapper {
    border: none;
    @include textfield-styles;
    border-color: $neutral-grey-400 !important;
    margin: 0;

    &::before {
      display: none;
    }

    &.e-input-focus {
      border: none !important;
      border: 1px solid $corporate-color !important;

      &.e-error {
        border-color: $error-color !important;
      }

      &::before,
      &::after {
        display: none;
      }
    }
  }

  .label-container {
    @include flex;
    color: $corporate-color;

    .label-text {
      @include label-text;
    }

    .label-icon {
      font-size: 15px;
    }
  }
}
</style>

<style lang="scss">
.e-datetimepicker .e-content {
  @include scroll-styles;
  background-color: green !important;
}

.e-datetimepicker.e-popup-open {
  @include scroll-styles;

  .e-list-item {
    &.e-active {
      background-color: $blue-07 !important;
      color: $corporate-color !important;
    }

    &:hover {
      background-color: $gray-04 !important;
    }
  }
}
</style>
