<template>
  <div class="lf-simple-row-field">
    <div class="lf-simple-row-container">
      <Grid
        :gridConfiguration="rowConfiguration"
        :itemData="itemData"
        :toolbarOptions="toolbarOptions"
        :allowPaging="false"
      ></Grid>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Grid from '@/components/grids/LfGrid/LfGridComponent.vue'
import { computed, PropType } from 'vue'
import { ActionName, GridConfiguration } from '@/components/grids/LfGrid/LfGridTypes'
import { ToolbarItemModel } from '@syncfusion/ej2-vue-filemanager'
import { formatFieldsTypeDateEPOCH } from '@/helpers/dateTime'

const props = defineProps({
  label: {
    type: String,
    required: true
  },
  rowConfiguration: {
    type: Object as PropType<GridConfiguration>,
    required: true
  },
  rowData: {
    type: [Array, Object],
    required: true
  }
})

const itemData = computed(() => {
  if (!props.rowData.length) {
    return []
  }
  return formatFieldsTypeDateEPOCH(props.rowData)
})

const toolbarOptions = computed((): ToolbarItemModel[] => {
  return [
    {
      id: ActionName.TITLE,
      text: props.label,
      cssClass: 'lf-title',
      align: 'Left'
    }
  ]
})
</script>
<style lang="scss" scoped>

.lf-simple-row-container {
  border-radius: $cornerRadius-md;
  border: 1px solid $main-200;
}

::v-deep .e-grid.grid-table .e-toolbar {
  background-color: $neutral-grey-050;
  margin: $spacing-sm 0;
}
</style>
