<template lang="pug">

  transition(name="fade" appear)
    div(
      v-if="showAlert && whereIsRendered === alert.componentWhereIsRendered"
      :class="[ getType, 'alert-container', { 'render-at-the-bottom': renderAtTheBottom, 'login-view': renderAtLoginView  } ]"
    )
      span(:class="[ getIcon, 'alert-icon' ]")

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { AlertsData, AlertsIcons, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'

const namespace: string = ModuleNamespaces.ALERTS

@Component
export default class IconAlert extends Vue {
  @Prop({
    type: String,
    required: true
  })
  whereIsRendered!: string

  @Prop({
    type: Number
  })
  durationTime!: number

  @Getter('showAlert', { namespace }) showAlert: boolean
  @Getter('getAlertData', { namespace }) alert: AlertsData

  @Action('closeAlert', { namespace }) closeAlertAction: () => {}

  get getType(): string {
    return this.alert.type
  }

  get getIcon(): string {
    return AlertsIcons[this.alert.type]
  }

  get renderAtTheBottom() {
    return this.whereIsRendered === ComponentWhereIsRendered.TABS_COMPONENT
  }

  get renderAtLoginView() {
    return this.whereIsRendered === ComponentWhereIsRendered.LOGIN_VIEW
  }

  created() {
    this.closeAlert()
  }

  updated() {
    this.closeAlert()
  }

  closeAlert() {
    if (this.durationTime) {
      setTimeout(() => {
        this.closeAlertAction()
      }, 5000) // forzamos la duración puesto q no tiene botón de cerrar
    }
  }
}
</script>

<style lang="scss" scoped>
@include fade-transition;

.alert-container {
  @include flex($justify-content: center, $align-items: center);
  width: 100%;
  color: $neutral-grey-800;
  padding: $spacing-xs $spacing-sm;
  border-radius: $cornerRadius-sm;
  box-shadow: 0px 0px 7px 0px rgba(86, 86, 86, 0.5);
  z-index: 99;
  gap: $spacing-xs;

  &.render-at-the-bottom {
    position: inherit;
  }

  &:not(.render-at-the-bottom) {
    position: absolute;
    top: $alert-top-position;
  }

  &.login-view {
    width: 65%;
    height: auto;
    top: 9%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    .close-icon {
      justify-self: start;
      margin-top: 16px;
    }

    .alert-text-container {
      align-items: center;
      justify-content: center;
      padding: 14px 0;

      .alert-icon {
        font-size: 20px;
      }
    }
  }

  &.info-text {
    background-color: $tertiary-byzantineBlue-050;
  }

  &.error-text {
    background-color: $tertiary-persianRed-050;
  }

  &.success-text {
    background-color: $tertiary-seaGreen-050;
  }

  &.warning-text {
    background-color: $tertiary-saffron-050;
  }

  .alert-icon {
    font-size: 32px;
  }

  .lf-icon-close-round-full {
    color: $tertiary-persianRed-200;
  }
  .lf-icon-warning-full {
    color: $tertiary-saffron-200;
  }
  .lf-icon-check-round-full {
    color: $tertiary-seaGreen-200;
  }
  .lf-icon-info-full {
    color: $tertiary-byzantineBlue-200;
  }
}
</style>
