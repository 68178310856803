<template lang="pug">

div(
  v-resize="iconsSizeInXsBreakpoint"
  :class="['custom-field-type-selector', breakpointClass]"
)
  div(class="row-title")
    span(class="row-text") {{ texts.title }}
  ul(class="fields-list")
    li(
      v-for="(item, index) in items"
      :class="['item', { 'xs-breakpoint': isXsBreakpoint }]"
      @click.stop="emitClickedItem(item)"
    )
      span(:class="[getIcon(item.id), 'item-icon']")
      span(
        v-if="!isXsBreakpoint"
        class="item-name"
      ) {{ item.description }}

</template>

<script lang="ts">
import { TranslateResult } from 'vue-i18n'
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import { customFieldTypesEnum, CustomFieldTypes } from '@/store/modules/customFields/customFieldsTypes'
import { Icons } from '@/icons/icons'

@Component
export default class CustomFieldTypeSelectorComponent extends Vue {
  @Prop({
    type: Array,
    required: true
  })
  items!: CustomFieldTypes[]

  icons = {
    fieldBoolean: Icons.SWITCH_OFF,
    fieldCurrency: Icons.COIN,
    fieldDate: Icons.CALENDAR,
    fieldGroup: Icons.PROCESS_STEP,
    fieldList: Icons.DROPDOWN,
    fieldNumeric: Icons.NUMBER_TEN,
    fieldText: Icons.TEXT_FIELD,
    fieldTextarea: Icons.TEXT_EDIT
  }

  @Emit()
  emitClickedItem(item: CustomFieldTypes) {
    return item
  }

  get isXsBreakpoint(): boolean {
    return this.$vuetify.breakpoint.xs
  }

  get breakpointClass(): string {
    return String(this.$vuetify.breakpoint.name)
  }

  get texts(): { title: TranslateResult } {
    return {
      title: this.$t('components.custom_field_type_selector.title')
    }
  }

  getIcon(id: number) {
    switch (Number(id)) {
      case customFieldTypesEnum.BOOLEAN:
        return this.icons.fieldBoolean
      case customFieldTypesEnum.CURRENCY:
        return this.icons.fieldCurrency
      case customFieldTypesEnum.DATE:
        return this.icons.fieldDate
      case customFieldTypesEnum.GROUP:
        return this.icons.fieldGroup
      case customFieldTypesEnum.LIST:
        return this.icons.fieldList
      case customFieldTypesEnum.NUMERIC:
        return this.icons.fieldNumeric
      case customFieldTypesEnum.TEXT:
        return this.icons.fieldText
      case customFieldTypesEnum.TEXTAREA:
        return this.icons.fieldTextarea
      default:
        return Icons.PROCESS_STEP
    }
  }

  iconsSizeInXsBreakpoint(): void {
    const items = this.$el.querySelectorAll('.item')
    items.forEach((item: any) => {
      item.style.width = this.isXsBreakpoint ? `${100 / this.items.length}%` : '100%'
    })
  }
}
</script>

<style lang="scss" scoped>
.custom-field-type-selector {
  display: flex;
  width: 280px;
  padding: $spacing-md;
  flex-direction: column;
  align-items: flex-start;
  gap: $spacing-xl;
  flex-shrink: 0;
  border-radius: $spacing-xxxs;
  background: $main-050;

  .row-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: $spacing-xs;

    .row-text {
      @include milano-bold-18;
      color: $main-1000;
    }
  }

  .fields-list {
    @include list;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-sm, 12px);
    align-self: stretch;

    .item {
      cursor: pointer;
      display: flex;
      height: $input-size;
      align-items: center;
      $justify-content: flex-start;
      gap: var(--Spacing-Nule, 0px);
      align-self: stretch;
      border-radius: var(--Corner-radius-SM, 4px);
      border: 1px solid var(--Main-1000, #001978);
      background: var(--Neutral-White-000, #fff);
      color: var(--Main-1000, #001978);
      text-align: center;

      &.xs-breakpoint {
        @include flex;
        text-align: center;

        .item-icon {
          padding-left: 0;
        }
      }

      .item-icon {
        font-size: 18px;
        padding-left: 8px;
      }

      .item-name {
        @include milano-bold-16;
        margin-left: $spacing-xs;
      }

      &:hover {
        background-color: $main-100;
      }
    }
  }

  &.xs {
    .fields-list {
      display: flex;
      flex-direction: row;
    }
  }

  &.sm {
    .item:first-of-type {
      display: flex;
      width: 100%;
    }

    .item:not(:first-of-type) {
      width: calc(50% - 20px);
      display: inline-flex;
    }

    .item:nth-child(even) {
      margin-right: 10px;
    }
  }
}
</style>
