import { Module } from 'vuex'
import { getters } from '@/store/modules/auth/authGetters'
import { actions } from '@/store/modules/auth/authActions'
import { mutations } from '@/store/modules/auth/authMutations'
import { AuthState } from '@/store/modules/auth/authTypes'
import { RootState } from '@/store/types/storeGlobalTypes'
import { getDataFromLocalStorage } from '@/helpers/helpers'

export const state: AuthState = {
  rememberCredentials: false,
  salt: process.env.VUE_APP_SALT,
  authData: getDataFromLocalStorage('auth-data') || null,
  preAuthData: getDataFromLocalStorage('pre-auth-data') || null,
  companyName: localStorage.getItem('company-name') || '',
  cancelTokens: [],
  encryptIdProUser: '',
  lastRefreshTokenTime: 0,
  tools: []
}

const namespaced: boolean = true

export const authModule: Module<AuthState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
