<template lang="pug">

  transition(name="fade" appear)
    div(
      v-if="showAlert && whereIsRendered === alert.componentWhereIsRendered"
      :class="[ getType, 'alert-container', { 'render-at-the-bottom': renderAtTheBottom, 'login-view': renderAtLoginView  } ]"
    )
      div(class="alert-text-container")
        span(:class="[ getIcon, 'alert-icon' ]")
        span(class="alert-text" v-html="alert.message")
      v-spacer
      div(class="alert-link-container")
        button(
            v-if="alert.acceptLink"
            class="alert-link"
            :title="alert.acceptLink.tooltip"
            @click="alert.acceptLink.callback"
          ) {{alert.acceptLink.text}}
        div(class="lf-icon-close close-icon" @click.stop="closeAlertAction")

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { AlertsData, AlertsIcons, ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'

const namespace: string = ModuleNamespaces.ALERTS

@Component
export default class MessageAlert extends Vue {
  @Prop({
    type: String,
    required: true
  })
  whereIsRendered!: string

  @Prop({
    type: Number
  })
  durationTime!: number

  @Getter('showAlert', { namespace }) showAlert: boolean
  @Getter('getAlertData', { namespace }) alert: AlertsData

  @Action('closeAlert', { namespace }) closeAlertAction: () => {}

  get getType(): string {
    return this.alert.type
  }

  get getIcon(): string {
    return AlertsIcons[this.alert.type]
  }

  get renderAtTheBottom() {
    return this.whereIsRendered === ComponentWhereIsRendered.TABS_COMPONENT
  }

  get renderAtLoginView() {
    return this.whereIsRendered === ComponentWhereIsRendered.LOGIN_VIEW
  }

  created() {
    this.closeAlert()
  }

  updated() {
    this.closeAlert()
  }

  closeAlert() {
    if (this.durationTime) {
      setTimeout(() => {
        this.closeAlertAction()
      }, this.durationTime)
    }
  }
}
</script>

<style lang="scss" scoped>
@include fade-transition;

.alert-container {
  @include flex($justify-content: space-between, $align-items: center);
  width: 100%;
  color: $neutral-grey-800;
  padding: $spacing-xs $spacing-sm;
  border-radius: $cornerRadius-sm;
  box-shadow: 0px 0px 7px 0px rgba(86, 86, 86, 0.5);
  z-index: 99;
  gap: $spacing-xs;

  &.render-at-the-bottom {
    position: inherit;
  }

  &:not(.render-at-the-bottom) {
    position: absolute;
    top: 0;
  }

  &.login-view {
    width: 65%;
    height: auto;
    top: 9%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    .close-icon {
      align-self: start;
      margin-top: 16px;
    }

    .alert-text-container {
      align-items: flex-start;
      padding: 14px 0;

      .alert-icon {
        font-size: 20px;
      }

      .alert-text {
        margin: 0 20px;
      }

      .alert-subtext {
        margin: 0 20px;
      }
    }
  }

  &.info-text {
    background-color: $tertiary-byzantineBlue-050;
  }

  &.error-text {
    background-color: $tertiary-persianRed-050;
  }

  &.success-text {
    background-color: $tertiary-seaGreen-050;
  }

  &.warning-text {
    background-color: $tertiary-saffron-050;
  }

  .alert-icon {
    font-size: 32px;
  }

  .lf-icon-close-round-full {
    color: $tertiary-persianRed-200;
  }
  .lf-icon-warning-full {
    color: $tertiary-saffron-200;
  }
  .lf-icon-check-round-full {
    color: $tertiary-seaGreen-200;
  }
  .lf-icon-info-full {
    color: $tertiary-byzantineBlue-200;
  }

  .alert-text-container {
    @include flex($justify-content: left-start, $flex-direction: row, $align-items: center);
    gap: 8px;
    .alert-text {
      font-family: $corporate-font-bold;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;

      ::v-deep a {
        font-family: $corporate-font;
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }

  .alert-link-container {
    @include flex($justify-content: left-start, $flex-direction: row, $align-items: center);
    font-family: $corporate-font-medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: $tertiary-byzantineBlue-200;
    gap: 8px;

    .close-icon {
      font-size: 16px;
      justify-self: end;
      cursor: pointer;
      border-radius: $cornerRadius-xs;
      width: $spacing-md;
      height: $spacing-md;
      flex-shrink: 0;
      color: $main-1000;
    }
  }
}
</style>
