<template lang="pug">

div(class="actions-container")
  button(
    :class="[icons.arrowDown, 'arrow', { disabled: index + 1 >= fieldsNumber }]"
    @click.stop="index + 1 >= fieldsNumber ?  false : moveDown(index)"
  )
  button(
    :class="[icons.arrowUp, 'arrow', { disabled: index === 0 }]"
    @click.stop="index === 0 ? false : moveUp(index)"
  )
  button(
    :class="[icons.remove, { disabled: disableDelete }]"
    @click.stop="removeField(index)"
  )
  button(
    v-if="item.idCustomFieldType !== numerationCode"
    :class="[icons.configuration]"
    @click.stop="editField(item, index)"
  )

</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { Icons } from '@/icons/icons'
import { NumerationPatternTypes } from '@/components/forms/fields/LexonNumeration/types/LexonNumerationComponentTypes'
import { CustomField, customFieldTypesEnum } from '@/store/modules/customFields/customFieldsTypes'

@Component
export default class CustomFieldActionButtonsComponent extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  index!: number

  @Prop({
    type: Object,
    required: true
  })
  item!: CustomField

  @Prop({
    type: Number,
    required: true
  })
  fieldsNumber!: number

  get numerationCode() {
    return customFieldTypesEnum.NUMERATION
  }

  get disableDelete() {
    return this.item.idNumerationType && this.item.idNumerationType === NumerationPatternTypes.CODE
  }

  icons = {
    arrowDown: Icons.ARROW_DOWN,
    arrowUp: Icons.ARROW_UP,
    configuration: Icons.EDIT,
    remove: Icons.REMOVE
  }

  @Emit()
  moveUp(index: number) {
    return index
  }

  @Emit()
  moveDown(index: number) {
    return index
  }

  @Emit()
  removeField(index: number) {
    return index
  }

  @Emit()
  editField(item: CustomField, index: number) {
    return { index, item }
  }
}
</script>

<style scoped lang="scss" scoped>
.actions-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-start;
  gap: $spacing-xs;
  margin-left: $spacing-xl;

  button {
    width: 32px;
    height: 32px;
    display: flex;
    padding: $spacing-xxs;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: $cornerRadius-sm;
    border: 1px solid $main-1000;
    background-color: $neutral-white-000;
  }

  .arrow {
    &:not(.disabled):hover {
      background-color: $main-100;
    }

    &.disabled {
      cursor: inherit;
    }
  }

  button.disabled {
    color: $blue-05;
  }
}
</style>
