import { computed, ComputedRef, Ref } from 'vue'
import { GridComponent as EjsGrid } from '@syncfusion/ej2-vue-grids'

interface PropsUseGridFilters {
  gridRef: Ref<InstanceType<typeof EjsGrid> | null>
}

interface ResponseUseGridFilters {
  filters: any
  hasFilters: ComputedRef<boolean>
  removeFilterByField: (field: string) => void
  clearFilter: () => void
}

export default function useGridFilters(props: PropsUseGridFilters): ResponseUseGridFilters {
  const { gridRef } = props

  const gridInstance = computed(() => (gridRef.value as any)?.ej2Instances)

  const clearFilter = () => {
    if (gridRef.value) {
      ;(gridRef.value as any).clearFiltering()
    }
  }

  const hasFilters = computed((): boolean => {
    return Boolean(filters.value.length)
  })

  const filters = computed(() => {
    if (gridRef.value) {
      const { columns } = (gridInstance.value as any)?.filterSettings
      return columns
    }
    return []
  })

  const removeFilterByField = (field: string) => {
    console.log({field, filters, gridRef, gridInstance})
    const filterResponse = filters.value.filter((item: any) => item.field !== field )
    console.log({filterResponse})
    ;(gridRef.value as any).filterSettings = filterResponse

  }

  return {
    removeFilterByField,
    hasFilters,
    filters,
    clearFilter
  }
}
