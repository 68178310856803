import { Icons } from '@/icons/icons'
import { i18n } from '@/plugins/vue-i18n'
import { URLS } from '@/router/routes/urlRoutes'

export interface Action {
  actionId: string
  subject: string
  parentId: string | null
  childCount: string
  appointmentId: string | null
  typeId: ActionTypes
  type: string
  startEPOCH: string | null
  endEPOCH: string | null
  expirationDateEPOCH: string | null
  state: string | null
  idExpedient: string | null
}

export interface RelatedAction {
  actionId: string
  economicDataId: string
  dateTimeAt: string
  duration: string
  economicEstimate: string
  subject: string
  type: string
  invoiced: boolean
}

export interface RelatedActionSummary {
  duration: string
  economicEstimate: string
}

export interface RelatedActionResponse {
  actionList: RelatedAction []
  summary: RelatedActionSummary
}

export interface File {
  canEdit: number
  fileId: number
  idOwnFile: string
}

export enum ActionMenu {
  ALL = 'all',
  CALLS = 'calls',
  EMAILS = 'emails',
  MEETINGS = 'meetings',
  OTHERS = 'others',
  LEXNET = 'lexnet',
  PROCEDURES = 'procedures'
}

export enum ActionBasePath {
  ALL = 'documentos/actuaciones',
  CALLS = 'documentos/actuaciones/llamadas',
  EMAILS = 'documentos/actuaciones/emails',
  MEETINGS = 'documentos/actuaciones/reuniones',
  LEXNET = 'documentos/actuaciones/lexnet',
  OTHERS = 'documentos/actuaciones/otras',
  PROCEDURES = 'documentos/actuaciones/tramites',
  INTERNAL_MANAGEMENT = 'documentos/actuaciones/internas',
  TASK = 'documentos/actuaciones/tareas'
}

export enum ActionTypes {
  ALL = 'ALL',
  CALLS = 'LLAM',
  EMAILS = 'EMAIL',
  INTERNAL_MANAGEMENT = 'GEST',
  LEXNET = 'LEXN',
  MEETINGS = 'REUN',
  OTHERS = 'OTRA',
  PROCEDURES = 'TRAM',
  TASK = 'TARE'
}

export const objectActionTypes = [
  {
    id: 1,
    status: i18n.t('components.lexon_kanban.columns.planned')
  },
  {
    id: 2,
    status: i18n.t('components.lexon_kanban.columns.initiated')
  },
  {
    id: 3,
    status: i18n.t('components.lexon_kanban.columns.out_of_time')
  },
  {
    id: 4,
    status: i18n.t('components.lexon_kanban.columns.done')
  },
  {
    id: 5,
    status: i18n.t('components.lexon_kanban.columns.cancelled')
  },
  {
    id: 6,
    status: i18n.t('components.lexon_kanban.columns.pending_resolution')
  }
]

export enum ActionRelationTypes {
  CUSTOMERS = 'CLI',
  EXPEDIENT = 'EXP'
}

export const actionClassificationTypes = {
  CLI: 'customers',
  NOT: 'notaries',
  CON: 'opponents',
  ABOC: 'opponent_lawyers',
  PROC: 'own_attorneys',
  PROCC: 'opponent_attorneys',
  OTR: 'others',
  ABO: 'own_lawyers',
  EXP: 'expedients',
  JUZ: 'courts'
}

export interface ActionsState {
  action: object | null
  parentAction: Action | null
  actions: ListResult[] | null
  selectedActionType: string
  chronometerNewActionInfo: ChronometerNewActionInfo
}

export interface ListResult {
  data: object
  pagination: ListPagination
}

export interface ListPagination {
  numPage: number
  totalRecs: number
}

export interface ChronometerNewActionInfo {
  start: string
  end: string
  duration: number
}

export interface MoveExpedientActionRequest {
  fromExpedientId: number
  toExpedientId: number
  actionIdLot: number[]
}

export interface AssociateEconomicDataToActionRequest {
  actionIdLot: number[],
  economicDataId: number
}

export const actionsMutationTypes = {
  FETCH_ACTION: 'FETCH_ACTION',
  FETCH_PARENT_ACTION: 'FETCH_PARENT_ACTION',
  FETCH_ACTIONS: 'FETCH_ACTIONS',
  FETCH_ACTION_RELATIONS: 'FETCH_ACTION_RELATIONS',
  RESET_ACTIONS: 'RESET_ACTIONS',
  RESET_PARENT_ACTION: 'RESET_PARENT_ACTION',
  RESET_CHRONOMETER_NEW_ACTION_INFO: 'RESET_CHRONOMETER_NEW_ACTION_INFO',
  SAVE_SELECTED_ACTION_TYPE: 'SAVE_SELECTED_ACTION_TYPE',
  SET_CHRONOMETER_NEW_ACTION_INFO: 'SET_CHRONOMETER_NEW_ACTION_INFO'
}

export const setBasePathByActionType = (actionType: string) => {
  switch (actionType) {
    case ActionTypes.CALLS:
      return ActionBasePath.CALLS
    case ActionTypes.EMAILS:
      return ActionBasePath.EMAILS
    case ActionTypes.MEETINGS:
      return ActionBasePath.MEETINGS
    case ActionTypes.LEXNET:
      return ActionBasePath.LEXNET
    case ActionTypes.OTHERS:
      return ActionBasePath.OTHERS
    case ActionTypes.PROCEDURES:
      return ActionBasePath.PROCEDURES
    case ActionTypes.INTERNAL_MANAGEMENT:
      return ActionBasePath.INTERNAL_MANAGEMENT
    case ActionTypes.TASK:
      return ActionBasePath.TASK
  }
}

export const getActionRouteName = (actionType: string) => {
  switch (actionType) {
    case ActionTypes.CALLS:
      return `${URLS.ACTIONS}-${URLS.CALLS}`
    case ActionTypes.MEETINGS:
      return `${URLS.ACTIONS}-${URLS.MEETINGS}`
    case ActionTypes.PROCEDURES:
      return `${URLS.ACTIONS}-${URLS.PROCEDURES}`
    case ActionTypes.OTHERS:
      return `${URLS.ACTIONS}-${URLS.ACTION_OTHERS}`
    case ActionTypes.LEXNET:
      return `${URLS.ACTIONS}-${URLS.ACTION_LEXNET}`
    case ActionTypes.EMAILS:
      return `${URLS.ACTIONS}-${URLS.ACTION_EMAILS}`
    case ActionTypes.INTERNAL_MANAGEMENT:
      return `${URLS.ACTIONS}-${URLS.ACTION_INTERNAL_MANAGEMENT}`
    case ActionTypes.TASK:
      return `${URLS.ACTIONS}-${URLS.ACTION_TASKS}`
  }
}

export const getActionRouteNameByAction = (typeId: Exclude<ActionTypes, ActionTypes.ALL>) => {
  const routes = {
    [ActionTypes.CALLS]: `${URLS.ACTIONS}-${URLS.CALLS}`,
    [ActionTypes.MEETINGS]: `${URLS.ACTIONS}-${URLS.MEETINGS}`,
    [ActionTypes.PROCEDURES]: `${URLS.ACTIONS}-${URLS.PROCEDURES}`,
    [ActionTypes.OTHERS]: `${URLS.ACTIONS}-${URLS.ACTION_OTHERS}`,
    [ActionTypes.LEXNET]: `${URLS.ACTIONS}-${URLS.ACTION_LEXNET}`,
    [ActionTypes.EMAILS]: `${URLS.ACTIONS}-${URLS.ACTION_EMAILS}`,
    [ActionTypes.INTERNAL_MANAGEMENT]: `${URLS.ACTIONS}-${URLS.ACTION_INTERNAL_MANAGEMENT}`,
    [ActionTypes.TASK]: `${ URLS.ACTIONS }-${ URLS.ACTION_TASKS }`,
  }
  return (routes as any)[typeId]
}

export const checkActionIcon = (type: string) => {
  const icons = {
    [ActionTypes.CALLS.toString()]: Icons.CALL,
    [ActionTypes.LEXNET.toString()]: Icons.LEXNET,
    [ActionTypes.MEETINGS.toString()]: Icons.MEETING,
    [ActionTypes.PROCEDURES.toString()]: Icons.FORMALITY,
    [ActionTypes.OTHERS.toString()]: Icons.OTHERS_F,
    [ActionTypes.EMAILS.toString()]: Icons.EMAIL,
    [ActionTypes.INTERNAL_MANAGEMENT.toString()]: Icons.INTERNAL_MANAGEMENT,
    [ActionTypes.TASK.toString()]: Icons.TASK,
    ['default']: Icons.OTHERS_F
  }
  return icons[type]
}

export const checkActionText = (value: string) => {
  switch (value) {
    case ActionTypes.OTHERS:
      return 'components.dashboard_list.other'
    case ActionTypes.EMAILS:
      return 'components.dashboard_list.email'
    case ActionTypes.PROCEDURES:
      return 'components.dashboard_list.formality'
    case ActionTypes.LEXNET:
      return 'components.dashboard_list.lexnet'
    case ActionTypes.MEETINGS:
      return 'components.dashboard_list.meeting'
    case ActionTypes.CALLS:
      return 'components.dashboard_list.call'
    case ActionTypes.INTERNAL_MANAGEMENT:
      return 'components.dashboard_list.internal_management'
    case ActionTypes.TASK:
      return 'components.dashboard_list.task'
    default:
      return value
  }
}

export enum ActionClassificationFields {
  ACTION_ID_TYPE = 'idActionType',
  OTHER_INTERVENER = 'idOtherCategory'
}

export const OTHER_INTERVENER_TYPE = 'OTR'

export enum ActionAliasEnum {
  CALLS = 'calls',
  MEETING = 'meetings',
  PROCEDURE = 'procedures',
  EMAIL = 'emails',
  LEXNET = 'lexnet',
  OTHER = 'actions_others',
  TASK = 'task',
  INTERNAL_MANAGEMENT = 'internal-management'
}

export const ActionNewTitleByAlias: { [key in ActionAliasEnum]: string } = {
  [ActionAliasEnum.CALLS]: i18n.t('forms.actions.new_call_title').toString(),
  [ActionAliasEnum.MEETING]: i18n.t('forms.actions.new_meeting_title').toString(),
  [ActionAliasEnum.PROCEDURE]: i18n.t('forms.actions.new_procedure_title').toString(),
  [ActionAliasEnum.EMAIL]: i18n.t('forms.actions.new_email_title').toString(),
  [ActionAliasEnum.LEXNET]: i18n.t('forms.actions.new_lexnet_title').toString(),
  [ActionAliasEnum.OTHER]: i18n.t('forms.actions.new_other_title').toString(),
  [ActionAliasEnum.TASK]: i18n.t('forms.actions.new_task_title').toString(),
  [ActionAliasEnum.INTERNAL_MANAGEMENT]: i18n.t('forms.actions.new_internal_management_title').toString(),
}
