<template lang="pug">

WidgetBaseComponent(
  class="last-actions-widget"
  :loadedData="allDataLoaded && !showSpinner"
)

  //- HEADER
  template(v-slot:header)
    div(class="header-container")
      h2 {{ widgetData.title }}
      span(:class="[openWindowIcon, 'lf-icon__md', 'header-icon']" @click.stop="goToActions")

  //- BODY
  template(v-slot:body)
    section(class="body-container element-with-scroll")

      table(v-if="widgetData.body.cells.length" class="last-actions-table")
        thead
          tr
            th(
              v-for="(header, headerIndex) in widgetData.body.headers"
              :key="headerIndex"
            ) {{ header }}

        tbody
          tr(
            v-if="widgetData.body.cells.length !== 0"
            v-for="(row, rowIndex) in widgetData.body.cells"
            :key="rowIndex"
            @click="openActionInNewWindow(row.idType, row.idAction)"
          )
            td(
              v-for="(cell, cellIndex) in widgetData.body.headers"
              :key="cellIndex"
            )
              span(
                v-if="cellIndex === 0"
                :class="[checkActionIconHelper(row['idType']), 'table-icon']")
              span {{ Object.values(row)[cellIndex + 2] }}

      //- Last actions table empty
      div(v-else class="empty-table") {{ emptyTableText }}

</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import WidgetBaseComponent from '@/components/widgets/WidgetBaseComponent.vue'
import { Icons } from '@/icons/icons'
import { Action, Getter } from 'vuex-class'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { TranslateResult } from 'vue-i18n'
import { ActionTypes, checkActionIcon } from '@/store/modules/actions/actionsTypes'
import { WidgetData } from '@/components/widgets/ExpedientWidgets/ExpedientLastActions/ExpedientLastActionsWidgetTypes'
import { URLS } from '@/router/routes/urlRoutes'

const expedientsModule = ModuleNamespaces.EXPEDIENTS
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    WidgetBaseComponent
  }
})
export default class ExpedientLastActionsWidget extends Vue {
  @Action('fetchExpedientLastActionsWidgetData', { namespace: expedientsModule })
  fetchExpedientLastActionsWidgetDataAction: ({}) => WidgetData

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterIdGetter: (context: string) => number

  @Getter('getExpedientStageState', { namespace: expedientsModule })
  expedientStageStateGetter: string

  emptyTableText: TranslateResult = this.$t('components.widgets.expedient_last_actions.empty_table_text')

  openWindowIcon = Icons.OPEN_WINDOW

  checkActionIconHelper = checkActionIcon

  widgetData: WidgetData = {
    title: '',
    body: {
      headers: [],
      cells: []
    },
    footer: {
      barData: [],
      total: {
        label: '',
        totalNumber: 0
      }
    }
  }

  contexts = {
    lastActions: ContextName.EXPEDIENT_LAST_ACTIONS_WIDGET,
    expedientContext: ContextName.EXPEDIENTS
  }

  showSpinner = true

  get title() {
    return this.widgetData.title
  }

  get allDataLoaded() {
    return !!this.widgetData.title
  }

  get total() {
    const { label, totalNumber } = this.widgetData.footer.total
    return `${label}: ${totalNumber}`
  }

  get selectedExpedientId() {
    return this.selectedRegisterIdGetter(this.contexts.expedientContext)
  }

  get isBreakpointXs(): boolean {
    return this.$vuetify.breakpoint.xs
  }

  get renderTotalLabel(): string {
    const totalLabel = this.widgetData.footer.total.label
    return this.isBreakpointXs ? totalLabel.substring(0, 5) : totalLabel
  }

  @Watch('expedientStageStateGetter')
  updateData() {
    this.fetchLastActionsData()
  }

  created() {
    this.fetchLastActionsData()
  }

  async fetchLastActionsData() {
    this.showSpinner = true

    this.widgetData = await this.fetchExpedientLastActionsWidgetDataAction({
      selectedExpedientId: this.selectedExpedientId,
      filter: {
        source: this.contexts.lastActions,
        idStage: this.expedientStageStateGetter
      }
    })

    this.showSpinner = false
  }

  async openActionInNewWindow(actionType: ActionTypes, selectedActionId: string) {
    let routeData: {} | null = null

    switch (actionType) {
      case ActionTypes.CALLS:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.CALLS}`,
          params: { selectedRegisterId: selectedActionId }
        })
        break
      case ActionTypes.MEETINGS:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.MEETINGS}`,
          params: { selectedRegisterId: selectedActionId }
        })
        break
      case ActionTypes.PROCEDURES:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.PROCEDURES}`,
          params: { selectedRegisterId: selectedActionId }
        })
        break
      case ActionTypes.OTHERS:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.ACTION_OTHERS}`,
          params: { selectedRegisterId: selectedActionId }
        })
        break
      case ActionTypes.LEXNET:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.ACTION_LEXNET}`,
          params: { selectedRegisterId: selectedActionId }
        })
        break
      case ActionTypes.EMAILS:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.ACTION_EMAILS}`,
          params: { selectedRegisterId: selectedActionId }
        })
        break
      case ActionTypes.INTERNAL_MANAGEMENT:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.ACTION_INTERNAL_MANAGEMENT}`,
          params: { selectedRegisterId: selectedActionId }
        })
        break
      case ActionTypes.TASK:
        routeData = this.$router.resolve({
          name: `${URLS.ACTIONS}-${URLS.ACTION_TASKS}`,
          params: { selectedRegisterId: selectedActionId }
        })
        break
    }

    window.open((routeData as any).href, '_blank')
  }

  goToActions() {
    this.$router.push({
      name: URLS.ACTION_ALL
    })
  }
}
</script>

<style lang="scss" scoped>
.last-actions-widget {
  display: flex;
  height: 366px;
  padding: $spacing-sm;
  flex-direction: column;
  align-items: center;
  border-radius: $cornerRadius-md;
  border: 1px solid $main-300;

  .header-container {
    display: flex;
    width: 100%;
    height: 40px;
    padding: $spacing-xxs 0px;
    justify-content: space-between;
    border-bottom: 1px solid $neutral-grey-400;

    .header-icon {
      color: $main-1000;
      cursor: pointer;
    }
  }

  .body-container {
    @include flex($flex-direction: column, $justify-content: flex-start);
    @include scroll-styles;
    width: 100%;
    height: 100%;

    .last-actions-table {
      width: 100%;
      height: 100%;
      font-family: $secondary-font;
      border-collapse: collapse;

      th {
        @include milano-bold-14;
        @include ellipsis;
        color: $main-1000;
        padding: $spacing-xs;
      }

      td,
      th {
        height: 38px;
      }

      td {
        @include ellipsis;
        @include arial-regular-12;
        padding: $spacing-xs;
      }

      tr {
        cursor: pointer;

        &:hover {
          background-color: $main-050;
        }
      }

      td:nth-child(2) {
        @include ellipsis;
        max-width: 300px;
        color: $corporate-color;
        font-weight: bold;
      }

      thead {
        text-align: left;
        border-bottom: 2px solid $main-1000;

        tr:hover {
          background-color: transparent;
        }

        th {
          padding-bottom: 4px;
          font-size: 14px;
        }
      }

      .table-icon {
        font-size: 16px;
        margin-right: $spacing-xxs;
      }
    }

    .empty-table {
      @include milano-regular-16;
      display: flex;
      width: 100%;
      padding: 14px $spacing-xxs 28px $spacing-xxs;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid $main-200;
      color: $neutral-grey-700;
      text-align: center;
    }
  }
}
</style>
