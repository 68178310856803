<template>
  <div>
    <Grid
      v-if="gridConfiguration"
      :gridConfiguration="gridConfiguration"
      :itemData="provisions"
      :contextMenuItems="contextMenuItems"
      :commandClick="onCommandClick"
      @rowClick="onRowClick"
      @contextMenuClicked="onContextMenuClicked"
    ></Grid>
  </div>
</template>
<script lang="ts" setup>
import Grid from '@/components/grids/LfGrid/LfGridComponent.vue'
import useGridConfiguration from '@/composables/useGridConfiguration'
import useContactProvisions from './composables/useContactProvisions'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { computed, onMounted } from 'vue'
import {
  CommandClickEventArgs,
  CommandColumnModel,
  ContextMenuClickEventArgs,
  ContextMenuItemModel,
  RecordClickEventArgs
} from '@syncfusion/ej2-vue-grids'
import { Icons } from '@/icons/icons'
import { useI18n } from 'vue-i18n-composable'
import { ActionName } from '@/components/grids/LfGrid/LfGridTypes'

const { t } = useI18n()

// DEFINE PROPS  ======================================================================================================
const props = defineProps({
  listName: {
    type: String,
    required: true
  },
  context: {
    type: String,
    default: 'contactProvisions'
  }
})

const texts = {
  contextMenuItems: {
    edit: t('action_buttons.edit').toString(),
    open_new_tab: t('action_buttons.open_new_window').toString()
  }
}
const commandsBts: CommandColumnModel[] = [
  {
    title: t('action_buttons.open_new_window').toString(),
    type: 'None',
    buttonOption: {
      iconCss: Icons.OPEN_WINDOW,
      cssClass: 'custombutton'
    }
  }
]
// COMPOSABLES   ======================================================================================================
const { gridConfiguration } = useGridConfiguration({
  listName: props.listName,
  context: props.context,
  commandButtons: commandsBts
})

const { provisions, read, open } = useContactProvisions({
  listName: 'listContactProvisions'
})

// STORE ACTIONS ======================================================================================================

// STORE GETTERS ======================================================================================================
const selectedEntityName = computed(() => store.getters[`${ModuleNamespaces.ENTITIES}/getSelectedEntityName`])

const selectedRegisterId = computed(() =>
  store.getters[`${ModuleNamespaces.SELECTED_REGISTER}/getSelectedRegisterId`](selectedEntityName.value).toString()
)

// COMPUTEDS ==========================================================================================================

const contextMenuItems = computed((): ContextMenuItemModel[] => {
  return [
    {
      id: ActionName.EDIT,
      text: texts.contextMenuItems.edit,
      iconCss: Icons.EDIT
    },
    {
      id: ActionName.OPEN_NEW_TAB,
      text: texts.contextMenuItems.open_new_tab,
      iconCss: Icons.OPEN_WINDOW
    }
  ]
})
// EVENTS =============================================================================================================
const onRowClick = (args: RecordClickEventArgs) => {
  const { rowData } = args
  const { id } = rowData as any
  open(id)
}

const onCommandClick = (args: CommandClickEventArgs) => {
  const { rowData } = args
  const { id } = rowData as any
  open(id, '_blank')
}

const onContextMenuClicked = ({ rowInfo, item }: ContextMenuClickEventArgs) => {
  const { rowData } = rowInfo as any
  const { id } = item
  if (id === ActionName.EDIT) {
    open(rowData.id)
  } else if (id === ActionName.OPEN_NEW_TAB) {
    open(rowData.id, '_blank')
  }
}
// METHODS ============================================================================================================

// HOOKS =============================================================================================================
onMounted(async () => {
  await read(selectedRegisterId.value)
})
</script>
